
export const ADD_MACHINE_QC="ADD_MACHINE_QC";
export const ADD_MACHINE_QC_SUCCESS="ADD_MACHINE_QC_SUCCESS";
export const ADD_MACHINE_QC_FAIL="ADD_MACHINE_QC_FAIL";

export const DELETE_MACHINE_QC="DELETE_MACHINE_QC";
export const DELETE_MACHINE_QC_SUCCESS="DELETE_MACHINE_QC_SUCCESS";
export const DELETE_MACHINE_QC_FAIL="DELETE_MACHINE_QC_FAIL";

export const UPDATE_MACHINE_QC="UPDATE_MACHINE_QC";
export const UPDATE_MACHINE_QC_SUCCESS="UPDATE_MACHINE_QC_SUCCESS";
export const UPDATE_MACHINE_QC_FAIL="UPDATE_MACHINE_QC_FAIL";