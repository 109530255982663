import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, Table, Spinner } from "reactstrap";
import { CustomButton, showToast } from "components/Common";
import ExcelJS from "exceljs";
import moment from "moment";

const ImportExcelModal = ({ modal, toggle, onSave }) => {
  const [tableData, setTableData] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleFileUpload = async event => {
    setIsUploading(true);
    const file = event.target.files[0];
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(await file.arrayBuffer());
    const worksheet = workbook.getWorksheet(1);
    const jsonData = [];

    worksheet.eachRow((row, rowIndex) => {
      if (rowIndex === 1) return;
      const rowData = {};
      row.eachCell((cell, colIndex) => {
        const header = worksheet.getRow(1).getCell(colIndex).value?.trim();
        rowData[header] = cell.value;
      });
      jsonData.push(rowData);
    });

    const requiredFields = [
      "Mã lô QC",
      "Lô QC",
      "Hạn sử dụng",
      "Hãng sản xuất",
      "Máy XN",
      "Số Mức",
      "CN xét nghiệm",
      "Nhóm lô QC",
    ];

    const filteredData = [];
    const invalidRows = [];
    const uniqueRecords = new Map();

    jsonData.forEach((row, index) => {
      const missingFields = requiredFields.filter(field => !row[field]);
      if (missingFields.length > 0) {
        invalidRows.push({ rowIndex: index + 2, missingFields });
      } else {
        const uniqueKey = row["Mã lô QC"];
        if (!uniqueRecords.has(uniqueKey)) {
          uniqueRecords.set(uniqueKey, row);
          filteredData.push(row);
        }
      }
    });

    if (invalidRows.length > 0) {
      invalidRows.forEach(({ rowIndex, missingFields }) => {
        showToast(
          `Row ${rowIndex} is missing the following required fields: ${missingFields.join(", ")}`,
          true
        );
      });
    }

    setTableData(filteredData);
    setIsUploading(false);
  };

  const handleSave = async () => {
    setIsSaving(true);
    const columnMapping = {
      "Mã lô QC": "LotId",
      "Lô QC": "LotName",
      "Hạn sử dụng": "ExpireDate",
      "Hãng sản xuất": "Manufactor",
      "Máy XN": "InstrumentId",
      "Số Mức": "Levels",
      "CN xét nghiệm": "TestCategory",
      "Nhóm lô QC": "LotGroup",
      "Số điểm áp dụng NSX": "NoOfPointPI",
      "Số lần chạy tối đa": "NoOfRun",
      "Số diểm tự tính toán": "NoOfPointCal",
      "Số ngày mở tối đa": "MaxDayOpen",
      "Ngày mở": "MaxOpenDate",
    };

    const requiredFields = [
      "LotId",
      "LotName",
      "ExpireDate",
      "Manufactor",
      "InstrumentId",
      "Levels",
      "TestCategory",
      "LotGroup",
    ];

    const validatedData = [];
    const invalidRows = [];
    tableData.forEach((row, rowIndex) => {
      const transformedRow = {};
      let isValid = true;

      Object.keys(row).forEach(key => {
        const mappedKey = columnMapping[key];
        if (mappedKey) {
          transformedRow[mappedKey] = row[key];
        }
      });

      requiredFields.forEach(field => {
        if (!transformedRow[field]) {
          isValid = false;
        }
      });

      if (isValid) {
        validatedData.push({
          ...transformedRow,
          ExpireDate: transformedRow.ExpireDate
            ? moment(transformedRow.ExpireDate, "DD-MM-YYYY").isValid()
              ? moment(transformedRow.ExpireDate, "DD-MM-YYYY").utc().toISOString()
              : null
            : null,
          MaxOpenDate: transformedRow.MaxOpenDate
            ? moment(transformedRow.MaxOpenDate, "DD-MM-YYYY").isValid()
              ? moment(transformedRow.MaxOpenDate, "DD-MM-YYYY").utc().toISOString()
              : null
            : null,
          InstrumentId: typeof transformedRow.InstrumentId === "string"
            ? transformedRow.InstrumentId.split(",").map(id => Number(id.trim()))
            : Array.isArray(transformedRow.InstrumentId)
            ? transformedRow.InstrumentId.map(Number)
            : transformedRow.InstrumentId
            ? [Number(transformedRow.InstrumentId)]
            : [],
        });
      } else {
        invalidRows.push(rowIndex + 1);
      }
    });

    if (invalidRows.length > 0) {
      const errorMessage = `Các hàng sau đang thiếu các trường bắt buộc: ${invalidRows.join(", ")}.`;
      alert(errorMessage);
    }

    await onSave(validatedData);
    setIsSaving(false);
    toggle();
    setTableData([]);
  };

  const handleToggle = () => {
    toggle();
    setTableData([]);
  };

  const handleDownloadTemplate = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Mẫu");

    const headers = [
      "Mã lô QC",
      "Lô QC",
      "Hạn sử dụng",
      "Hãng sản xuất",
      "Máy XN",
      "Số Mức",
      "CN xét nghiệm",
      "Nhóm lô QC",
      "Số điểm áp dụng NSX",
      "Số lần chạy tối đa",
      "Số diểm tự tính toán",
      "Số ngày mở tối đa",
      "Ngày mở",
    ];

    worksheet.addRow(headers);

    const sampleData = [
      "12345",
      "Lô QC 1",
      "27-03-2025",
      "Nhà Sản Xuất A",
      "1,2,3",
      "1",
      "DM",
      "Nhóm 1",
      "5",
      "10",
      "3",
      "30",
      "27-03-2025",
    ];

    worksheet.addRow(sampleData);

    const columnWidths = [
      15,
      20,
      15,
      25,
      20,
      10,
      20,
      20,
      20,
      20,
      20,
      20,
      15,
    ];

    worksheet.columns = columnWidths.map((width, index) => ({
      key: headers[index],
      width,
    }));

    worksheet.getRow(1).eachCell(cell => {
      cell.font = { bold: true };
      cell.alignment = { horizontal: "center" };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.getRow(2).eachCell(cell => {
      cell.alignment = { horizontal: "center" };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "Mẫu_Lô_QC.xlsx";
    link.click();
  };

  const styles = {
    modal: {
      maxWidth: "70%",
      margin: "auto",
      borderRadius: "10px",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
    },
    modalBody: {
      backgroundColor: "#f9f9f9",
      padding: "20px",
      borderRadius: "10px",
    },
    modalFooter: {
      backgroundColor: "#f1f1f1",
      borderTop: "1px solid #ddd",
      padding: "15px",
      display: "flex",
      justifyContent: "flex-end",
      gap: "10px",
    },
    tableContainer: {
      overflowX: "auto",
      overflowY: "auto",
      maxHeight: "400px",
      border: "1px solid #ddd",
      borderRadius: "4px",
      backgroundColor: "#fff",
      padding: "0px 10px 10px 10px",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
      tableLayout: "auto",
    },
    tableHeader: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      minWidth: "150px",
      maxWidth: "300px",
      textAlign: "center",
      padding: "8px",
      border: "1px solid #ddd",
      fontWeight: "bold",
      backgroundColor: "#f1f1f1",
      position: "sticky",
      top: "0px",
      zIndex: 2,
    },
    tableCell: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      minWidth: "150px",
      maxWidth: "300px",
      textAlign: "center",
      padding: "8px",
      border: "1px solid #ddd",
    },
  };

  return (
    <Modal isOpen={modal} toggle={handleToggle} size="xl" style={styles.modal}>
      <ModalBody style={styles.modalBody}>
        {isUploading && (
          <div className="text-center mb-3">
            <Spinner color="primary" />
            <p>Đang tải...</p>
          </div>
        )}
        {isSaving && (
          <div className="text-center mb-3">
            <Spinner color="primary" />
            <p>Đang lưu...</p>
          </div>
        )}
        <div className="d-flex justify-content-between align-items-center mb-3" style={{ gap: "10px" }}>
          <div style={{ position: "relative", display: "inline-block" }}>
            <input
              type="file"
              accept=".xlsx"
              onChange={handleFileUpload}
              style={{
                position: "absolute",
                opacity: 0,
                width: "100%",
                height: "100%",
                cursor: "pointer",
              }}
            />
            <span
              style={{
                display: "inline-block",
                padding: "10px 20px",
                background: "linear-gradient(90deg, #007bff, #0056b3)",
                color: "#fff",
                borderRadius: "4px",
                cursor: "pointer",
                transition: "all 0.3s ease",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              {isUploading ? "Uploading..." : "Choose File"}
            </span>
          </div>
          <CustomButton
            text="Download Template"
            color="info"
            onClick={handleDownloadTemplate}
            style={{
              background: "linear-gradient(90deg, #17a2b8, #117a8b)",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              padding: "10px 20px",
              transition: "all 0.3s ease",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          />
        </div>
        <div style={styles.tableContainer}>
          {tableData.length > 0 ? (
            <Table bordered style={styles.table}>
              <thead>
                <tr>
                  <th style={styles.tableHeader}>#</th>
                  {tableData[0] &&
                    Object.keys(tableData[0]).map((key, index) => (
                      <th key={index} style={styles.tableHeader} title={key}>
                        {key}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td style={styles.tableCell}>{rowIndex + 1}</td>
                    {Object.keys(tableData[0]).map((key, colIndex) => (
                      <td key={colIndex} style={styles.tableCell} title={row[key] !== undefined ? row[key] : "-"}>
                        {row[key] !== undefined ? row[key] : "-"}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div style={{ textAlign: "center", color: "#888", fontSize: "16px", padding: "20px" }}>
              Không có dữ liệu hiển thị
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter style={styles.modalFooter}>
        <CustomButton
          text="Cancel"
          onClick={handleToggle}
          style={{
            background: "linear-gradient(90deg, #6c757d, #495057)",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            padding: "10px 20px",
            transition: "all 0.3s ease",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
          disabled={isUploading || isSaving}
        />
        <CustomButton
          text="Save"
          color="primary"
          onClick={handleSave}
          style={{
            background: "linear-gradient(90deg, #556ee6, #3949ab)",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            padding: "10px 20px",
            transition: "all 0.3s ease",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
          disabled={isUploading || isSaving}
        />
      </ModalFooter>
    </Modal>
  );
};

export default ImportExcelModal;
