import { showToast } from "components/Common"
import {
  createAntibioticResistances,
  updateAntibioticResistances,
  deleteAntibioticResistances,
  getAntibioticResistancesList,
  getAntibioticResistancesById,
} from "helpers/app-backend/VS/VSAntibioticResistance_backend_helper"
import i18n from "i18n"
import { call, put, takeEvery } from "redux-saga/effects"
import ANTIBIOTIC_RESISTANCES from "./actionTypes"
import {
  addNewAntibioticResistancesFail,
  addNewAntibioticResistancesSuccess,
  deleteAntibioticResistancesFail,
  deleteAntibioticResistancesSuccess,
  getListAntibioticResistancesFail,
  getListAntibioticResistancesSuccess,
  getAntibioticResistancesDetailFail,
  getAntibioticResistancesDetailSuccess,
  updateAntibioticResistancesFail,
  updateAntibioticResistancesSuccess,
} from "./actions"
const t = (msg, param) => i18n.t(msg, param)

function* fetchGetListAntibioticResistances({ payload }) {
  try {
    let response = yield call(getAntibioticResistancesList, payload)
    yield put(getListAntibioticResistancesSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getListAntibioticResistancesFail(error))
  }
}

function* fetchAntibioticResistancesDetail({ id, callback }) {
  try {
    const response = yield call(getAntibioticResistancesById, id)
    yield put(getAntibioticResistancesDetailSuccess(response))
    callback && callback(response)
  } catch (error) {
    console.log(error)
    yield put(getAntibioticResistancesDetailFail(error))
  }
}

function* onAddNewAntibioticResistances({
  payload: { antibioticResistances, callback },
}) {
  try {
    console.log("antibioticResistances", antibioticResistances)
    const response = yield call(
      createAntibioticResistances,
      antibioticResistances
    )
    yield put(addNewAntibioticResistancesSuccess(antibioticResistances))
    showToast(
      `${t("message:CreatedMessage", {
        field: `${t("")} <span class='text-decoration-underline fw-bold'>${
          antibioticResistances["code"]
        }</span>`,
      })}`
    )

    callback && callback()
  } catch (error) {
    console.log(error)
    yield put(addNewAntibioticResistancesFail(error))
  }
}

function* onUpdateAntibioticResistances({
  payload: { antibioticResistances, callback },
}) {
  try {
    yield call(updateAntibioticResistances, antibioticResistances)
    yield put(updateAntibioticResistancesSuccess(antibioticResistances))
    showToast(`${t("message:UpdatedMessage")}`)
    callback && callback()
  } catch (error) {
    console.log(error)
    yield put(updateAntibioticResistancesFail(error))
  }
}

function* onDeleteAntibioticResistances({
  payload: { antibioticResistances, callback },
}) {
  try {
    yield call(deleteAntibioticResistances, antibioticResistances)
    yield put(deleteAntibioticResistancesSuccess())
    showToast(`${t("message:DeletedMessage")}`)
    callback && callback()
  } catch (error) {
    console.log("error", error)
    yield put(deleteAntibioticResistancesFail(error))
  }
}

function* AntibioticResistancesSaga() {
  yield takeEvery(
    ANTIBIOTIC_RESISTANCES.GET_LIST_ANTIBIOTIC_RESISTANCES,
    fetchGetListAntibioticResistances
  )
  yield takeEvery(
    ANTIBIOTIC_RESISTANCES.GET_ANTIBIOTIC_RESISTANCES_DETAIL,
    fetchAntibioticResistancesDetail
  )
  yield takeEvery(
    ANTIBIOTIC_RESISTANCES.ADD_NEW_ANTIBIOTIC_RESISTANCES,
    onAddNewAntibioticResistances
  )
  yield takeEvery(
    ANTIBIOTIC_RESISTANCES.UPDATE_ANTIBIOTIC_RESISTANCES,
    onUpdateAntibioticResistances
  )
  yield takeEvery(
    ANTIBIOTIC_RESISTANCES.DELETE_ANTIBIOTIC_RESISTANCES,
    onDeleteAntibioticResistances
  )
}

export default AntibioticResistancesSaga
