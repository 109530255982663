import {
  GET_QC_DATAPOINTS,
  GET_QC_DATAPOINTS_FAIL,
  GET_QC_DATAPOINTS_SUCCESS,
  GET_QC_DATAPOINTS_POSNEG,
  GET_QC_DATAPOINTS_POSNEG_FAIL,
  GET_QC_DATAPOINTS_POSNEG_SUCCESS,
  GET_TEST_FOR_CHART,
  GET_TEST_FOR_CHART_FAIL,
  GET_TEST_FOR_CHART_SUCCESS,
} from "./actionTypes"

export const getQCDatapoints = payload => ({
  type: GET_QC_DATAPOINTS,
  payload: payload,
})

export const getQCDatapointsSuccess = payload => ({
  type: GET_QC_DATAPOINTS_SUCCESS,
  payload: payload,
})

export const getQCDatapointsFail = error => ({
  type: GET_QC_DATAPOINTS_FAIL,
  payload: error,
})

export const getQCDatapointsPosNeg = payload => ({
  type: GET_QC_DATAPOINTS_POSNEG,
  payload: payload,
})

export const getQCDatapointsPosNegSuccess = payload => ({
  type: GET_QC_DATAPOINTS_POSNEG_SUCCESS,
  payload: payload,
})

export const getQCDatapointsPosNegFail = error => ({
  type: GET_QC_DATAPOINTS_POSNEG_FAIL,
  payload: error,
})

export const getTestForChart = payload => ({
  type: GET_TEST_FOR_CHART,
  payload: payload,
})

export const getTestForChartSuccess = payload => ({
  type: GET_TEST_FOR_CHART_SUCCESS,
  payload: payload,
})

export const getTestForChartFail = error => ({
  type: GET_TEST_FOR_CHART_FAIL,
  payload: error,
})