import {
  GET_QC_DATAPOINTS_FAIL,
  GET_QC_DATAPOINTS_SUCCESS,
  GET_QC_DATAPOINTS_POSNEG_SUCCESS,
  GET_QC_DATAPOINTS_POSNEG_FAIL,
  GET_TEST_FOR_CHART_SUCCESS,
  GET_TEST_FOR_CHART_FAIL
} from "./actionTypes"

const INIT_STATE = {
  datapoints: {},
  datapointsPosNeg:{},
  lstTest: [],
  resultBySeq: {},
  loadingDatapoints: false,
  error: null,
}

const qcDatapoints = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_QC_DATAPOINTS_SUCCESS:
      return {
          ...state,
          datapoints: action.payload,
          updateNotifyTime: new Date(),
          loadingDatapoints: false
      }
    case GET_QC_DATAPOINTS_FAIL:
      return {
          ...state,
      }
      case GET_QC_DATAPOINTS_POSNEG_SUCCESS:
        return {
            ...state,
            datapointsPosNeg: action.payload,
            updateNotifyTime: new Date(),
            loadingDatapoints: false
        }
      case GET_QC_DATAPOINTS_POSNEG_FAIL:
        return {
            ...state,
        }
    case GET_TEST_FOR_CHART_SUCCESS:
      return {
          ...state,
          lstTest: action.payload,
      }
    case GET_TEST_FOR_CHART_FAIL:
      return {
          ...state,
      }

  default:
      return state
  }
}

export default qcDatapoints
