import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../../api_helper2"
const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/qc/result`

const getQCResultList = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}

const updateQCResult = data => {
    return post(`${BASE_API_URL}/UpdateQCResult`, data)
}

const updateResultQCResult = data => {
    return put(`${BASE_API_URL}/UpdateResultQCResult`, data)
}

const validQCResult = data => {
    return post(`${BASE_API_URL}/ValidQCResult`, data)
}

const cancelQCResult = data => {
    return post(`${BASE_API_URL}/CancelQCResult`, data)
}

const cancelValidQCResult = data => {
    return post(`${BASE_API_URL}/CancelValidQCResult`, data)
}

const deleteQCResult = data => {
    return post(`${BASE_API_URL}/DeleteQCResult`, data)
}

const getCurrentReportNumber = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}/getCurrentReportNumber?${q}`)
}

const checkCurrentReportNumber = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}/checkCurrentReportNumber?${q}`)
}

const updateQCResultRuleCause = data => {
    return post(`${BASE_API_URL}/UpdateQCResultRuleCause`, data)
}

const getResultRuleCauseDetail = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}/GetResultRuleCauseDetail?${q}`)
}

const getResultRuleActionDetail = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}/GetResultRuleActionDetail?${q}`)
}

const updateQCResultRuleAction = data => {
    return post(`${BASE_API_URL}/UpdateQCResultRuleAction`, data)
}

const exportQCResult = (payload) => {
    let q = spreadSearchQuery(payload)
    return post(`${BASE_API_URL}/ExportQCResult?${q}`, null, {
        responseType: 'arraybuffer',
        headers:
        {
            'Content-Disposition': "attachment; filename=template.xlsx",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
    })
}


export {
    getQCResultList,
    updateQCResult,
    cancelQCResult,
    cancelValidQCResult,
    deleteQCResult,
    validQCResult,
    updateResultQCResult,
    checkCurrentReportNumber,
    getCurrentReportNumber,
    updateQCResultRuleCause,
    getResultRuleCauseDetail,
    getResultRuleActionDetail,
    updateQCResultRuleAction,
    exportQCResult
}
