/* QC */
export const GET_LIST_LOT_QC = "GET_LIST_LOT_QC"
export const GET_LIST_LOT_QC_SUCCESS = "GET_LIST_LOT_QC_SUCCESS"
export const GET_LIST_LOT_QC_FAIL = "GET_LIST_LOT_QC_FAIL"
/* SEARCH QUERY */
export const SET_LOT_QC_SEARCH_QUERY = "SET_LOT_QC_SEARCH_QUERY"
/* RESET SEARCH QUERY */
export const RESET_LOT_QC_SEARCH_QUERY = "RESET_LOT_QC_SEARCH_QUERY"
/* DETAIL */
export const GET_LOT_QC_DETAIL = "GET_LOT_QC_DETAIL"
export const GET_LOT_QC_DETAIL_SUCCESS = "GET_LOT_QC_DETAIL_SUCCESS"
export const GET_LOT_QC_DETAIL_FAIL = "GET_LOT_QC_DETAIL_FAIL"
/* ADD */
export const ADD_NEW_LOT_QC = "ADD_NEW_LOT_QC"
export const ADD_NEW_LOT_QC_SUCCESS = "ADD_NEW_LOT_QC_SUCCESS"
export const ADD_NEW_LOT_QC_FAIL = "ADD_NEW_LOT_QC_FAIL"
/* EDIT */
export const UPDATE_LOT_QC = "UPDATE_LOT_QC"
export const UPDATE_LOT_QC_SUCCESS = "UPDATE_LOT_QC_SUCCESS"
export const UPDATE_LOT_QC_FAIL = "UPDATE_LOT_QC_FAIL"
/* DELETE */
export const DELETE_LOT_QC = "DELETE_LOT_QC"
export const DELETE_LOT_QC_SUCCESS = "DELETE_LOT_QC_SUCCESS"
export const DELETE_LOT_QC_FAIL = "DELETE_LOT_QC_FAIL"
//add new lot test
export const ADD_NEW_LOT_TEST_QC = "ADD_NEW_LOT_TEST_QC"
export const ADD_NEW_LOT_TEST_QC_SUCCESS = "ADD_NEW_LOT_TEST_QC_SUCCESS"
export const ADD_NEW_LOT_TEST_QC_FAIL = "ADD_NEW_LOT_TEST_QC_FAIL"
export const GET_LIST_LOT_LIST_FROM_LOT_TEST = "GET_LIST_LOT_LIST_FROM_LOT_TEST"
export const GET_LIST_LOT_LIST_FROM_LOT_TEST_SUCCESS = "GET_LIST_LOT_LIST_FROM_LOT_TEST_SUCCESS"
export const GET_LIST_LOT_LIST_FROM_LOT_TEST_FAIL = "GET_LIST_LOT_LIST_FROM_LOT_TEST_FAIL"
export const GET_QC_LOT_TESTS = "GET_QC_LOT_TESTS"
export const GET_QC_LOT_TESTS_SUCCESS = "GET_QC_LOT_TESTS_SUCCESS"
export const GET_QC_LOT_TESTS_FAIL = "GET_QC_LOT_TESTS_FAIL"
export const UPDATE_LOT_TEST_QC = "UPDATE_LOT_TEST_QC"
export const UPDATE_LOT_TEST_QC_SUCCESS = "UPDATE_LOT_TEST_QC_SUCCESS"
export const UPDATE_LOT_TEST_QC_FAIL = "UPDATE_LOT_TEST_QC_FAIL"

export const UPDATE_LOT_TEST_COUNT_TEST_QC = "UPDATE_LOT_TEST_COUNT_TEST_QC"
export const UPDATE_LOT_TEST_COUNT_TEST_QC_SUCCESS = "UPDATE_LOT_TEST_COUNT_TEST_QC_SUCCESS"
export const UPDATE_LOT_TEST_COUNT_TEST_QC_FAIL = "UPDATE_LOT_TEST_COUNT_TEST_QC_FAIL"