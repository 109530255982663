import React, { useEffect, useState } from "react"
import { Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import PropTypes from "prop-types"
//i18n
import { withTranslation } from "react-i18next"
import { CustomAvField, CustomButton, CustomModal } from "components/Common"
import { AvForm, AvRadio, AvRadioGroup } from "availity-reactstrap-validation"
import { chartTypes, statusTypes } from "constant"
const ChartModal = ({
    modal,
    toggle,
    isEdit,
    onValidSubmit,
    data,
    formEl,
    dataCheckRef,
    t
}) => {
    const [comment, setComment] = useState()
    const [chartType, setChartType] = useState(chartTypes.PXN)
    const [checkChartType, setCheckChartType] = useState()
    const [enablePrintChart, setEnablePrintChart] = useState()

    useEffect(() => {
        setCheckChartType(dataCheckRef)
    }, [dataCheckRef])

    const onChangeChartType = (e) => {
        setChartType(e.target.value)
        if (!checkChartType?.includes(e.target.value)) {
            setEnablePrintChart(true);
        } else {
            setEnablePrintChart(false);
        }
    }
    useEffect(() => {
        setChartType(chartTypes.PXN)
        setComment("")
    }, [modal])
    return (
        <CustomModal modal={modal} onToggle={toggle} size="lg" title={t('chartiQCPage:ExportChart')}>
            <ModalBody>
                <AvForm
                    ref={formEl}
                    id="sidModalForm"
                    onValidSubmit={() => onValidSubmit(comment, chartType)}
                // model={model}
                >
                    <Row style={{ alignItems: 'baseline' }}>
                        <Col lg={2} style={{ alignContent: 'center', paddingRight: 0 }}>
                            <Label for="comment" className="font-weight-semibold">
                                {t("chartiQCPage:Comment")}
                            </Label>
                        </Col>
                        <Col lg={10} style={{}}>
                            <div className="mb-1">
                                <CustomAvField
                                    // style={{ marginLeft: '18px', width: 'calc(97% + 3px' }}
                                    name={'comment'}
                                    type={'text'}
                                    placeholder={t("chartiQCPage:Comment")}
                                    onChange={(e, value) => {
                                        setComment(e)
                                    }}
                                    value={""}
                                // required
                                // errorMessage={getInvalidMessageI18n(t, "resultPage:Current SID")}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className="">
                            <AvRadioGroup name="chartType" value={""} defaultValue={chartType}>
                                <AvRadio label={t("chartiQCPage:PXN")} onChange={onChangeChartType} value={chartTypes.PXN} />
                                <AvRadio label={t("chartiQCPage:NSX")} onChange={onChangeChartType} value={chartTypes.NSX} />
                            </AvRadioGroup>
                        </div>
                    </Row>
                </AvForm>
            </ModalBody>
            <ModalFooter>
                <CustomButton
                    text={t("common:Cancel")}
                    type="button"
                    onClick={toggle}
                    data-dismiss="modal"
                />
                <CustomButton
                    text={t("chartiQCPage:Print")}
                    type="submit"
                    color="primary"
                    onClick={() => formEl?.current?.submit()}
                    className="save-user"
                    isEdit
                    disabled={enablePrintChart}
                />
            </ModalFooter>
        </CustomModal>
    )
}
ChartModal.propTypes = {
    t: PropTypes.any,
}
ChartModal.defaultProps = {
}
export default withTranslation(["chartiQCPage", "common"])(ChartModal)