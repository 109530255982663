import { useEffect, useState } from "react"
import Select from "react-select"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import {
  convertDayMonthYear,
  convertDayMonth
} from "helpers/utilities"
import { cloneDeep } from "lodash"
import lotQCsSaga from "store/laboratoryIQC/ManagementQC/saga"

const LotIndex = ({
  placeholder,
  isInvalid,
  errorMessage,
  readOnly,
  onChangeGeneralData,
  onChangeAllLotLevel,
  onChangeReportIndex,
  onChangeListData,
  onChangeDateArr,
  onChangeSelectedLots,
  datapoints,
  isPosNeg,
  datapointsPosNeg,
  machineItems,
  filter,
  dataTestSelect,
  model
}) => {
  const [selectedLots, setSelectedLots] = useState([])
  const [dropdown, setDropdown] = useState([])
  const [dropdownDict, setDropdownDict] = useState([])
  const [selectedLotsDict, setSelectedLotsDict] = useState([])
  const [dataset, setDataset] = useState({})

  useEffect(() => {
    let arr = []
    let newAllLotLevel = []
    let newSelectedLotsDict = []
    if (datapoints != null && Object.keys(datapoints).length > 0) {
      let newDataset = datapoints["total"]
      arr = datapoints["sortedLot"]
      newAllLotLevel = Object.keys(newDataset).map(Number)
      for(let i = 0; i < arr.length; i++){
        newSelectedLotsDict.push([arr[i], datapoints["expireAndMachine"][arr[i]].lotName])
      }
    }
    if (onChangeAllLotLevel) {
      onChangeAllLotLevel(newAllLotLevel)
    }
    setSelectedLots(arr)
    setDropdown(arr)
    setSelectedLotsDict(newSelectedLotsDict)
    setDropdownDict(newSelectedLotsDict)
    setDataset(datapoints)
  }, [datapoints])

  useEffect(() => {
    let newAllLotLevel = []
    let newSelectedLotsDict = []
    let arr = []
    if (datapointsPosNeg != null && Object.keys(datapointsPosNeg).length > 0) {
      let newDataset = datapointsPosNeg["total"]
      newAllLotLevel = Object.keys(newDataset).map(Number)
      arr = datapointsPosNeg["sortedLot"]
      for(let i = 0; i < arr.length; i++){
        newSelectedLotsDict.push([arr[i], datapointsPosNeg["expireAndMachine"][arr[i]].lotName])
      }
    }
    if (onChangeAllLotLevel) {
      onChangeAllLotLevel(newAllLotLevel)
    }
    setSelectedLots(arr)
    setDropdown(arr)
    setSelectedLotsDict(newSelectedLotsDict)
    setDropdownDict(newSelectedLotsDict)
    setDataset(datapointsPosNeg)
  }, [datapointsPosNeg])

  useEffect(() => {
    if (onChangeSelectedLots) {
      onChangeSelectedLots(selectedLots)
    }
  }, [selectedLots])

  useEffect(() => {
    setSelectedLots([])
    setDropdown([])
    setSelectedLotsDict([])
    setDropdownDict([])
    if (onChangeGeneralData) {
      onChangeGeneralData({})
    }
  }, [model])

  useEffect(() => {
    let newLastLot = ""
    const mapOrder = new Map();
    let newGeneralData = {}
    let newReportIndex = { "allLot": [], "lastLot": '', "start": '', "end": '', "test": '', "numpoint": 0, "allDataPoint": [], "allExpireMachineLot": {}, "isPosNeg": '' }
    let dicByDate = {}
    let cnt = 0
    let dateArr = []
    let lstData = []
    
    if (selectedLots != null && selectedLots.length != 0) {
      for (let i = 0; i < dropdown.length; i++) {
        mapOrder.set(dropdown[i], i)
      }
      newLastLot = selectedLots[0]
      for (let i = 0; i < selectedLots.length; i++) {
        if (mapOrder.get(newLastLot) < mapOrder.get(selectedLots[i])) {
          newLastLot = selectedLots[i]
        }
      }
      let idxCalLastLot = dataset?.["indexCal"]?.[filter]?.[newLastLot]
      let idxLastLot = dataset?.["index"]?.[newLastLot]
      let totalData = dataset?.["total"]
      let expireAndMachine = dataset?.["expireAndMachine"]
      let selectedLotsSet = new Set(selectedLots)
      
      if (expireAndMachine != null && Object.keys(expireAndMachine).length > 0) {
        newGeneralData["expireAndMachine"] = expireAndMachine[newLastLot]
      }
      if (idxCalLastLot != null) {
        newGeneralData["indexCal"] = idxCalLastLot
      }
      if (idxLastLot != null) {
        newGeneralData["index"] = idxLastLot
      }
      if (isPosNeg == true) {
        newGeneralData["indexTotal"] = dataset?.["index"]
      }

      if (totalData != null && Object.keys(totalData).length > 0) {
        newGeneralData["data"] = {}
        newGeneralData["lenLvLastLot"] = {}
        let mapMachineItems = new Map()
        for (let i = 0; i < machineItems.length; i++) {
          mapMachineItems.set(machineItems[i].insID, machineItems[i].name)
        }
        Object.keys(totalData).forEach(lv => {
          let tempArr = []
          for (let i in totalData[lv]) {
            let data = totalData[lv][i]
            if (filter != "validated" && selectedLotsSet.has(data.lotId)) {
              tempArr.push(data)
            } else if (filter == "validated" && selectedLotsSet.has(data.lotId) && data.validatedBy != null) {
              tempArr.push(data)
            }
          }
          tempArr.filter(
            item =>
              mapMachineItems.has(item.insId)
          )
          for (let i = 0; i < tempArr.length; i++) {
            tempArr[i]["insName"] = mapMachineItems.get(tempArr[i]["insId"])
          }
          if (tempArr.length > 0) {
            cnt += tempArr.length
            newGeneralData["data"][lv] = tempArr
          }
        })
        newGeneralData["expireAndMachine"]["insName"] = mapMachineItems.get(Number(newGeneralData["expireAndMachine"]["machine"]))
      }

      let tempData = cloneDeep(newGeneralData?.["data"])
      if (tempData != null && Object.keys(tempData).length > 0) {
        Object.keys(tempData).forEach(lv => {
          for (let i in tempData[lv]) {
            if (tempData[lv][i].lotId == newLastLot) {
              if (!(lv in newGeneralData["lenLvLastLot"])) {
                newGeneralData["lenLvLastLot"][lv] = [0, tempData[lv][i].isNSX]
              }
              newGeneralData["lenLvLastLot"][lv][0] += 1
            }
            lstData.push(tempData[lv][i])
          }
        })
      }
      lstData.sort((a, b) => a.order - b.order)

      for (let i in lstData) {
        let data = lstData[i]
        let dateKey = convertDayMonth(data?.runTime)
        let lv = lstData[i]?.levels
        if (!(dateKey in dicByDate)) {
          dicByDate[dateKey] = {}
        }
        if (!(lv in dicByDate[dateKey])) {
          dicByDate[dateKey][lv] = []
        }
        dicByDate[dateKey][lv].push(data)
        if (!("maxLength" in dicByDate[dateKey])) {
          dicByDate[dateKey]["maxLength"] = 0
        }
        dicByDate[dateKey]["maxLength"] = Math.max(dicByDate[dateKey][lv].length, dicByDate[dateKey]["maxLength"])
      }
      let setLv = new Set()
      let resByLv = new Object()

      Object.keys(dicByDate).forEach(date => {
        for (let i in dicByDate[date]) {
          if (i != "maxLength") {
            setLv.add(i)
          }
        }
      })
      Object.keys(dicByDate).forEach(date => {
        for (let lv of setLv) {
          if (!(dicByDate[date].hasOwnProperty(lv))) {
            dicByDate[date][lv] = []
          }
          if (!resByLv.hasOwnProperty(lv)) {
            resByLv[lv] = []
          }
        }
      })
      Object.keys(dicByDate).forEach(date => {
        for (let lv in dicByDate[date]) {
          if (lv == "maxLength") continue
          if (dicByDate[date][lv].length < dicByDate[date]["maxLength"]) {
            let addition = dicByDate[date]["maxLength"] - dicByDate[date][lv].length
            for (let i = 0; i < addition; i++) {
              dicByDate[date][lv].push("")
            }
          }
        }
        dateArr.push(...new Array(dicByDate[date]["maxLength"]).fill(date))
      })

      Object.keys(dicByDate).forEach(date => {
        for (let lv in dicByDate[date]) {
          if (lv != "maxLength") {
            resByLv[lv].push(...dicByDate[date][lv])
          }
        }
      })
      newGeneralData["data"] = cloneDeep(resByLv)
      newGeneralData["allExpireMachineLot"] = expireAndMachine
      newReportIndex["lastLot"] = newLastLot
      newReportIndex["start"] = convertDayMonthYear(model.start)
      newReportIndex["end"] = convertDayMonthYear(model.end)
      newReportIndex["test"] = dataTestSelect?.testName
      newReportIndex["numpoint"] = cnt
      newReportIndex["allDataPoint"] = resByLv
      newReportIndex["allLot"] = selectedLots
      newReportIndex["isPosNeg"] = isPosNeg
    }
    if (onChangeDateArr) {
      onChangeDateArr(dateArr)
    }
    if (onChangeReportIndex) {
      onChangeReportIndex(newReportIndex)
    }
    if (onChangeGeneralData) {
      onChangeGeneralData(newGeneralData)
    }
    if (onChangeListData) {
      onChangeListData(lstData)
    }
  }, [dataset, selectedLots, filter])

  const handleChange = selected => {
    let newSelectedValues = selected
      ? selected.map(option => option.value)
      : []
    let newSelectedLotsDict = []
    for(let i = 0; i < newSelectedValues.length; i++){
      newSelectedLotsDict.push([newSelectedValues[i], dataset["expireAndMachine"][newSelectedValues[i]].lotName])
    }
    setSelectedLotsDict(newSelectedLotsDict)
    setSelectedLots(newSelectedValues)
  }

  return (
    <>
      {/* <span>{t("Index")}</span> */}
      <div style={styles.dropdownContainer}>
        <Select
          onChange={handleChange}
          options={dropdownDict?.map(lot => ({
            value: lot[0],
            label: lot[1],
          }))}
          isClearable
          placeholder={placeholder || ""}
          value={selectedLotsDict?.map(lot => ({
            value: lot[0],
            label: lot[1],
          }))}
          isDisabled={readOnly}
          isMulti
          menuPlacement="auto"
          maxMenuHeight={150}
        />
      </div>

      {isInvalid && (
        <div className="text-danger form-group">
          <div className="invalid-feedback">{errorMessage}</div>
        </div>
      )}
    </>
  )
}

const styles = {
  lotList: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "15px",
  },
  lotItem: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: "8px 15px",
    margin: "5px",
    borderRadius: "25px",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
    fontSize: "14px",
  },
  lotText: {
    marginRight: "10px",
    color: "#333",
  },
  removeButton: {
    backgroundColor: "red",
    color: "white",
    border: "none",
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    fontSize: "14px",
    cursor: "pointer",
  },
  dropdownContainer: {
    marginTop: "10px",
    zIndex: 2,
    position: "relative",
  },
}

LotIndex.propTypes = {
  datapoints: PropTypes.object,
}

const mapStateToProps = ({ qcDatapoints }) => ({
  datapoints: qcDatapoints.datapoints,
  datapointsPosNeg: qcDatapoints.datapointsPosNeg,
  loadingDatapoints: qcDatapoints.loadingDatapoints,
  error: qcDatapoints.error,
})
const mapDispatchToProps = dispatch => ({
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    withTranslation(["chartiQCPage", "message", "common", "sidebar"])(LotIndex)
  )
)
