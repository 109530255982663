import { AvForm } from "availity-reactstrap-validation"
import {
  CustomAvField,
  CustomButton,
  CustomCheckbox,
  CustomSelectAsync,
  CustomTagsInput,
} from "components/Common"
import CustomModal from "components/Common/Modals/CustomModal"
import { parameterCode } from "constant"
import { getInvalidMessageI18n, isEmptyValues } from "helpers/utilities"
import { useReducer, useState } from "react"
import { withTranslation } from "react-i18next"
import { Col, ModalBody, ModalFooter, Row } from "reactstrap"

const CodeMappingModalVS = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  formEl,
  instrumentId,
  onChange,
  t,
}) => {

  const [model, setModel] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      testCode: data.testCode || "",
      testName: data.testName || "",
    }
  )
  if (isEdit && isEmptyValues(data)) return null

  const title =
    (!!isEdit ? t("common:Edit") : t("common:Add")) +
    " " +
    t("machinePage:MappingCode")
  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody>
        <AvForm
          id="machineMappingForm"
          onValidSubmit={onValidSubmit}
          ref={formEl}
          model={data}
          onChange={onChange}
        >
          <Row form>
            <CustomAvField
              name="instrumentId"
              type="hidden"
              value={instrumentId || ""}
            />
            <CustomAvField
              name="instrumentMapId"
              type="hidden"
              value={data.instrumentMapId || ""}
            />

            <Col sm="12" className="mb-3">
              <CustomSelectAsync
                name="genus"
                portal
                // value={genus.code || ""}
                keyGetValue="code"
                code={parameterCode.VS_ORGM_GENUS}
                label={t("organismsPage:Genus")}
                // detected={isEdit}
                // valueNameNotSearch={genus.name || ""}
                onChange={(e, vals, name, items) => {
                  setModel({
                    genusCode: vals[0] || '',
                    genusName: items[0]?.name || '',
                    code: '',
                    name: '',
                  })
                }}
              />
            </Col>
            <Col sm="12" className="mb-3">
              <CustomSelectAsync
                name="testCode"
                portal
                value={model.testCode || ""}
                code={parameterCode.VS_ORGANISM_IN_MAPPING}
                label={t("organismsPage:Organisms Code")}
                required
                errorMessage={getInvalidMessageI18n(t, "organismsPage:Organisms Code")}
                validate={{
                  required: { value: true },
                }}
                group={model.genusCode || ""}
                keyGetValue="code"
                isDependent={!!model.genusCode}
                detected={isEdit}
                valueNameNotSearch={model.testName || ""}
                onChange={(e, vals, name, items) => {
                  setModel({
                    testCode: vals[0] || '',
                    testName: items[0]?.name || '',
                  })
                }}
              />
            </Col>
            <Col sm="12" className="mb-3">
              <CustomAvField
                label={t("machinePage:Instrument Code")}
                name="instrumentCode"
                type="text"
                required
                errorMessage={getInvalidMessageI18n(
                  t,
                  "machinePage:Instrument Code"
                )}
                validate={{
                  required: { value: true },
                }}
                value={data.instrumentCode || ""}
                detected={isEdit}
              />
            </Col>
            <Col sm="12" className="mb-3">
              <CustomCheckbox
                name="inUse"
                checked={!!data.inUse}
                direction={"down"}
                label={`${t("common:Active")}`}
                detected={isEdit}
              />
            </Col>
          </Row>
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
        />
        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          onClick={() => formEl?.current?.submit()}
          className="save-user button-width"
          isEdit
          isModal
        />
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["machinePage", "common", "message", "organismsPage"])(
  CodeMappingModalVS
)
