import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"

import { Container } from "reactstrap"

import { isEmptyValues } from "helpers/utilities"
import { DefaultValue } from "constant"
import { setFieldChange } from "store/actions"

const PageContent = ({ title, children, t, ...rest }) => {
  const { isFetchedPermission, errorPermission } = useSelector(state => ({
    isFetchedPermission: state.Authorization.fetched,
    errorPermission: state.Authorization.errorPermission,
  }))
  const dispatch = useDispatch()
  
  useEffect(() => {
    dispatch(setFieldChange([]))
  }, [])

  // if (!isFetchedPermission) return null
  document.title = title + " | " + DefaultValue.WEB_APP_TITLE
  return (
    <div style={{ paddingBottom: 0 }} className="page-content" {...rest}>
      <title>
        {title} | {t("title")}
      </title>
      <Container fluid>
        {/* body && title */}
        {children}
      </Container>
    </div>
  )
}

PageContent.propTypes = {
  title: PropTypes.string.isRequired,
  t: PropTypes.func,
}

export default withTranslation(["common"])(PageContent)
