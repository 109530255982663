import { useEffect, useState, useCallback, useRef } from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import CreatableSelect from "react-select/creatable"
import { AvField } from "availity-reactstrap-validation"
import { getI18nextLng, isEmptyArray } from "helpers/utilities"
import { useDetectedChanges } from "helpers/hooks"

import { Label } from "reactstrap"
import { RollbackButton } from "."
import { isEmpty } from "lodash"

const components = {
    DropdownIndicator: null,
}

const CustomTagsInput = ({
    tagsValue,
    name,
    required,
    errorMessage,
    onChange,
    label,
    detected,
    readOnly,
}) => {
    let lang = getI18nextLng()

    const [inputValue, setInputValue] = useState("")

    const [tagsInput, setTagsInput] = useState([])

    const [defaultValue, setDefaultValue] = useState(tagsValue)

    const [currentTagsVal, setCurrentTagsVal] = useState(tagsValue)

    const [oldValue, isChanged] = useDetectedChanges(tagsValue, defaultValue, name)

    const inputRef = useRef()

    const onUndoHandler = () => {
        let arr = tagsValue.split(",")
        let tagsInputOptions = []
        arr.forEach(item => {
            if (!isEmpty(item)) tagsInputOptions.push(createOption(item))
        })
        setCurrentTagsVal(tagsValue)
        setTagsInput(tagsInputOptions)
        onChangeHandler({ value: tagsValue })
    }

    const onChangeHandler = (e, b) => {
        const element = document.getElementsByName(name)[0]
        var event = new Event("change", { bubbles: true })

        const tempvalue = [e?.value] || []

        element.value = tempvalue || ""
        element.dispatchEvent(event)
        setDefaultValue(tempvalue || "")

        onChange(name, currentTagsVal)
    }

    const isTouched = inputRef.current?.FormCtrl.isTouched()
    const isDirty = inputRef.current?.FormCtrl.isDirty()
    const isInvalid =
        !readOnly &&
        required &&
        isEmptyArray(defaultValue) &&
        (isTouched || isDirty)

    const createOption = label => ({
        label,
        value: label,
    })

    useEffect(() => {
        setDefaultValue([tagsValue])
        let arr = tagsValue.split(",")
        let tagsInputOptions = []
        arr.forEach(item => {
            if (!isEmpty(item)) tagsInputOptions.push(createOption(item))
        })
        setTagsInput(tagsInputOptions)

    }, [tagsValue])

    const handleInputChange = inputValue => {
        setInputValue(inputValue)
    }

    const handleKeyDown = event => {
        let pureInput = inputValue;
        if (!inputValue) {
            setInputValue("")
            return
        }

        switch (event.key) {
            case ",":
            case " ":
            case ";":
            case "Enter":
            case "Tab": {
                pureInput = inputValue.replace(/[,;\s]/g, "")
                if (!pureInput) {
                    setInputValue("")
                    return
                }

                let arr = currentTagsVal.split(",")
                if (arr.includes(pureInput)) {
                    setInputValue(pureInput)
                    return
                }

                let tagsArr = []
                if (tagsInput) {
                    tagsArr = [...tagsInput, createOption(pureInput)]
                }
                else {
                    tagsArr = [createOption(pureInput)]
                }
                setTagsInput(tagsArr)
                let tagsInputArr = []
                tagsArr.forEach(item => {
                    if (!isEmpty(item.value)) tagsInputArr.push(item.value)
                })
                let valueString = tagsInputArr.join(",")
                setCurrentTagsVal(valueString)
                onChangeHandler({ value: valueString })
                setInputValue("")
                event.preventDefault()
                return
            }
        }
    }

    const handleRemoveItem = (values, actionMeta) => {
        setTagsInput(values)
        let tagsInputArr = []
        if (!values) {
            setCurrentTagsVal('')
            onChangeHandler({ value: '' })
            return;
        }
        values.forEach(item => {
            if (!isEmpty(item.value)) tagsInputArr.push(item.value)
        })
        let valueString = tagsInputArr.join(",")
        setCurrentTagsVal(valueString)
        onChangeHandler({ value: valueString })
    }

    const colourStyles = {
        control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
            ...styles,
            backgroundColor: isDisabled ? "#edf1f2 !important" : "white",
            fontSize: "13px",
            cursor: isDisabled ? "not-allowed" : "default",
            ...isValidStyle,
            // ...isVaild,
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);
            return {
                ...styles,
                fontSize: "13px",
                // color: "black",
                // backgroundColor: isDisabled ? null : isSelected ? data.color : null,
                cursor: isDisabled ? "not-allowed" : "default",
            }
        },
        singleValue: (provided, state) => {
            const opacity =
                state.isDisabled || !state.data.value || state.data.value === "0"
                    ? 0.7
                    : 1
            const transition = "opacity 300ms"

            return { ...provided, opacity, transition }
        },

        menuPortal: provided => ({
            ...provided,
            zIndex: 9999,
        }),
        menu: provided => ({ ...provided, zIndex: 9999 }),
    }

    const isValidStyle = isInvalid && {
        borderColor: "#f46a6a !important",
        backgroundImage: `url(
      "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f46a6a'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='%23f46a6a' stroke='none'/></svg>"
    )`,
        backgroundPosition: "right 2.75rem center, center right 0.5rem",
        backgroundSize: "17px 18px, calc(0.75em + 0.47rem) calc(0.75em + 0.47rem)",
        backgroundRepeat: "no-repeat",
    }

    return (
        <>
            <div className="label-group-relative position-relative">
                {label && (
                    <Label for={name}>
                        {label}
                        {required && <span className="text-danger">*</span>}
                    </Label>
                )}
                <RollbackButton
                    display={isChanged && detected}
                    onClick={onUndoHandler}
                />
            </div>
            <CreatableSelect
                components={components}
                name={name}
                inputValue={inputValue}
                isClearable
                isMulti={true}
                styles={colourStyles}
                isDisabled={readOnly}
                menuIsOpen={false}
                onInputChange={handleInputChange}
                onChange={handleRemoveItem}
                onKeyDown={handleKeyDown}
                placeholder=""
                value={tagsInput}
                classNamePrefix="select2-selection"
                className={classnames(
                    { "has-changed": isChanged && detected },
                    "form-select2 is-touched is-dirty av-invalid is-invalid"
                )}
            />
            {isInvalid && (
                <div className="text-danger form-group">
                    <div className="is-touched is-dirty av-invalid is-invalid"></div>
                    <div className="invalid-feedback">{errorMessage}</div>
                </div>
            )}
            {!readOnly && (
                <div className="d-none">
                    <AvField
                        name={name}
                        type="text"
                        value={currentTagsVal}
                        //ref={inputRef}
                        readOnly={true}
                    ></AvField>
                </div>
            )}
        </>
    )
}

CustomTagsInput.propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    tagsValue: PropTypes.any,
    label: PropTypes.any.isRequired,
    detected: PropTypes.bool,
    readOnly: PropTypes.bool,
}

CustomTagsInput.defaultProps = {
    name: "",
    onChange: () => { },
    errorMessage: "This field is invalid",
}

export default CustomTagsInput
