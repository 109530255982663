import { Check, CustomButton } from "components/Common"
import { ModuleIds, permissionType } from "constant"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

const HeaderButtons = ({ resource, onAdd, t, isButtonEnabled, onAddLotTest, onImportExcel }) => {
  return (
    <>
          {/* <Check permission={permissionType.AddLotTest} resource={resource}>
      <CustomButton color="warning" onClick={onAddLotTest} outline disabled={!isButtonEnabled}>
          {t("Thêm Lot Test")}
        </CustomButton>
        </Check> */}
      <Check permission={permissionType.C} resource={resource}>
        <CustomButton color="primary" onClick={onAdd} outline disabled={""}>
          {t("Add Lot")}
        </CustomButton>
      </Check>
      <Check permission={permissionType.ImportExcel} resource={resource}>
        <CustomButton
          text={t("Import Excel")}
          type="button"
          color="success"
          onClick={onImportExcel}
        >
          {t("Import Excel")}
        </CustomButton>
      </Check>
    </>
  )
}

HeaderButtons.propTypes = {
  resource: PropTypes.string.isRequired,
  onAddConfiglotQCModalClick: PropTypes.func.isRequired,
  onUpdateValidClick: PropTypes.func.isRequired,
  onUpdateInvalidClick: PropTypes.func.isRequired,
}

export default withTranslation(["managementQCPage", "common"])(HeaderButtons)
