import { AvForm } from "availity-reactstrap-validation"
import { Col, Row } from "reactstrap"
import { CompanyInformation, GeneralInformation } from "../Form"
import { Check, CustomButton, SystemInformation } from "components/Common"
import { ModuleIds, permissionType } from "constant"
import { withTranslation } from "react-i18next"

const RESOURCE = ModuleIds.User

const EditMode = ({ onValidSubmit, data, onCancel, t, isEdit }) => {
  return (
    <Row>
      <Col sm="12">
        <AvForm onValidSubmit={onValidSubmit} model={data} autoComplete="off">
          {/* GeneralInformation */}
          <GeneralInformation t={t} data={data} isEdit={isEdit} />

          {/* CompanyInformation */}
          <CompanyInformation t={t} data={data} isEdit={isEdit} />

          {/* SystemInformation */}
          <SystemInformation t={t} data={data} />
          {/* Buttons */}
          <Row>
            <Col>
              <div className="d-flex flex-wrap gap-2 modal-footer justify-content-center">
                <Check resource={RESOURCE} permission={permissionType.R}>
                  <CustomButton
                    type="button"
                    onClick={onCancel}
                    text={t("common:Cancel")}
                    className="button-width"
                  />
                </Check>

                <Check resource={RESOURCE} permission={permissionType.C}>
                  <CustomButton
                    type="submit"
                    color="primary"
                    className="save-user button-width"
                    text={t("common:Save")}
                    isEdit
                    isModal
                  />
                </Check>
              </div>
            </Col>
          </Row>
        </AvForm>
      </Col>
    </Row>
  )
}

export default withTranslation(["userPage", "common"])(EditMode)
