import {
    GET_TESTS,
    GET_TESTS_SUCCESS,
    GET_TESTS_FAIL,
    SET_TESTS_SEARCH_QUERY,
    RESET_TESTS_SEARCH_QUERY,

    GET_TEST_DETAIL,
    GET_TEST_DETAIL_SUCCESS,
    GET_TEST_DETAIL_FAIL,

    ADD_NEW_TEST,
    ADD_TEST_SUCCESS,
    ADD_TEST_FAIL,

    UPDATE_TEST,
    UPDATE_TEST_SUCCESS,
    UPDATE_TEST_FAIL,

    DELETE_TESTS,
    DELETE_TESTS_SUCCESS,
    DELETE_TESTS_FAIL,

    CHANGE_TESTS_TREE_VIEW_ITEM,
    CHANGE_TESTS_TREE_VIEW_ITEM_SUCCESS,
    CHANGE_TESTS_TREE_VIEW_ITEM_FAIL,
    GET_FULL_TEST,
    GET_FULL_TEST_SUCCESS,
    GET_FULL_TEST_FAIL,
    SAVE_DISPLAY_ORDER_TEST,
    SAVE_DISPLAY_ORDER_TEST_SUCCESS,
    SAVE_DISPLAY_ORDER_TEST_FAIL,
    GET_POSITION_SCROLL,
    UPDATE_TEST_VENDOR,
    UPDATE_TEST_VENDOR_SUCCESS,
    UPDATE_TEST_VENDOR_FAIL,
    DELETE_TESTS_VENDOR_SUCCESS,
    DELETE_TESTS_VENDOR_FAIL,
    UPDATE_INFO_TEST,
    UPDATE_INFO_TEST_SUCCESS,
    UPDATE_INFO_TEST_FAIL,

} from "./actionTypes"

const INIT_STATE = {
    tests: [],
    paging: {},
    loadingTests: false,
    updatingTest: true,
    updatedTime: new Date(),
    test: {},
    searchQuery: {},
    errorTest: {},
    error: {},
    treeViewItem: {},
    position: {}
}

const Tests = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_TEST_DETAIL:
            return {
                ...state,
                test: {},
                error: {},
                updatingTest: true,
            }

        case GET_TEST_DETAIL_SUCCESS:
            const test = action.payload
            return {
                ...state,
                test: test,
                updatingTest: false,
            }

        case GET_TEST_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case GET_TESTS:
            return {
                ...state,
                //tests: [],
                error: {},
                loadingTests: true,
            }

        case GET_TESTS_SUCCESS:
            const { data, totalElements, ...rest } = action.payload
            return {
                ...state,
                tests: [...data],
                paging: { ...rest, dataSize: totalElements },
                loadingTests: false,
                updatedTime: new Date(),
            }

        case GET_TESTS_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingTests: false,
            }
        case ADD_NEW_TEST:
            return {
                ...state,
                updatingTest: true,
                errorCode: {},
            }
        case ADD_TEST_SUCCESS:
            return {
                ...state,
                tests: [...state.tests, action.payload],
            }

        case ADD_TEST_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case UPDATE_TEST:
            return {
                ...state,
                updatingTest: true,
                error: {},
            }

        case UPDATE_TEST_SUCCESS:
            return {
                ...state,
                tests: state.tests.map(test =>
                    test.id?.toString() === action.payload.id.toString()
                        ? { ...test, ...action.payload }
                        : test
                ),
                updatingTest: false,
            }

        case UPDATE_TEST_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingTest: false,
            }

        case DELETE_TESTS_SUCCESS:
            const payload = action.payload
            const testsUpdate = state.tests.filter(
                _test => payload.findIndex(_payload => _test.id === _payload.id) < 0
            )

            const statePaging = { ...state.paging }
            statePaging.dataSize = statePaging.dataSize - payload.length
            if (statePaging.dataSize < 0) statePaging = 0

            return {
                ...state,
                tests: testsUpdate,
                paging: statePaging,
            }

        case DELETE_TESTS_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case SET_TESTS_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: { ...action.payload },
            }

        // TREE VIEW
        case CHANGE_TESTS_TREE_VIEW_ITEM:
            return {
                ...state,
                treeViewItem: {},
            }

        case CHANGE_TESTS_TREE_VIEW_ITEM_SUCCESS:
            return {
                ...state,
                treeViewItem: action.payload,
            }

        case CHANGE_TESTS_TREE_VIEW_ITEM_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case RESET_TESTS_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: {},
            }
        case GET_FULL_TEST:
            return {
                ...state,
                //tests: [],
                error: {},
                loadingTests: true,
            }

        case GET_FULL_TEST_SUCCESS:
            return {
                ...state,
                tests: action.payload,
                paging: { dataSize: action.payload.length },
                loadingTests: false,
                updatedTime: new Date(),
            }

        case GET_FULL_TEST_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingTests: false,
            }
        case SAVE_DISPLAY_ORDER_TEST:
            return {
                ...state,
                displayOrder: {},
            }

        case SAVE_DISPLAY_ORDER_TEST_SUCCESS:
            return {
                ...state,
            }

        case SAVE_DISPLAY_ORDER_TEST_FAIL:
            return {
                ...state,
                displayOrder: action.payload,
            }
        case UPDATE_TEST_VENDOR:
            return {
                ...state,
                updatingTest: true,
                error: {},
            }

        case UPDATE_TEST_VENDOR_SUCCESS:
            return {
                ...state,
                updatingTest: false,
            }

        case UPDATE_TEST_VENDOR_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingTest: false,
            }
        case DELETE_TESTS_VENDOR_SUCCESS:
            return {
                ...state,
                error: action.payload,
            }
        case DELETE_TESTS_VENDOR_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case UPDATE_INFO_TEST:
            return {
                ...state,
                updatingTest: true,
                error: {},
            }

        case UPDATE_INFO_TEST_SUCCESS:
            return {
                ...state,
                updatingTest: false,
            }

        case UPDATE_INFO_TEST_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingTest: false,
            }
        default:
            return state
    }
}

export default Tests