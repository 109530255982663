import { Check, CustomBootstrapInlineTable, CustomButton, CustomPrintButton, CustomSID, showErrToast } from "components/Common"
import { ModuleIds, RESULT_STATE_Text, ReportResource, permissionType } from "constant"
import { getFilePrint, pingToPrintService, sendDataPrint } from "helpers/app-backend"
import { GetDataUrlReportConfig, convertDateFormat } from "helpers/utilities"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import { getGeneralSetting } from "store/setting/generalSetting/actions"
import {
  ChangeRequestSampleTableSuccess,
  setRequestSampleClear,
  setRequestSampleCollectTime,
  setRequestSampleCollector,
  setRequestSampleQuality,
  setRequestSampleReceivedTime,
  setRequestSampleReceiver,
  updateNumbOfLabels
} from "../../../../../store/laboratory/testRequest/actions"

const RESOURCE = ModuleIds.TestRequest

const DetailSampleTabTable = ({
  onSelect,
  onSelectAll,
  data,
  onEdit,
  onDelete,
  t,
  loading,
  updatedTime,
  onSetRequestSampleCollector,
  onSetRequestSampleCollectTime,
  onSetRequestSampleQuality,
  requestDate,
  testRequest,
  onSetRequestSampleReceivedTime,
  onSetRequestSampleReceiver,
  onSetRequestSampleClear,
  onChangeRequestSampleTableSuccess,
  onSetNumberOfLabel,
  onUpdateNumberOfLabel,
  onChangeData,
  onEditTestRequestSampleId,
  onGetGeneralSetting,
  generalSetting,
  generalSettingObject,
  onCancelCollectSample,
  ...rest
}) => {
  const [subProfileData, setSubProfileData] = useState([])
  const [printerName, setPrinterName] = useState('')
  const userInfo = localStorage.getItem("userInfo")
  const isCancel = (code) => code.state === RESULT_STATE_Text.Canceled ? { textDecoration: 'line-through' } : {}
  useEffect(() => {
    if (isEmpty(generalSetting) && userInfo?.company) {
      onGetGeneralSetting(userInfo?.company)
    }
  }, [])

  let printConfig = localStorage.getItem("printConfig")

  useEffect(() => {
    const tmpPrinter = printConfig ? JSON.parse(printConfig) : {}
    setPrinterName(tmpPrinter?.printerName)
  }, [printConfig])

  const { statusRequest, reportInfos } = useSelector(state => ({
    statusRequest: state.testRequest.testRequest?.state,
    reportInfos: state.Authorization.reportinfo || [],
  }))

  useEffect(() => {
    let tmp = data.map(x => ({
      ...x,
      requestDate: testRequest.requestDate,
      isClear: x.isClear !== undefined ? x.isClear : ((x.collectionTime && x.collectorUserId) || (x.receivedTime && x.receiverUserId)),
    }))
    setSubProfileData(tmp)
  }, [data])

  const submitUpdateNumberOfLabel = async (item, value) => {
    await onUpdateNumberOfLabel({
      request: {
        ...item,
        numberOfLabels: value,
      },
      callback: {},
    })
  }

  const PrintConfig = async (cell, item, index) => {
    const print = JSON.parse(localStorage.getItem("printConfig"))
    let printColumns = print.columns
    let printPage = ['B1', 'B2', 'B3']
    if (reportInfos.length > 0 && !isEmpty(reportInfos[0].uri)) {
      const res = await getFilePrint({
        ...GetDataUrlReportConfig(reportInfos, ReportResource.RequestId, ""),
        SID: item.sid,
        SubSID: (item.subSID !== '' && item.subSID !== null) ? item.subSID : 0,
        RequestDate: item.requestDate,
        SampleType: item.sampleType,
        Gender: testRequest.gender,
        Page: printPage[printColumns - 1]
      })
      try {
        const getPingPrintService = await pingToPrintService()
        if (getPingPrintService.pingTime) {
          const dataSendPrint = await sendDataPrint({
            ...GetDataUrlReportConfig(
              reportInfos,
              ReportResource.DeliveryId,
              ""
            ),
            filePath: `${res.reportUrl}`,
            quantity: item.numberOfLabels,
            "printerNameBarcode": printerName
          })

          if (dataSendPrint === 'failed') {
            if (isEmpty(printerName)) {
              showErrToast(
                'Vui lòng chọn máy in'
              )
              setTimeout(() => {
                window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
              }, 1500)
            } else {

              let isPrint = true
              let attempt = 0

              const maxAttempts = 4
              do {
                const newRes = await getFilePrint({
                  ...GetDataUrlReportConfig(reportInfos, ReportResource.RequestId, ""),
                  SID: item.sid,
                  SubSID: (item.subSID !== '' && item.subSID !== null) ? item.subSID : 0,
                  RequestDate: item.requestDate,
                  SampleType: item.sampleType,
                  Gender: testRequest.gender,
                  Page: printPage[printColumns - 1]
                })

                const dataSendPrint = await sendDataPrint({
                  ...GetDataUrlReportConfig(
                    reportInfos,
                    ReportResource.DeliveryId,
                    ""
                  ),
                  filePath: `${newRes.reportUrl}`,
                  quantity: item.numberOfLabels,
                  "printerNameBarcode": printerName
                })
                if (dataSendPrint !== 'failed') {
                  isPrint = false
                }
                attempt++;
                if (attempt >= maxAttempts) {
                  isPrint = false
                }
              } while (isPrint)
            }
          }
        } else {
          window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
        }
      } catch {
        window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
      }
    } else {
      console.log("failed")
    }
  }

  const onPrintBarcodeHandler = async (cell, item, index) => {
    PrintConfig(cell, item, index)
  }

  const displayCollected =
    generalSetting?.find(item => item.fieldName === "Collected")?.fieldValue === "True"
  const displayReceived =
    generalSetting?.find(item => item.fieldName === "Received")?.fieldValue === "True"

  let widthColumn = 0
  if (displayCollected) {
    widthColumn + 20
  }
  if (displayReceived) {
    widthColumn + 20
  }

  const columns = [
    {
      dataField: "id",
      text: "id",
      hidden: true,
      searchable: false,
    },
    {
      dataField: "",
      text: "#",
      headerStyle: { width: "5px" },
      formatter: (cellContent, item, index) => {
        return index + 1
      },
    },
    // {
    //   dataField: "",
    //   text: "",
    //   searchable: false,
    //   headerStyle: { width: "10px" },
    //   formatter: (cellContent, item, index) => {
    //     return (
    //       <>
    //         <FormSwitch
    //           label=""
    //           value={item.isClear}
    //           disabled={
    //             subProfileData.length > 1 &&
    //             ((item.receivedTime && item.receiverUserId) ||
    //             (item.collectionTime && item.collectorUserId)) &&
    //             item.state != RESULT_STATE_Text.Canceled
    //               ? false
    //               : true
    //           }
    //           onChangeSwitch={value => {
    //             subProfileData.map(x => {
    //               if (x.sid === item.sid && x.sampleType === item.sampleType) {
    //                 x.isClear = value
    //               }
    //             })
    //             onSetRequestSampleClear({ item, value })
    //             setSubProfileData([...subProfileData])
    //             onChangeData && onChangeData()
    //           }}
    //         />
    //       </>
    //     )
    //   },
    // },
    {
      dataField: "",
      text: t("Barcode"),
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center", width: "50px", fontSize: 14 },
      formatter: (cellContent, item, index) => {
        return (
          <>
            {item.state != RESULT_STATE_Text.Canceled ? (
              <CustomPrintButton
                name={"Barcode_Template_no4_Other_Report"}
                isPrint={true}
                sid={item.sid}
                subSID={(item.subSID !== '' && item.subSID !== null) ? item.subSID : 0}
                sampleType={item.sampleType}
                requestDate={item.requestDate}
                reportResource={ReportResource.RequestId}
                fileId={""}
                reportInfos={reportInfos}
                gender={testRequest.gender}
              />
            ) : (
              <span>---</span>
            )}
          </>
        )
      },
    },
    {
      dataField: "sid",
      text: t("Sample ID"),
      style: { padding: "8px 40px" },
      headerStyle: { width: `${widthColumn + 80}px`, padding: "8px 40px" },
      formatter: (cellContent, item, index) => {
        return <span style={isCancel(item)}>
          <CustomSID
            sid={`${item.sid}${item.subSID !== 0 ? '-' + item.subSID : ''}`}
            textOnly={true}
          /></span>
      },
      // formatter: (cellContent, item, index) => {
      //     const idHTML = `sid${item.sid}`.replace(' ', '')
      //     return (<React.Fragment>
      //         <div id={idHTML + '_'} className={'requestSampleId'}
      //             style={{ width: 150, margin: '0 auto' }} onClick={(e) => {
      //                 e.stopPropagation();
      //             }}>
      //             <Editable
      //                 alwaysEditing={false}
      //                 disabled={false}
      //                 editText={item.sid || '---'}
      //                 initialValue={item.sid || ''}
      //                 id={item.sid}
      //                 isValueClickable={false}
      //                 mode="inline"
      //                 placement="top"
      //                 showText={false}
      //                 type="textfield"
      //                 renderConfirmElement={confirmElement}
      //                 renderCancelElement={cancelElement}
      //                 onSubmit={(value) => {
      //                     if (item.sid != value || (item.sid != "" && value != ""))
      //                         onEditTestRequestSampleId({ item, sid: value })
      //                 }}
      //             />
      //         </div>
      //     </React.Fragment>
      //     )
      // },
    },
    // {
    //   dataField: "subSID",
    //   style: { padding: "0px", textAlign: "center", fontSize: 12 },
    //   headerStyle: {
    //     padding: "10px 0px",
    //     textAlign: "center",
    //     width: `${widthColumn}px`,
    //     fontSize: 12,
    //   },
    //   text: t("SubSID"),
    //   formatter: (cellContent, item, index) => {
    //     return (
    //       <span style={isCancel(item)}>
    //         {cellContent != 0 && cellContent}
    //       </span>
    //     )
    //   },
    // },
    {
      dataField: "sampleTypeName",
      text: t("Sample Type"),
      style: { padding: "0px 30px" },
      headerStyle: { width: `${widthColumn + 90}px`, padding: "8px 30px" },
      formatter: (cellContent, item, index) => {
        return <span style={isCancel(item)}>{cellContent}</span>
      },
    },
    {
      dataField: "sampleColor",
      text: "",
      style: { padding: "0px" },
      headerStyle: { padding: "0px 0px", fontSize: 14, width: "30px" },
      formatter: (cellContent, item, index) =>
        item?.sampleColor && (
          <i style={{ color: item?.sampleColor }} className="fas fa-circle"></i>
        ),
    },
    {
      dataField: "sampleType",
      text: t("Sample Code"),
      style: { padding: "0px 70px", textAlign: "center" },
      headerStyle: {
        width: `${widthColumn + 50}px`,
        fontSize: 12,
        textAlign: "center",
        padding: "8px 0px",
      },
      formatter: (cellContent, item, index) => {
        return <span style={isCancel(item)}>{cellContent}</span>
      },
    },
    // {
    //   dataField: "numberOfLabels",
    //   style: { padding: "0px", textAlign: "-webkit-center " },
    //   headerStyle: {
    //     width: `${widthColumn + 20}px`,
    //     fontSize: 12,
    //     padding: "10px 70px",
    //     textAlign: "center",
    //   },
    //   text: t("Labels"),
    //   align: "center",
    //   headerAlign: "center",
    //   formatter: (cellContent, item, index) => {
    //     const idHTML = `numberOfLabels${item.numberOfLabels}`.replace(" ", "")
    //     return (
    //       <>
    //         {item.isClear && item.state !== RESULT_STATE_Text.Canceled ? (
    //           <div
    //             id={idHTML + "_"}
    //             className={"requestSampleItemFocus"}
    //             style={{ width: 150, margin: "0 auto" }}
    //             onClick={e => {
    //               e.stopPropagation()
    //             }}
    //           >
    //             <Editable
    //               alwaysEditing={false}
    //               disabled={false}
    //               editText={item.numberOfLabels || 1}
    //               initialValue={item.numberOfLabels || ""}
    //               id={item.numberOfLabels}
    //               isValueClickable={false}
    //               mode="inline"
    //               placement="top"
    //               showText={false}
    //               type="textfield"
    //               renderConfirmElement={confirmElement}
    //               renderCancelElement={cancelElement}
    //               onSubmit={value => {
    //                 submitUpdateNumberOfLabel(item, value)
    //                 onChangeData && onChangeData()
    //               }}
    //             />
    //           </div>
    //         ) : (
    //           <span>---</span>
    //         )}
    //       </>
    //     )
    //   },
    // },
    ...(displayCollected
      ? [
        {
          dataField: "collectorName",
          text: t("Collector"),
          headerStyle: {
            width: "140px",
            padding: "8px 0px",
            fontSize: 14,
          },
          style: {
            padding: "8px 0px",
            fontSize: 14,
          },
          align: "left",
          headerAlign: "left",
          formatter: (cellContent, item, index) => {
            return item.collectionTime && item.collectorName ? item.collectorName : "";
          }
          // formatter: (cellContent, item, index) => {
          //   return (
          //     <>
          //       {item.isCollected &&
          //       item.isClear &&
          //       item.state !== RESULT_STATE_Text.Canceled ? (
          //         <div onClick={e => e.stopPropagation()}>
          //           <CustomPopover
          //             isSearch={true}
          //             code={InlineEditType.TESTREQUEST_SAMPLE_COLLECTOR}
          //             title={item.collectorName}
          //             valueName={item.collectorName}
          //             value={`${item.collectorUserId}`}
          //             onChange={value => {
          //               onSetRequestSampleCollector({ item, value })
          //               onChangeData && onChangeData()
          //             }}
          //           />
          //         </div>
          //       ) : (
          //         <span>---</span>
          //       )}
          //     </>
          //   )
          // },
        },
      ]
      : []),
    ...(displayCollected
      ? [
        {
          dataField: "collectionTime",
          text: t("Collection Time"),
          align: "left",
          headerAlign: "left",
          style: { width: "140px" },
          formatter: (cellContent, item, index) => {
            return convertDateFormat(cellContent, "HH:mm:ss DD-MM-YYYY")
          }
          // formatter: (cellContent, item, index) => {
          //   return (
          //     <>
          //       {item.isCollected &&
          //       item.isClear &&
          //       item.state !== RESULT_STATE_Text.Canceled ? (
          //         <div onClick={e => e.stopPropagation()}>
          //           <CustomPopover
          //             type={"datetime"}
          //             code={InlineEditType.TESTREQUEST_SAMPLE_TIME}
          //             title={convertDateFormat(item.collectionTime)}
          //             valueName={item.collectionTime}
          //             value={`${item.collectionTime}`}
          //             namepopover="dateTime_collectionTime"
          //             onChange={values => {
          //               let value = moment(values[0]).format(
          //                 "YYYY-MM-DDTHH:mm"
          //               )
          //               onSetRequestSampleCollectTime({ item, value })
          //               onChangeData && onChangeData()
          //             }}
          //           />
          //         </div>
          //       ) : (
          //         <span>---</span>
          //       )}
          //     </>
          //   )
          // },
        },
      ]
      : []),
    ...(displayReceived
      ? [
        {
          dataField: "receiverName",
          text: t("Receiver"),
          headerStyle: {
            width: "140px",
            padding: "8px 0px",
            fontSize: 14,
          },
          style: {
            padding: "8px 0px",
            fontSize: 14,
            width: "140px",
          },
          align: "left",
          headerAlign: "left",
          formatter: (cellContent, item, index) => {
            return cellContent || ''
          }
          // formatter: (cellContent, item, index) => {
          //   return (
          //     <>
          //       {item.isReceived &&
          //       item.isClear &&
          //       item.state !== RESULT_STATE_Text.Canceled ? (
          //         <div onClick={e => e.stopPropagation()}>
          //           <CustomPopover
          //             isSearch={true}
          //             code={InlineEditType.TESTREQUEST_SAMPLE_COLLECTOR}
          //             title={item.receiverName}
          //             valueName={item.receiverName}
          //             value={`${item.receiverUserId}`}
          //             onChange={value => {
          //               onSetRequestSampleReceiver({ item, value })
          //               onChangeData && onChangeData()
          //             }}
          //           />
          //         </div>
          //       ) : (
          //         <span>---</span>
          //       )}
          //     </>
          //   )
          // },
        },
      ]
      : []),
    ...(displayReceived
      ? [
        {
          dataField: "receivedTime",
          text: t("Received Time"),
          align: "left",
          headerAlign: "left",
          style: { width: "140px" },
          formatter: (cellContent, item, index) => {
            return cellContent || ''
          }
          // formatter: (cellContent, item, index) => (
          //   <React.Fragment>
          //     {item.isReceived &&
          //     item.isClear &&
          //     item.state !== RESULT_STATE_Text.Canceled ? (
          //       <div onClick={e => e.stopPropagation()}>
          //         <CustomPopover
          //           type={"datetime"}
          //           code={InlineEditType.TESTREQUEST_SAMPLE_TIME}
          //           title={convertDateFormat(item.receivedTime)}
          //           valueName={item.receivedTime}
          //           value={`${item.receivedTime}`}
          //           namepopover="dateTime_receivedTime"
          //           onChange={value => {
          //             onSetRequestSampleReceivedTime({ item, value })
          //             onChangeData && onChangeData()
          //           }}
          //         />
          //       </div>
          //     ) : (
          //       <span>---</span>
          //     )}
          //   </React.Fragment>
          // ),
        },
      ]
      : []),
    ...(generalSettingObject?.QualityField ? [{
      dataField: "qualityName",
      text: t("Quality"),
      style: {
        padding: "0px",
        textAlign: "center",
        textDecoration: "underline",
        color: "blue",
        width: "100px",
      },
      headerStyle: {
        textAlign: "center",
        fontSize: 14,
        width: `${widthColumn + 75}px`,
        padding: "8px 0px",
      },
      // formatter: (cellContent, item, index) => {
      //   return (
      //     <>
      //       {item.isClear && item.state !== RESULT_STATE_Text.Canceled ? (
      //         <CustomPopover
      //           code={parameterCode.TESTREQUEST_SAMPLE_QUALITY}
      //           title={item.qualityName?.trim()}
      //           valueName={item.qualityName}
      //           value={`${item.quality}`}
      //           onChange={value => {
      //             onSetRequestSampleQuality({ item, value })
      //             onChangeData && onChangeData()
      //           }}
      //         />
      //       ) : (
      //         <span>---</span>
      //       )}
      //     </>
      //   )
      // },
    }] : []),
    {
      dataField: "",
      text: t("message:Action"),
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center", width: `${widthColumn + 220}px` },
      formatter: (cell, item, index) => {
        return (
          <div className="d-flex gap-2 justify-content-center">
            <CustomButton
              text={t("common:Print")}
              type="submit"
              color="success"
              className="save-user button-width"
              style={{ paddingBottom: 2, paddingTop: 2 }}
              onClick={() => {
                onPrintBarcodeHandler(cell, item, index)
              }}
              disabled={item.state === RESULT_STATE_Text.Canceled}
            >
              <i className="fa fa-print" style={{ marginRight: 8 }}></i>
              {t("common:Print")}
            </CustomButton>
            <Check permission={permissionType.CancelCollectSample} resource={RESOURCE} fallback={() => <></>}>
              <CustomButton
                type="submit"
                color="danger"
                style={{ paddingBottom: 2, paddingTop: 2 }}
                disabled={item.state === RESULT_STATE_Text.Canceled || item.state === RESULT_STATE_Text.RDS || item.state < RESULT_STATE_Text.Collected}
                className="save-user button-width"
                onClick={() => {
                  onCancelCollectSample(item)
                }}
              >
                {t("common:Cancel")} {t("testConfig:Sample Collection")}
              </CustomButton>
            </Check>
          </div>
        )
      },
    },
    // {
    //   dataField: " ",
    //   text: t("common:Cancel"),
    //   style: { textAlign: "center", width: '50px' },
    //   headerStyle: { textAlign: "center", width: `${widthColumn + 20}px` },
    //   formatter: (cell, item, index) => {
    //     return (
    //       <Check permission={permissionType.CancelCollectSample} resource={RESOURCE} fallback={() => <></>}>
    //         <CustomButton
    //           type="submit"
    //           color="danger"
    //           style={{ paddingBottom: 2, paddingTop: 2 }}
    //           disabled={item.state === RESULT_STATE_Text.Canceled || item.state === RESULT_STATE_Text.RDS || item.state < RESULT_STATE_Text.Collected}
    //           className="save-user button-width"
    //           onClick={() => {
    //             onCancelCollectSample(item)
    //           }}
    //         >
    //           {t("common:Cancel")} {t("testConfig:Sample Collection")}
    //         </CustomButton>
    //       </Check>
    //     )
    //   },
    // }
  ]

  return (
    <div>
      <CustomBootstrapInlineTable
        columns={columns}
        data={subProfileData}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
        isScrollable
        loading={loading}
        updatedTime={updatedTime}
        searchText={""}
        draggable
        keyField={"id"}
        {...rest}
        isEnableLoadInfo={true}
      />
    </div>
  )
}

DetailSampleTabTable.propTypes = {
  onSetRequestSampleCollector: PropTypes.func,
  onSetRequestSampleCollectTime: PropTypes.func,
  onSetRequestSampleQuality: PropTypes.func,
  onSetRequestSampleClear: PropTypes.func,
}

const mapStateToProps = ({ testRequest, generalSetting }) => ({
  testRequest: testRequest.testRequest,
  generalSetting: generalSetting.generalSetting,
})

const mapDispatchToProps = dispatch => ({
  onSetRequestSampleCollector: payload =>
    dispatch(setRequestSampleCollector(payload)),

  onSetRequestSampleCollectTime: payload =>
    dispatch(setRequestSampleCollectTime(payload)),

  onSetRequestSampleClear: payload =>
    dispatch(setRequestSampleClear(payload)),

  onSetRequestSampleQuality: payload =>
    dispatch(setRequestSampleQuality(payload)),

  onSetRequestSampleReceiver: payload =>
    dispatch(setRequestSampleReceiver(payload)),
  onSetRequestSampleReceivedTime: payload =>
    dispatch(setRequestSampleReceivedTime(payload)),
  onChangeRequestSampleTableSuccess: payload =>
    dispatch(ChangeRequestSampleTableSuccess(payload)),
  onUpdateNumberOfLabel: payload => dispatch(updateNumbOfLabels(payload)),
  onGetGeneralSetting: payload => dispatch(getGeneralSetting(payload))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["testRequestPage", "common"])(DetailSampleTabTable)))