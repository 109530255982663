import {
    GET_LIST_ORGANISMS,
    GET_LIST_ORGANISMS_FAIL,
    GET_LIST_ORGANISMS_SUCCESS,
    GET_ORGANISMS_DETAIL,
    GET_ORGANISMS_DETAIL_FAIL,
    GET_ORGANISMS_DETAIL_SUCCESS,
    ADD_NEW_ORGANISMS,
    ADD_NEW_ORGANISMS_FAIL,
    ADD_NEW_ORGANISMS_SUCCESS,
    UPDATE_ORGANISMS,
    UPDATE_ORGANISMS_FAIL,
    UPDATE_ORGANISMS_SUCCESS,
    DELETE_ORGANISMS,
    DELETE_ORGANISMS_FAIL,
    DELETE_ORGANISMS_SUCCESS,
    GET_LIST_ORGANISMS_GENUS,
    GET_LIST_ORGANISMS_GENUS_FAIL,
    GET_LIST_ORGANISMS_GENUS_SUCCESS,
    GET_ORGANISMS_GENUS_DETAIL,
    GET_ORGANISMS_GENUS_DETAIL_FAIL,
    GET_ORGANISMS_GENUS_DETAIL_SUCCESS,
    ADD_NEW_ORGANISMS_GENUS,
    ADD_NEW_ORGANISMS_GENUS_FAIL,
    ADD_NEW_ORGANISMS_GENUS_SUCCESS,
    UPDATE_ORGANISMS_GENUS,
    UPDATE_ORGANISMS_GENUS_FAIL,
    UPDATE_ORGANISMS_GENUS_SUCCESS,
    DELETE_ORGANISMS_GENUS,
    DELETE_ORGANISMS_GENUS_FAIL,
    DELETE_ORGANISMS_GENUS_SUCCESS,
    GET_LIST_ORGANISMS_GROUP,
    GET_LIST_ORGANISMS_GROUP_FAIL,
    GET_LIST_ORGANISMS_GROUP_SUCCESS,
    GET_ORGANISMS_GROUP_DETAIL,
    GET_ORGANISMS_GROUP_DETAIL_FAIL,
    GET_ORGANISMS_GROUP_DETAIL_SUCCESS,
    ADD_NEW_ORGANISMS_GROUP,
    ADD_NEW_ORGANISMS_GROUP_FAIL,
    ADD_NEW_ORGANISMS_GROUP_SUCCESS,
    UPDATE_ORGANISMS_GROUP,
    UPDATE_ORGANISMS_GROUP_FAIL,
    UPDATE_ORGANISMS_GROUP_SUCCESS,
    DELETE_ORGANISMS_GROUP,
    DELETE_ORGANISMS_GROUP_FAIL,
    DELETE_ORGANISMS_GROUP_SUCCESS
} from "./actionTypes"

//VSV
export const getListOrganisms = payload => {
    return {
        type: GET_LIST_ORGANISMS,
        payload: payload || {},
    }
}
export const getListOrganismsSuccess = payload => ({
    type: GET_LIST_ORGANISMS_SUCCESS,
    payload: payload,
})
export const getListOrganismsFail = error => ({
    type: GET_LIST_ORGANISMS_FAIL,
    payload: error,
})

export const getOrganismsDetail = (id, callback) => ({
    type: GET_ORGANISMS_DETAIL,
    id,
    callback,
})
export const getOrganismsDetailSuccess = payload => ({
    type: GET_ORGANISMS_DETAIL_SUCCESS,
    payload: payload,
})
export const getOrganismsDetailFail = error => ({
    type: GET_ORGANISMS_DETAIL_FAIL,
    payload: error,
})

export const addNewOrganisms = payload => ({
    type: ADD_NEW_ORGANISMS,
    payload: payload,
})
export const addNewOrganismsSuccess = payload => ({
    type: ADD_NEW_ORGANISMS_SUCCESS,
    payload: payload,
})
export const addNewOrganismsFail = error => ({
    type: ADD_NEW_ORGANISMS_FAIL,
    payload: error,
})

export const updateOrganisms = payload => ({
    type: UPDATE_ORGANISMS,
    payload: payload,
})
export const updateOrganismsSuccess = payload => ({
    type: UPDATE_ORGANISMS_SUCCESS,
    payload: payload,
})
export const updateOrganismsFail = error => ({
    type: UPDATE_ORGANISMS_FAIL,
    payload: error,
})

export const deleteOrganisms = payload => ({
    type: DELETE_ORGANISMS,
    payload: payload,
})
export const deleteOrganismsSuccess = payload => ({
    type: DELETE_ORGANISMS_SUCCESS,
    payload: payload,
})
export const deleteOrganismsFail = error => ({
    type: DELETE_ORGANISMS_FAIL,
    payload: error,
})

//CHI vsv
export const getListOrganismsGenus = payload => {
    return {
        type: GET_LIST_ORGANISMS_GENUS,
        payload: payload || {},
    }
}
export const getListOrganismsGenusSuccess = payload => ({
    type: GET_LIST_ORGANISMS_GENUS_SUCCESS,
    payload: payload,
})
export const getListOrganismsGenusFail = error => ({
    type: GET_LIST_ORGANISMS_GENUS_FAIL,
    payload: error,
})

export const getOrganismsGenusDetail = (id, callback) => ({
    type: GET_ORGANISMS_GENUS_DETAIL,
    id,
    callback,
})
export const getOrganismsGenusDetailSuccess = payload => ({
    type: GET_ORGANISMS_GENUS_DETAIL_SUCCESS,
    payload: payload,
})
export const getOrganismsGenusDetailFail = error => ({
    type: GET_ORGANISMS_GENUS_DETAIL_FAIL,
    payload: error,
})

export const addNewOrganismsGenus = payload => ({
    type: ADD_NEW_ORGANISMS_GENUS,
    payload: payload,
})
export const addNewOrganismsGenusSuccess = payload => ({
    type: ADD_NEW_ORGANISMS_GENUS_SUCCESS,
    payload: payload,
})
export const addNewOrganismsGenusFail = error => ({
    type: ADD_NEW_ORGANISMS_GENUS_FAIL,
    payload: error,
})

export const updateOrganismsGenus = payload => ({
    type: UPDATE_ORGANISMS_GENUS,
    payload: payload,
})
export const updateOrganismsGenusSuccess = payload => ({
    type: UPDATE_ORGANISMS_GENUS_SUCCESS,
    payload: payload,
})
export const updateOrganismsGenusFail = error => ({
    type: UPDATE_ORGANISMS_GENUS_FAIL,
    payload: error,
})

export const deleteOrganismsGenus = payload => ({
    type: DELETE_ORGANISMS_GENUS,
    payload: payload,
})
export const deleteOrganismsGenusSuccess = payload => ({
    type: DELETE_ORGANISMS_GENUS_SUCCESS,
    payload: payload,
})
export const deleteOrganismsGenusFail = error => ({
    type: DELETE_ORGANISMS_GENUS_FAIL,
    payload: error,
})

//HỌ VSV
export const getListOrganismsGroup = payload => {
    return {
        type: GET_LIST_ORGANISMS_GROUP,
        payload: payload || {},
    }
}
export const getListOrganismsGroupSuccess = payload => ({
    type: GET_LIST_ORGANISMS_GROUP_SUCCESS,
    payload: payload,
})
export const getListOrganismsGroupFail = error => ({
    type: GET_LIST_ORGANISMS_GROUP_FAIL,
    payload: error,
})

export const getOrganismsGroupDetail = (id, callback) => ({
    type: GET_ORGANISMS_GROUP_DETAIL,
    id,
    callback,
})
export const getOrganismsGroupDetailSuccess = payload => ({
    type: GET_ORGANISMS_GROUP_DETAIL_SUCCESS,
    payload: payload,
})
export const getOrganismsGroupDetailFail = error => ({
    type: GET_ORGANISMS_GROUP_DETAIL_FAIL,
    payload: error,
})

export const addNewOrganismsGroup = payload => ({
    type: ADD_NEW_ORGANISMS_GROUP,
    payload: payload,
})
export const addNewOrganismsGroupSuccess = payload => ({
    type: ADD_NEW_ORGANISMS_GROUP_SUCCESS,
    payload: payload,
})
export const addNewOrganismsGroupFail = error => ({
    type: ADD_NEW_ORGANISMS_GROUP_FAIL,
    payload: error,
})

export const updateOrganismsGroup = payload => ({
    type: UPDATE_ORGANISMS_GROUP,
    payload: payload,
})
export const updateOrganismsGroupSuccess = payload => ({
    type: UPDATE_ORGANISMS_GROUP_SUCCESS,
    payload: payload,
})
export const updateOrganismsGroupFail = error => ({
    type: UPDATE_ORGANISMS_GROUP_FAIL,
    payload: error,
})

export const deleteOrganismsGroup = payload => ({
    type: DELETE_ORGANISMS_GROUP,
    payload: payload,
})
export const deleteOrganismsGroupSuccess = payload => ({
    type: DELETE_ORGANISMS_GROUP_SUCCESS,
    payload: payload,
})
export const deleteOrganismsGroupFail = error => ({
    type: DELETE_ORGANISMS_GROUP_FAIL,
    payload: error,
})
