export const GET_LIST_CHANGE_QC = "GET_LIST_CHANGE_QC"
export const GET_LIST_CHANGE_QC_SUCCESS = "GET_LIST_CHANGE_QC_SUCCESS"
export const GET_LIST_CHANGE_QC_FAIL = "GET_LIST_CHANGE_QC_FAIL"
/* DETAIL */
export const GET_CHANGE_QC_DETAIL = "GET_CHANGE_QC_DETAIL"
export const GET_CHANGE_QC_DETAIL_SUCCESS = "GET_CHANGE_QC_DETAIL_SUCCESS"
export const GET_CHANGE_QC_DETAIL_FAIL = "GET_CHANGE_QC_DETAIL_FAIL"
/* ADD */
export const ADD_NEW_CHANGE_QC = "ADD_NEW_CHANGE_QC"
export const ADD_NEW_CHANGE_QC_SUCCESS = "ADD_NEW_CHANGE_QC_SUCCESS"
export const ADD_NEW_CHANGE_QC_FAIL = "ADD_NEW_CHANGE_QC_FAIL"
/* EDIT */
export const UPDATE_CHANGE_QC = "UPDATE_CHANGE_QC"
export const UPDATE_CHANGE_QC_SUCCESS = "UPDATE_CHANGE_QC_SUCCESS"
export const UPDATE_CHANGE_QC_FAIL = "UPDATE_CHANGE_QC_FAIL"
/* DELETE */
export const DELETE_CHANGE_QC = "DELETE_CHANGE_QC"
export const DELETE_CHANGE_QC_SUCCESS = "DELETE_CHANGE_QC_SUCCESS"
export const DELETE_CHANGE_QC_FAIL = "DELETE_CHANGE_QC_FAIL"

export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY"


