import PropTypes from "prop-types"
import { Label, Row } from "reactstrap"
import { CustomDatePicker, CustomSelect } from "components/Common"
import { parameterCode } from "constant"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

const FilterForm = ({ model, t, onChange }) => {
    return (
        <>
            <Row>
            <div className="col-12 mb-3">
                    <CustomSelect
                        label={t("Lot QC")}
                        portal
                        name="lot"
                        value={model?.lot}
                        code={parameterCode.LOT_QC}
                        onChange={(e, vals) => {
                            onChange({ lot: vals[0] || '' })
                        }}
                    />
                </div>
                <div className="col-12 mb-3">
                    <CustomSelect
                        label={t("Group Lot QC")}
                        portal
                        name="lotGroup"
                        value={model?.lotGroup}
                        code={parameterCode.LOT_GROUP_QC}
                        onChange={(e, vals) => {
                            onChange({ lotGroup: vals[0] || '' })
                        }}
                    />
                </div>
                <div className="col-12 mb-3">
                    <CustomSelect
                        label={t("Category")}
                        portal
                        name="category"
                        value={model?.category }
                        code={parameterCode.TEST_CATEGORIES}
                        onChange={(e, vals) => {
                            onChange({ category: vals[0] || '' })
                        }}
                    />
                </div>
            </Row>

        </>
    )
}

FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any
}

const mapDispatchToProps = dispatch => ({
})

export default withTranslation(["chartiQCPage", "common"])(connect(
    null,
    mapDispatchToProps
)(FilterForm))