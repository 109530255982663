import React from "react"
import { AvForm } from "availity-reactstrap-validation"
import { ModalBody, ModalFooter } from "reactstrap"

import { CustomModal, CustomButton, Check } from "components/Common"
import { GeneralInformation } from "../Form"

//i18n
import { withTranslation } from "react-i18next"
import { isEmpty } from "lodash"
import { ModuleIds, permissionType } from "constant"

const RESOURCE = ModuleIds.Role

const RoleModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  formEl,
  t,
}) => {
  const title =
    (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("Role")

  if (isEdit && isEmpty(data)) return null
  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody className="">
        <AvForm
          ref={formEl}
          id="companyForm"
          onValidSubmit={onValidSubmit}
          model={data}
        >
          {/* GeneralInformation */}
          <GeneralInformation t={t} data={data} isEdit={isEdit} />

          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <Check
          resource={RESOURCE}
          permission={isEdit ? permissionType.U : permissionType.C}
        >
          <CustomButton
            type="button"
            onClick={toggle}
            data-dismiss="modal"
            text={t("common:Cancel")}
            className="button-width"
          />
          <CustomButton
            type="submit"
            onClick={() => formEl?.current?.submit()}
            color="primary"
            className="save-user button-width"
            text={t("common:Save")}
            isEdit
            isModal
          />
        </Check>
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["rolePage","common"])(RoleModal)
