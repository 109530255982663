import React, { useCallback, useEffect, useState } from "react"
import { AvForm } from "availity-reactstrap-validation"

import { ModalBody, Row, Col, ModalFooter } from "reactstrap"

import {
    CustomSelect,
    CustomModal,
    CustomButton,
    CustomAvField,
    CustomCheckbox,
    CustomSelectGroup,
    CustomSelectAsync,
} from "components/Common"
import { parameterCode } from "constant/utility"

//i18n
import { withTranslation } from "react-i18next"
import { getInvalidMessageI18n } from "helpers/utilities"
import { isEmpty } from "lodash"
import { getAllParameters } from "helpers/app-backend"

const ConnectorModal = ({
    modal,
    toggle,
    isEdit,
    onValidSubmit,
    data,
    formEl,
    t,
}) => {
    const title =
        (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("HIS")
    if (isEdit && isEmpty(data)) return null
    const [isRequireTestMapping, setIsRequireTestMapping] = useState(false);
    const [isRequireProfileMapping, setIsRequireProfileMapping] = useState(false);
    const [isRequireInsuranceMapping, setIsRequireInsuranceMapping] = useState(false);
    const [dataOptions, setDataOptions] = useState([]);
    const checkRequire = value => {
        return value !== null && value !== undefined && value !== '';
    }
    useEffect(() => {
        setIsRequireTestMapping(checkRequire(data.testParamaterKey))
        setIsRequireProfileMapping(checkRequire(data.profileParamaterKey))
    }, [data])

    useEffect(() => {
        fetchAllParameters()
        return () => {
            setDataOptions([]);
        };
    }, [])

    const fetchAllParameters = useCallback(async () => {
        const query = {
            size: 15,
            search: '',
        }
        const res = await getAllParameters(query)
        let data = []
        if (res.data)
            data = res.data.map(_item => {
                _item.value = _item.parameterKey
                _item.label = `${_item.name}`
                return _item
            })
        setDataOptions(data)
    }, [])
    return (
        <CustomModal modal={modal} title={title} onToggle={toggle}>
            <ModalBody>
                <AvForm
                    ref={formEl}
                    id="profileForm"
                    onValidSubmit={onValidSubmit}
                    model={data}
                >
                    <CustomAvField name="id" type="hidden" value={data.id || ""} />
                    <Row>
                        <Col xs="6">
                            <div className="mb-3">
                                <CustomAvField
                                    name="code"
                                    type="text"
                                    required
                                    errorMessage={getInvalidMessageI18n(t, "Manage Code")}
                                    validate={{
                                        required: { value: true },
                                    }}
                                    value={data.code || ""}
                                    label={t("Manage Code")}
                                    detected={isEdit}
                                    maxLength={50}
                                />
                            </div>
                        </Col>
                        <Col xs="6">
                            <div className="mb-3">
                                <CustomAvField
                                    name="name"
                                    type="text"
                                    required
                                    errorMessage={getInvalidMessageI18n(t, "HIS Name")}
                                    validate={{
                                        required: { value: true },
                                    }}
                                    value={data.name || ""}
                                    label={t("HIS Name")}
                                    detected={isEdit}
                                    maxLength={200}
                                />
                            </div>
                        </Col>
                        <Col xs="6">
                            <div className="mb-3">
                                <CustomSelect
                                    name="companyId"
                                    value={data.companyId || ''}
                                    required
                                    errorMessage={getInvalidMessageI18n(t, "common:Company")}
                                    validate={{
                                        required: { value: true },
                                    }}
                                    code={parameterCode.COMPANIES}
                                    label={t("common:Company")}
                                    detected={isEdit}
                                />
                            </div>
                        </Col>
                        <Col xs="6">
                            <div className="mb-3">
                                <CustomAvField
                                    name="serviceUrl"
                                    value={data.serviceUrl || ""}
                                    label={t("Service Url")}
                                    detected={isEdit}
                                    type="text"
                                    maxLength={50}
                                />
                            </div>
                        </Col>
                        <Col xs="6">
                            <div className="mb-3">
                                <CustomAvField
                                    name="remark"
                                    value={data.remark || ""}
                                    label={t("common:Remark")}
                                    detected={isEdit}
                                    type="textarea"
                                    rows="3"
                                    maxLength={300}
                                />
                            </div>
                        </Col>
                        <Col xs="6">
                            <div className="mb-3">
                                <CustomCheckbox
                                    type="checkbox"
                                    direction={"down"}
                                    name="statuss"
                                    checked={!!data.status}
                                    label={`${t("common:Active")} ?`}
                                    detected={isEdit}
                                />
                            </div>
                        </Col>
                        <Col className="col-12">
                            <div className={`accordion custom-accordion `} id="accordion">
                                <div className="accordion-list py-2">
                                    <div className="accordion-title">
                                        {t("Mapping Setup")}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs="6">
                            <div className="mb-3">
                                <CustomSelectAsync
                                    name="testParamaterKey"
                                    value={data.testParamaterKey || ""}
                                    valueName={data.testParamaterKeyName || ""}
                                    code={parameterCode.HISCONNECTOR}
                                    group={parameterCode.HIS_CODE}
                                    isDependent={true}
                                    label={t("Test Paramater")}
                                    detected={isEdit}
                                    onChange={(e, values) => {
                                        setIsRequireTestMapping(checkRequire(values[0]))
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xs="6">
                            <div className="mb-3">
                                <CustomSelectAsync
                                    name="testMappingKey"
                                    value={data.testMappingKey || ""}
                                    valueName={data.testMappingKeyName || ""}
                                    code={parameterCode.HISCONNECTOR}
                                    group={parameterCode.MAPPING_CODE}
                                    isDependent={true}
                                    label={t("Test Mapping")}
                                    detected={isEdit}
                                    required={isRequireTestMapping}
                                    errorMessage={getInvalidMessageI18n(t, "Test Mapping")}
                                    validate={{
                                        required: { value: isRequireTestMapping },
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xs="6">
                            <div className="mb-3">
                                <CustomSelectAsync
                                    name="profileParamaterKey"
                                    value={data.profileParamaterKey || ""}
                                    valueName={data.profileParamaterKeyName || ""}
                                    code={parameterCode.HISCONNECTOR}
                                    group={parameterCode.HIS_CODE}
                                    isDependent={true}
                                    label={t("Profile Paramater")}
                                    detected={isEdit}
                                    onChange={(e, values) => {
                                        setIsRequireProfileMapping(checkRequire(values[0]))
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xs="6">
                            <div className="mb-3">
                                <CustomSelectAsync
                                    name="profileMappingKey"
                                    value={data.profileMappingKey || ""}
                                    valueName={data.profileMappingKeyName || ""}
                                    code={parameterCode.HISCONNECTOR}
                                    group={parameterCode.MAPPING_CODE}
                                    isDependent={true}
                                    label={t("Profile Mapping")}
                                    detected={isEdit}
                                    required={isRequireProfileMapping}
                                    errorMessage={getInvalidMessageI18n(t, "Profile Mapping")}
                                    validate={{
                                        required: { value: isRequireProfileMapping },
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xs="6">
                            <div className="mb-3">
                                <CustomSelectAsync
                                    name="insuranceParameterKey"
                                    value={data.insuranceParameterKey || ""}
                                    valueName={data.insuranceParameterKeyName || ""}
                                    code={parameterCode.HISCONNECTOR}
                                    group={parameterCode.HIS_CODE}
                                    isDependent={true}
                                    label={t("Insurance Paramater")}
                                    detected={isEdit}
                                    onChange={(e, values) => {
                                        setIsRequireInsuranceMapping(checkRequire(values[0]))
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xs="6">
                            <div className="mb-3">
                                <CustomSelectAsync
                                    name="insuranceMappingKey"
                                    value={data.insuranceMappingKey || ""}
                                    valueName={data.insuranceMappingKeyName || ""}
                                    code={parameterCode.HISCONNECTOR}
                                    group={parameterCode.MAPPING_CODE}
                                    isDependent={true}
                                    label={t("Insurance Mapping")}
                                    detected={isEdit}
                                    required={isRequireInsuranceMapping}
                                    errorMessage={getInvalidMessageI18n(t, "Insurance Mapping")}
                                    validate={{
                                        required: { value: isRequireInsuranceMapping },
                                    }}
                                />
                            </div>
                        </Col>
                        <Col xs="6">
                            <div className="mb-3">
                                <CustomSelectAsync
                                    name="departmentMappingKey"
                                    value={data.departmentMappingKey || ""}
                                    valueName={data.departmentMappingKeyName || ""}
                                    code={parameterCode.HISCONNECTOR}
                                    label={t("Department Mapping")}
                                    detected={isEdit}
                                    errorMessage={getInvalidMessageI18n(t, "Department Mapping")}
                                />
                            </div>
                        </Col>
                        <Col xs="6">
                            <div className="mb-3">
                                <CustomSelectAsync
                                    name="instrumentMappingKey"
                                    value={data.instrumentMappingKey || ""}
                                    valueName={data.instrumentMappingKeyName || ""}
                                    code={parameterCode.HISCONNECTOR}
                                    label={t("Instrument Mapping")}
                                    detected={isEdit}
                                    errorMessage={getInvalidMessageI18n(t, "Instrument Mapping")}
                                />
                            </div>
                        </Col>
                    </Row>
                    <button type="submit" className="d-none" />
                </AvForm>
            </ModalBody>
            <ModalFooter>
                <CustomButton
                    text={t("common:Cancel")}
                    type="button"
                    onClick={toggle}
                    data-dismiss="modal"
                    className="button-width"
                />
                <CustomButton
                    text={t("common:Save")}
                    type="submit"
                    color="primary"
                    onClick={() => formEl?.current?.submit()}
                    className="save-user button-width"
                    isEdit
                    isModal
                />
            </ModalFooter>
        </CustomModal>
    )
}

export default withTranslation(["hisConnectorPage", "common"])(ConnectorModal)
