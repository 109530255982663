import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const showPersistentToastNotify = (id, message = "", onClick = null) => {
  toast.warning(
    <div style={{ display: 'flex', alignItems: 'center', fontSize: '12px', maxWidth: '300px', wordWrap: 'break-word', lineHeight: '1.5' }}>
      <i className="mdi mdi-alert" style={{ marginRight: '8px', color: '#FFA500', fontSize: '25px' }}></i>
      <div dangerouslySetInnerHTML={{ __html: message }}></div>
    </div>,
    {
      position: "bottom-right",
      autoClose: 30 * 60 * 1000, // 30 minutes in milliseconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      onClose: () => {
        const currentNotifications = JSON.parse(localStorage.getItem("persistentNotifications") || "[]");
        const updatedNotifications = currentNotifications.filter(notification => notification.id !== id);
        localStorage.setItem("persistentNotifications", JSON.stringify(updatedNotifications));
      },
      onClick: () => {
        onClick && onClick();
      }
    }
  );
};

export { showPersistentToastNotify };
