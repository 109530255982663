import ANTIBIOTIC_RESISTANCES from "./actionTypes"

const INIT_STATE = {
  antibioticResistances: [],
  antibioticResistancesDetail: null,
  loading: false,
  paging: {},
  loadingDetail: false,
  updating: false,
  updateTime: new Date(),
  error: null,
}

const AntibioticResistances = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ANTIBIOTIC_RESISTANCES.GET_LIST_ANTIBIOTIC_RESISTANCES: {
      return { ...state, loading: true, error: {} }
    }
    case ANTIBIOTIC_RESISTANCES.GET_LIST_ANTIBIOTIC_RESISTANCES_SUCCESS: {
      const { data, totalElements, ...rest } = action.payload
      return {
        ...state,
        antibioticResistances: data,
        paging: { ...rest, dataSize: totalElements },
        loading: false,
        updatedTime: new Date(),
      }
    }
    case ANTIBIOTIC_RESISTANCES.GET_LIST_ANTIBIOTIC_RESISTANCES_FAIL: {
      return { ...state, loading: false, error: action.payload }
    }
    case ANTIBIOTIC_RESISTANCES.GET_ANTIBIOTIC_RESISTANCES_DETAIL: {
      return {
        ...state,
        loadingDetail: true,
        antibioticResistancesDetail: {},
        error: {},
      }
    }
    case ANTIBIOTIC_RESISTANCES.GET_ANTIBIOTIC_RESISTANCES_DETAIL_SUCCESS: {
      return {
        ...state,
        antibioticResistancesDetail: action.payload,
        loadingDetail: false,
      }
    }
    case ANTIBIOTIC_RESISTANCES.GET_ANTIBIOTIC_RESISTANCES_DETAIL_FAIL: {
      return { ...state, loadingDetail: false, error: action.payload }
    }
    case ANTIBIOTIC_RESISTANCES.ADD_NEW_ANTIBIOTIC_RESISTANCES: {
      return { ...state, error: {} }
    }
    case ANTIBIOTIC_RESISTANCES.ADD_NEW_ANTIBIOTIC_RESISTANCES_SUCCESS: {
      return { ...state }
    }
    case ANTIBIOTIC_RESISTANCES.ADD_NEW_ANTIBIOTIC_RESISTANCES_FAIL: {
      return { ...state, error: action.payload }
    }
    case ANTIBIOTIC_RESISTANCES.UPDATE_ANTIBIOTIC_RESISTANCES: {
      return { ...state, updating: true, error: {} }
    }
    case ANTIBIOTIC_RESISTANCES.UPDATE_ANTIBIOTIC_RESISTANCES_SUCCESS: {
      return { ...state, updating: false }
    }
    case ANTIBIOTIC_RESISTANCES.UPDATE_ANTIBIOTIC_RESISTANCES_FAIL: {
      return { ...state, updating: false, error: action.payload }
    }
    case ANTIBIOTIC_RESISTANCES.DELETE_ANTIBIOTIC_RESISTANCES: {
      return { ...state }
    }
    case ANTIBIOTIC_RESISTANCES.DELETE_ANTIBIOTIC_RESISTANCES_SUCCESS: {
      return { ...state }
    }
    case ANTIBIOTIC_RESISTANCES.DELETE_ANTIBIOTIC_RESISTANCES_FAIL: {
      return { ...state, error: action.payload }
    }
    default:
      return state
  }
}

export default AntibioticResistances
