import { CustomBootstrapTable, CustomButton } from "components/Common"
import { ModuleIds } from "constant"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import ReactTooltip from "react-tooltip"
import CalculationSigmaTEButtons from "./CalculationSigmaTEButtons"
import ConfigCalculateModal from "./Modals/ConfigCalculateModal"
import { isEmpty } from "lodash"
import { getQCExpressionList } from "helpers/app-backend/IQC/QCExpression_backend_helper"
import { convertDateFormat } from "helpers/utilities"

const RESOURCE = ModuleIds.CalculationiQC

const CalculationSigmaTETable = ({
  onSelect,
  lottests,
  config,
  onSearch,
  onRefresh,
  onSubmitFilter,
  loading,
  t,
  updatedTime,
  onChangeModel,
  submitConfig,
  onLottestExport,
  onLottestChange,
  onLotChange,
  lot,
}) => {
  const [data, setData] = useState([])
  const [model, setModel] = useState({})
  const [machine, setMachine] = useState({})
  const [isCVEnable, setIsCVEnable] = useState(null)
  const [isBiasEnable, setBiasEnable] = useState(null)

  useEffect(() => {
    fetchExpression()
  }, [])

  useEffect(() => {
    onChangeModel && onChangeModel(model)
  }, [model])

  useEffect(() => {
    if (lottests) {
      let lottestData = []
      lottests?.forEach(item => {
        if (item.lottests) {
          item.lottests?.forEach(x => {
            x.testName = item.testName
          })
          lottestData.push(...item.lottests)
        }
      })
      setData([...lottestData])
      onLottestChange(lottests)
    }
  }, [lottests])

  const fetchExpression = async () => {
    let query = { size: 0, inUse: true }
    const res = await getQCExpressionList(query)

    const cv = res.data.find(item => item.manageCode == "CV")
    const bias = res.data.find(item => item.manageCode == "NSX")

    if (cv) {
      setIsCVEnable(cv)
    }

    if (bias) {
      setBiasEnable(bias)
    }
  }

  const columns = [
    {
      dataField: "id",
      text: "",
      hidden: true,
      formatter: (cellContent, item, index) => {
        return cellContent
      },
    },
    {
      dataField: "",
      text: t(""),
      headerStyle: { width: "30px" },
      formatter: (cellContent, item, index) => {
        return (
          <div className="d-flex flex-column gap-3">
            <input
              type="checkbox"
              className="form-check-input"
              onChange={(e) => { onSelect(item, e.target.checked) }}
            />
          </div>
        )
      },
    },
    {
      dataField: "SEQ",
      text: "#",
      headerStyle: { width: "30px" },
      formatter: (cellContent, item, index) => {
        return index + 1
      },
    },

    {
      dataField: "testCode",
      text: t("Test Code"),
      style: { minWidth: 100, maxWidth: 100, with: 100 },
      headerStyle: { minWidth: 100, maxWidth: 100, with: 100 },
    },
    {
      dataField: "testName",
      text: t("Test Name"),
      style: { minWidth: 300, maxWidth: 300, with: 300 },
      headerStyle: { minWidth: 300, maxWidth: 300, with: 300 },
    },
    {
      dataField: "unit",
      text: t("Unit"),
      style: { width: "30px" },
      headerStyle: { width: "30px" },
    },
    {
      dataField: "levels",
      text: t("Level"),
      style: { width: "30px" },
      headerStyle: { width: "30px" },
    },
    {
      dataField: "numPoint",
      text: t("Number of Runs"),
      style: { minWidth: 150, maxWidth: 150, with: 150 },
      headerStyle: { minWidth: 150, maxWidth: 150, with: 150 },
    },
    {
      dataField: "meanPI",
      text: t("Mean NSX"),
      style: { minWidth: 150, maxWidth: 150, with: 150 },
      headerStyle: { minWidth: 150, maxWidth: 150, with: 150 },
      formatter: (cellContent, item, index) => {
        return <div className="d-flex flex-column gap-3">
          {!!item?.meanPI && parseFloat(item?.meanPI)}
        </div>
      },
    },
    {
      dataField: "sdpi",
      text: t("SD NSX"),
      style: { minWidth: 150, maxWidth: 150, with: 150 },
      headerStyle: { minWidth: 150, maxWidth: 150, with: 150 },
      formatter: (cellContent, item, index) => {
        return <div className="d-flex flex-column gap-3">
          {!!item?.sdpi && parseFloat(item?.sdpi)}
        </div>
      },
    },
    // {
    //   dataField: "mean",
    //   text: t("Mean Old"),
    //   style: { minWidth: 150, maxWidth: 150, with: 150 },
    //   headerStyle: { minWidth: 150, maxWidth: 150, with: 150 },
    //   formatter: (cellContent, item, index) => {
    //     return (
    //       <div className="d-flex flex-column gap-3">
    //         {parseFloat(item?.oldMean?.toFixed(3))}
    //       </div>
    //     )
    //   },
    // },
    // {
    //   dataField: "sd",
    //   text: t("SD Old"),
    //   style: { minWidth: 150, maxWidth: 150, with: 150 },
    //   headerStyle: { minWidth: 150, maxWidth: 150, with: 150 },
    //   formatter: (cellContent, item, index) => {
    //     return (
    //       <div className="d-flex flex-column gap-3">
    //         {parseFloat(item?.oldSD)}
    //       </div>
    //     )
    //   },
    // },
    {
      dataField: "mean",
      text: t("Mean PXN"),
      style: { minWidth: 150, maxWidth: 150, with: 150 },
      headerStyle: { minWidth: 150, maxWidth: 150, with: 150 },
      formatter: (cellContent, item, index) => {
        return <div className="d-flex flex-column gap-3">
          {!!item?.mean && parseFloat(item?.mean)}
        </div>
      },
    },
    {
      dataField: "sd",
      text: t("SD PXN"),
      style: { minWidth: 150, maxWidth: 150, with: 150 },
      headerStyle: { minWidth: 150, maxWidth: 150, with: 150 },
      formatter: (cellContent, item, index) => {
        return <div className="d-flex flex-column gap-3">
          {!!item?.sd && parseFloat(item?.sd)}
        </div>
      },
    },
    {
      dataField: "cv",
      text: t("%CV"),
      style: { minWidth: 150, maxWidth: 150, with: 150 },
      headerStyle: { minWidth: 150, maxWidth: 150, with: 150 },
      formatter: (cellContent, item, index) => {
        return <div className="d-flex flex-column gap-3">
          {!!item?.cv && parseFloat(item?.cv)}
        </div>
      },
    },
    {
      dataField: "biasPercent",
      text: t("Bias%"),
      style: { minWidth: 150, maxWidth: 150, with: 150 },
      headerStyle: { minWidth: 150, maxWidth: 150, with: 150 },
      formatter: (cellContent, item, index) => {
        return (
          <div className="d-flex flex-column gap-3">
            {!!item?.biasPercent && parseFloat(item?.biasPercent?.toFixed(6))}
          </div>
        )
      },
    },
    {
      dataField: "tEa",
      text: t("TEa"),
      style: { minWidth: 150, maxWidth: 150, with: 150 },
      headerStyle: { minWidth: 150, maxWidth: 150, with: 150 },
      formatter: (cellContent, item, index) => {
        return (
          <div className="d-flex flex-column gap-3">
            {!!item?.tEa && parseFloat(item?.tEa?.toFixed(3))}
          </div>
        )
      },
    },
    {
      dataField: "biasUnit",
      text: t("BiasUnit"),
      style: { minWidth: 150, maxWidth: 150, with: 150 },
      headerStyle: { minWidth: 150, maxWidth: 150, with: 150 },
      formatter: (cellContent, item, index) => {
        return (
          <div className="d-flex flex-column gap-3">
            {!!item?.biasUnit && parseFloat(item?.biasUnit?.toFixed(6))}
          </div>
        )
      },
    },
    {
      dataField: "te",
      text: t("TE"),
      style: { minWidth: 150, maxWidth: 150, with: 150 },
      headerStyle: { minWidth: 150, maxWidth: 150, with: 150 },
      formatter: (cellContent, item, index) => {
        return (
          <div className="d-flex flex-column gap-3">
            {!!item?.te && parseFloat(item?.te?.toFixed(6))}
          </div>
        )
      },
    },
    {
      dataField: "sigma",
      text: t("Sigma"),
      style: { minWidth: 150, maxWidth: 150, with: 150 },
      headerStyle: { minWidth: 150, maxWidth: 150, with: 150 },
      formatter: (cellContent, item, index) => {
        return (
          <div className="d-flex flex-column gap-3">
            {!!item?.sigma && parseFloat(item?.sigma?.toFixed(6))}
          </div>
        )
      },
    },
    {
      dataField: "qgi",
      text: t("QGI"),
      style: { minWidth: 150, maxWidth: 150, with: 150 },
      headerStyle: { minWidth: 150, maxWidth: 150, with: 150 },
      formatter: (cellContent, item, index) => {
        return (
          <div className="d-flex flex-column gap-3">
            {!!item?.qgi && parseFloat(item?.qgi?.toFixed(6))}
          </div>
        )
      },
    },
  ]

  return (
    <div className="calculation-table">
      <CustomBootstrapTable
        columns={columns}
        data={data}
        onRefresh={onRefresh}
        showSelectRow={false}
        isEnableRefresh={false}
        onSubmitFilter={onSubmitFilter}
        loading={loading}
        paging={{
          dataSize: data.length,
        }}
        updatedTime={updatedTime}
        resource={RESOURCE}
        keyField="id"
        isImportExportButton={true}
        importExportForm={() => (
          <div className="tooltip-custom">
            <CustomButton
              isEdit
              color="secondary"
              outline
              disabled={lottests?.length == 0}
              onClick={onLottestExport}
            >
              <i className={"fas fa-download"}></i>
            </CustomButton>
            <span className="tooltiptext-custom-button-icon-left">
              {t("common:Export")}
            </span>
          </div>
        )}
        customButtonRight={() => (
          <ConfigCalculateModal
            submitConfig={submitConfig}
            model={model}
            data={lottests}
            lot={lot}
            machine={machine}
            resource={RESOURCE}
          />
        )}
        customButtonTable={() => (
          <CalculationSigmaTEButtons
            t={t}
            model={model}
            searchText={model.search}
            onSearch={onSearch}
            loading={loading}
            onMachineChange={data => {
              setMachine(data[0])
            }}
            onLotChange={onLotChange}
            onChange={val => {
              setModel({ ...model, ...val })
            }}
            onSubmit={val => {
              onSubmitFilter(val)
            }}
          />
        )}
        infoExtension={
          <div className="d-flex gap-2 mt-1 font-size-15 justify-content-end">
            <div className="tooltip-custom">
              <i
                data-tip
                data-for={`tooltip-calculate`}
                className="fas fa-exclamation-circle text-primary"
              ></i>
              <ReactTooltip
                id={`tooltip-calculate`}
                place="top"
                effect="solid"
              >
                <div>
                  {t("Calculate %CV", {
                    date: `${config?.isCV
                      ? (Array.isArray(config?.cvDate) ? config?.cvDate?.join(" - ") : config?.cvDate)
                      : !isEmpty(lot)
                        ? `${convertDateFormat(
                          (lot?.firstPointTime || new Date()),
                          "DD/MM/YYYY"
                        )} - ${convertDateFormat(new Date(), "DD/MM/YYYY")}`
                        : ""
                      }`,
                  })}
                </div>
                <div>
                  {t("Bias Calculate Time", {
                    date: `${config?.isBias
                      ? typeof config?.biasDate == "string"
                        ? config?.biasDate
                        : config?.biasDate?.join(" - ")
                      : !isEmpty(lot)
                        ? `${convertDateFormat(
                          (lot?.firstPointTime || new Date()),
                          "DD/MM/YYYY"
                        )} - ${convertDateFormat(new Date(), "DD/MM/YYYY")}`
                        : ""
                      }`,
                  })}
                </div>
              </ReactTooltip>
            </div>
            <span>
              <b>{t("%CV Calculate")}:</b>{" "}
              <b className="text-primary">
                {config.isCV ? config.cvCalculateName : (isCVEnable ? isCVEnable.formulaName : '')}
              </b>
            </span>
            -
            <span>
              <b>{t("Bias Calculate")}:</b>{" "}
              <b className="text-primary">
                {config.isBias ? config.biasCalculateName : (isBiasEnable ? isBiasEnable.formulaName : '')}
              </b>
            </span>
            -
            <span>
              <b>{t("Point Valid Calculate")}: </b>
              {config.isValid ? (
                <i className="font-size-15 fas fa-check-circle text-success" />
              ) : (
                <i className="font-size-15 fas fa-times-circle text-secondary" />
              )}
            </span>
          </div>
        }
      />
    </div>
  )
}

export default withTranslation(["calculationQCPage", "message"])(
  CalculationSigmaTETable
)
