import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Check, CustomBootstrapTable } from "components/Common";
import { indexCalculator } from "helpers/utilities";

import CustomTooltipButton, { TooltipButton } from "components/Common/Button/CustomTooltipButton";
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync";
import { ModuleIds, permissionType, statusTypes } from "constant";
import { withTranslation } from "react-i18next";
import IconStatus from "components/Common/IconStatus";

const RESOURCE = ModuleIds.ExpressionIQC;

const ExpressionIQCTable = ({
    onSelect,
    onSelectAll,
    dataConfigs,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onEdit,
    onDelete,
    loading,
    t,
    updatedTime,
    onClone
}) => {

    const [data, setData] = useState([]);
    const [model, setModel] = useState({
        search: "",
    });
    const searchQuery = useSelector(state => state.sttGPBConfig.searchQuery);

    const onResetHandler = () => {
    }

    useEffect(() => {
        setModel(searchQuery)
    }, [searchQuery])


    useEffect(() => {
        setData([...dataConfigs])
    }, [dataConfigs])

    const columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
            formatter: (cellContent, test, index) => {
                return cellContent
            },
        },
        {
            dataField: "id",
            text: "#",
            style: { width: 20 },
            formatter: (cellContent, test, index) => {
                return indexCalculator(paging.page, paging.size, index + 1)
            },
        },
        {
            dataField: "manageCode",
            text: t("Expression Code"),
            style: { width: 160 },
            formatter: (cellContent, data) => {
                return <span>{cellContent}</span>
            }
        },
        {
            dataField: "name",
            text: t("Expression Name"),
            formatter: (cellContent, data) => {
                return <span>{data.formulaName}</span>
            }
        },
        {
            dataField: "applicableName",
            text: t("Applicable"),
            formatter: (cellContent, data) => {
                return <span>{data.applicableName}</span>
            }
        },
        {
            dataField: "parameters",
            text: t("Parameter"),
            formatter: (cellContent, item, index) => {
                return (
                    <>
                        {item.parameters != "" &&
                            <div style={{
                                display: 'flex',
                                gap: '2px',
                            }}>
                                {item.parameters.split(', ').map((row, index) => <div key={index}>
                                    <label
                                        style={{
                                            color: "#5F5F5F",
                                            backgroundColor: "#fff",
                                            padding: "1px 5px",
                                            marginRight: "5px",
                                            borderRadius: "5px",
                                            border: "1px solid #808080",
                                        }}
                                    >
                                        {`${row}`}
                                    </label>
                                </div>)}
                            </div>
                        }
                    </>
                )
            },
        },
        {
            dataField: "formula",
            text: t("Formula"),
            style: { width: 200 },
            formatter: (cellContent, item, index) => {
                return <span>{item.formula}</span>
            },
        },
        {
            dataField: "decOdd",
            text: t("Numeric Round"),
            formatter: (cellContent, data) => {
                return <span>{cellContent}</span>
            }
        },
        {
            dataField: "inUse",
            text: t("common:Status"),
            align: 'center',
            headerAlign: 'center',
            formatter: (cellContent, item, index) => {
                return <IconStatus
                    isRound={true}
                    type={cellContent == true ? statusTypes.ACTIVE : statusTypes.INACTIVE}
                />
            },
        },
        {
            dataField: "action",
            text: "",
            style: { width: 140 },
            formatter: (cellContent, data, index) => {
                return (
                    <CustomTooltipButton
                        index={index}
                        component={data}
                        onClone={onClone}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        t={t}
                        resource={RESOURCE}
                    />
                )
            },
        },
    ]
    const getDataExport = () => {
        let dataExport = [];
        let dt = data || [];
        if (dt.length > 0) {
            //header
            dataExport.push([
                "#", t("Expression Code"), t("Expression Name"), t("Applicable"), t("Parameter"), t("Formula"), t("Numeric Round"), t("Active/InActive")
            ])
            dt = dt.filter(x => x.id != -1).map((item, index) => {
                return [
                    index + 1, item.manageCode, item.formulaName, item.applicableName, item.parameters, item.formula, item.decOdd, item.inUse == true
                ]
            });
            dataExport = dataExport.concat(dt)
        }
        return [...dataExport]
    }
    const CalculateData = (worksheet) => {
        worksheet.eachRow((row) => {
            row.eachCell((cell) => {
                cell.font = { name: "Times New Roman", size: 11 };
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" }
                };
            });
        });
        let headerRow = worksheet.getRow(1);
        headerRow.font = { name: "Times New Roman", size: 11, bold: true };
        worksheet.getColumn(1).width = 7;
        worksheet.getColumn(2).width = 18;
        worksheet.getColumn(3).width = 18;
        worksheet.getColumn(4).width = 15;
        worksheet.getColumn(5).width = 15;
        worksheet.getColumn(6).width = 30;
        worksheet.getColumn(7).width = 15;
        worksheet.getColumn(8).width = 25;
        return worksheet
    }
    return (
        <div className="expression-iqc-table">
            <CustomBootstrapTable
                columns={columns}
                search
                data={data}
                paging={paging}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                onSearch={onSearch}
                onSort={onSort}
                onRefresh={onRefresh}
                onReset={onResetHandler}
                onPageChange={onPageChange}
                onSizePerPageChange={onSizePerPageChange}
                searchText={model.search}
                loading={loading}
                updatedTime={updatedTime}
                resource={RESOURCE}
                isEnableExport={false}
                isEnableCustomExportExcel={true}
                dataExport={getDataExport()}
                excelConfig={{
                    fileName: t("Formula"),
                    calculateData: (e) => { CalculateData(e) },
                    messageEmpty: t("common:NoDataToExport"),
                    classNameTooltip: "tooltip-custom-result-qc-export-button",
                    classNameTooltipButton: "tooltip-custom",
                    classNameTooltipTitle: "tooltiptext-custom-button-icon-left",
                    tooltipTitle: t("Export"),
                }}
            />

        </div>
    )
}

export default withTranslation(["expressionsIQCPage"])(ExpressionIQCTable)