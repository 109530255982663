import { getLottestQCList, updateApplySigmaTeQC, updateLottestQC } from "helpers/app-backend/IQC/QC_Calculation_backend_helper"
import i18n from "i18next"
import { call, put, select, takeEvery } from "redux-saga/effects"
import {
    GET_LOT_TEST_QC_LIST,
    UPDATE_APPLY_SIGMA_TE_CALCULATION_QC,
    UPDATE_LOT_TEST_CALCULATION_QC
} from "./actionTypes"
import {
    getLottestListQCFail,
    getLottestListQCSuccess,
    updateApplySigmaTeCalculationQCFail,
    updateApplySigmaTeCalculationQCSuccess,
    updateLottestCalculationQCFail,
    updateLottestCalculationQCSuccess
} from "./actions"
import { showToast } from "components/Common"

const t = (msg, param) => i18n.t(msg, param)

function* fetchLottestList({ payload, callback }) {
    try {
        const meanPeer = yield select(state => state.calculationQC.meanPeer);
        const cvPeer = yield select(state => state.calculationQC.cvPeer);

        const response = yield call(getLottestQCList, payload);
        if (cvPeer.length > 0) {
            response.forEach(item => {
                item.lottests.forEach(x => {
                    x.cv = cvPeer.find(cv => cv.id == x.id)?.cv;
                    x.sd = parseFloat(((x.cv * x.mean) / 100)?.toFixed(6))
                });
            });
        }
        if (meanPeer.length > 0) {
            response.forEach(item => {
                item.lottests.forEach(x => {
                    x.mean = meanPeer.find(mean => mean.id == x.id)?.mean;
                    x.sd = parseFloat(((x.cv * x.mean) / 100)?.toFixed(6))
                });
            });
        }

        // Tính toán TE, Sigma, QGI cho mỗi lottest
        response.forEach(item => {
            item.lottests.forEach(x => {

                if (x.mean) {
                    if (x.meanPI) {
                        x.biasPercent = Math.abs(x.mean - x.meanPI) / x.meanPI * 100;
                        x.biasUnit = Math.abs(x.mean - x.meanPI);
                    } else if (x.meanPeer) {
                        x.biasPercent = Math.abs(x.mean - x.meanPeer) / x.meanPeer * 100;
                        x.biasUnit = Math.abs(x.mean - x.meanPeer);
                    } else if (x.meanEQC) {
                        x.biasPercent = Math.abs(x.mean - x.meanEQC) / x.meanEQC * 100;
                        x.biasUnit = Math.abs(x.mean - x.meanEQC);
                    }
                }
                if (x.biasPercent != null && x.sd != null) {
                    x.te = Math.abs(x.biasPercent) + 1.65 * x.sd;
                } else {
                    x.te = 0;
                }

                // Tính cvPXN: sd PXN / mean PXN * 100
                if (x.sd != null && x.mean != null) {
                    x.cvPXN = (x.sd / x.mean) * 100;
                } else {
                    x.cvPXN = 0;
                }

                // Tính Sigma theo cvPXN
                if (x.tEa != null && x.biasPercent != null) {
                    if (x.cvPXN != null) {
                        x.sigma = (x.tEa - x.biasPercent) / x.cvPXN;
                    } else {
                        x.sigma = 0;
                    }
                } else {
                    x.sigma = 0;
                }

                // Tính QGI theo cvPXN
                if (x.biasPercent != null && x.cvPXN != null) {
                    x.qgi = x.biasPercent / (1.5 * x.cvPXN);
                } else {
                    x.qgi = 0;
                }
            });
        });
        if (callback) {
            const noPointCV = []
            const noPointMean = []
            const noPointBias = []
            response.forEach(item => {
                noPointCV.push(...item.noPointCV)
                noPointMean.push(...item.noPointMean)
                noPointBias.push(...item.noPointBias)
            })
            callback({ noPointCV, noPointMean, noPointBias })
        }
        yield put(getLottestListQCSuccess(response))
    } catch (error) {
        yield put(getLottestListQCFail(error))
    }
}


function* onUpdateLottestCalculationQC({ payload, callback }) {
    try {
        const response = yield call(updateLottestQC, payload)
        yield put(updateLottestCalculationQCSuccess(response))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "calculationQCPage:Calculation List"
                )}`,
            })}`
        )
        if (callback)
            callback()
    } catch (error) {
        yield put(updateLottestCalculationQCFail(error))
    }
}

function* onUpdateApplySigmaTeCalculationQC({ payload, callback }) {
    try {
        const response = yield call(updateApplySigmaTeQC, payload)
        yield put(updateApplySigmaTeCalculationQCSuccess(response))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "calculationQCPage:Calculation List"
                )}`,
            })}`
        )
        if (callback)
            callback()
    } catch (error) {
        yield put(updateApplySigmaTeCalculationQCFail(error))
    }
}

function* calculationQCsSaga() {
    yield takeEvery(GET_LOT_TEST_QC_LIST, fetchLottestList)
    yield takeEvery(UPDATE_LOT_TEST_CALCULATION_QC, onUpdateLottestCalculationQC)
    yield takeEvery(UPDATE_APPLY_SIGMA_TE_CALCULATION_QC, onUpdateApplySigmaTeCalculationQC)
}

export default calculationQCsSaga