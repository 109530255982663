import { CustomBreadcrumb, PageContent } from "components/Common";
import { ModuleIds } from "constant";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Organisms from "./Organisms";

const RESOURCE = ModuleIds.MicroorganismList;

const OrganismsContainer = ({ t }) => {
    const titleTag = `Microorganism List`
    return (
        <PageContent title={t(titleTag)}>
            < CustomBreadcrumb
                breadcrumbItem={t("Microorganism List")}
                resource={RESOURCE}
            />
            <Organisms />
        </PageContent >
    )
}

OrganismsContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["organismsPage"])(OrganismsContainer)