import React, { useEffect, useState } from "react"
import { Button, Popover, PopoverBody, PopoverHeader } from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import {
  exportReport,
  getReportById,
  urlExportReport,
} from "helpers/app-backend/reports_backend_helper"
import printJS from "print-js"
import { getFilePrint, getFilePrintResult } from "helpers/app-backend"
import { GetDataUrlReportConfig } from "helpers/utilities"
import { isEmpty } from "lodash"
import FastReportView from "pages/FastReport/Designer/FastReportView"

const CustomPrintButton = ({
  isPrint,
  reportInfos = [],
  reportResource,
  fileId,
  t,
  sid,
  subSID,
  requestDate,
  sampleType,
  name,
  gender
}) => {
  const [popoverleft, setpopoverleft] = useState(false)
  const [reportName, setReportName] = useState("")
  const [newSID, setNewSID] = useState()
  useEffect(() => {
    const { reportId } = GetDataUrlReportConfig(
      reportInfos,
      reportResource,
      fileId
    )
    fetchReportById(reportId)
  }, [sid, requestDate])

  // useEffect(() => {
  //   setNewSID(sid?.split('-')[0])
  // }, [sid, requestDate])

  const fetchReportById = async id => {
    await getReportById(id).then(res => {
      setReportName(res.reportUUID)
    })
  }
  const onClickHandler = async () => {
    const print = JSON.parse(localStorage.getItem("printConfig"))
    let printColumns = print.columns
    let printPage = ['B1', 'B2', 'B3']
    if (reportInfos.length > 0 && !isEmpty(reportInfos[0].uri)) {
      const res = await getFilePrint({
        ...GetDataUrlReportConfig(reportInfos, reportResource, fileId),
        SID: sid,
        SubSID: subSID,
        RequestDate: requestDate,
        SampleType: sampleType,
        Gender: gender,
        Page: 'B1'
      })
      window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
    }
  }
  return isPrint ? (
    <div>
      <Button
        color="secondary"
        outline
        onClick={onClickHandler}
        id={`Popoverleft-${sid}-${sampleType?.replace(/[^a-zA-Z0-9\s]/g, char => char.charCodeAt(0) % 10)}`}
        style={{ background: "transparent", border: "none" }}
        onMouseEnter={() => {
          setpopoverleft(true)
        }}
        onMouseLeave={() => {
          setpopoverleft(false)
        }}
        className="p-0"
      >
        <div style={{ width: 18, height: 14, overflow: "hidden" }}>
          <svg
            viewBox="0 0 512 448"
            style={{ marginTop: -10 }}
            fill={popoverleft ? "#007aff" : "#000"}
          >
            <path d="M24 32C10.7 32 0 42.7 0 56V456c0 13.3 10.7 24 24 24H40c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24H24zm88 0c-8.8 0-16 7.2-16 16V464c0 8.8 7.2 16 16 16s16-7.2 16-16V48c0-8.8-7.2-16-16-16zm72 0c-13.3 0-24 10.7-24 24V456c0 13.3 10.7 24 24 24h16c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24H184zm96 0c-13.3 0-24 10.7-24 24V456c0 13.3 10.7 24 24 24h16c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24H280zM448 56V456c0 13.3 10.7 24 24 24h16c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24H472c-13.3 0-24 10.7-24 24zm-64-8V464c0 8.8 7.2 16 16 16s16-7.2 16-16V48c0-8.8-7.2-16-16-16s-16 7.2-16 16z" />
          </svg>
        </div>
      </Button>
      <Popover
        placement="right"
        isOpen={popoverleft}
        target={`Popoverleft-${sid}-${sampleType?.replace(/[^a-zA-Z0-9\s]/g, char => char.charCodeAt(0) % 10)}`}
        toggle={() => {
          setpopoverleft(!popoverleft)
        }}
        style={{
          maxWidth: 135,
          maxHeight: 90,
          overflow: "hidden",
          border: "1px solid #ccc",
        }}
      >
        <PopoverBody style={{ padding: 1, marginLeft: '-10px', marginTop: '-5px' }}>
          <FastReportView
            nameReport={reportName}
            sid={sid}
            subSID={subSID}
            requestdate={requestDate}
            report
            style={{ overFlow: "hidden" }}
            isHideToolBars={false}
            sampleType={sampleType}
            gender={gender}
          />
        </PopoverBody>
      </Popover>
    </div>
  ) : (
    <></>
  )
}
CustomPrintButton.propTypes = {
  onClick: PropTypes.func,
  t: PropTypes.func,
  isPrint: PropTypes.bool,
  isPreview: PropTypes.bool,
  sid: PropTypes.string,
  subSID: PropTypes.string,
  requestDate: PropTypes.any,
}
CustomPrintButton.defaultProps = {
  type: "button",
  onClick: () => { },
}
export default withTranslation(["button"])(CustomPrintButton)
