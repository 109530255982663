import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../../api_helper2"
const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/qc/qcchange`

const createQCChange = req => {
    return post(`${BASE_API_URL}`, req)
}
const updateQCChange = req => {
    const { id } = req
    return put(`${BASE_API_URL}/${id}`, req)
}
const deleteQCChange = req => {
    let ids = ""
    req.forEach(_item => (ids += `id=${_item.id}&`))
    return del(`${BASE_API_URL}?${ids}`)
}
const getQCChangeList = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}
const getQCChangeById = id => {
    return get(`${BASE_API_URL}/${id}`)
}

const exportQC = (payload) => {
    let q = spreadSearchQuery(payload)
    return post(`${BASE_API_URL}/export-qc-change?${q}`, null, {
        responseType: 'arraybuffer',
        headers:
        {
            'Content-Disposition': "attachment; filename=ExportUserList.xlsx",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
    })
}

const getIntrumentsByLotId = (payLoad) => {
    let q = spreadSearchQuery(payLoad)
    return get(`${BASE_API_URL}/getIntrumentsByLotId?${q}`)
}

export {
    createQCChange,
    updateQCChange,
    deleteQCChange,
    getQCChangeList,
    getQCChangeById,
    exportQC,
    getIntrumentsByLotId
}
