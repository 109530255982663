import { AvForm } from "availity-reactstrap-validation"
import { useRef, useState } from "react"
import {
  ModalBody,
  ModalFooter
} from "reactstrap"

import {
  CustomButton,
  CustomModal,
  CustomSID,
  WarningModal
} from "components/Common"
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"
import { withTranslation } from "react-i18next"
import MicrobialOrder from "./MicrobialOrder"
import { hasDuplicate } from "helpers/utilities"
import { STTVS } from "constant"

const TestMicrobialModal = ({
  modal,
  onToggle,
  onMicrobialSubmit,
  t,
  data
}) => {
  const title = t("deliveryPage:STTVS Popup")
  const [isDuplicate, setIsDuplicate] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)

  const microbialSubmit = () => {
    const isSttvsDuplicate = hasDuplicate(data, 'sttvs')

    const isEmpty = data.find(item => (item.sttvs == null || item.sttvs == '') && item.mbNumType === STTVS.CH)

    if (isEmpty) {
      setIsEmpty(isEmpty)
      return
    }

    if (isSttvsDuplicate) {
      setIsDuplicate(isSttvsDuplicate)
      return
    }
    onMicrobialSubmit(data)
  }

  const columns = [
    {
      dataField: "id",
      text: "#",
      hidden: true,
      formatter: (cellContent, item, index) => {
        return cellContent
      },
    },
    {
      dataField: "id",
      text: "#",
      formatter: (cellContent, item, index) => {
        return index + 1;
      },
    },
    {
      dataField: "sid",
      text: t("SID"),
      sort: false,
      formatter: (cellContent, item, index) => {
        return <CustomSID
          key={`sampleType-${index}`}
          keyToolTip={`sampleType-${index}`}
          sid={`${item.sid}${item.subSID ? ` - ${item.subSID}` : ''}`}
        />
      }
    },
    {
      dataField: "testCode",
      text: t("Test Code"),
      sort: false,
      style: { width: '100px' },
    },
    {
      dataField: "testName",
      text: t("Test Name"),
      sort: false,
    },
    {
      dataField: "sttvs",
      text: t("STTVS"),
      sort: false,
      style: { width: '200px' },
      formatter: (cellContent, item, index) => {
        return <MicrobialOrder
          data={item}
          defaultValue={cellContent}
          index={index}
          onMicrobialOrderChange={(value) => {
            data.find(x => x.sid == item.sid && x.testCode == item.testCode).sttvs = value
          }}
        />
      },
    }
  ]

  let microbialData = data.filter(item => item.mbNumType == 'CH')

  return (
    <CustomModal modal={modal} title={title} onToggle={onToggle} size="xl">
      <ModalBody>
        <CustomBootstrapTableAsync
          columns={columns}
          data={microbialData}
          isSelected={false}
          keyField="id"
        />
      </ModalBody>
      <ModalFooter>
        <CustomButton
          type="button"
          onClick={onToggle}
          color="secondary"
          data-dismiss="modal"
          text={"common:Cancel"}
          className="button-width"
        />
        <CustomButton
          type="button"
          color="primary"
          onClick={microbialSubmit}
          className="save-user button-width"
          text={"common:Save"}
        />
      </ModalFooter>

      <WarningModal
        modal={isEmpty}
        onToggle={() => setIsEmpty(prev => !prev)}
        message={`${t("deliveryPage:Choose STTVS")}`}
      />

      <WarningModal
        modal={isDuplicate}
        onToggle={() => setIsDuplicate(prev => !prev)}
        message={`${t("deliveryPage:STTVS Duplicate")}`}
      />
    </CustomModal>
  )
}

export default withTranslation(["deliveryPage", "message", "common"])(TestMicrobialModal)
