import { CustomButton, Header } from "components/Common"
import { ModuleIds } from "constant"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import HeaderFilter from "./headerFilter"
import TestQCTable from "./testQCTable"
import LotIndex from "./LotIndex"
import LotIndexTable from "./LotIndexTable"
import {
  convertToNumber
} from "helpers/utilities"
import { getQCDatapoints, getTestForChart, getQCDatapointsPosNeg } from "store/actions"
import moment from "moment"
import { cloneDeep } from "lodash"

const RESOURCE = ModuleIds.ChartiQC

const BodyLeft = ({
  t,
  lstTest,
  level,
  filter,
  onGetQCDatapoints,
  onGetQCDatapointsPosNeg,
  onGetTestForChart,
  onChangeGeneralData,
  onChangeAllLotLevel,
  onChangeDateArr,
  onChangeIsPosNeg,
  onChangeSelectedLots,
  showBothCharts,
  onChangeListData,
  onChangeFlg,
  onChangeReportIndex,
  onChangeModel
}) => {
  let initModel = {
    start: moment().format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
    machine: "",
    levels: "",
    lotGroup: "",
    lot: "",
    category: "",
  }
  const [model, setModel] = useState(initModel)
  const [subModel, setSubModel] = useState({lotGroup: "",
    lot: "",
    category: ""})
  const [generalData, setGeneralData] = useState({})
  const [chartDataInfo, setChartDataInfo] = useState([])
  const [chartDataIndex, setChartDataIndex] = useState([])
  const [machineItems, setMachineItems] = useState([])
  const [isPosNeg, setIsPosNeg] = useState(false)
  const [dataTestSelect, setDataTestSelect] = useState()

  useEffect(() => {
    onGetTestForChart(model)
  }, [model.start,model.end,model.machine,model.test,model.levels])

  useEffect(() => {
    if (lstTest != null) {
      setChartDataInfo(lstTest)
    }
  }, [lstTest])

  useEffect(() => {
    onChangeGeneralData(generalData || {})
  }, [generalData])

  useEffect(()=>{
    onChangeIsPosNeg(isPosNeg)
  }, [isPosNeg])

  const onRefreshHandler = () => {
    onGetTestForChart(model)
  }

  useEffect(() => {
    let dicLevel = { 1: "", 2: "", 3: "", 4: "", 5: "" }
    let flatIndex = []
    let flatIndexBDL = []
    for (let i = 0; i < 6; i++) {
      flatIndex.push([Object.assign({}, dicLevel)])
      if (i < 3) {
        flatIndexBDL.push([Object.assign({}, dicLevel)])
      }
    }
    let fixIndex = [
      "Mean (PXN/NXS)",
      "SD (PXN/NXS)",
      "CV% (PXN/NXS)",
      "GHKS PXN",
      "GHKS NXS",
      t("Evaluation"),
    ]
    let fixIndexBDL = [
      t('Low Index'),
      t('High Index'),
      t('Eval Index'),
    ]
    let newChartDataIndex = []
    let index = {}
    let indexCal = {}
    let checkLevel = {}
    let checkTab = false
    let numpoint = 0
    for (let lv in generalData["data"]) {
      numpoint += generalData["data"]?.[lv]?.length
    }
    if (
      generalData?.["index"] != null &&
      Object.keys(generalData?.["index"]).length > 0
    ) {
      if (level != "default") {
        let idx = generalData["index"]?.[level]
        let data = generalData["data"]?.[level]
        if (data != undefined && data?.length > 0) {
          index[level] = idx
          checkTab = true
        }
      } else {
        let idxTotal = generalData["index"]
        Object.keys(idxTotal).forEach(key => {
          let idx = generalData["index"]?.[key]
          let data = generalData["data"]?.[key]
          if (data != undefined && data.length > 0) {
            index[key] = idx
            checkTab = true
          }
        })
      }
    }
    if (checkTab == false) {
      newChartDataIndex.push(checkLevel)
      setChartDataIndex(newChartDataIndex)
      return
    }
    if (
      generalData?.["indexCal"] != null &&
      isPosNeg != true &&
      Object.keys(generalData?.["indexCal"]).length > 0
    ) {
        indexCal = generalData["indexCal"]
      }
      if (isPosNeg != true) {
      Object.keys(indexCal).forEach(key => {
        let entry = indexCal[key]
        let flg = false
        if(Object.keys(generalData["lenLvLastLot"]).length == 1 && generalData["lenLvLastLot"][key][0] == 1){
          entry = index[key]
          if(generalData["lenLvLastLot"][key][1] == true){
            flg = true
          }
        }
        checkLevel[key] = true
        let entryPI = index[key]
        let cv = "", mean = "", sd = "", cvpi = "", meanPI = "", sdpi = ""
        cv = entry?.cv != null ? entry.cv : ""
        mean = entry?.mean != null ? entry.mean : ""
        sd = entry?.sd != null ? entry.sd : ""
        if(flg){
          onChangeFlg(flg)
          cv = entry?.cvpi != null ? entry.cvpi : ""
          mean = entry?.meanPI != null ? entry.meanPI : ""
          sd = entry?.sdpi != null ? entry.sdpi : ""
        }
        cvpi = entryPI?.cvpi != null ? entryPI.cvpi : ""
        meanPI = entryPI?.meanPI != null ? entryPI.meanPI : ""
        sdpi = entryPI?.sdpi != null ? entryPI.sdpi : ""
        let evaluate = t("Unachieved")
        let manulimit = ""
        let lablimit = ""
        // if (meanPI === "" || sdpi === "") {
        //   evaluate = t("Achieved")
        // } 
        // else if (mean === "" || sd === "") {
        //   evaluate = t("Unachieved")
        // } else {
        meanPI = convertToNumber(meanPI)
        mean = convertToNumber(mean)
        sdpi = convertToNumber(sdpi)
        sd = convertToNumber(sd)

        let manuCheck = (typeof meanPI === "number" && typeof sdpi === "number")
        let labCheck = (typeof mean === "number" && typeof sd === "number")
        let highLab = Infinity 
        let lowLab = -Infinity
        let highManu = Infinity
        let lowManu = -Infinity
          if (manuCheck) {
            lowManu = meanPI - 2 * sdpi
            highManu = meanPI + 2 * sdpi
            manulimit =
              lowManu.toFixed(2) +
              " - " +
              highManu.toFixed(2)
          }

          if (labCheck) {
            lowLab = mean - 2 * sd
            highLab = mean + 2 * sd
            lablimit =
              lowLab.toFixed(2) + " - " + highLab.toFixed(2)
          }

          if(manuCheck && labCheck && highLab != Infinity && highManu != Infinity && lowManu != -Infinity && lowLab != -Infinity){
            if((sd < sdpi && highLab < highManu && lowManu < lowLab) || (sd == sdpi && highLab == highManu && lowManu == lowLab)){
              evaluate = t("Achieved")
            }
          }
        
        flatIndex[0][key] = mean + " / " + meanPI
        flatIndex[1][key] = sd + " / " + sdpi
        flatIndex[2][key] = cv + " / " + cvpi
        flatIndex[3][key] = lablimit
        flatIndex[4][key] = manulimit
        flatIndex[5][key] = evaluate
      })
      for (let i = 0; i < fixIndex.length; i++) {
        newChartDataIndex.push({
          index: fixIndex[i],
          level1: flatIndex[i][1] !== null ? flatIndex[i][1] : "",
          level2: flatIndex[i][2] !== null ? flatIndex[i][2] : "",
          level3: flatIndex[i][3] !== null ? flatIndex[i][3] : "",
          level4: flatIndex[i][4] !== null ? flatIndex[i][4] : "",
          level5: flatIndex[i][5] !== null ? flatIndex[i][5] : "",
        })
      }
    }
    else {
      Object.keys(index).forEach(key => {
        checkLevel[key] = true
        let low = "",
          high = "",
          evalI = ""
        const entry = index[key]
        low = entry?.low != null ? entry.low : ""
        high = entry?.high != null ? entry.high : ""
        evalI = entry?.eval != null ? entry.eval : ""
        flatIndexBDL[0][key] = low
        flatIndexBDL[1][key] = high
        flatIndexBDL[2][key] = evalI
      })
      for (let i = 0; i < fixIndexBDL.length; i++) {
        newChartDataIndex.push({
          index: fixIndexBDL[i],
          level1: flatIndexBDL[i][1] !== null ? flatIndexBDL[i][1] : "",
          level2: flatIndexBDL[i][2] !== null ? flatIndexBDL[i][2] : "",
          level3: flatIndexBDL[i][3] !== null ? flatIndexBDL[i][3] : "",
          level4: flatIndexBDL[i][4] !== null ? flatIndexBDL[i][4] : "",
          level5: flatIndexBDL[i][5] !== null ? flatIndexBDL[i][5] : "",
        })
      }
    }

    if (level != "default") {
      if (level in checkLevel) {
        checkLevel = {}
        checkLevel[level] = true
      } else {
        checkLevel = {}
      }
    }
    newChartDataIndex.push(checkLevel)
    setChartDataIndex(newChartDataIndex)
  }, [generalData, showBothCharts, level])
  console.log("model",model)
  return (
    <React.Fragment>
      <div className="d-flex pt-2 justify-content-between">
        <Header
          title={t("QualityManagement")}
          resource={RESOURCE}
          subtitle={t("sidebar:Chart iQC")}
        />
      </div>
      <HeaderFilter
        model={model}
        onChange={val => {
          setModel({ ...model, ...val })
        }}
        onSubmitFilter={() => {
          onGetTestForChart(model)
        }}
        onChangeMachine={items => setMachineItems(items)}
      />

      <TestQCTable
        model={model}
        onChangeModel={val => {
          setSubModel({ ...subModel, ...val})
        }}
        configs={chartDataInfo || []}
        onRefresh={onRefreshHandler}
        paging={{ page: 1, size: chartDataInfo.length }}
        onSelected={row => {
          let val = cloneDeep(model)
          val.test = row.testCode
          setIsPosNeg(row.isPosNeg)
          setDataTestSelect(row)
          if (row.isPosNeg == false) {
            onGetQCDatapoints(val)
          } else {
            onGetQCDatapointsPosNeg(val)
          }
        }}
        onReset={() => {
          let val = {...model, lot:"", lotGroup:"",category:""}
          setSubModel({lotGroup: "",
            lot: "",
            category: ""})
          setModel(val)
          onGetTestForChart(val)
        }}
        onSubmitFilter={() => {
          let val = {...model, lot:subModel?.lot, lotGroup:subModel?.lotGroup,category:subModel?.category}
          setModel(val)
          onGetTestForChart(val)
        }}
      />
      <LotIndex
        onChangeGeneralData={val => setGeneralData(val)}
        onChangeAllLotLevel={val => onChangeAllLotLevel(val)}
        onChangeReportIndex={val => onChangeReportIndex(val)}
        onChangeDateArr={val => onChangeDateArr(val)}
        onChangeListData={val => onChangeListData(val)}
        onChangeSelectedLots={val=>onChangeSelectedLots(val)}
        machineItems={machineItems}
        isPosNeg={isPosNeg}
        filter={filter}
        model={model}
        dataTestSelect={dataTestSelect}
      />
      <LotIndexTable
        configs={chartDataIndex || []}
        paging={{ page: 1, size: chartDataIndex.length }}
      />
    </React.Fragment>
  )
}

BodyLeft.propTypes = {
  datapoints: PropTypes.object,
  lstTest: PropTypes.array,
  filter: PropTypes.string
}
const mapStateToProps = ({ qcDatapoints }) => {
  return {
    datapoints: qcDatapoints.datapoints,
    lstTest: qcDatapoints.lstTest,
    loadingDatapoints: qcDatapoints.loadingDatapoints,
    error: qcDatapoints.error,
  }
}
const mapDispatchToProps = dispatch => ({
  onGetTestForChart: payload => dispatch(getTestForChart(payload)),
  onGetQCDatapoints: payload => dispatch(getQCDatapoints(payload)),
  onGetQCDatapointsPosNeg: payload => dispatch(getQCDatapointsPosNeg(payload)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    withTranslation(["chartiQCPage", "message", "common", "sidebar"])(BodyLeft)
  )
)
