import {
  ConfirmModal,
  ConfirmModal2,
  TitleAndTable,
  WarningModal,
} from "components/Common"
import { ModuleIds } from "constant"
import {
  getInputChangedValue,
  onDelete,
  onDeleteToggle,
  selectCheckboxHandler,
} from "helpers/utilities"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import {
  addNewMachineMappingCodeVS,
  deleteMachineMappingCodeVS,
  getMachineCodeMappingVS,
  resetInstrumentMappingCodeSearchQuery,
  saveMachineCodeMappingVS,
  updateMachineMappingCodeVS
} from "store/actions"
import CodeMappingModalVS from "./Modal/CodeMappingModalVS"
import CodeMappingTable from "./CodeMappingTable"
import HeaderButtons from "./HeaderButtons"

const RESOURCE = ModuleIds.Instrument

const CodeMappingVSTab = ({
  instrumentId,
  resource,
  machine,
  codeMappings,
  onGetMappingCodes,
  onResetInstrumentMapSearchQuery,
  onAddMappingCode,
  onUpdateMappingCode,
  onDeleteMappingCodes,
  onActiveMachineMappingCode,
  updateCodeMappingTime,
  loadingCodeMapping,
  t,
}) => {
  const [confirmModal, setConfirmModal] = useState(false)
  const [row, setRow] = useState({})
  const [rowDelete, setRowDelete] = useState({})
  const [rows, setRows] = useState([])
  const [rowAddNew, setRowAddNew] = useState({})
  const [warningModal, setWarningModal] = useState(false)
  const [rowEdit, setRowEdit] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [isClone, setIsClone] = useState(false)
  const [modal, setModal] = useState(false)

  const [confirmActiveModal, setConfirmActiveModal] = useState(false)
  const [confirmInActiveModal, setConfirmInActiveModal] = useState(false)

  const formEl = useRef(null)

  const [model, setModel] = useState({
    search: "",
  })

  const toggle = () => {
    if (modal) {
      setRowEdit({})
      setRowAddNew({})
    }
    setModal(prev => !prev)
  }

  const onAddClick = () => {
    setIsEdit(false)
    setIsClone(false)
    toggle()
    // setSelectMappingModal(true)
  }

  const handleValidSubmit = async (e, values) => {
    if (isEdit) {
      // update mapCode
      if (values.instrumentMapId) {
        onUpdateMappingCode({ code: values, callback: afterCreate })
      } else {
        // Tao moi
        onAddMappingCode({ code: values, callback: afterCreate })
      }
    } else {
      const newCode = {
        ...values,
      }
      // save new A
      onAddMappingCode({ code: newCode, callback: afterCreate })
    }
  }

  const afterCreate = () => {
    fetchMappingCode(instrumentId)
    toggle()
  }

  const afterUpdate = () => {
    resetState()
    fetchMappingCode(instrumentId)
  }

  const onEditHandler = (e, code) => {
    if (isEmpty(row) && !code) {
      setWarningModal(true)
      return
    }

    if (!code) {
      code = row
    }
    setIsEdit(true)

    setRowEdit(code)
    toggle()
  }

  const onDeleteToggleHandler = (e, field) => {
    onDeleteToggle({
      rows,
      row: field || rowDelete,
      setConfirmModal,
      setWarningModal,
      setRowDelete,
    })
  }

  const onDeleteMultipleRows = rowsState => {
    const code2Delete = rowsState.filter(c => +c.instrumentMapId > 0)
    const mapCodeId = code2Delete.map(el => {
      return {
        ...el,
        id: el.instrumentMapId,
      }
    })

    if (code2Delete.length > 0) {
      onDeleteMappingCodes({
        codes: mapCodeId,
        instrumentId,
        callback: afterUpdate,
      })
    } else {
      fetchMappingCode(instrumentId)
    }
    resetState()
  }

  const onDeleteSingleRow = rowsState => {
    const code2Delete = rowsState.filter(c => +c.instrumentMapId > 0)
    const mapCodeId = code2Delete.map(el => {
      return {
        ...el,
        id: el.instrumentMapId,
      }
    })

    if (code2Delete.length > 0) {
      onDeleteMappingCodes({
        codes: mapCodeId,
        instrumentId,
        callback: afterUpdate,
      })
    } else {
      fetchMappingCode(instrumentId)
    }
    resetState()
  }

  const onDeleteCodeHandler = () => {
    onDelete({
      rowDelete,
      rows,
      onDeleteSingleRow,
      onDeleteMultipleRows,
    })
    // toggle modal
    setConfirmModal(false)
  }

  /**Get selected row and set to state
   *
   */
  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }

  const fetchMappingCode = instrumentId => {
    onGetMappingCodes({ instrumentId, query: { ...model } })
  }

  const onRefreshHandler = () => {
    resetState()
    fetchMappingCode(instrumentId)
  }

  const onSortHandler = (field, order) => {
    const sortString = `${field}:${order}`
    onGetMappingCodes({ query: { sort: sortString }, instrumentId })
  }

  /** Form Functions */
  const onSelectChanged = e => {
    const { name, value } = getInputChangedValue(e)

    // check if edit mode
    if (isEdit) setRowEdit(prev => ({ ...prev, [name]: value }))
    else if (isClone) setRow(prev => ({ ...prev, [name]: value }))
    else setRowAddNew(prev => ({ ...prev, [name]: value }))
  }

  const resetState = () => {
    setRows([])
    setRow({})
  }

  const onCheckDownloadRejectedHandler = (event, mapCode) => {
    const checked = event.target.checked
    if (checked) {
      if (mapCode.instrumentMapId > 0) {
        onUpdateMappingCode({
          code: { ...mapCode, downloadRejected: true },
          callback: afterUpdate,
        })
      } else {
        onAddMappingCode({
          code: { ...mapCode, downloadRejected: true, instrumentId },
          callback: afterUpdate,
        })
      }
    } else {
      if (mapCode.instrumentMapId > 0) {
        onUpdateMappingCode({
          code: { ...mapCode, downloadRejected: false },
          callback: afterUpdate,
        })
      } else {
        onAddMappingCode({
          code: { ...mapCode, downloadRejected: false, instrumentId },
          callback: afterUpdate,
        })
      }
    }
  }
  

  const onCheckDownloadEnhancedHandler = (event, mapCode) => {
    const checked = event.target.checked
    if (checked) {
      if (mapCode.instrumentMapId > 0) {
        onUpdateMappingCode({
          code: { ...mapCode, downloadEnhanced: true },
          callback: afterUpdate,
        })
      } else {
        onAddMappingCode({
          code: { ...mapCode, downloadEnhanced: true, instrumentId },
          callback: afterUpdate,
        })
      }
    } else {
      if (mapCode.instrumentMapId > 0) {
        onUpdateMappingCode({
          code: { ...mapCode, downloadEnhanced: false },
          callback: afterUpdate,
        })
      } else {
        onAddMappingCode({
          code: { ...mapCode, downloadEnhanced: false, instrumentId },
          callback: afterUpdate,
        })
      }
    }
  }


  const onCheckActiveHandler = (event, mapCode) => {
    const checked = event.target.checked
    if (checked) {
      if (mapCode.instrumentMapId > 0) {
        onUpdateMappingCode({
          code: { ...mapCode, activeTestCode: true },
          callback: afterUpdate,
        })
      } else {
        onAddMappingCode({
          code: { ...mapCode, activeTestCode: true, instrumentId },
          callback: afterUpdate,
        })
      }
    } else {
      if (mapCode.instrumentMapId > 0) {
        onUpdateMappingCode({
          code: { ...mapCode, activeTestCode: false },
          callback: afterUpdate,
        })
      } else {
        onAddMappingCode({
          code: { ...mapCode, activeTestCode: false, instrumentId },
          callback: afterUpdate,
        })
      }
    }
  }

  const onActiveCodeToggleHandler = (e, field) => {
    onDeleteToggle({
      rows,
      row: [],
      setConfirmModal: setConfirmActiveModal,
      setWarningModal,
      setRowDelete: () => {},
    })
  }

  const onInActiveCodeToggleHandler = () => {
    onDeleteToggle({
      rows,
      row: [],
      setConfirmModal: setConfirmInActiveModal,
      setWarningModal,
      setRowDelete: () => {},
    })
  }

  const onActiveCodeHandler = () => {
    const mappingCodes = [...rows]
    const machineId = instrumentId
    // Map lại từ , => | cho subCode
    mappingCodes.forEach(el => {
      el.instrumentSubCode = el.instrumentSubCode?.replaceAll(",", "|")
    })
    onActiveMachineMappingCode({
      mappingCodes,
      machineId,
      active: true,
      callback: onRefreshHandler,
    })
    // toggle modal
    setConfirmActiveModal(false)
  }

  const onInActiveCodeHandler = () => {
    const mappingCodes = [...rows]
    const machineId = instrumentId
    // Map lại từ , => | cho subCode
    mappingCodes.forEach(el => {
      el.instrumentSubCode = el.instrumentSubCode?.replaceAll(",", "|")
    })
    onActiveMachineMappingCode({
      mappingCodes,
      machineId,
      active: false,
      callback: onRefreshHandler,
    })
    // toggle modal
    setConfirmInActiveModal(false)
  }

  const onSearchChangeHandler = e => {
    const { value } = getInputChangedValue(e)
    setModel(prev => ({ ...prev, search: value }))
  }

  const onClearSearchHandler = () => {
    setModel(prev => ({ ...prev, search: "" }))
    onGetMappingCodes({ instrumentId, query: {  } })
  }

  const onSubmitSearchHandler = e => {
    e.preventDefault()
    onGetMappingCodes({ instrumentId, query: { ...model } })
  }

  useEffect(() => {
    onResetInstrumentMapSearchQuery()
  }, [])

  useEffect(() => {
    onGetMappingCodes({ instrumentId })
  }, [instrumentId])

  return (
    <React.Fragment>
      {/* Table */}
      <TitleAndTable
        resource={RESOURCE}
        buttons={() => (
          <HeaderButtons
            resource={RESOURCE}
            onRefreshClick={onRefreshHandler}
            // onSubmitFilter={onSubmitFilter}
            // onResetClick={onResetHandler}
            onAddClick={onAddClick}
            onEditClick={onEditHandler}
            onDeleteClick={onDeleteToggleHandler}
            onActiveClick={onActiveCodeToggleHandler}
            onInActiveClick={onInActiveCodeToggleHandler}
            model={model}
            onSearchChange={onSearchChangeHandler}
            onClearSearch={onClearSearchHandler}
            onSubmitSearch={onSubmitSearchHandler}
          />
        )}
        table={() => (
          <CodeMappingTable
            data={codeMappings}
            onSelect={onSelectCheckbox}
            onSelectAll={onSelectAllCheckbox}
            onDelete={onDeleteToggleHandler}
            onEdit={onEditHandler}
            loading={loadingCodeMapping}
            model={model}
            updatedTime={updateCodeMappingTime}
            // onDragEnd={onDragEnd}
            // isSorted={isSorted}
            onSort={onSortHandler}
            // onDownloadRejectedAll={onDownloadRejectedAllHandler}
            // onDownloadEnhancedAll={onDownloadEnhancedAllHandler}
            onCheckDownloadRejected={onCheckDownloadRejectedHandler}
            onCheckDownloadEnhanced={onCheckDownloadEnhancedHandler}
            onCheckActiveHandler={onCheckActiveHandler}
            resource={resource}
          />
        )}
        external
        subtitle={t("machinePage:Mapping Code")}
        icon={false}
      />
      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("SelectRowWarning")}
      />
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("common:Profile")}`}
        message={t("DeleteConfirm")}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteCodeHandler}
      />
      {/* Modal Active */}
      <ConfirmModal2
        modal={confirmActiveModal}
        title={`${t("common:Active")}`}
        message={t("ActiveInstrumentCodeConfirm")}
        onToggle={onActiveCodeToggleHandler}
        onConfirm={onActiveCodeHandler}
        btnConfirmText={t("common:Save")}
      />
      {/* Modal In-Active */}
      <ConfirmModal2
        modal={confirmInActiveModal}
        title={`${t("common:In-Active")}`}
        message={t("InActiveInstrumentCodeConfirm")}
        onToggle={onInActiveCodeToggleHandler}
        onConfirm={onInActiveCodeHandler}
        btnConfirmText={t("common:Save")}
      />
      {modal && (
        <CodeMappingModalVS
          instrumentId={instrumentId}
          formEl={formEl}
          modal={modal}
          isClone={isClone}
          toggle={toggle}
          isEdit={isEdit}
          onValidSubmit={handleValidSubmit}
          data={!isEdit ? (isClone ? row : rowAddNew) : rowEdit}
          onChange={onSelectChanged}
          resource={resource}
        />
      )}
    </React.Fragment>
  )
}

CodeMappingVSTab.propTypes = {
  codeMappings: PropTypes.array,
  updateCodeMappingTime: PropTypes.any,
  loadingCodeMapping: PropTypes.bool,

  machineCodeMappings: PropTypes.array,
  t: PropTypes.any,
}

CodeMappingVSTab.defaultProps = {}

const mapStateToProps = ({ machine }) => ({
  machine: machine.machine,
  codeMappings: machine.codeMappingVS,
  updateCodeMappingTime: machine.updateCodeMappingVSTime,
  loadingCodeMapping: machine.loadingCodeMappingVS,
})

const mapDispatchToProps = dispatch => ({
  onGetMappingCodes: payload => dispatch(getMachineCodeMappingVS(payload)),
  onResetInstrumentMapSearchQuery: () => dispatch(resetInstrumentMappingCodeSearchQuery()),
  onAddMappingCode: code => { dispatch(addNewMachineMappingCodeVS(code)) },
  onUpdateMappingCode: code => { dispatch(updateMachineMappingCodeVS(code)) },
  onDeleteMappingCodes: codes => dispatch(deleteMachineMappingCodeVS(codes)),
  onActiveMachineMappingCode: payload => dispatch(saveMachineCodeMappingVS(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["message", "common", "machinePage"])(CodeMappingVSTab))
