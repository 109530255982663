import React, { useState } from "react"
import { Col, Label, Row } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"

import { parameterCode } from "constant/utility"

//i18n
import { withTranslation } from "react-i18next"
import { getInvalidMessageI18n } from "helpers/utilities"
import {
  CustomButton,
  CustomButtonGroup,
  CustomSelect,
  CustomAvField,
  CustomCheckbox,
} from "components/Common"

const EditMode = ({
  onValidSubmit,
  data,
  onCancel,
  t,
  isEdit,
  listProfileType,
}) => {
  const [isPatient, setIsPatient] = useState(data.isPatient)
  const [type, setType] = useState(data.type || "1")
  return (
    <Row>
      <Col sm="12">
        <AvForm  
        onValidSubmit={(e, values) => {
            onValidSubmit(e, values, type)
            if (values == 1) {
              setIsPatient(true)
            }
          }}model={data}>
          <Row>
            <Col sm="6">
              <div className="mb-3">
                <div className="d-none">
                  <CustomAvField
                    name="id"
                    type="hidden"
                    value={data.id || ""}
                  />
                </div>
                <CustomAvField
                  name="name"
                  type="text"
                  required
                  errorMessage={getInvalidMessageI18n(t, "Profile Name")}
                  validate={{
                    required: { value: true },
                  }}
                  value={data.name || ""}
                  label={t("Profile Name")}
                  detected={isEdit}
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="mb-3">
                <div className="d-none">
                  <CustomAvField
                    name="type"
                    type="hidden"
                    value={data.type || ""}
                  />
                </div>
                <Label for="example">{t("Loại")} </Label>
                {/* <AvRadioGroup
                  className="radioDelivery"
                  name="type"
                  value={
                    listDeliveryType.length == 1
                      ? listDeliveryType[0].code
                      : data.type || ""
                  }
                >
                  {listDeliveryType.map((item, index) => (
                    <AvRadio
                      style={{ display: "flex" }}
                      label={t(`${item.message}`)}
                      value={item.code}
                      key={index}
                    />
                  ))}
                </AvRadioGroup> */}
                {listProfileType.length <= 3 ? (
                  <CustomButtonGroup className="justify-content-start">
                    {listProfileType.map((item, index) => (
                      <CustomButton
                        text={t(`common:${item.message}`)}
                        name={"type" + index}
                        type="button"
                        onClick={e => {
                          setType(item.code)
                        }}
                        color={item.code == type ? "primary" : "light"}
                        className="button-width"
                        value={item.code}
                        key={index}
                        disabled={isEdit}
                      />
                    ))}
                  </CustomButtonGroup>
                ) : (
                  <CustomSelect
                    name="type"
                    value={data.type || ""}
                    required
                    errorMessage={getInvalidMessageI18n(t, "Profile Type")}
                    validate={{
                      required: { value: true },
                    }}
                    code={parameterCode.PARTY_TYPE_CODE}
                    label={t("Profile Type")}
                    detected={isEdit}
                    onChange={(name, value) => {
                      if (value == 1) {
                        setIsPatient(true)
                      }
                    }}
                  />
                )}
              </div>
            </Col>

            <Col className="col-6">
              <div className="mb-3">
                <CustomCheckbox
                  type="checkbox"
                  direction={"down"}
                  name="internal"
                  checked={!!data.internal}
                  label={`${t("common:Internal")} ?`}
                  detected={isEdit}
                />
              </div>
            </Col>

            <Col sm="6">
              <div className="mb-3">
                <CustomAvField
                  name="description"
                  value={data.description || ""}
                  label={t("common:Description")}
                  detected={isEdit}
                  type="textarea"
                  rows="3"
                />
              </div>
            </Col>

            <Col className="col-6">
              <div className="mb-3">
                <CustomCheckbox
                  type="checkbox"
                  direction={"down"}
                  name="statuss"
                  checked={!!data.status}
                  label={`${t("common:Active")} ?`}
                  detected={isEdit}
                />
              </div>
              <div className="d-none">
                <CustomCheckbox
                  type="checkbox"
                  direction={"down"}
                  name="isSystem"
                  checked={!!data.isSystem}
                  label={`${t("common:isSystem")} ?`}
                  // detected={isEdit}
                />
              </div>
            </Col>

            <Col className="col-6">
              <div className="mb-3">
                <CustomCheckbox
                  type="checkbox"
                  direction={"down"}
                  name="isPatient"
                  checked={!!isPatient}
                  label={`${t("isPatient")} ?`}
                  detected={isEdit}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <CustomButtonGroup className="justify-content-center">
                <CustomButton
                  text={t("common:Cancel")}
                  type="button"
                  onClick={onCancel}
                  className="button-width"
                />
                <CustomButton
                  text={t("common:Save")}
                  type="submit"
                  color="primary"
                  className="save-user button-width"
                  isEdit
                  isModal
                />
              </CustomButtonGroup>
            </Col>
          </Row>
        </AvForm>
      </Col>
    </Row>
  )
}

export default withTranslation(["profilePage", "common", "message"])(EditMode)
