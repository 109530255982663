import { AvField, AvForm, AvInput } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import {
  Col,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap"

import CustomModal from "components/Common/Modals/CustomModal"

//i18n
import { CustomButton, CustomSelect } from "components/Common"
import { getInvalidMessageI18n } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import MessageInputLang from "../Detail/MessageInputLang"
import { ModuleIds, parameterCode } from "constant"

const MultiLangCodeModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  formEl,
  t,
  dataLength,
  resource,
  parameter
}) => {

  const title =
    (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("common:Code")
  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody>
        <AvForm
          id="parameterForm"
          onValidSubmit={onValidSubmit}
          ref={formEl}
          model={data}
        >
          <Row form>
            <AvField
              name="parameterId"
              type="hidden"
              value={data.parameterId || ""}
            />
            <AvField
              name="isDefault"
              type="hidden"
              value={data.isDefault || ""}
            />
            <AvField name="id" type="hidden" value={data.id || ""} />
            <Col className="col-12">
              <div className="mb-3">
                <Label for="example">
                  {t("common:Code")} <span className="text-danger">*</span>
                </Label>
                <AvField
                  readOnly={isEdit == true && resource != ModuleIds.ParameterCode}
                  name="code"
                  type="text"
                  errorMessage={getInvalidMessageI18n(t, "common:Code")}
                  validate={{
                    required: { value: true },
                    maxLength: { value: dataLength }
                  }}
                  value={data.code || ""}
                />
              </div>

              <MessageInputLang data={data} />

              <div className="mb-3">
                <Label for="example">{t("common:Sequence")}</Label>
                <AvField
                  name="sequence"
                  type="number"
                  value={data.sequence || 1}
                ></AvField>
              </div>
              <div className="mb-3">
                <Label for="example">{t("common:Group")}</Label>
                <AvField name="group" type="text" value={data.group || ""} />
              </div>
              {parameter && parameter.parameterKey == parameterCode.VS_PRINT_TYPE &&
                <div className="mb-3">
                  <Label className="form-check-label" htmlFor="invalidCheck" style={{ marginRight: '5px', marginBottom: '7px' }}>
                    {t("parameterPage:Apply Screen")}
                  </Label>
                  <CustomSelect
                    label={""}
                    name="applyScreen"
                    value={data.applyScreen || ""}
                    code={parameterCode.VS_APPLY_SCREEN_PRINT_TYPE}
                  />
                </div>
              }
              <div className="mb-3">
                <Label className="form-check-label" htmlFor="invalidCheck" style={{ marginRight: '5px' }}>
                  {t("common:In Use")} ?
                </Label>
                <AvInput tag={Input} type="checkbox" name="inUse" />
              </div>
              <div className="mb-3">
                <Label className="form-check-label" htmlFor="invalidCheck" style={{ marginRight: '5px' }}>
                  {t("common:Is Default Value")} ?
                </Label>
                <AvInput tag={Input} type="checkbox" name="isDefault" />
              </div>
            </Col>
          </Row>
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          className="button-width"
        />
        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          onClick={() => formEl?.current?.submit()}
          className="save-user button-width"
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  )
}
MultiLangCodeModal.propTypes = {
  onUpdateCode: PropTypes.func,
}

const mapDispatchToProps = dispatch => ({
  onUpdateCode: code => dispatch(updateCode(code)),
})

export default connect(
  // mapStateToProps,
  mapDispatchToProps
)(withTranslation(["common", "parameterPage", "message"])(
  MultiLangCodeModal
))
