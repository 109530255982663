import { Check, CustomBreadcrumb, PageContent } from "components/Common";
import { ModuleIds, permissionType } from "constant";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import WestgardSigmaIQCTab from "./WestgardSigmaQCTab";

const RESOURCE = ModuleIds.WestgardSigmaQC

const WestgardSigmaIQCContainer = ({ t }) => {
    const titleTag = `WestgardSigmaIQC`
    return (
        <PageContent title={t(titleTag)}>
            < CustomBreadcrumb
                breadcrumbItem={t("WestgardSigmaQCList")}
                resource={RESOURCE}
            />
            <Check permission={permissionType.R} resource={RESOURCE}>
                <WestgardSigmaIQCTab />
            </Check>
        </PageContent >
    )
}

WestgardSigmaIQCContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["westgardSigmaQCPage"])(WestgardSigmaIQCContainer)