import { AvForm } from "availity-reactstrap-validation"
import { CustomSelect } from "components/Common"
import CustomSearchBar from "components/Common/CustomTable/SearchBar"
import { parameterCode } from "constant"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
let isRender = false;
const HeaderFilter = ({
    t,
    model,
    onChange,
    onSubmitFilter,
    onClear
}) => {
    const [machine, setMachine] = useState(model.machine || '')
    const [category, setCategory] = useState(model.category || '')
    useEffect(() => {
        if (isRender == true) {
            onSubmitFilter()
        }
    }, [machine, category])

    return (
        <React.Fragment>
            <AvForm>
                <div className="row w-100 header-management-qc" style={{ paddingTop: '10px' }}>
                    <div className="col-12 mb-2">
                        <CustomSearchBar
                            onSearch={(e) => {
                                onChange({ search: e.target.value })
                            }}
                            searchText={model.search}
                            placeholder={t("Search management QC")}
                            onClear={() => {
                                onChange({ search: '' })
                                onClear && onClear()
                            }}
                            onSubmit={(e) => {
                                e.preventDefault()
                                onSubmitFilter()
                            }}
                            autoComplete="off"
                        />
                    </div>
                    <div className="col-lg-6 mb-2">
                        <CustomSelect
                            name="machineId"
                            label={""}
                            onChange={(e, vals) => {
                                isRender = true
                                onChange({ machine: vals[0] || '' })
                                setMachine(vals[0] || '')
                            }}
                            showID
                            placeholder={t("Machine")}
                            value={model.machine || ''}
                            code={parameterCode.INSTRUMENT_QC}
                        />
                    </div>
                    <div className="col-lg-6 mb-2">
                        <CustomSelect
                            name="category"
                            code={parameterCode.TESTPROFILE_SUB_CATEGORY_CODE}
                            label={""}
                            onChange={(e, vals) => {
                                isRender = true
                                onChange({ category: vals[0] || '' })
                                setCategory(vals[0] || '')
                            }}
                            placeholder={t("Group Test")}
                            value={model.category}
                        />
                    </div>
                </div>
            </AvForm>
        </React.Fragment>
    )
}

const mapStateToProps = ({ }) => ({
})

const mapDispatchToProps = dispatch => ({
})
HeaderFilter.displayName = 'HeaderFilter';
export default withTranslation(["managementQCPage", "common"], { withRef: true })(connect(
    mapStateToProps,
    mapDispatchToProps, null, { forwardRef: true }
)(HeaderFilter))