import { AvForm } from "availity-reactstrap-validation"

import { ModalBody, ModalFooter } from "reactstrap"

import {
  CustomAvField,
  CustomButton,
  CustomModal,
  CustomSelect,
  CustomSelectAsync
} from "components/Common"
import { parameterCode, VS_ORGM_GROUP_TYPE } from "constant/utility"

//i18n
import FormSwitchForm from "components/Common/FormSwitchForm"
import { getInvalidMessageI18n } from "helpers/utilities"
import { isEmpty } from "lodash"
import { useRef } from "react"
import { withTranslation } from "react-i18next"

let isChange = false;
const OrganismsModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  t,
}) => {

  const formEl = useRef()

  const title =
    (!!isEdit ? t("common:Edit") : t("common:Add New")) + " " + t("Genus VSV")
  if (isEdit && isEmpty(data)) return null

  return (
    <CustomModal modal={modal} title={title} onToggle={() => { toggle() }}>
      <ModalBody>
        <AvForm
          ref={formEl}
          id="machineForm"
          onValidSubmit={(e, values) => {
            onValidSubmit(values)
          }}
          model={data}
        >
          <CustomAvField name="id" type="hidden" value={data.id || ""} />
          <div className="row px-3">
            <div className="col-md-6">
              <div className="mb-3">
                <CustomAvField
                  name="code"
                  type="text"
                  required
                  errorMessage={getInvalidMessageI18n(t, "Code")}
                  validate={{
                    required: { value: true },
                  }}
                  value={data.code || ""}
                  label={t("Code")}
                  detected={isEdit}
                  disabled={isEdit}
                  maxLength={15}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <CustomAvField
                  name="name"
                  type="text"
                  required
                  errorMessage={getInvalidMessageI18n(t, "Organisms Name")}
                  validate={{
                    required: { value: true },
                  }}
                  value={data.name || ""}
                  label={t("Organisms Name")}
                  detected={isEdit}
                  maxLength={250}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <CustomAvField
                  name="shortName"
                  type="text"
                  errorMessage={getInvalidMessageI18n(t, "Short Name")}
                  validate={{
                    required: { value: true },
                  }}
                  value={data.shortName || ""}
                  label={t("Short Name")}
                  detected={isEdit}
                  maxLength={100}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <CustomSelectAsync
                  portal
                  label={t("Genus")}
                  name="genusCode"
                  keyGetValue={"code"}
                  value={data.genusCode || ""}
                  valueNameNotSearch={data.genusName || ""}
                  code={parameterCode.VS_ORGM_GENUS}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <CustomSelectAsync
                  portal
                  label={t("Group VSV")}
                  name="familyCode"
                  keyGetValue={"code"}
                  value={data.familyCode || ""}
                  valueNameNotSearch={data.familyName || ""}
                  code={parameterCode.VS_ORGM_GROUP}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <CustomSelect
                  // required
                  name="Infectivity"
                  value={data.infectivity || ""}
                  errorMessage={getInvalidMessageI18n(t, "Infectivity")}
                  code={parameterCode.VS_INFECTIVITY}
                  label={t("Infectivity")}
                  detected={isEdit}
                />
              </div>
            </div>
            <div className="mb-3">
              <FormSwitchForm
                name="inUse"
                label={`${t("common:Active")}: `}
                value={(data.inUse == null || data.inUse == undefined) ? true : (data.inUse == true)}

              />
            </div>
          </div>
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={() => {
            isChange = false;
            toggle()
          }}
          data-dismiss="modal"
          className="button-width"
        />

        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          // disabled={disableSave}
          onClick={() => {
            formEl?.current?.submit()
          }}
          className="save-user button-width"
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["organismsPage", "common"])(OrganismsModal)
