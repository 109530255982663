import { showToast } from "components/Common"
import { cancelQCResult, cancelValidQCResult, deleteQCResult, getQCResultList, updateQCResult, validQCResult } from "helpers/app-backend"
import i18n from "i18next"
import { call, put, takeEvery } from "redux-saga/effects"
import {
    CANCEL_RESULT_QC,
    CANCEL_VALID_RESULT_QC,
    DELETE_RESULT_QC,
    GET_LIST_RESULT_QC,
    UPDATE_LIST_RESULT_QC,
    VALID_RESULT_QC
} from "./actionTypes"
import {
    cancelResultsQCFail,
    cancelResultsQCSuccess,
    cancelValidResultsQCQCFail,
    cancelValidResultsQCSuccess,
    deleteResultsQCFail,
    deleteResultsQCSuccess,
    getListResultQCFail,
    getListResultQCSuccess,
    updateResultsQCFail,
    updateResultsQCSuccess,
    validResultsQCQCFail,
    validResultsQCSuccess
} from "./actions"
const t = (msg, param) => i18n.t(msg, param)

function* fetchGetListResultQC({ payload }) {
    try {
        let response = yield call(getQCResultList, payload)
        // let newData = response || []
        // if (newData.length > 0) {
        //     let ins = newData.filter(x => x.machineId != null && x.machineId != '').map(x => x.machineId)
        //     if (ins.length > 0) {
        //         let resIns = yield call(getInstrumentListByIns, { instrumentId: ins })
        //         if (resIns.data && resIns.data.length > 0) {
        //             newData.forEach(element => {
        //                 element.machineName = resIns.data.find(x => x.insID == element.machineId)?.name || ''
        //             });
        //         }
        //     }
        // }
        yield put(getListResultQCSuccess(response))
    } catch (error) {
        console.log(error)
        yield put(getListResultQCFail(error))
    }
}

function* onUpdateResultsQC({ payload: { data, callback } }) {
    try {
        const response = yield call(updateQCResult, data)
        yield put(updateResultsQCSuccess())
        let testCodes = []
        if (data.data && data.data.length > 0) {
            testCodes = testCodes.concat(data.data.map(x => x.testCode))
        }
        if (data.dataDelete && data.dataDelete.length > 0) {
            testCodes = testCodes.concat(data.dataDelete.map(x => x.testCode))
        }
        if (testCodes && testCodes.length > 0) {
            let code = [...new Set(testCodes)].toString()
            let msg = t("resultIQCPage:UpdateLotTestSucceeded").replaceAll("[testcode]", code)
            showToast(msg)
        }
        //update danh sách rỗng trước khi load data mới sau khi cập nhật
        yield put(getListResultQCSuccess([]))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(updateResultsQCFail(error))
    }
}

function* onValidQCResult({ payload, callback }) {
    try {
        const response = yield call(validQCResult, payload)
        yield put(validResultsQCSuccess())
        showToast(
            `${t("message:Succeeded", {
                field: `${t("resultIQCPage:Valid Result")}`,
            })}`
        )
        callback && callback()
    } catch (error) {
        console.log(error)
        callback && callback(error)
        yield put(validResultsQCQCFail(error))
    }
}

function* onCancelValidQCResult({ payload, callback }) {
    try {
        const response = yield call(cancelValidQCResult, payload)
        yield put(cancelValidResultsQCSuccess())
        showToast(
            `${t("message:Succeeded", {
                field: `${t("resultIQCPage:Cancel Valid")}`,
            })}`
        )
        callback && callback()
    } catch (error) {
        console.log(error)
        callback && callback(error)
        yield put(cancelValidResultsQCQCFail(error))
    }
}

function* onCancelQCResult({ payload, callback }) {
    try {
        const response = yield call(cancelQCResult, payload)
        yield put(cancelResultsQCSuccess())
        callback && callback()
    } catch (error) {
        console.log(error)
        callback && callback(error)
        yield put(cancelResultsQCFail(error))
    }
}

function* onDeleteQCResult({ payload, callback }) {
    try {
        const response = yield call(deleteQCResult, payload)
        yield put(deleteResultsQCSuccess())
        callback && callback()
    } catch (error) {
        console.log(error)
        callback && callback(error)
        yield put(deleteResultsQCFail(error))
    }
}

function* ResultQCsSaga() {
    yield takeEvery(GET_LIST_RESULT_QC, fetchGetListResultQC),
        yield takeEvery(UPDATE_LIST_RESULT_QC, onUpdateResultsQC),
        yield takeEvery(VALID_RESULT_QC, onValidQCResult),
        yield takeEvery(CANCEL_VALID_RESULT_QC, onCancelValidQCResult),
        yield takeEvery(CANCEL_RESULT_QC, onCancelQCResult),
        yield takeEvery(DELETE_RESULT_QC, onDeleteQCResult)
}
export default ResultQCsSaga