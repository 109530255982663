import { TitleAndTable, WarningModal } from "components/Common"
import { ModuleIds } from "constant"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import {
  getLottestListQC,
  getLottestListQCSuccess,
  updateApplySigmaTeCalculationQC,
  updateCvPeer,
  updateLottestCalculationQC,
  updateMeanPeer,
} from "store/actions"
import ExcelJS from 'exceljs';
import CalculationSigmaTETable from "./CalculationSigmaTETable"
import HeaderButtons from "./HeaderButtons"
const RESOURCE = ModuleIds.CalculationiQC

let lottestCalculates = []

const CalculationSigmaTE = ({
  paging,
  onGetLotTestQCList,
  onGetLottestListQCSuccess,
  loadingLottest,
  updatedTime,
  lottests,
  onUpdateApplySigmaTeCalculationQC,
  cvPeer,
  meanPeer,
  t,
  onMeanPeerUpdate,
  onCVPeerUpdate
}) => {
  const [rows, setRows] = useState([])
  const [warningModal, setWarningModal] = useState(false)
  const [config, setConfig] = useState({ isValid: true, cvDate: '' })
  const [lot, setLot] = useState({})
  const [model, setModel] = useState({
    search: ""
  })
  const [isCalculation, setIsCalculation] = useState(false)
  const [noPoint, setNoPoint] = useState({})


  useEffect(() => {
    onGetLottestListQCSuccess([])
  }, [])
  useEffect(() => {
    if (cvPeer.length > 0 || meanPeer.length > 0) {
      setIsCalculation(true)
    }
  }, [cvPeer, meanPeer])

  const onGetLotQCList = payload => {
    onGetLotTestQCList(payload)
    setIsCalculation(false)
    onMeanPeerUpdate([])
    onCVPeerUpdate([])
  }

  const resetState = () => {
    setRows([])
  }

  const onSelectCheckbox = (row, isSelected) => {
    if (isSelected) {
      setRows((prev) => [...prev, row])
    } else {
      setRows((prev) => prev.filter(item => item.id != row.id))
    }
  }

  const featchLotQC = () => {
    onGetLotTestQCList({
      ...model,
      page: 1,
    })
  }

  const onRefreshHandler = () => {
    resetState()
    featchLotQC()
  }

  const onSearch = searchText => {
    let val = {
      search: searchText || "",
    }
    setModel({ ...model, ...val })
    onGetLotQCList({ ...model, ...val })
  }

  const onSubmitFilter = values => {
    onGetLotQCList({ page: 1, ...values })
  }

  const onLottestExport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // Define columns
    const columns = [
      { header: 'Mã XN', key: 'testCode' },
      { header: 'Tên XN', key: 'testName' },
      { header: 'Đơn vị', key: 'unit' },
      { header: 'Mức', key: 'levels' },
      { header: 'Số điểm được tính', key: 'numPoint' },
      { header: 'Mean NSX', key: 'meanNSX' },
      { header: 'SD NSX', key: 'sdNSX' },
      { header: 'Mean PXN', key: 'meanPXN' },
      { header: 'SD PXN', key: 'sdPXN' },
      { header: '%CV', key: 'cv' },
      { header: 'Bias%', key: 'biasPercent' },
      { header: 'TEa', key: 'tEa' },
      { header: 'BiasUnit', key: 'biasUnit' },
      { header: 'TE', key: 'te' },
      { header: 'Sigma', key: 'sigma' },
      { header: 'QGI', key: 'qgi' }
    ];

    worksheet.columns = columns;

    // Add data rows
    lottests.forEach(item => {
      item.lottests.forEach(test => {
        worksheet.addRow({
          testCode: test.testCode,
          testName: item.testName,
          unit: test.unit || '',
          levels: test.levels,
          numPoint: test.numPoint,
          meanNSX: test?.meanPI ? parseFloat(test?.meanPI?.toFixed(3)) : '',
          sdNSX: test?.sdpi ? parseFloat(test?.sdpi?.toFixed(3)) : '',
          meanPXN: test?.mean ? parseFloat(test.mean.toFixed(3)) : '',
          sdPXN: test?.sd ? parseFloat(test.sd.toFixed(3)) : '',
          cv: test?.cv ? parseFloat(test?.cv?.toFixed(3)) : '',
          biasPercent: test?.biasPercent ? parseFloat(test?.biasPercent?.toFixed(3)) : '',
          tEa: test?.tEa ? parseFloat(test?.tEa?.toFixed(3)) : '',
          biasUnit: test?.biasUnit ? parseFloat(test?.biasUnit?.toFixed(3)) : '',
          te: test?.te ? parseFloat(test?.te?.toFixed(3)) : '',
          sigma: test?.sigma ? parseFloat(test?.sigma?.toFixed(3)) : '',
          qgi: test?.qgi ? parseFloat(test?.qgi?.toFixed(3)) : ''
        });
      });
    });

    // Style all cells
    worksheet.eachRow((row, rowNumber) => {
      row.eachCell((cell) => {
        cell.font = {
          name: 'Times New Roman',
          size: 11,
          color: { argb: 'FF000000' }
        };
        cell.alignment = {
          vertical: 'middle',
          horizontal: 'left'
        };

        // Special styling for header row
        if (rowNumber === 1) {
          cell.font.bold = true;
        }
      });
    });

    // Auto-fit columns
    worksheet.columns.forEach(column => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength > 65 ? 65 : maxLength + 3; //witdh max 65
    });

    // Generate buffer and create blob for browser download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    // Create download link and trigger download
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'LottestCalculation.xlsx';
    link.click();
    window.URL.revokeObjectURL(url);
  };

  const submitConfig = values => {
    setConfig(values)
  }

  const onLottestChange = lottests => {
    lottestCalculates = lottests
  }
  const onApply = () => {
    let lottestData = [];
    let lottests = []
    if (rows.length > 0) {
      lottests = rows
    } else {
      lottests = lottestCalculates
    }
    lottests.forEach(item => {
      let lottests = []
      lottests = item.lottests && item.lottests.length > 0 ? item.lottests : [item]
      lottestData = [
        ...lottestData,
        ...lottests
          .filter(x => (x.mean != null && x.mean != 0 && x.mean != '') || (x.cv != null && x.cv != 0 && x.cv != ''))
          .map(lotTest => {
            let formattedLotTest = {
              id: lotTest.id || 0,
              testCode: lotTest.testCode || '',
              lotId: lotTest.lotId || '',
              insId: lotTest.insId || 0,
              levels: lotTest.levels || '',
              method: lotTest.method || '',
              mean: lotTest.mean || 0,
              sd: lotTest.sd || 0,
              cv: lotTest.cv || 0,
              cvPXN: lotTest.cvPXN || null,
              oldSD: lotTest.oldSD || 0,
              oldMean: lotTest.oldMean || 0,
              calNum: lotTest.calNum ?? null,
              biasUnit: lotTest.biasUnit || '',
              biasPercent: lotTest.biasPercent || ''
            };

            if (lotTest.te !== null && lotTest.te !== undefined) {
              formattedLotTest.te = lotTest.te;
            }
            if (lotTest.sigma !== null && lotTest.sigma !== undefined) {
              formattedLotTest.sigma = lotTest.sigma;
            }
            if (lotTest.qgi !== null && lotTest.qgi !== undefined) {
              formattedLotTest.qgi = lotTest.qgi;
            }

            return formattedLotTest;
          })
      ];
    });

    const firstLotTest = lottests?.length > 0 && lottests[0]?.lottests?.length > 0 ? lottests[0] : {};

    const data = {
      ...model,
      lotId: firstLotTest.lotId || '',
      lotTests: lottestData
    };
    onUpdateApplySigmaTeCalculationQC(data, () => {
      setIsCalculation(false)
    });
  };

  const onCalculate = () => {
    if (config.isCV) {
      config.formulaCodeCV = config.cvCalculation
    }

    const firstPointTime = lot.firstPointTime ? moment(lot.firstPointTime, 'YYYY-MM-DD').format("DD-MM-YYYY 00:00:00") : new Date()

    if (config.lotQcs) {
      config.startCV = null
      config.endCV = null
    } else {
      config.lotIds = null
      config.startCV = moment(config.cvDate ? config.cvDate[0] : firstPointTime, 'DD-MM-YYYY').format("YYYY-MM-DD 00:00:00")
      config.endCV = moment(config.cvDate ? config.cvDate[1] : new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD 23:59:59")
    }
    if (config.isBias) {
      config.formulaCodeBias = config.biasCalculation
    }
    config.startBias = moment(config.biasDate ? config.biasDate[0] : firstPointTime, 'DD-MM-YYYY').format("YYYY-MM-DD 00:00:00")
    config.endBias = moment(config.biasDate ? config.biasDate[1] : new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD 23:59:59")

    config.isCalculation = true
    config.tab = 2 // enum 1: Calculate, 2: Six Sigma
    onGetLotTestQCList({ ...model, ...config }, (data) => {
      setIsCalculation(true)
      if (data.noPointCV.length > 0 || data.noPointMean.length > 0 || data.noPointBias.length > 0) {
        setNoPoint(data)
        setWarningModal(true)
      } else {
        setNoPoint({})
      }

    })
  }

  return (
    <React.Fragment>
      <TitleAndTable
        table={() => (
          <CalculationSigmaTETable
            lottests={lottests}
            lot={lot}
            config={config}
            onSelect={onSelectCheckbox}
            onSearch={onSearch}
            onRefresh={onRefreshHandler}
            paging={paging}
            onSubmitFilter={onSubmitFilter}
            loading={loadingLottest}
            updatedTime={updatedTime}
            submitConfig={submitConfig}
            onLottestExport={onLottestExport}
            onLottestChange={onLottestChange}
            onLotChange={(data) => {
              setLot(data[0])
            }}
            onChangeModel={val => {
              setModel({ ...model, ...val })
            }}
          />
        )}
        resource={RESOURCE}
        buttons={() => (
          <HeaderButtons
            lottests={lottests}
            resource={RESOURCE}
            onApply={onApply}
            onCalculate={onCalculate}
            isCalculation={isCalculation}
          />
        )}
        external
        title={t("Quality Management")}
        subtitle={t("Calucate 6Signma, TE")}
      />

      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={
          <>
            <div>
              {noPoint?.noPointCV?.length > 0 ? `Mã XN ${[...new Set(noPoint.noPointCV.map(item => item.testCode))].join(',')} chưa có điểm để tính toán CV ` : ''}
            </div>
            <div>
              {noPoint?.noPointMean?.length > 0 ? `Mã XN ${[...new Set(noPoint.noPointMean.map(item => item.testCode))].join(',')} chưa có điểm để tính toán Mean ` : ''}
            </div>
            <div>
              {noPoint?.noPointBias?.length > 0 ? `Mã XN ${[...new Set(noPoint.noPointBias.map(item => item.testCode))].join(',')} chưa có điểm để tính toán Bias ` : ''}
            </div>
          </>
        }
      />

    </React.Fragment>
  )
}

CalculationSigmaTE.propTypes = {
  lottests: PropTypes.array,
  onGetLotTestQCList: PropTypes.func,
  onUpdateLottestCalculationQC: PropTypes.func,
  onUpdateApplySigmaTeCalculationQC: PropTypes.func,
  loadingLottest: PropTypes.bool,
  t: PropTypes.any,
}

const mapStateToProps = ({ calculationQC }) => ({
  lottests: calculationQC.lottests,
  loadingLottest: calculationQC.loadingLottest,
  cvPeer: calculationQC.cvPeer,
  meanPeer: calculationQC.meanPeer,
})

const mapDispatchToProps = dispatch => ({
  onGetLotTestQCList: (payload, callback) => dispatch(getLottestListQC(payload, callback)),
  onUpdateLottestCalculationQC: (payload, callback) =>
    dispatch(updateLottestCalculationQC(payload, callback)),
  onUpdateApplySigmaTeCalculationQC: (payload, callback) =>
    dispatch(updateApplySigmaTeCalculationQC(payload, callback)),
  onGetLottestListQCSuccess: payload =>
    dispatch(getLottestListQCSuccess(payload)),
  onMeanPeerUpdate: (data) => (dispatch(updateMeanPeer(data))),
  onCVPeerUpdate: (data) => (dispatch(updateCvPeer(data)))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    withTranslation(["calculationQCPage", "message", "common"])(
      CalculationSigmaTE
    )
  )
)
