import { AvField } from "availity-reactstrap-validation"
import classnames from "classnames"
import _, { debounce, isEmpty } from "lodash"
import PropTypes from "prop-types"
import { useCallback, useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { withRouter } from "react-router-dom"
import Select, { components } from "react-select"
import ReactTooltip from "react-tooltip"
import { Label } from "reactstrap"
import BadgeItem from "../BadgeItem"

import {
  getAllCompanies,
  getAllDepartments,
  getAllIndividuals,
  getAllOrganizations,
  getAllParameters,
  getAllParties,
  getAllPhysicians,
  getAllProfiles,
  getAllRoles,
  getAllTestProfiles,
  getAllTests,
  getAllUsers,
  getCodesByParameterId,
  getListRuleAction,
  GetQCLotInLotTestList,
  getQCLotGroup,
  getQCLotList,
  getTestCategoryIQC,
  getUserListByResouceAction,
} from "helpers/app-backend"
import { getAllMachine } from "helpers/app-backend/machines_backend_helper"
import { getAllDeliveries, getDeliverySampleById, getRequestSamples } from "helpers/app-backend/sampleDelivery_backend_helper"
import { useDetectedChanges } from "helpers/hooks"
import { convertDateFormat, getI18nextLng, isEmptyArray, SelectPopupRatio125 } from "helpers/utilities"


import { BadgeParameterType, CONNECTION_TYPE, DefaultValue, ModuleIds, PartyTypeText, SAMPLES_DELIVERY_TYPE, parameterCode } from "constant/utility"
import { getFullTestrequestSamplesDetailById } from "helpers/app-backend/testRequest_backend_helper"
import { RollbackButton } from "."
import { getCausalActionQCList } from "helpers/app-backend/IQC/QC_Causal_Action_backend_helper"
import { getIntrumentsByLotId } from "helpers/app-backend/IQC/QCChangeSID_backend_helper"


const CustomSelect = ({
  value,
  name,
  required,
  dangered,
  isShowParameterCode,
  showID,
  errorMessage,
  code,
  group,
  onChange,
  onMachineItemsChange,
  isDependent,
  isMulti,
  portal,
  label,
  detected,
  options,
  readOnly,
  searchQuery,
  customSearchQuery,
  showDefaultAll,
  placeholder,
  t,
  isClearable = true,
  customStyle,
  sort,
  keyGetValue,
  isLoading = false,
  onDebounce
}) => {
  const [items, setItems] = useState([])
  const [defaultValue, setDefaultValue] = useState()
  const inputRef = useRef()
  const [valueInput, isChanged] = useDetectedChanges(
    value,
    isMulti ? defaultValue : defaultValue?.[0],
    name
  )

  const onUndoHandler = () => {
    onChangeHandler(
      isMulti ? (isEmpty(valueInput) ? [] : valueInput) : { value: valueInput }
    )
  }

  const isTouched = inputRef.current?.FormCtrl.isTouched()
  const isDirty = inputRef.current?.FormCtrl.isDirty()
  const isInvalid =
    !readOnly &&
    required &&
    isEmptyArray(defaultValue) &&
    (isTouched || isDirty)

  let lang = getI18nextLng()

  // COMMON CODES
  const fetchCodes = async (code, group, searchQuery) => {
    let query = { lang, group, ...searchQuery }
    if (sort) {
      query = { ...query, sort }
    }
    if (code == parameterCode.LEVEL_QC) {
      if (group == "") {
        return []
      }
    }
    let res = await getCodesByParameterId(code, query);
    res = res.filter(r => r.inUse)
    if (code === parameterCode.TEST_TYPES) {
      res?.map(_item => {
        _item.value = _item.code
        _item.label = _item.message
        if (isShowParameterCode) {
          _item.label = `${_item.message} - ${_item.code}`
        }
        return _item
      })
    } else if (code === parameterCode.PARAMETER_TYPE) {
      res?.map(_item => {
        _item.value = _item.code
        _item.label = SetBadge(_item)
        return _item
      })
    } else if (code == parameterCode.INSTRUMENT_RESULT_STATUS) {
      res?.map(_item => {
        _item.value = _item.code
        _item.label = _item.message
        if (isShowParameterCode) {
          _item.label = `${_item.code}-${_item.message} `
        }
        return _item
      })
    } else if (code == parameterCode.USER_STATUS_CODE) {
      res?.map(_item => {
        _item.value = _item.code
        _item.label = _item.message
        if (isShowParameterCode) {
          _item.label = `${_item.message} - ${_item.code}`
        }
        return _item
      })
    }
    else if (code == parameterCode.TESTREQUEST_STATE_KEY) {
      res?.map(_item => {
        _item.value = _item.code
        _item.label = t("testRequestPage:" + _item.message)
        if (isShowParameterCode) {
          _item.label = `${_item.message} - ${_item.code}`
        }
        return _item
      })
    }
    else if (code == parameterCode.TESTRESULT_STATE_KEY) {
      res?.map(_item => {
        _item.value = _item.code
        _item.label = t("testResultPage:" + _item.message)
        if (isShowParameterCode) {
          _item.label = `${_item.message} - ${_item.code}`
        }
        return _item
      })
    }
    else if (code == parameterCode.AREA) {
      res?.map(_item => {
        _item.value = _item.code
        _item.label = _item.message
        if (isShowParameterCode) {
          _item.label = `${_item.message} - ${_item.code}`
        }
        return _item
      })
    }
    else {
      res?.map(_item => {
        _item.value = _item.code
        _item.label = _item.message
        if (isShowParameterCode) {
          _item.label = `${_item.message} - ${_item.code}`
        }
        return _item
      })
    }
    // if (code === parameterCode.SAMPLE_TYPES ||
    //   code === parameterCode.TEST_TYPES ||
    //   code == parameterCode.BILLING_TYPE) {
    //   const defaultItemIndex = res.find(item => item.isDefault === true);
    //   setDefaultValue([defaultItemIndex?.value])
    //   if (defaultItemIndex !== -1) {
    //     const defaultItem = res[defaultItemIndex];
    //     res.splice(defaultItemIndex, 1);
    //     res.unshift(defaultItem)
    //   }
    // }
    return res
  }
  const SetBadge = ({ code, message }) => {
    const { color, key, classname } = BadgeParameterType.filter(p => p.key === code)[0] ?? [];
    return key !== undefined ? <BadgeItem classname={classname} color={color} label={key} message={message} Perfix=" " /> : message

  }
  // PARAMETERS
  const fetchParameters = async () => {
    const query = { size: 0 }
    const res = await getAllParameters(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.parameterKey
        _item.label = _item.name
        return _item
      })

    return data
  }

  // COMPANIES
  const fetchCompanies = async () => {
    const query = { size: 0 }
    const res = await getAllCompanies(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = `${_item.name}${_item.manageCode ? ` - ${_item.manageCode}` : ''}`
        return _item
      })
    if (showDefaultAll) {
      data.unshift({
        value: 1,
        label: '--All--'
      })
    }
    return data
  }

  // DEPARTMENTS
  const fetchDepartments = async (group) => {

    const query = {
      size: 0,
      companyId: group
    }
    const res = await getAllDepartments(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label =
          lang === "vi" ? _item.name : _item.englishName || _item.name
        return _item
      })

    return data
  }

  // DEPARTMENTS
  const fetchPhysicians = async () => {
    const query = { size: 0 }
    const res = await getAllPhysicians(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label =
          lang === "vi" ? _item.name : _item.englishName || _item.name
        return _item
      })

    return data
  }

  // INDIVIDUALS
  const fetchIndividuals = async () => {
    const query = { size: 0 }
    const res = await getAllIndividuals(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label =
          lang === "vi" ? _item.name : _item.englishName || _item.name
        return _item
      })

    return data
  }

  // ROLES
  const fetchRoles = async () => {
    const query = { size: 0, status: true }
    const res = await getAllRoles(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = _item.roleName
        return _item
      })

    return data
  }

  const fetchUsersValidResultQC = async () => {
    const query = { ResourceCode: ModuleIds.ResultIQC, Action: ModuleIds.ValidResult }
    const res = await getUserListByResouceAction(query)
    let data = []
    if (res)
      data = res.map(_item => {
        _item.value = _item.id
        _item.label = _item.name
        return _item
      })

    return data
  }
  // USERS
  const fetchUsers = async () => {
    const query = { size: 0 }
    const res = await getAllUsers(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = _item.familyName + " " + _item.givenName
        return _item
      })

    return data
  }

  // PARTIES
  const fetchParties = async profileId => {
    const query = { size: 0, profileId }
    const res = await getAllParties(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = _item.name
        return _item
      })

    return data
  }

  // PROFILES
  const fetchProfiles = async searchQuery => {
    const query = { size: 0, ...searchQuery }
    const res = await getAllProfiles(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = _item.name
        return _item
      })

    return data
  }

  const fetchProfilesIndividual = async searchQuery => {
    const query = {
      size: 0, type: PartyTypeText.Individual,
      status: true,
      isSystem: false, ...searchQuery
    }
    const res = await getAllProfiles(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = _item.name
        return _item
      })

    return data
  }

  const fetchProfilesOrganization = async searchQuery => {
    const query = {
      size: 0, type: PartyTypeText.Organization,
      status: true,
      isSystem: false, ...searchQuery
    }
    const res = await getAllProfiles(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = _item.name
        return _item
      })

    return data
  }

  // TESTPROFILES
  const fetchTestProfiles = async searchQuery => {
    const query = { size: 0, ...searchQuery }
    const res = await getAllTestProfiles(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = _item.profileName
        return _item
      })

    return data

  }

  const fetchRuleActions = async searchQuery => {
    const res = await getListRuleAction()
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.code
        _item.label = _item.name
        return _item
      })

    return data

  }

  const fetchTestProfileCode = async searchQuery => {
    const query = { size: 0, ...searchQuery, status: 1 }
    const res = await getAllTestProfiles(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.code
        _item.label = _item.profileName
        return _item
      })
    return data

  }

  const fetchTestProfileCodeCustom = async searchQuery => {
    const query = { size: 0, ...searchQuery, status: 1 }
    const res = await getAllTestProfiles(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.code
        _item.label = `[${_item.code}] - ${_item.profileName}`
        return _item
      })
    return data

  }

  // TESTPROFILES
  const fetchTestS = async searchQuery => {
    const query = { size: 0, ...searchQuery, inUse: true }
    const res = await getAllTests(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = _item.testName
        return _item
      })

    return data
  }

  // ORGANIZATIONS
  const fetchOrganizations = async (searchQuery) => {
    const query = { ...searchQuery, size: 0 }
    const res = await getAllOrganizations(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = _item.name
        return _item
      })

    return data
  }

  // SAMPLE FOR DELIVERY
  const fetchSampleForDelivery = async (searchQuery) => {
    const query = { size: 0, ...customSearchQuery, ...searchQuery }
    if (!query.dateTo) {
      query.dateTo = query.dateFrom
    }
    const res = await getRequestSamples(query)
    let arrDepartment = res.map(x => x.departmentId) || [];
    let resDepartment = [];
    arrDepartment = arrDepartment.filter(x => !isEmpty(`${x}`))
    if (arrDepartment.length > 0) {
      const deptValues = await getAllDepartments({ id: arrDepartment })
      resDepartment = deptValues.data || []
    }
    let data = []
    if (res)
      data = res.map(_item => {
        const departmentName = resDepartment?.find(x => `${x.id}` == `${_item.departmentId}`)?.name
        _item.value = _item.id
        _item.label = `${_item.sid} • ${_item.sidName} • ${_item.sampleType} • ${_item.sampleTypeName}${_item.patientName ? " • " + _item.patientName : ""} • ${convertDateFormat(_item.dob, "YYYY")} `
        if (!isEmpty(departmentName)) {
          _item.label += `• ${departmentName}`
        }
        return _item
      })
    return _.uniqBy(data, 'value')
  }

  const BackData = (data) => {
    setDefaultValue(data)
    onChange(null, null, null, data)
  }
  const fetchSampleSelectForDelivery = async (searchQuery) => {
    if (!searchQuery) {
      BackData([])
      return []
    }
    const { id, sid } = searchQuery
    if (!id) {
      BackData([])
      return []
    }
    const res = await getFullTestrequestSamplesDetailById(id)
    let data = []
    if (res.samples) {
      const sampleExist = searchQuery.sampleExist || []
      const readOnly = searchQuery.readOnly ? searchQuery.readOnly : false
      if (readOnly) {
        data = res.samples.filter(x => x.sid == sid).map(_item => {
          _item.value = _item.sampleType
          _item.label = `${_item.sampleTypeName}`
          return _item
        })
      }
      else {
        data = res.samples.filter(x => x.sid == sid && sampleExist.findIndex(z => z.sampleType == x.sampleType && z.sid == sid) < 0).map(_item => {
          _item.value = _item.sampleType
          _item.label = `${_item.sampleTypeName}`
          return _item
        })
      }
    }
    BackData(data.map(x => x.sampleType) || [])
    return data
  }

  const fetchSampleForTestDelivery = async (searchQuery) => {
    const query = { ...customSearchQuery }
    const res = await getDeliverySampleById(query.id)
    let data = []
    if (res)
      data = res.map(_item => {
        _item.value = _item.sid
        _item.label = `${_item.sid} • ${_item.sampleType} • ${_item.patientName} `
        return _item
      })
    return data
  }

  const fetchTestCode = async searchQuery => {
    const query = { size: 0, ...searchQuery, inUse: true }
    const res = await getAllTests(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.testCode
        _item.label = _item.testName
        return _item
      })
    return data
  }

  // TESTPROFILES
  const fetchMachines = async searchQuery => {
    const query = { size: 0, ...searchQuery, status: 1 }
    const res = await getAllMachine(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = _item.name
        if (isShowParameterCode) {
          _item.value = _item.insID
          _item.label = _item.connectionType ? `${_item.name} - ${_item.connectionType ? CONNECTION_TYPE[_item.connectionType] : ""} - ID : ${_item.insID}` : `${_item.name}`;
        }
        if (showID) {
          _item.value = _item.insID
          _item.label = `ID: ${_item.insID} - ${_item.name}`
        }
        if (keyGetValue) {
          _item.value = _item[keyGetValue]
          _item.label = _item.name;
        }
        return _item
      })
    return data
  }

  const fetchAllTests = async searchQuery => {
    const query = { size: 0, ...searchQuery }
    const res = await getAllTests(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.testCode
        _item.label = `${_item.testName} - ${_item.testCode}`
        return _item
      })

    return data
  }

  const fetchAllDelivery = async (searchQuery, id) => {
    const query = { size: 0, ...searchQuery }
    const res = await getAllDeliveries(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = `${_item.deliveryDate}`
        return _item
      })
    data = data.filter(x => `${x.id}` != `${id}`)
    return data
  }

  const fetchDeliveryTypeABD = async (code, group, searchQuery) => {
    const query = { lang, group, ...searchQuery }
    let res = await getCodesByParameterId(code, query);
    res = res.filter(r => r.inUse && [SAMPLES_DELIVERY_TYPE.Delivery, SAMPLES_DELIVERY_TYPE.Receipt, SAMPLES_DELIVERY_TYPE.DeliveryAndReceipt].findIndex(x => x == r.code) >= 0)
    if (code === parameterCode.TEST_TYPES) {
      res?.map(_item => {
        _item.value = +_item.code
        _item.label = _item.message
        if (isShowParameterCode) {
          _item.label = `${_item.message} - ${_item.code}`
        }
        return _item
      })
    } else {
      res?.map(_item => {
        _item.value = _item.code
        _item.label = _item.message
        if (isShowParameterCode) {
          _item.label = `${_item.message} - ${_item.code}`
        }
        return _item
      })
    }
    if (code === parameterCode.SAMPLE_TYPES && showDefaultAll) {
      res.unshift({
        value: DefaultValue.SAMPLE_TYPES_DEFAULT,
        label: 'All'
      })
    }
    return res
  }

  const fetchDateTypeResultListInput = async () => {
    // const res = await getAllDeliveries(query)
    let data = []
    data = [
      { value: 1, label: t("testRequestPage:Reception") },
      { value: 2, label: t("testRequestPage:Sample") },
      { value: 3, label: t("testRequestPage:Delivery") },
    ]
    // if (res.data)
    // {
    //   data = res.data.map(_item => {
    //     _item.value = _item.id
    //     _item.label = `${_item.deliveryDate}`
    //     return _item
    //   })
    // }
    return data
  }

  const fetchLotQcs = async (searchQuery) => {
    let query = { size: 0, inUse: true }
    if (typeof (searchQuery) === typeof ({})) {
      query = { ...query, ...searchQuery }
    }
    else {
      query = { ...query, search: searchQuery }
    }
    const res = await getQCLotList(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.lotId
        _item.label = _item.lotName
        return _item
      })
    return data
  }

  const fetchLotInLotTestQcs = async (searchQuery) => {
    let query = { size: 0, inUse: true }
    if (typeof (searchQuery) === typeof ({})) {
      query = { ...query, ...searchQuery }
    }
    else {
      query = { ...query, search: searchQuery }
    }
    const res = await GetQCLotInLotTestList(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.lotId
        _item.label = _item.lotName
        return _item
      })
    return data
  }

  const fetchTestCategoryIQC = async () => {
    const res = await getTestCategoryIQC()
    let data = []
    if (res)
      data = res.map(_item => {
        _item.value = _item.category
        _item.label = _item.categoryName
        return _item
      })
    return data
  }

  const fetchResultCausalQCList = async () => {
    const query = { size: 0, inUse: true, category: 1 }
    const res = await getCausalActionQCList(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = _item.name
        return _item
      })
    return data
  }

  const fetchResultActionQCList = async () => {
    const query = { size: 0, inUse: true, category: 2 }
    const res = await getCausalActionQCList(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = _item.name
        return _item
      })
    return data
  }

  const fetchMachineQCs = async searchQuery => {
    const query = { size: 0, ...searchQuery, inUse: true }
    const res = await getAllMachine(query)
    let data = []
    if (res.data)
      data = res.data.filter(x => x.instrumentQC != null && x.instrumentQC != '' && x.status == true).map(_item => {
        _item.value = _item.id
        _item.label = _item.name
        if (showID) {
          _item.value = _item.insID
          _item.label = _item.name
        }
        return _item
      })
    return data
  }

  const fetchLotGroups = async (searchQuery) => {
    let query = { lang, ...searchQuery }
    let res = await getCodesByParameterId(code, query);
    let groups = await getQCLotGroup()
    res = res.filter(r => r.inUse)
    res?.map(_item => {
      _item.value = _item.code
      _item.label = _item.message
      return _item
    })

    if (groups && groups.length > 0) {
      groups.forEach(element => {
        if (res.findIndex(x => x.value == element.groupCode) < 0) {
          res.push({ value: element.groupCode, label: element.groupCode })
        }
      });
    }

    return res
  }

  const fetchMachineQCChangeSID = async searchQuery => {
    const allData = await fetchMachineQCs()
    if (!searchQuery || !searchQuery?.lotId)
      return allData

    const res = await getIntrumentsByLotId({ size: 0, lotId: searchQuery.lotId ?? "" })
    let data = []
    data = allData.filter(x => res.data.findIndex(y => y.insId == x.value) >= 0)
    return data
  }

  const fetchOptions = useCallback(async (code, group, searchQuery) => {
    let res = []
    if (code === parameterCode.COMPANIES) {
      res = await fetchCompanies()
    } else if (code === parameterCode.PARAMETER) {
      res = await fetchParameters()
    } else if (code === parameterCode.INSTRUMENT) {
      res = await fetchMachines()
    } else if (code === parameterCode.INSTRUMENT_QC) {
      res = await fetchMachineQCs()
    } else if (code === parameterCode.ORGANIZATIONS) {
      res = await fetchOrganizations(searchQuery)
    } else if (code === parameterCode.TESTPROFILE_TEST) {
      res = await fetchAllTests()
    } else if (code === parameterCode.PROFILES) {
      res = await fetchProfiles(searchQuery)
    } else if (code === parameterCode.PROFILES_Individual) {
      res = await fetchProfilesIndividual(searchQuery)
    } else if (code === parameterCode.PROFILES_Organization) {
      res = await fetchProfilesOrganization(searchQuery)
    } else if (code === parameterCode.INDIVIDUALS) {
      res = await fetchIndividuals()
    } else if (code === parameterCode.DEPARTMENTS) {
      res = await fetchDepartments(group)
    } else if (code === parameterCode.PHYSICIANS) {
      res = await fetchPhysicians()
    } else if (code === parameterCode.ROLES) {
      res = await fetchRoles()
    } else if (code === parameterCode.USERS) {
      res = await fetchUsers()
    } else if (code === parameterCode.LOT_QC) {
      res = await fetchLotQcs(searchQuery)
    } else if (code === parameterCode.LOT_IN_LOT_TEST_QC) {
      res = await fetchLotInLotTestQcs(searchQuery)
    } else if (code == parameterCode.QC_RESULT_RULE_ACTIONS) {
      res = await fetchResultActionQCList()
    }
    else if (code === parameterCode.QC_LOCATION) {
      res = await fetchTestCategoryIQC()
    } else if (code === parameterCode.USERS_QC_RESULT_VALID) {
      res = await fetchUsersValidResultQC()
    } else if (code === parameterCode.QC_RESULT_RULE_CAUSES) {
      res = await fetchResultCausalQCList()
    } else if (code === parameterCode.TESTPROFILE) {
      res = await fetchTestProfiles(searchQuery)
    } else if (code === parameterCode.LOT_GROUP_QC) {
      res = await fetchLotGroups(searchQuery)
    } else if (code === parameterCode.RULE_ACTION) {
      res = await fetchRuleActions(searchQuery)
    } else if (code === parameterCode.TESTPROFILE_CODE) {
      res = await fetchTestProfileCode(searchQuery)
    } else if (code === parameterCode.TESTPROFILE_CODE_CUSTOM) {
      res = await fetchTestProfileCodeCustom(searchQuery)
    } else if (code === parameterCode.TEST) {
      res = await fetchTestS(searchQuery)
    } else if (code === parameterCode.SAMPLES_FOR_DELIVERY) {
      res = await fetchSampleForDelivery(searchQuery)
    } else if (code === parameterCode.SAMPLES_SELECT_FOR_DELIVERY) {
      res = await fetchSampleSelectForDelivery(searchQuery)
    } else if (code === parameterCode.SAMPLES_FOR_TEST_DELIVERY) {
      res = await fetchSampleForTestDelivery(searchQuery)
    } else if (code === parameterCode.DELIVERY_TYPE_CODE_ABD) {
      res = await fetchDeliveryTypeABD(parameterCode.DELIVERY_TYPE_CODE, group, searchQuery)
    } else if (code === parameterCode.TEST_CODE) {
      res = await fetchTestCode(searchQuery)
    } else if (code === parameterCode.DATE_TYPE_RESULT_INPUT_LIST) {
      res = await fetchDateTypeResultListInput(searchQuery)
    } else if (code === parameterCode.DELIVERY) {
      res = await fetchAllDelivery(searchQuery, group)
    } else if (code === parameterCode.INSTRUMENT_QC_CHANGESID) {
      res = await fetchMachineQCChangeSID(searchQuery)
    }
    else if (code.indexOf("_") >= 0 || code === parameterCode.PROFILE) {
      const profileId = code.substring(code.indexOf("_") + 1)
      if (profileId > 0) res = await fetchParties(profileId)
      else res = await fetchParties()
    } else {
      res = await fetchCodes(code, group, searchQuery)
    }
    setItems(res)
    if (onMachineItemsChange) {
      onMachineItemsChange(res);
    }
  }, [])

  const onChangeHandler = (e, b) => {
    const element = document.getElementsByName(name)[0]
    var event = new Event("change", { bubbles: true })
    const value =
      (isMulti
        ? (Array.isArray(e) ? e?.map(_value => {
          return _value.value
        }) : e?.split(','))
        : [e?.value]) || []

    element.value = value || ""
    const label =
      (isMulti
        ? value?.map(_option => {
          return _option.label
        })
        : [value?.label]) || []
    const item =
      (isMulti
        ? e
        : [e]) || []
    element.dispatchEvent(event)

    setDefaultValue(value || "")
    onChange(name, value, label, item)
    if (onMachineItemsChange) {
      onMachineItemsChange(items); // send all items to parent
    }
  }

  const isValidStyle = isInvalid && {
    borderColor: "#f46a6a !important",
    backgroundImage: `url(
    "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f46a6a'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='%23f46a6a' stroke='none'/></svg>"
  )`,
    backgroundPosition: "right 2.75rem center, center right 0.5rem",
    backgroundSize: "17px 18px, calc(0.75em + 0.47rem) calc(0.75em + 0.47rem)",
    backgroundRepeat: "no-repeat",
  }

  const colourStyles = {
    control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isDisabled ? "#edf1f2 !important" : "white",
      fontSize: "13px",
      cursor: isDisabled ? "not-allowed" : "default",
      ...customStyle,
      ...isValidStyle,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontSize: "13px",
        cursor: isDisabled ? "not-allowed" : "default",
      }
    },
    singleValue: (provided, state) => {
      const opacity =
        state.isDisabled || !state.data.value || state.data.value === "0"
          ? 0.7
          : 1
      const transition = "opacity 300ms"

      return { ...provided, opacity, transition }
    },

    menuPortal: provided => ({
      ...provided,
      zIndex: 9999,
    }),
    menu: provided => ({ ...provided, zIndex: 9999 }),
  }

  useEffect(() => {
    if (code && (!isDependent || (isDependent && group)))
      fetchOptions(code, group, searchQuery)

    else setItems([])
  }, [code, group, isDependent, searchQuery])

  useEffect(() => {
    if (code) {
      customSearchQuery && fetchOptions(code, group, customSearchQuery)
    }
    else setItems([])
  }, [customSearchQuery])

  useEffect(() => {
    setDefaultValue(isMulti ? value : [value])
  }, [])

  useEffect(() => {
    setDefaultValue(isMulti ? value : [value])
  }, [value])

  useEffect(() => {
    if (options) {
      setItems(options)
      if (onMachineItemsChange) {
        onMachineItemsChange(options);
      }
    }
  }, [options])
  const MultiValueContainer = (props) => {
    return (
      <>
        <div data-tip data-for={props.data.label}>
          <components.MultiValueLabel {...props} />
        </div>
        <ReactTooltip id={props.data.label} place="bottom" effect="solid">
          {props.data.label}
        </ReactTooltip>
      </>
    );
  };
  const onInputChangeHandler = debounce(value => {
    onDebounce && onDebounce(value)
  }, 600)
  return (
    <>
      <div className="label-group-relative position-relative">
        {label && (
          <Label for={name}>
            {label}
            {required && <span className="text-danger">*</span>}
            {dangered && <span className="text-danger">*</span>}
          </Label>
        )}

        <RollbackButton
          display={isChanged && detected}
          onClick={onUndoHandler}
        />
      </div>

      <Select
        isClearable={isClearable}
        isLoading={isLoading || false}
        menuPlacement="auto"
        maxMenuHeight={150}
        onMenuOpen={() => {
          SelectPopupRatio125('.select2-selection__menu-portal')
        }}

        placeholder={placeholder || t("Select Custom")}
        menuPortalTarget={!portal && document.body}
        styles={
          colourStyles

        }
        isDisabled={readOnly}
        isMulti={isMulti}
        value={items.filter(
          _item =>
            defaultValue?.findIndex(
              _defaultValue =>
                JSON.stringify(_defaultValue + "") ===
                JSON.stringify(_item.value + "")
            ) >= 0
        )}
        onInputChange={onInputChangeHandler}
        components={{ MultiValueContainer }}
        onChange={onChangeHandler}
        options={items}
        noOptionsMessage={() => t("No options")}
        loadingMessage={() => t("Loading...")}
        classNamePrefix="select2-selection"
        className={classnames(
          { "has-changed": isChanged && detected },
          "form-select2 is-touched is-dirty av-invalid is-invalid"
        )}
      />
      {isInvalid && (
        <div className="text-danger form-group">
          <div className="is-touched is-dirty av-invalid is-invalid"></div>
          <div className="invalid-feedback">{errorMessage}</div>
        </div>
      )}
      <div className="d-none">
        <AvField
          multiple={isMulti}
          name={name}
          type="select"
          className={`form-select`}
          value={isMulti ? defaultValue : defaultValue?.[0]}
          errorMessage={errorMessage}
          required={!readOnly && required}
          ref={inputRef}
          readOnly={readOnly}
          data-tip data-for={defaultValue}
        >

          <option value="">--Select--</option>
          {items.map((_item, idx) => (
            <option key={idx} value={_item.value}>
              {_item.label}
            </option>
          ))}
        </AvField>
      </div>
    </>
  )
}

CustomSelect.propTypes = {
  onChange: PropTypes.func,
  onMachineItemsChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  isDependent: PropTypes.bool,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  value: PropTypes.any,
  code: PropTypes.string,
  isMulti: PropTypes.bool,
  portal: PropTypes.bool,
  label: PropTypes.any.isRequired,
  detected: PropTypes.bool,
  options: PropTypes.array,
  readOnly: PropTypes.bool,
  searchQuery: PropTypes.object,
  showDefaultAll: PropTypes.bool,
  isClearable: PropTypes.bool,
  customStyle: PropTypes.object,
}

CustomSelect.defaultProps = {
  name: "",
  onChange: () => { },
  isDependent: false,
  errorMessage: "This field is invalid",
  portal: false,
  searchQuery: {},
}

export default (withRouter(withTranslation(["common", "testRequestPage"])(CustomSelect)))
