import {
    GET_LIST_ORGANISMS,
    GET_LIST_ORGANISMS_FAIL,
    GET_LIST_ORGANISMS_SUCCESS,
    GET_ORGANISMS_DETAIL,
    GET_ORGANISMS_DETAIL_FAIL,
    GET_ORGANISMS_DETAIL_SUCCESS,
    ADD_NEW_ORGANISMS,
    ADD_NEW_ORGANISMS_FAIL,
    ADD_NEW_ORGANISMS_SUCCESS,
    UPDATE_ORGANISMS,
    UPDATE_ORGANISMS_FAIL,
    UPDATE_ORGANISMS_SUCCESS,
    DELETE_ORGANISMS,
    DELETE_ORGANISMS_FAIL,
    DELETE_ORGANISMS_SUCCESS,
    GET_LIST_ORGANISMS_GROUP,
    GET_LIST_ORGANISMS_GROUP_FAIL,
    GET_LIST_ORGANISMS_GROUP_SUCCESS,
    GET_ORGANISMS_GROUP_DETAIL,
    GET_ORGANISMS_GROUP_DETAIL_FAIL,
    GET_ORGANISMS_GROUP_DETAIL_SUCCESS,
    ADD_NEW_ORGANISMS_GROUP,
    ADD_NEW_ORGANISMS_GROUP_FAIL,
    ADD_NEW_ORGANISMS_GROUP_SUCCESS,
    UPDATE_ORGANISMS_GROUP,
    UPDATE_ORGANISMS_GROUP_FAIL,
    UPDATE_ORGANISMS_GROUP_SUCCESS,
    DELETE_ORGANISMS_GROUP,
    DELETE_ORGANISMS_GROUP_FAIL,
    DELETE_ORGANISMS_GROUP_SUCCESS,
    GET_LIST_ORGANISMS_GENUS,
    GET_LIST_ORGANISMS_GENUS_FAIL,
    GET_LIST_ORGANISMS_GENUS_SUCCESS,
    GET_ORGANISMS_GENUS_DETAIL,
    GET_ORGANISMS_GENUS_DETAIL_FAIL,
    GET_ORGANISMS_GENUS_DETAIL_SUCCESS,
    ADD_NEW_ORGANISMS_GENUS,
    ADD_NEW_ORGANISMS_GENUS_FAIL,
    ADD_NEW_ORGANISMS_GENUS_SUCCESS,
    UPDATE_ORGANISMS_GENUS,
    UPDATE_ORGANISMS_GENUS_FAIL,
    UPDATE_ORGANISMS_GENUS_SUCCESS,
    DELETE_ORGANISMS_GENUS,
    DELETE_ORGANISMS_GENUS_FAIL,
    DELETE_ORGANISMS_GENUS_SUCCESS,
} from "./actionTypes"
const INIT_STATE = {
    organisms: [],
    paging: {},
    loading: false,
    loadingDetail: false,
    updating: false,
    updatedTime: new Date(),
    organismsDetail: {},
    error: {},
    organismsGroup: [],
    organismsGroupDetail: {},
    organismsGenus: [],
    organismsGenusDetail: {},
}
const Organisms = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_ORGANISMS:
            return {
                ...state,
                error: {},
                loading: true,
            }
        case GET_LIST_ORGANISMS_SUCCESS:
            {
                const { data, totalElements, ...rest } = action.payload
                return {
                    ...state,
                    organisms: data,
                    paging: { ...rest, dataSize: totalElements },
                    loading: false,
                    updatedTime: new Date(),
                }
            }
        case GET_LIST_ORGANISMS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        case GET_ORGANISMS_DETAIL:
            return {
                ...state,
                organismsDetail: {},
                error: {},
                loadingDetail: true,
            }
        case GET_ORGANISMS_DETAIL_SUCCESS:
            const organismsDetail = action.payload
            return {
                ...state,
                organismsDetail: organismsDetail,
                loadingDetail: false,
            }
        case GET_ORGANISMS_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingDetail: false,
            }
        case ADD_NEW_ORGANISMS:
            return {
                ...state,
                errorCode: {},
            }
        case ADD_NEW_ORGANISMS_SUCCESS:
            return {
                ...state,
            }
        case ADD_NEW_ORGANISMS_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case UPDATE_ORGANISMS:
            return {
                ...state,
                updating: true,
                error: {},
            }
        case UPDATE_ORGANISMS_SUCCESS:
            return {
                ...state,
                updating: false,
            }
        case UPDATE_ORGANISMS_FAIL:
            return {
                ...state,
                error: action.payload,
                updating: false,
            }

        case DELETE_ORGANISMS:
            return {
                ...state,
            }
        case DELETE_ORGANISMS_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case DELETE_ORGANISMS_SUCCESS:
            return {
                ...state,
            }

        //////////////////////////////
        case GET_LIST_ORGANISMS_GROUP:
            return {
                ...state,
                error: {},
                loading: true,
            }
        case GET_LIST_ORGANISMS_GROUP_SUCCESS:
            {
                const { data, totalElements, ...rest } = action.payload
                return {
                    ...state,
                    organismsGroup: data,
                    paging: { ...rest, dataSize: totalElements },
                    loading: false,
                    updatedTime: new Date(),
                }
            }
        case GET_LIST_ORGANISMS_GROUP_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        case GET_ORGANISMS_GROUP_DETAIL:
            return {
                ...state,
                organismsGroupDetail: {},
                error: {},
                loadingDetail: true,
            }
        case GET_ORGANISMS_GROUP_DETAIL_SUCCESS:
            const organismsGroupDetail = action.payload
            return {
                ...state,
                organismsGroupDetail: organismsGroupDetail,
                loadingDetail: false,
            }
        case GET_ORGANISMS_GROUP_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingDetail: false,
            }
        case ADD_NEW_ORGANISMS_GROUP:
            return {
                ...state,
                errorCode: {},
            }
        case ADD_NEW_ORGANISMS_GROUP_SUCCESS:
            return {
                ...state,
            }
        case ADD_NEW_ORGANISMS_GROUP_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case UPDATE_ORGANISMS_GROUP:
            return {
                ...state,
                updating: true,
                error: {},
            }
        case UPDATE_ORGANISMS_GROUP_SUCCESS:
            return {
                ...state,
                updating: false,
            }
        case UPDATE_ORGANISMS_GROUP_FAIL:
            return {
                ...state,
                error: action.payload,
                updating: false,
            }

        case DELETE_ORGANISMS_GROUP:
            return {
                ...state,
            }
        case DELETE_ORGANISMS_GROUP_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case DELETE_ORGANISMS_GROUP_SUCCESS:
            return {
                ...state,
            }
        //////////////////////////////
        case GET_LIST_ORGANISMS_GENUS:
            return {
                ...state,
                error: {},
                loading: true,
            }
        case GET_LIST_ORGANISMS_GENUS_SUCCESS:
            {
                const { data, totalElements, ...rest } = action.payload
                return {
                    ...state,
                    organismsGenus: data,
                    paging: { ...rest, dataSize: totalElements },
                    loading: false,
                    updatedTime: new Date(),
                }
            }
        case GET_LIST_ORGANISMS_GENUS_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }

        case GET_ORGANISMS_GENUS_DETAIL:
            return {
                ...state,
                organismsGenusDetail: {},
                error: {},
                loadingDetail: true,
            }
        case GET_ORGANISMS_GENUS_DETAIL_SUCCESS:
            const organismsGenusDetail = action.payload
            return {
                ...state,
                organismsGenusDetail: organismsGenusDetail,
                loadingDetail: false,
            }
        case GET_ORGANISMS_GENUS_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingDetail: false,
            }
        case ADD_NEW_ORGANISMS_GENUS:
            return {
                ...state,
                errorCode: {},
            }
        case ADD_NEW_ORGANISMS_GENUS_SUCCESS:
            return {
                ...state,
            }
        case ADD_NEW_ORGANISMS_GENUS_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case UPDATE_ORGANISMS_GENUS:
            return {
                ...state,
                updating: true,
                error: {},
            }
        case UPDATE_ORGANISMS_GENUS_SUCCESS:
            return {
                ...state,
                updating: false,
            }
        case UPDATE_ORGANISMS_GENUS_FAIL:
            return {
                ...state,
                error: action.payload,
                updating: false,
            }

        case DELETE_ORGANISMS_GENUS:
            return {
                ...state,
            }
        case DELETE_ORGANISMS_GENUS_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case DELETE_ORGANISMS_GENUS_SUCCESS:
            return {
                ...state,
            }
        default:
            return state
    }
}
export default Organisms