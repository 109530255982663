import { AvForm } from "availity-reactstrap-validation"
import { CustomButton, CustomSelect, CustomSelectAsync } from "components/Common"
import CustomSearchBar from "components/Common/CustomTable/SearchBar"
import { parameterCode } from "constant"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"

const CalculationIndexTableButtons = ({
  model,
  onChange,
  onSubmit,
  onLotChange,
  t,
  loading,
  onMachineChange
}) => {

  const [searchText, setSearchText] = useState('')
  const [instrumentId, setInstrumentId] = useState(null)
  const [lot, setlot] = useState(null)
  const [levels, setLevels] = useState([])

  const ref = useRef()

  const onSubmitHandler = (e, value) => {
    value.search = searchText
    onSubmit({ ...value })
  }

  const onRefreshHandler = () => {
    ref.current.submit()
  }

  useEffect(() => {
    if (lot) {
      let number = parseInt(lot[0]?.levels || 5)
      while (number >= 1) {
        levels.push({ value: number, label: number })
        number = number - 1
      }
      levels.reverse()
      setLevels(() => [...levels])
    } else {
      setLevels(() => [])
    }
  }, [lot])


  return (
    <React.Fragment>
      <AvForm
        ref={ref}
        onValidSubmit={(e, v) => {
          onSubmitHandler(e, v)
        }}>
        <div className="calculate-index-filter d-flex">
          <div style={{ width: '230px' }}>
            <CustomSelectAsync
              name="testCode"
              portal
              placeholder={t("Code Name")}
              value={model.testCode || ""}
              customSearchQuery={{ ...model }}
              code={parameterCode.TEST_CODE_QC_CALCULATION}
              errorMessage=''
              onChange={(name, value, label, item) => {
                onChange({
                  ...model,
                  testCode: value[0],
                })
                ref.current.submit()
              }}
            />
          </div>
          <div style={{ width: '200px', marginLeft: "5px" }}>
            <CustomSelect
              name="insId"
              portal
              required
              showID
              placeholder={t("Machine")}
              value={model.insId || ""}
              code={parameterCode.INSTRUMENT_QC}
              errorMessage=''
              onChange={(name, value, label, item) => {
                onChange({
                  ...model,
                  insId: value[0],
                  lotId: null
                })
                setInstrumentId(value)
                onMachineChange(item)
                onLotChange({})
                setlot(null)
                ref.current.submit()
              }}
            />
          </div>
          <div style={{ width: '200px', marginLeft: "5px" }}>
            <CustomSelectAsync
              name="lotId"
              portal
              required
              errorMessage=''
              readOnly={!instrumentId}
              placeholder={t("Lot QC")}
              customSearchQuery={{ ...model, instrumentId: instrumentId }}
              value={model.lotId || ""}
              code={parameterCode.LOT_QC_CALCULATION}
              onChange={(name, value, label, item) => {
                onChange({
                  ...model,
                  lotId: value[0],
                })
                setLevels([])
                setlot(item)
                onLotChange(item)
                ref.current.submit()
              }}
            />
          </div>
          <div style={{ width: '200px', marginLeft: "5px" }}>
            <CustomSelect
              name="levels"
              portal
              errorMessage=''
              required
              readOnly={!lot}
              placeholder={t("Level")}
              value={model.level || ""}
              options={levels}
              onChange={(name, value, label, item) => {
                onChange({
                  levels: value[0],
                })
                ref.current.submit()
              }}
            />
          </div>
          <div>
            <CustomButton
              color="secondary"
              outline
              onClick={onRefreshHandler}
            >
              <i className={`fas fa-sync-alt ${loading == true ? " loading-spin" : ""}`}></i>
            </CustomButton>
          </div>
        </div>
      </AvForm>
    </React.Fragment>
  )
}

CalculationIndexTableButtons.displayName = "CalculationIndexTableButtons"
export default withTranslation(["calculationQCPage", "common", "button"], { withRef: true, })(CalculationIndexTableButtons)
