// ChartiQC.jsx
import CustomSplitPane from "components/Common/CustomSplitPaneTriple"
import { ModuleIds } from "constant"
import PropTypes from "prop-types"
import { useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import "react-splitter-layout/lib/index.css"
import BodyLeft from "./LeftFrame"
import BodyMid from "./MidFrame"
import BodyRight from "./RightFrame"

const RESOURCE = ModuleIds.ChartiQC

const ChartiQC = ({ t }) => {
  const [generalData, setGeneralData] = useState({});
  const [level, setLevel] = useState('default');
  const [filter, setFilter] = useState('validated')
  const [allLotLevel, setAllLotLevel] = useState([]);
  const [dataInfo, setDataInfo] = useState([])
  const [showBothCharts, setShowBothCharts] = useState(false)
  const [reportIndex, setReportIndex] = useState({})
  const [dateArr, setDateArr] = useState({})
  const [listData, setListData] = useState([])
  const [isPosNeg, setIsPosNeg] = useState(false)
  const [selectedLots, setSelectedLots] = useState([])
  const [flg, setFlg] = useState(false)
  return (
    <div className="chart-qc row position-relative">
      <CustomSplitPane
        leftFrame={() => (
          <div className="px-2">
            <BodyLeft
              onChangeReportIndex={val => setReportIndex(val)}
              onChangeGeneralData={val => setGeneralData(val)}
              onChangeAllLotLevel={val => setAllLotLevel(val)}
              onChangeIndexRange={val => setIndexRange(val)}
              onChangeListData={val=>setListData(val)}
              onChangeIsPosNeg={val=>setIsPosNeg(val)}
              onChangeDateArr={val => setDateArr(val)}
              onChangeSelectedLots={val=>setSelectedLots(val)}
              onChangeFlg={val=>setFlg(val)}
              showBothCharts={showBothCharts}
              level={level}
              filter={filter}
            />
          </div>
        )}
        midFrame={() => (
          <div className="px-2">
            <BodyMid
              allLotLevel={allLotLevel}
              generalData={generalData}
              dataInfo={dataInfo}
              reportIndex={reportIndex}
              dateArr={dateArr}
              isPosNeg={isPosNeg}
              selectedLots={selectedLots}
              onChangeShowBothCharts={val => setShowBothCharts(val)}
              onChangeLevel={val => setLevel(val)}
              flg={flg}
              onChangeFilterChart={val => setFilter(val)}
            />
          </div>
        )}
        rightFrame={() => (
          <div>
            <BodyRight
              listData={listData}
              filter={filter}
              showBothCharts={showBothCharts}
              level={level}
              isPosNeg={isPosNeg}
              onChangeDataInfo={val => setDataInfo(val)}
            />
          </div>
        )}
      />
    </div>
  )
}

ChartiQC.propTypes = {
  showBothCharts: PropTypes.bool,
  showUnvalidatedPoint: PropTypes.bool,
  levels: PropTypes.string,
  payload: PropTypes.object,
  loadingDatapoints: PropTypes.bool,
  error: PropTypes.string,
}

const mapStateToProps = ({ }) => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["chartiQCPage", "common"])(ChartiQC)))
