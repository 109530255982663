import { convertDateFormat, convertDateFormat_VN } from "helpers/utilities"
import React, { useState, useEffect } from "react"

const Header = ({
  t,
  showBothCharts,
  setShowBothCharts,
  showUnvalidatedPoint,
  setShowUnvalidatedPoint,
  setTimeOption,
  allLotLevel,
  onChangeLevel,
  levelsChart,
  reportIndex,
  setLevelsChartOption,
  generalData
}) => {
  const [isExpanded, setIsExpanded] = useState(true)
  const [levels, setLevels] = useState('default')
  const allLotLevelSet = new Set(allLotLevel)

  useEffect(() => {
      setLevels("default")
      if(onChangeLevel){
        onChangeLevel("default")
      }
    }, [generalData])

  return (
    <div className="d-flex p-2 " style={headerStyle}>
      {isExpanded && (
        <div className="d-flex flex-wrap justify-content-between w-100 gap-2">
          <div className="d-flex flex-column">
            <div className="d-flex gap-2 mb-2">
              <input
                defaultChecked
                type="radio"
                name="timeOption"
                value="ByTime"
                onChange={() => setTimeOption("ByTime")} // Directly set state here
              />
              <label style={labelStyle}>{t("ByTime")}</label>
            </div>
            <div className="d-flex gap-2">
              <input
                type="radio"
                name="timeOption"
                value="ByRun"
                onChange={() => setTimeOption("ByRun")} // Directly set state here
              />
              <label style={labelStyle}>{t("ByRun")}</label>
            </div>
          </div>
          <div className="d-flex flex-column">
            <div className="d-flex gap-2 mb-2">
              <input
                type="checkbox"
                checked={showBothCharts}
                onChange={e => setShowBothCharts(e.target.checked)}
              />
              <label style={labelStyle}>{t("UseManuIndicator")}</label>
            </div>
            <div className="d-flex gap-2">
              <input
                type="checkbox"
                checked={showUnvalidatedPoint}
                onChange={e => setShowUnvalidatedPoint(e.target.checked)}
              />
              <label style={labelStyle}>{t("ShowUnapprovedPoints")}</label>
            </div>
          </div>
          <div className="d-flex flex-column mb-2">
            <div>
              <select
                style={dropdownStyle}
                value={levelsChart === null ? "multi" : levelsChart}
                onChange={e => {
                  const value = e.target.value
                  setLevelsChartOption(value)
                }}
              >
                <option value="multi">{t("MultiLevelChart")}</option>
                <option value="one">{t("LevelChart")}</option>
              </select>
            </div>
          </div>
          <div className="d-flex flex-column mb-2">
            <div>
              <select
                style={dropdownStyle}
                value={levels === null ? "default" : levels}
                onChange={e => {
                  const value = e.target.value
                  setLevels(value === "default" ? "default" : parseInt(value, 10))
                  if (onChangeLevel) {
                    onChangeLevel(
                      value === "default" ? "default" : parseInt(value, 10)
                    )
                  }
                }}
              >
                <option value="default">{t("Level")}</option>
                {[1, 2, 3, 4, 5].map(
                  value =>
                    allLotLevelSet.has(value) && (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    )
                )}
              </select>
            </div>
            <div style={verificationStyle}>
              <span style={verificationTextStyle}>
                ✔ {t("Levey Jennings Chart")}
              </span>
            </div>
          </div>
        </div>
      )}

      {!isExpanded && (
        <div className="d-flex gap-2 w-100">
          <div>
            {t("Lot")}:
            <span style={{ fontWeight: "bold", color: "blue" }}>
              {reportIndex?.lastLot}
            </span>
          </div>
          <div className="d-flex gap-1">
            {t("Start Date")}:<span style={{ fontWeight: "bold" }}>
              {reportIndex?.start}
            </span>
            {t("End Date")}:<span style={{ fontWeight: "bold" }}>
              {reportIndex?.end}
            </span>
          </div>
          <div className="d-flex gap-1">
            {showBothCharts && (
              <i
                className="fas fa-check-circle text-success font-size-14"
                style={{ alignSelf: "center" }}
              ></i>
            )}
            {!showBothCharts && (
              <i
                className="fas fa-times-circle text-secondary font-size-14"
                style={{ alignSelf: "center" }}
              ></i>
            )}
            <span style={{ fontWeight: "bold" }}>{t("UseManuIndicator")}</span>
          </div>
          <div className="d-flex gap-1">
            <i
              className="fas fa-chart-bar font-size-15"
              style={{ ...verificationTextStyle, alignSelf: "center" }}
            ></i>
            <span style={{ ...verificationTextStyle, fontWeight: "bold" }}>
              {t("Levey Jennings Chart")}
            </span>
          </div>
        </div>
      )}
      <div>
        <span
          onClick={() => setIsExpanded(!isExpanded)}
          style={toggleButtonStyle}
        >
          <i
            className={
              isExpanded ? "dripicons-chevron-up" : "dripicons-chevron-down"
            }
          />
        </span>
      </div>
    </div>
  )
}

const headerStyle = {
  border: "1px solid #ccc",
  borderRadius: "5px",
  backgroundColor: "#f9f9f9", // Header background color
}

const labelStyle = {
  fontSize: "14px",
}

const dropdownStyle = {
  padding: "5px",
  fontSize: "14px",
  borderRadius: "5px",
  border: "1px solid #ccc",
}

const verificationStyle = {
  marginTop: "5px",
}

const verificationTextStyle = {
  color: "green",
  fontSize: "14px",
  fontWeight: "bold",
}

const toggleButtonStyle = {
  cursor: "pointer",
}

export default Header
