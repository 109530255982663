import {
    GET_LOT_TEST_QC_LIST,
    GET_LOT_TEST_QC_LIST_FAIL,
    GET_LOT_TEST_QC_LIST_SUCCESS,
    UPDATE_CV_PEER_QC,
    UPDATE_MEAN_EQC_QC,
    UPDATE_MEAN_PEER_QC
} from "./actionTypes"

const INIT_STATE = {
    lottests: [],
    paging: {},
    loadingLottest: false,
    updatingLottest: true,
    updatedTime: new Date(),
    loadingLottest: {},
    searchQuery: {},
    errorLottest: {},
    error: {},
    meanPeer: [],
    cvPeer: []
}

const CalculationQC = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LOT_TEST_QC_LIST:
            return {
                ...state,
                error: {},
                loadingLottest: true,
            }
        case GET_LOT_TEST_QC_LIST_SUCCESS:
            const data = action.payload
            return {
                ...state,
                lottests: data,
                loadingLottest: false,
                updatedTime: new Date(),
            }
        case GET_LOT_TEST_QC_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingLottest: false,
            }
        case UPDATE_MEAN_PEER_QC:
            return {
                ...state,
                meanPeer: action.payload,
            }
        case UPDATE_CV_PEER_QC:
            return {
                ...state,
                cvPeer: action.payload,
            }
        case UPDATE_MEAN_EQC_QC:
            return {
                ...state,
                meanEQC: action.payload,
            }    
        default:
            return state
    }
}
export default CalculationQC