import React from 'react';
import PropTypes from "prop-types";
//i18n
import { withTranslation } from "react-i18next"
import { PageContent, CustomBreadcrumb } from "components/Common"
import { ModuleIds } from "constant"
import ReportStatisticIQC from './ReportStatisticIQC';

const RESOURCE = ModuleIds.ReportStatisticIQC
const ReportStatisticIQCContainer = ({ t }) => {
    const titleTag = `Report`
    return (
        <PageContent title={titleTag}>
            <CustomBreadcrumb
                breadcrumbItem={t("Report Statistic IQC")}
                resource={RESOURCE}
            />
            <ReportStatisticIQC />
        </PageContent>
    );
}

ReportStatisticIQCContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["reportPage"])(ReportStatisticIQCContainer)