import { parameterCode } from "constant";
import { AvForm } from "availity-reactstrap-validation";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { CustomAvField, CustomDatePicker, CustomSelect, CustomSelectAsync } from "components/Common";
import { convertDateFormat, getI18nextLng } from "helpers/utilities";
let isRender = false;

const HeaderFilter = ({
  t,
  model,
  onChange,
  onChangeMachine,
  onSubmitFilter
}) => {
  const [machine, setMachine] = useState(model?.machine || '');
  const [level, setLevel] = useState(model?.level || '');
  const [test, setTest] = useState(model?.test || '');
  const [dates, setDates] = useState([
    convertDateFormat(new Date(), "YYYY-MM-DD"),
    convertDateFormat(new Date(), "YYYY-MM-DD")
  ]);

  const handleMachineItemsChange = (items) => {
    if (onChangeMachine) {
      onChangeMachine(items);  // Pass the items upward.
    }
  };

  useEffect(() => {
    if (isRender === true) {
      onSubmitFilter();
    }
  }, [machine, level, dates]);

  useEffect(() => {
    const timer = setTimeout(() => {
      onChange({ test: test })
    }, 1000)

    return () => clearTimeout(timer)
  }, [test])

  return (
    <React.Fragment>
      <AvForm>
        <div className="d-flex flex-column pt-2 ">
          <div className="d-flex gap-1">
            <div className="col-6 mb-2 ">
              <CustomDatePicker
                name="requestDate"
                label={''}
                format={"DD/MM/YYYY"}
                dateFormat={"d-m-Y"}
                value={[
                  convertDateFormat(dates[0], "DD-MM-YYYY"),
                  convertDateFormat(dates[1], "DD-MM-YYYY")
                ]}
                onChangeHandler={(date) => {
                  if (date.time.length === 2) {
                    const newDates = [
                      convertDateFormat(date.time[0], "YYYY-MM-DD"),
                      convertDateFormat(date.time[1], "YYYY-MM-DD")
                    ];
                    if (dates[0] != newDates[0] || dates[1] != newDates[1]) {
                      setDates(newDates);
                      onChange({
                        start: newDates[0],
                        end: newDates[1]
                      });
                      isRender = true;
                    }
                  }
                }}
                closeOnSelect={true}
                mode='range'
                maxDate={new Date(new Date().setHours(0, 0, 0, 0))}
                conjunction={'  '}
              />
            </div>
            <div className="col-6 mb-2 gap-1">
              <CustomSelect
                name="machineId"
                label={""}
                onChange={(e, vals) => {
                  isRender = true;
                  onChange({ machine: vals[0] || '' });
                  setMachine(vals[0] || '');
                }}
                showID
                onMachineItemsChange={handleMachineItemsChange}
                placeholder={t("Machine")}
                value={model?.machine || ''}
                code={parameterCode.INSTRUMENT_QC}
              />
            </div>
          </div>
          <div className="d-flex gap-1">
            <div className="col-6 mb-2">
              <CustomAvField
                name="testId"
                placeholder={t("TestCodeName")}
                onChange={(vals) => {
                  setTest(vals || '')
                }}
              />
            </div>
            <div className="col-6 mb-2">
              <CustomSelect
                name="level"
                code={parameterCode.LEVEL_QC}
                label={""}
                onChange={(e, vals) => {
                  isRender = true;
                  onChange({ levels: vals[0] || '' });
                  setLevel(vals[0] || '');
                }}
                placeholder={t("Level")}
                value={model?.level}
              />
            </div>
          </div>
        </div>
      </AvForm>
    </React.Fragment>
  );
};

const mapStateToProps = ({ }) => ({
});

const mapDispatchToProps = dispatch => ({
});

HeaderFilter.displayName = 'HeaderFilter';
export default withTranslation(["chartiQCPage", "common"], { withRef: true })(connect(
  mapStateToProps,
  mapDispatchToProps, null, { forwardRef: true }
)(HeaderFilter));
