import PROGRESSES from "./actionTypes"

export const getListProgresses = payload => {
  return {
    type: PROGRESSES.GET_LIST_PROGRESSES,
    payload: payload || {},
  }
}

export const getListProgressesSuccess = payload => ({
  type: PROGRESSES.GET_LIST_PROGRESSES_SUCCESS,
  payload: payload,
})

export const getListProgressesFail = error => ({
  type: PROGRESSES.GET_LIST_PROGRESSES_FAIL,
  error: error,
})

export const getProgressesDetail = (id, callback) => ({
  type: PROGRESSES.GET_PROGRESSES_DETAIL,
  id,
  callback,
})

export const getProgressesDetailSuccess = payload => ({
  type: PROGRESSES.GET_PROGRESSES_DETAIL_SUCCESS,
  payload: payload,
})

export const getProgressesDetailFail = error => ({
  type: PROGRESSES.GET_PROGRESSES_DETAIL_FAIL,
  payload: error,
})

export const addNewProgresses = payload => ({
  type: PROGRESSES.ADD_NEW_PROGRESSES,
  payload: payload,
})

export const addNewProgressesSuccess = payload => ({
  type: PROGRESSES.ADD_NEW_PROGRESSES_SUCCESS,
  payload: payload,
})

export const addNewProgressesFail = error => ({
  type: PROGRESSES.ADD_NEW_PROGRESSES_FAIL,
  error: error,
})

export const updateProgresses = payload => ({
  type: PROGRESSES.UPDATE_PROGRESSES,
  payload: payload,
})

export const updateProgressesSuccess = payload => ({
  type: PROGRESSES.UPDATE_PROGRESSES_SUCCESS,
  payload: payload,
})

export const updateProgressesFail = error => ({
  type: PROGRESSES.UPDATE_PROGRESSES_FAIL,
  error: error,
})

export const deleteProgresses = payload => ({
  type: PROGRESSES.DELETE_PROGRESSES,
  payload: payload,
})

export const deleteProgressesSuccess = payload => ({
  type: PROGRESSES.DELETE_PROGRESSES_SUCCESS,
  payload: payload,
})

export const deleteProgressesFail = error => ({
  type: PROGRESSES.DELETE_PROGRESSES_FAIL,
  error: error,
})
