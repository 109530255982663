import REAGENTS from "./actionTypes"

const INIT_STATE = {
  reagents: [],
  reagentsDetail: null,
  loading: false,
  loadingDetail: false,
  updating: false,
  updateTime: new Date(),
  error: null,
}

const Reagents = (state = INIT_STATE, action) => {
  switch (action.type) {
    case REAGENTS.GET_LIST_REAGENTS: {
      return { ...state, loading: true, error: {} }
    }
    case REAGENTS.GET_LIST_REAGENTS_SUCCESS: {
      const { data, totalElements, ...rest } = action.payload
      return {
        ...state,
        reagents: data,
        paging: { ...rest, dataSize: totalElements },
        loading: false,
        updatedTime: new Date(),
      }
    }
    case REAGENTS.GET_LIST_REAGENTS_FAIL: {
      return { ...state, loading: false, error: action.payload }
    }
    case REAGENTS.GET_REAGENTS_DETAIL: {
      return { ...state, loadingDetail: true, reagentsDetail: {}, error: {} }
    }
    case REAGENTS.GET_REAGENTS_DETAIL_SUCCESS: {
      return { ...state, reagentsDetail: action.payload, loadingDetail: false }
    }
    case REAGENTS.GET_REAGENTS_DETAIL_FAIL: {
      return { ...state, loadingDetail: false, error: action.payload }
    }
    case REAGENTS.ADD_NEW_REAGENTS: {
      return { ...state, error: {} }
    }
    case REAGENTS.ADD_NEW_REAGENTS_SUCCESS: {
      return { ...state }
    }
    case REAGENTS.ADD_NEW_REAGENTS_FAIL: {
      return { ...state, error: action.payload }
    }
    case REAGENTS.UPDATE_REAGENTS: {
      return { ...state, updating: true, error: {} }
    }
    case REAGENTS.UPDATE_REAGENTS_SUCCESS: {
      return { ...state, updating: false }
    }
    case REAGENTS.UPDATE_REAGENTS_FAIL: {
      return { ...state, updating: false, error: action.payload }
    }
    case REAGENTS.DELETE_REAGENTS: {
      return { ...state }
    }
    case REAGENTS.DELETE_REAGENTS_SUCCESS: {
      return { ...state }
    }
    case REAGENTS.DELETE_REAGENTS_FAIL: {
      return { ...state, error: action.payload }
    }
    default:
      return state
  }
}

export default Reagents
