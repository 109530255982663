import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import SplitterLayout from "react-splitter-layout"
import "react-splitter-layout/lib/index.css"; // Import default styles

const CustomSplitPanelTriple = ({ leftFrame, midFrame, rightFrame }) => {
  const [hideLeft, setHideLeft] = useState(false)
  const [hideRight, setHideRight] = useState(false)

  useEffect(() => {
    let midCols = document.getElementsByClassName("layout-splitter")
    if (midCols.length > 0) {
      let midCol = midCols[0]
      let midCol1 = midCols[1]

      midCol.innerHTML = `<div id="layout-splitter-content1" class="layout-splitter-content"><i class="fa fa-angle-left"></i></div>`
      midCol1.innerHTML = `<div id="layout-splitter-content2" class="layout-splitter-content"><i class="fa fa-angle-right"></i></div>`

      document.getElementById("layout-splitter-content1").onclick = () => {
        let pane = document.getElementsByClassName("layout-pane")[1]
        let midCols = document.getElementsByClassName("layout-splitter-content")
        let midCol = midCols[0]
        if (pane.clientWidth < 1600) {
          midCol.innerHTML = `<i class="fa fa-angle-right"></i>`
          pane.style.width = "100%"
          setHideLeft(true)
        } else {
          midCol.innerHTML = `<i class="fa fa-angle-left"></i>`
          pane.style.width = "74%"
          setHideLeft(false)
        }
      }

      document.getElementById("layout-splitter-content2").onclick = () => {
        let pane = document.getElementsByClassName("layout-pane")[3]
        let midCols = document.getElementsByClassName("layout-splitter-content")
        let midCol = midCols[1]
        if (pane.clientWidth > 0 && pane.clientWidth > 20) {
          midCol.innerHTML = `<i class="fa fa-angle-left"></i>`
          pane.style.width = "19px"
          setHideRight(true)
        }
        else if (pane.clientWidth < 50 && pane.clientWidth >= 0) {
          midCol.innerHTML = `<i class="fa fa-angle-right"></i>`
          pane.style.width = "35%"
          setHideRight(false)
        }
      }
    }
  }, [])

  return (
    <div className="triple-panel">
      <SplitterLayout percentage secondaryInitialSize={70}
        onSecondaryPaneSizeChange={size => {
          if (size > 95) {
            setHideLeft(true)
          } else {
            setHideLeft(false)
          }
        }}>
        <div style={{ display: hideLeft ? "none" : "" }}>
          {leftFrame()}
        </div>
        <SplitterLayout percentage secondaryInitialSize={30}
          onSecondaryPaneSizeChange={size => {
            if (size < 5) {
              setHideRight(true)
            } else {
              setHideRight(false)
            }
          }}
        >
          <div style={{ height: "99%" }}>
            {midFrame()}
          </div>
          <div style={{ height: "99%", display: hideRight ? "none" : "" }}>
            {rightFrame()}
          </div>
        </SplitterLayout>
      </SplitterLayout>
    </div>

  )
}

CustomSplitPanelTriple.propTypes = {
  LeftFrame: PropTypes.func.isRequired,
  MidFrame: PropTypes.func.isRequired,
  RightFrame: PropTypes.func.isRequired,
  InitialSizeLeft: PropTypes.number, // Default 20% for left pane
  InitialSizeMid: PropTypes.number,  // Default 60% for middle pane
  InitialSizeRight: PropTypes.number, // Default 20% for right pane
}

export default CustomSplitPanelTriple
