import PropTypes from "prop-types"
import {
  ButtonDropdownGroup,
  Check,
  CustomButton,
  FilterButton,
} from "components/Common"
import { permissionType } from "constant"
import FilterForm from "../FilterForm"
import { DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import CustomSearchBar from "components/Common/CustomTable/SearchBar"
const HeaderButtons = ({
  resource,
  onRefreshClick,
  onSearchChange,
  onClearSearch,
  onSubmitSearch,
  model,
  onAddClick,
  onActiveClick,
  onInActiveClick,
  onDeleteClick,
  t,
}) => {
  return (
    <>
      <div className="d-flex flex-wrap flex-row">
        <Check permission={permissionType.R} resource={resource}>
          <CustomSearchBar
            onSearch={onSearchChange}
            searchText={model.search}
            placeholder={t("Search")}
            onClear={onClearSearch}
            onSubmit={onSubmitSearch}
          />
          <CustomButton color="secondary" outline onClick={onRefreshClick}>
            <i className="fas fa-sync-alt"></i>
          </CustomButton>
        </Check>

        <Check permission={permissionType.C} resource={resource}>
          <CustomButton color="primary" onClick={onAddClick} outline>
            {t("machinePage:Add Mapping")}
          </CustomButton>
        </Check>

        <ButtonDropdownGroup onDeleteClick={() => {}} onCloneClick={() => {}}>
          <Check permission={permissionType.C} resource={resource}>
            <CustomButton color="primary" onClick={onActiveClick} outline>
              {t("common:Active")}
            </CustomButton>
          </Check>

          <Check permission={permissionType.C} resource={resource}>
            <CustomButton color="primary" onClick={onInActiveClick} outline>
              {t("machinePage:In-Active")}
            </CustomButton>
          </Check>
          <Check
            permissions={[permissionType.U, permissionType.C]}
            resource={resource}
          >
            <Check permission={permissionType.D} resource={resource}>
              <DropdownToggle caret color="primary" outline>
                <i className="mdi mdi-chevron-down"></i>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem disabled>{t("Action")}</DropdownItem>
                <DropdownItem onClick={onDeleteClick}>
                  {t("Delete")}
                </DropdownItem>
              </DropdownMenu>
            </Check>
          </Check>
        </ButtonDropdownGroup>
      </div>
    </>
  )
}

HeaderButtons.propTypes = {
  resource: PropTypes.string.isRequired,
  onRefreshClick: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onActiveClick: PropTypes.func.isRequired,
  onInActiveClick: PropTypes.func.isRequired,
  model: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
}

export default withTranslation(["common", "machinePage"])(HeaderButtons)
