import ReactTooltip from "react-tooltip"
import CustomButton from "./CustomButton"
import "../../../assets/scss/custom/pages/_party.scss"
import Check from "../Check"
import { permissionType } from "constant"

export const TooltipButton = ({ id, color, iconClass, onClick, tooltipText, disabled = false }) => (
  <>
    <CustomButton id={id} color={color} onClick={onClick} disabled={disabled}>
      <i className={iconClass} data-tip data-for={id} />
    </CustomButton>
    <ReactTooltip placement="top" id={id} effect="solid">
      {tooltipText}
    </ReactTooltip>
  </>
)
const CustomTooltipButton = ({
  index,
  component,
  onClone,
  onEdit,
  onDelete,
  isId,
  t,
  resource,
  isDelete = true
}) => (
  <div className="d-flex toolbar button-items text-end">
    {onClone && (
      <Check
        permission={permissionType.C}
        resource={resource}
      >
        <TooltipButton
          id={`copy-${index}`}
          color="btn btn-outline-primary btn-soft-copy"
          iconClass="bx bx-copy-alt"
          onClick={() => {
            onClone(isId ? component.id : component)
          }}
          tooltipText={t("common:Copy")}
        />
      </Check>
    )}
    <Check
      permission={permissionType.U}
      resource={resource}
    >
      <TooltipButton
        id={`edit-${index}`}
        color="btn btn-outline-info btn-soft-info"
        iconClass="mdi mdi-pencil-outline"
        onClick={e => {
          onEdit(e, component.id, component)
        }}
        tooltipText={t("common:Edit")}
      />
    </Check>
    <Check
      permission={permissionType.D}
      resource={resource}
    >
      <TooltipButton
        id={`delete-${index}`}
        color="btn btn-outline-danger btn-soft-danger"
        iconClass="mdi mdi-delete-outline"
        onClick={e => onDelete(e, component)}
        tooltipText={t("common:Delete")}
        disabled={isDelete != true}
      />
    </Check>
  </div>
)

export default CustomTooltipButton
