import { AvForm } from "availity-reactstrap-validation";
import { CustomBootstrapTable } from "components/Common";
import FormSwitchForm from "components/Common/FormSwitchForm";
import IconStatus from "components/Common/IconStatus";
import { ModuleIds, permissionType } from "constant";
import { indexCalculator } from "helpers/utilities";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import FilterForm from "./FilterForm";
import { CustomButton } from "components/Common"


const RESOURCE = ModuleIds.MachineTestiQC

const TestTable = ({
  tests,
  onSearch,
  onSort,
  onRefresh,
  onPageChange,
  paging,
  onSizePerPageChange,
  onSubmitFilter,
  loading,
  t,
  updatedTime,
  updateTestQC,
  userPermissions,
  onExportExcel,
  loadingDownload,
}) => {
  const [data, setData] = useState([])
  const [model, setModel] = useState({
    search: "",
    type: "",
    category: "",
    sampleType: "",
  });
  const searchQuery = useSelector(state => state.test.searchQuery);

  const checkPermission = (permission, resource) => {
    const userPermission = userPermissions[resource]?.permissions || []
    return userPermission.includes(permission)
  }

  const onResetHandler = () => {
    const initModel = {
      search: "",
      type: "",
      category: "",
      sampleType: "",
    }
    let x = document.getElementsByName("type")
    for (let i = 0; i < x.length; i++) {
      x[i].checked = false
    }
    setModel(initModel)
  }

  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])

  useEffect(() => {
    setData(tests)
  }, [tests])

  const columns = [
    {
      dataField: "id",
      text: "#",
      hidden: true,
      formatter: (cellContent, test, index) => {
        return cellContent
      },
    },
    {
      dataField: "id",
      text: "#",
      style: { width: 50 },
      formatter: (cellContent, test, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "testCode",
      text: t("Code"),
      sort: true,
      formatter: cellContent => {
        return <span style={{ fontWeight: "bold" }}>{cellContent}</span>
      },
    },
    {
      dataField: "testName",
      text: t("Name"),
      style: { maxWidth: "300px" },
      sort: true,
    },
    {
      dataField: "categoryName",
      text: t("Category"),
      sort: true,
    },
    {
      dataField: "unit",
      text: t("Unit"),
      sort: true,
      style: { maxWidth: "70px" },
      align: "-webkit-center",
      headerAlign: "-webkit-center",
      formatter: cellContent => {
        return cellContent ? cellContent.trim() : ""
      }
    },
    {
      dataField: "isQC",
      text: t("QC"),
      formatter: (cellContent, test, index) => {
        let hasPermission = checkPermission(permissionType.U, RESOURCE)
        if (!hasPermission) {
          hasPermission = true;
        } else {
          hasPermission = false;
        }
        return (
          <AvForm>
            <FormSwitchForm
              name="qc"
              label=""
              value={!!cellContent}
              onChange={value => {
                updateTestQC(test, value)
              }}
              disabled={hasPermission}
            />
          </AvForm>
        )
      },
    },
    {
      dataField: "inUse",
      text: t("Status"),
      style: { minWidth: "85px" },
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, test, index) => {
        return (
          <IconStatus
            isRound={true}
            type={cellContent ? "Active" : "Inactive"}
          />
        )
      },
    },
  ]

  return (
    <div className="table-patient-visit table-expand-custom">
      <CustomBootstrapTable
        columns={columns}
        search
        data={data}
        paging={paging}
        onSearch={onSearch}
        onSort={onSort}
        isSelected
        onRefresh={onRefresh}
        onReset={onResetHandler}
        onPageChange={onPageChange}
        onSizePerPageChange={onSizePerPageChange}
        searchText={model.search}
        loading={loading}
        updatedTime={updatedTime}
        resource={RESOURCE}
        keyField="id"
        style={{ height: window.innerHeight - 280 }}
        isImportExportButton={true}
        importExportForm={() =>
          <div className="tooltip-custom">
            <CustomButton
              isEdit
              color="secondary"
              outline
              onClick={onExportExcel}
              disabled={loadingDownload}
            >
              <i className={loadingDownload ? "fa fa-spinner fa-spin" : "fas fa-download"}></i>
            </CustomButton>
            <span className="tooltiptext-custom-button-icon-left">
              {t("common:Export All")}
            </span>
          </div>
        }
      />
      {loading && (
        <div className="text-center mt-3">
          <i className="fa fa-spinner fa-spin mr-2"></i>
          {t("common:Loading...")}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = ({ Authorization }) => {
  return {
    userPermissions: Authorization.permissions,
  }
}

export default connect(
  mapStateToProps,
  null
)(withTranslation(["testPage"])(TestTable))
