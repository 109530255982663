import { AvForm } from "availity-reactstrap-validation"
import {
  CustomAvField,
  CustomButton,
  CustomModal,
  CustomSelect,
  CustomSelectAsync
} from "components/Common"
import FormSwitchForm from "components/Common/FormSwitchForm"
import { parameterCode } from "constant/utility"
import { getInvalidMessageI18n } from "helpers/utilities"
import { isEmpty } from "lodash"
import { useEffect, useRef } from "react"
import { withTranslation } from "react-i18next"
import { Col, Label, ModalBody, ModalFooter, Row } from "reactstrap"

let warningRules = []
let rejectRules = []

const WestgardSigmaQCModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  t,
}) => {

  const title =
    (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("Westgard Sigma")
  if (isEdit && isEmpty(data)) return null

  useEffect(() => {
    return () => {
      warningRules = []
      rejectRules = []
    }
  }, [])

  useEffect(() => {
    if (data?.warningRules?.length > 0) {
      warningRules = data?.warningRules?.split(',')
    }

    if (data?.rejectRules?.length > 0) {
      rejectRules = data?.rejectRules?.split(',')
    }
  }, [data])

  const formEl = useRef(null)

  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody>
        <AvForm
          ref={formEl}
          id="westgardSigmaQCForm"
          onValidSubmit={onValidSubmit}
        >
          <CustomAvField name="id" type="hidden" value={data.id || ""} />
          <Row className="px-2">
            <Col className="col-6">
              <div className="mb-3">
                <CustomAvField
                  name="code"
                  type="text"
                  required
                  errorMessage={getInvalidMessageI18n(t, "Code")}
                  validate={{
                    required: { value: true },
                    maxLength: { value: 20 },
                  }}
                  value={data.code || ""}
                  disabled={isEdit}
                  label={t("Code")}
                  detected={isEdit}
                />
              </div>
            </Col>

            <Col className="col-6">
              <div className="mb-3">
                {/* <CustomAvField
                  name="name"
                  type="text"
                  required
                  errorMessage={getInvalidMessageI18n(t, "Name")}
                  validate={{
                    required: { value: true },
                    maxLength: { value: 50 },
                    pattern: { value: '^[1-6]+$', errorMessage: t('message:InputWarning', { field: '1 - 6' }) },
                  }}
                  value={data.name || ""}
                  label={t("Name")}
                  detected={isEdit}
                /> */}

                <CustomSelect
                  required
                  name="name"
                  options={[
                    { label: '1', value: 1 },
                    { label: '2', value: 2 },
                    { label: '3', value: 3 },
                    { label: '4', value: 4 },
                    { label: '5', value: 5 },
                    { label: '6', value: 6 }
                  ]}
                  value={data.name || ""}
                  errorMessage={getInvalidMessageI18n(t, "Name")}
                  label={t("Name")}
                  detected={isEdit}
                />
              </div>
            </Col>

            <Col className="col-6">
              <div className="mb-3">
                {(!isEdit || warningRules.length > 0) && <CustomSelectAsync
                  // required
                  isMulti
                  name="warningRules"
                  value={warningRules}
                  // errorMessage={getInvalidMessageI18n(t, "Warning Rules")}
                  code={parameterCode.WESTGARD_SIGMA}
                  label={t("Warning Rules")}
                  detected={isEdit}
                />}
              </div>
            </Col>

            <Col className="col-6">
              <div className="mb-3">
                {(!isEdit || rejectRules.length > 0) && <CustomSelectAsync
                  required
                  isMulti
                  name="rejectRules"
                  value={rejectRules}
                  errorMessage={getInvalidMessageI18n(t, "Reject Rules")}
                  code={parameterCode.WESTGARD_SIGMA}
                  label={t("Reject Rules")}
                  detected={isEdit}
                />}
              </div>
            </Col>

            <Col className="col-6">
              <div className="mb-3">
                <CustomSelectAsync
                  name="level"
                  value={data.level || ''}
                  code={parameterCode.LEVEL_QC}
                  label={t("Levels")}
                  detected={isEdit}
                />
              </div>
            </Col>

            <Col className="col-6">
              <Label for="inUse">{t("common:In Use")}</Label>
              <FormSwitchForm
                style={{ marginLeft: 2 }}
                name="inUse"
                label=""
                value={data.inUse}
              />
            </Col>
          </Row>
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          className="button-width"
        />

        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          onClick={() => formEl?.current?.submit()}
          className="save-user button-width"
          isEdit
          isModal
        />
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["westgardSigmaQCPage", "common"])(WestgardSigmaQCModal)
