import EXAMPLE_TYPES from "./actionTypes"

export const getListExampleTypes = payload => {
  return {
    type: EXAMPLE_TYPES.GET_LIST_EXAMPLE_TYPES,
    payload: payload || {},
  }
}

export const getListExampleTypesSuccess = payload => ({
  type: EXAMPLE_TYPES.GET_LIST_EXAMPLE_TYPES_SUCCESS,
  payload: payload,
})

export const getListExampleTypesFail = error => ({
  type: EXAMPLE_TYPES.GET_LIST_EXAMPLE_TYPES_FAIL,
  error: error,
})

export const getExampleTypesDetail = (id, callback) =>({
    type: EXAMPLE_TYPES.GET_EXAMPLE_TYPES_DETAIL,
    id,
    callback,
  }
)

export const getExampleTypesDetailSuccess = payload => ({
  type: EXAMPLE_TYPES.GET_EXAMPLE_TYPES_DETAIL_SUCCESS,
  payload: payload,
})

export const getExampleTypesDetailFail = error => ({
  type: EXAMPLE_TYPES.GET_EXAMPLE_TYPES_DETAIL_FAIL,
  payload: error,
})

export const addNewExampleTypes = payload => ({
    type: EXAMPLE_TYPES.ADD_NEW_EXAMPLE_TYPES,
    payload: payload,
  }
)

export const addNewExampleTypesSuccess = payload => ({
  type: EXAMPLE_TYPES.ADD_NEW_EXAMPLE_TYPES_SUCCESS,
  payload: payload,
})

export const addNewExampleTypesFail = error => ({
  type: EXAMPLE_TYPES.ADD_NEW_EXAMPLE_TYPES_FAIL,
  error: error,
})

export const updateExampleTypes = payload => ({
    type: EXAMPLE_TYPES.UPDATE_EXAMPLE_TYPES,
    payload: payload,
  }
)

export const updateExampleTypesSuccess = payload => ({
  type: EXAMPLE_TYPES.UPDATE_EXAMPLE_TYPES_SUCCESS,
  payload: payload,
})

export const updateExampleTypesFail = error => ({
  type: EXAMPLE_TYPES.UPDATE_EXAMPLE_TYPES_FAIL,
  error: error,
})

export const deleteExampleTypes = payload => ({
    type: EXAMPLE_TYPES.DELETE_EXAMPLE_TYPES,
    payload: payload,
  }
)

export const deleteExampleTypesSuccess = payload => ({
  type: EXAMPLE_TYPES.DELETE_EXAMPLE_TYPES_SUCCESS,
  payload: payload,
})

export const deleteExampleTypesFail = error => ({
  type: EXAMPLE_TYPES.DELETE_EXAMPLE_TYPES_FAIL,
  error: error,
})
