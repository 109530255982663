import i18n from "i18next"
import { all, call, put, select, takeEvery } from "redux-saga/effects"

//setting redux states
import {
  ADD_INSTRUMENT_TRANSLATOR,
  ADD_LA_TRANSLATOR,
  ADD_MACHINE,
  ADD_MACHINE_MAPPING_CODE_KS,
  ADD_MACHINE_MAPPING_CODE_VS,
  ADD_NEW_MACHINE_MAPPING_CODE,
  DELETE_INSTRUMENT_TRANSLATOR,
  DELETE_LA_TRANSLATOR,
  DELETE_MACHINE,
  DELETE_MACHINE_MAPPING_CODE_KS,
  DELETE_MACHINE_MAPPING_CODE_VS,
  DELETE_MACHINE_MAPPING_CODES,
  GET_INSTRUMENT_TRANSLATORS,
  GET_INSTRUMENT_TRANSLATORS_DETAIL,
  GET_LA_TRANSLATORS,
  GET_LA_TRANSLATORS_DETAIL,
  GET_MACHINE_CODE_MAPPING,
  GET_MACHINE_CODE_MAPPING_KS,
  GET_MACHINE_CODE_MAPPING_VS,
  GET_MACHINE_DETAIL,
  GET_MACHINES,
  SAVE_MACHINE_CODE_MAPPING,
  SAVE_MACHINE_CODE_MAPPING_KS,
  SAVE_MACHINE_CODE_MAPPING_VS,
  UPDATE_INSTRUMENT_TRANSLATOR,
  UPDATE_LA_TRANSLATOR,
  UPDATE_MACHINE,
  UPDATE_MACHINE_MAPPING_CODE,
  UPDATE_MACHINE_MAPPING_CODE_KS,
  UPDATE_MACHINE_MAPPING_CODE_VS
} from "./actionTypes"

import {
  addNewInstrumentTranslatorFail,
  addNewInstrumentTranslatorSuccess,
  addNewLaTranslatorFail,
  addNewLaTranslatorSuccess,
  addNewMachineFail,
  addNewMachineMappingCodeFail,
  addNewMachineMappingCodeKSFail,
  addNewMachineMappingCodeKSSuccess,
  addNewMachineMappingCodeSuccess,
  addNewMachineMappingCodeVSFail,
  addNewMachineMappingCodeVSSuccess,
  addNewMachineSuccess,
  deleteInstrumentTranslatorsFail,
  deleteInstrumentTranslatorsSuccess,
  deleteLaTranslatorsFail,
  deleteLaTranslatorsSuccess,
  deleteMachineMappingCodeFail,
  deleteMachineMappingCodeKSFail,
  deleteMachineMappingCodeKSSuccess,
  deleteMachineMappingCodesSuccess,
  deleteMachineMappingCodeVSFail,
  deleteMachineMappingCodeVSSuccess,
  deleteMachinesFail,
  deleteMachinesSuccess,
  getInstrumentTranslatorDetailFail,
  getInstrumentTranslatorDetailSuccess,
  getInstrumentTranslatorsFail,
  getInstrumentTranslatorsSuccess,
  getLaTranslatorDetailFail,
  getLaTranslatorDetailSuccess,
  getLaTranslatorsFail,
  getLaTranslatorsSuccess,
  getMachineCodeMappingFail,
  getMachineCodeMappingKSFail,
  getMachineCodeMappingKSSuccess,
  getMachineCodeMappingSuccess,
  getMachineCodeMappingVSFail,
  getMachineCodeMappingVSSuccess,
  getMachineDetailFail,
  getMachineDetailSuccess,
  getMachinesFail,
  getMachinesSuccess,
  saveMachineCodeMappingFail,
  saveMachineCodeMappingKSFail,
  saveMachineCodeMappingKSSuccess,
  saveMachineCodeMappingSuccess,
  saveMachineCodeMappingVSFail,
  saveMachineCodeMappingVSSuccess,
  setInstrumentMappingCodeSearchQuery,
  setInstrumentTranslatorSearchQuery,
  setLaTranslatorSearchQuery,
  setMachineSearchQuery,
  updateInstrumentTranslatorFail,
  updateInstrumentTranslatorSuccess,
  updateLaTranslatorFail,
  updateLaTranslatorSuccess,
  updateMachineFail,
  updateMachineMappingCodeFail,
  updateMachineMappingCodeKSFail,
  updateMachineMappingCodeKSSuccess,
  updateMachineMappingCodeSuccess,
  updateMachineMappingCodeVSFail,
  updateMachineMappingCodeVSSuccess,
  updateMachineSuccess,
  updateMachineTestCount
} from "./actions"

import { getAllParameters } from "helpers/app-backend"

//call api thật
import {
  createInstrumentTranslator,
  createLaTranslator,
  createMachine,
  createMachineMappingCode,
  createMachineMappingCodeKS,
  createMachineMappingCodeVS,
  deleteInstrumentTranslatorById,
  deleteLaTranslatorById,
  deleteMachineById,
  deleteMachineMappingCodeById,
  deleteMachineMappingCodeKSById,
  deleteMachineMappingCodeVSById,
  getAllInstrumentTranslator,
  getAllLaTranslator,
  getAllMachine,
  getInstrumentTranslatorById,
  getLaTranslatorById,
  getMachineById,
  getMachineMappingCodeKS,
  getMachineMappingCodes,
  getMachineMappingCodeVS,
  updateInstrumentTranslator,
  updateLaTranslator,
  updateMachineById,
  updateMachineMappingCodeById,
  updateMachineMappingCodeKS,
  updateMachineMappingCodeKSById,
  updateMachineMappingCodes,
  updateMachineMappingCodeVS,
  updateMachineMappingCodeVSById,
} from "helpers/app-backend/machines_backend_helper"


import { getAllCompanies } from "helpers/app-backend/companies_backend_helper"

//call api fake
// import {
//     createMachine, deleteMachineById, getAllMachine, getMachineById, updateMachineById
// } from "helpers/fakebackend_helper"
import { showToast } from "components/Common"
const t = (msg, param) => i18n.t(msg, param)

function* fetchMachines({ payload }) {
  try {
    const searchQuery = yield select(state => {
      return state.machine.searchQuery
    })
    payload = { ...searchQuery, ...payload }
    const response = yield call(getAllMachine, payload)
    yield put(getMachinesSuccess(response))
    yield put(setMachineSearchQuery(payload))
  } catch (error) {
    console.log(error)
    yield put(getMachinesFail(error))
  }
}

function* fetchMachineDetail({ id }) {
  try {
    const { response, companies, parameters } = yield all({
      response: call(getMachineById, id),
      companies: call(getAllCompanies, { size: 100 }),
      parameters: call(getAllParameters, { size: 999 }),
    })

    response.companyName = companies?.data.find(
      x => `${x.id}` === `${response.companyId}`
    )?.name
    let parameter = parameters?.data.find(
      x => `${x.parameterKey}` === `${response.mappingKey}`
    )?.name
    if (parameter) {
      response.mappingKeyName = `${parameter} (${response.mappingKey})`
    }

    yield put(getMachineDetailSuccess(response))
  } catch (error) {
    yield put(getMachineDetailFail(error))
  }
}

function* onAddNewMachine({ payload: { machine, history } }) {
  try {
    //call api thật
    const response = yield call(createMachine, machine)
    const id = response?.id
    yield put(addNewMachineSuccess(id))
    const url = `/Instrument/${id}/view`
    showToast(
      `${t("message:CreatedMessage", {
        field: `${t(
          "machinePage:Instrument"
        )} <span class='text-decoration-underline fw-bold'>${machine["name"]
          }</span>`,
      })}`
    )
    history.push(url)
  } catch (error) {
    console.log(error)
    yield put(addNewMachineFail(error))
  }
}

function* onUpdateMachine({ payload: { machine, callback } }) {
  try {
    const response = yield call(updateMachineById, machine)
    yield put(updateMachineSuccess(machine))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "machinePage:Instrument"
        )} <span class='text-decoration-underline fw-bold'>${machine["name"]
          }</span>`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(updateMachineFail(error))
  }
}

function* onDeleteMachine({ payload: { machines, callback } }) {
  try {
    const response = yield call(deleteMachineById, machines)
    yield put(deleteMachinesSuccess(machines))
    showToast(
      `${t("message:DeletedMessage", {
        field: `${t("machinePage:Instrument")}`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(deleteMachinesFail(error))
  }
}

function* fetchMachineCodeMappings({ payload, callback }) {
  try {
    const { instrumentId, query } = payload

    const searchQuery = yield select(
      state => state.machine.searchQueryMappingCode
    )
    const newQuery = { ...searchQuery, ...query }

    const instrumentMappingCodes = yield call(
      getMachineMappingCodes,
      instrumentId,
      newQuery
    )

    const mappingFromkey = instrumentMappingCodes.map((element, index) => {
      element.id = index
      element.instrumentSubCode = element?.instrumentSubCode?.replaceAll("|", ",")
      if (element.testName === null) {
        element.testName = ""
      }
      return element
    })
    yield put(updateMachineTestCount(mappingFromkey.length))
    yield put(getMachineCodeMappingSuccess(mappingFromkey))
    yield put(setInstrumentMappingCodeSearchQuery(newQuery))
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(getMachineCodeMappingFail(error))
  }
}

function* saveMachineCodeMappings({ payload }) {
  try {
    const { machineId, mappingCodes, active, callback } = payload

    yield call(updateMachineMappingCodes, machineId, active, mappingCodes)
    yield put(saveMachineCodeMappingSuccess(mappingCodes))
    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "machinePage:InstrumentMappingCode"
        )} <span class='text-decoration-underline fw-bold'></span>`,
      })}`
    )

    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(saveMachineCodeMappingFail(error))
  }
}

function* onAddMachineMappingCode({ payload: { code, callback } }) {
  try {
    const response = yield call(createMachineMappingCode, code)
    const id = response?.id
    yield put(addNewMachineMappingCodeSuccess(id))

    showToast(
      `${t("message:CreatedMessage", {
        field: `${t(
          "machinePage:InstrumentMappingCode"
        )} <span class='text-decoration-underline fw-bold'>${code["testCode"]
          }</span>`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(addNewMachineMappingCodeFail(error))
  }
}

// update address
function* onUpdateMachineMappingCode({ payload: { code, callback } }) {
  try {
    const response = yield call(updateMachineMappingCodeById, code)
    yield put(updateMachineMappingCodeSuccess(code))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "machinePage:InstrumentMappingCode"
        )} <span class='text-decoration-underline fw-bold'>${code["testCode"]
          }</span>`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(updateMachineMappingCodeFail(error))
  }
}

function* onDeleteMachineMappingCodes({
  payload: { codes, instrumentId, callback },
}) {
  try {
    const response = yield call(
      deleteMachineMappingCodeById,
      codes,
      instrumentId
    )
    yield put(deleteMachineMappingCodesSuccess(codes))

    showToast(
      `${t("message:DeletedMessage", {
        field: `${t("machinePage:InstrumentMappingCode")}`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(deleteMachineMappingCodeFail(error))
  }
}

function* fetchInstrumentTranslators({ payload }) {
  try {
    const searchQuery = yield select(state => {
      return state.machine.searchQueryInstrumentTranslator
    })
    payload = { ...searchQuery, ...payload }
    const response = yield call(getAllInstrumentTranslator, payload)
    yield put(getInstrumentTranslatorsSuccess(response))
    yield put(setInstrumentTranslatorSearchQuery(payload))
  } catch (error) {
    console.log(error)
    yield put(getInstrumentTranslatorsFail(error))
  }
}

function* onAddNewInstrumentTranslator({ payload: { machine, callback } }) {
  try {
    //call api thật
    const response = yield call(createInstrumentTranslator, machine)
    yield put(addNewInstrumentTranslatorSuccess(id))
    showToast(
      `${t("message:CreatedMessage", {
        field: `${t(
          "machinePage:Instrument Translator"
        )} <span class='text-decoration-underline fw-bold'></span>`,
      })}`
    )
    callback && callback()
  } catch (error) {
    console.log(error)
    yield put(addNewInstrumentTranslatorFail(error))
  }
}

function* onUpdateInstrumentTranslator({ payload: { machine, callback } }) {
  try {
    const response = yield call(updateInstrumentTranslator, machine)
    yield put(updateInstrumentTranslatorSuccess(machine))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "machinePage:Instrument Translator"
        )} <span class='text-decoration-underline fw-bold'></span>`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(updateInstrumentTranslatorFail(error))
  }
}

function* onDeleteInstrumentTranslator({ payload: { instrumentTranslators, callback } }) {
  try {
    const response = yield call(deleteInstrumentTranslatorById, instrumentTranslators)
    yield put(deleteInstrumentTranslatorsSuccess(instrumentTranslators))
    showToast(
      `${t("message:DeletedMessage", {
        field: `${t("machinePage:Instrument Translator")}`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(deleteInstrumentTranslatorsFail(error))
  }
}

function* fetchInstrumentTranslatorDetail({ id }) {
  try {
    const { response } = yield all({
      response: call(getInstrumentTranslatorById, id),
    })
    yield put(getInstrumentTranslatorDetailSuccess(response))
  } catch (error) {
    console.log(error);
    yield put(getInstrumentTranslatorDetailFail(error))
  }
}


//
function* fetchLaTranslators({ payload }) {
  try {
    const searchQuery = yield select(state => {
      return state.machine.searchQueryLaTranslator
    })
    payload = { ...searchQuery, ...payload }
    const response = yield call(getAllLaTranslator, payload)
    yield put(getLaTranslatorsSuccess(response))
    yield put(setLaTranslatorSearchQuery(payload))
  } catch (error) {
    console.log(error)
    yield put(getLaTranslatorsFail(error))
  }
}

function* onAddNewLaTranslator({ payload: { machine, callback } }) {
  try {
    //call api thật
    const response = yield call(createLaTranslator, machine)
    yield put(addNewLaTranslatorSuccess(id))
    showToast(
      `${t("message:CreatedMessage", {
        field: `${t(
          "machinePage:Instrument Expression"
        )} <span class='text-decoration-underline fw-bold'></span>`,
      })}`
    )
    callback && callback()
  } catch (error) {
    console.log(error)
    yield put(addNewLaTranslatorFail(error))
  }
}

function* onUpdateLaTranslator({ payload: { machine, callback } }) {
  try {
    const response = yield call(updateLaTranslator, machine)
    yield put(updateLaTranslatorSuccess(machine))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "machinePage:Instrument Expression"
        )} <span class='text-decoration-underline fw-bold'></span>`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(updateLaTranslatorFail(error))
  }
}

function* onDeleteLaTranslator({ payload: { instrumentTranslators, callback } }) {
  try {
    const response = yield call(deleteLaTranslatorById, instrumentTranslators)
    yield put(deleteLaTranslatorsSuccess(instrumentTranslators))
    showToast(
      `${t("message:DeletedMessage", {
        field: `${t("machinePage:Instrument Expression")}`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(deleteLaTranslatorsFail(error))
  }
}

function* fetchLaTranslatorDetail({ id }) {
  try {
    const { response } = yield all({
      response: call(getLaTranslatorById, id),
    })
    yield put(getLaTranslatorDetailSuccess(response))
  } catch (error) {
    console.log(error);
    yield put(getLaTranslatorDetailFail(error))
  }
}

//vi sinh
function* fetchMachineCodeMappingVS({ payload, callback }) {
  try {
    const { instrumentId, query } = payload

    const searchQuery = yield select(
      state => state.machine.searchQueryMappingCodeVS
    )
    const newQuery = { ...searchQuery, ...query }

    const instrumentMappingCodes = yield call(
      getMachineMappingCodeVS,
      instrumentId,
      newQuery
    )

    const mappingFromkey = instrumentMappingCodes.map((element, index) => {
      element.id = index
      element.instrumentSubCode = element?.instrumentSubCode?.replaceAll("|", ",")
      if (element.testName === null) {
        element.testName = ""
      }
      return element
    })
    
    yield put(updateMachineTestCount(mappingFromkey.length))
    yield put(getMachineCodeMappingVSSuccess(mappingFromkey))
    // yield put(setInstrumentMappingCodeSearchQuery(newQuery))
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(getMachineCodeMappingVSFail(error))
  }
}

function* saveMachineCodeMappingVS({ payload }) {
  try {
    const { machineId, mappingCodes, active, callback } = payload

    yield call(updateMachineMappingCodeVS, machineId, active, mappingCodes)
    yield put(saveMachineCodeMappingVSSuccess(mappingCodes))
    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "machinePage:InstrumentMappingCode"
        )} <span class='text-decoration-underline fw-bold'></span>`,
      })}`
    )

    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(saveMachineCodeMappingVSFail(error))
  }
}

function* onAddMachineMappingCodeVS({ payload: { code, callback } }) {
  try {
    const response = yield call(createMachineMappingCodeVS, code)
    const id = response?.id
    yield put(addNewMachineMappingCodeVSSuccess(id))

    showToast(
      `${t("message:CreatedMessage", {
        field: `${t(
          "machinePage:InstrumentMappingCode"
        )} <span class='text-decoration-underline fw-bold'>${code["testCode"]
          }</span>`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(addNewMachineMappingCodeVSFail(error))
  }
}

// update address
function* onUpdateMachineMappingCodeVS({ payload: { code, callback } }) {
  try {
    const response = yield call(updateMachineMappingCodeVSById, code)
    yield put(updateMachineMappingCodeVSSuccess(code))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "machinePage:InstrumentMappingCode"
        )} <span class='text-decoration-underline fw-bold'>${code["testCode"]
          }</span>`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(updateMachineMappingCodeVSFail(error))
  }
}

function* onDeleteMachineMappingCodeVS({
  payload: { codes, instrumentId, callback },
}) {
  try {
    const response = yield call(
      deleteMachineMappingCodeVSById,
      codes,
      instrumentId
    )
    yield put(deleteMachineMappingCodeVSSuccess(codes))

    showToast(
      `${t("message:DeletedMessage", {
        field: `${t("machinePage:InstrumentMappingCode")}`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(deleteMachineMappingCodeVSFail(error))
  }
}


//kháng sinh
function* fetchMachineCodeMappingKS({ payload, callback }) {
  try {
    const { instrumentId, query } = payload

    const searchQuery = yield select(
      state => state.machine.searchQueryMappingCodeKS
    )
    const newQuery = { ...searchQuery, ...query }

    const instrumentMappingCodes = yield call(
      getMachineMappingCodeKS,
      instrumentId,
      newQuery
    )

    const mappingFromkey = instrumentMappingCodes.map((element, index) => {
      element.id = index
      element.instrumentSubCode = element?.instrumentSubCode?.replaceAll("|", ",")
      if (element.testName === null) {
        element.testName = ""
      }
      return element
    })
    yield put(updateMachineTestCount(mappingFromkey.length))
    yield put(getMachineCodeMappingKSSuccess(mappingFromkey))
    // yield put(setInstrumentMappingCodeSearchQuery(newQuery))
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(getMachineCodeMappingKSFail(error))
  }
}

function* saveMachineCodeMappingKS({ payload }) {
  try {
    const { machineId, mappingCodes, active, callback } = payload

    yield call(updateMachineMappingCodeKS, machineId, active, mappingCodes)
    yield put(saveMachineCodeMappingKSSuccess(mappingCodes))
    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "machinePage:InstrumentMappingCode"
        )} <span class='text-decoration-underline fw-bold'></span>`,
      })}`
    )

    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(saveMachineCodeMappingKSFail(error))
  }
}

function* onAddMachineMappingCodeKS({ payload: { code, callback } }) {
  try {
    const response = yield call(createMachineMappingCodeKS, code)
    const id = response?.id
    yield put(addNewMachineMappingCodeKSSuccess(id))

    showToast(
      `${t("message:CreatedMessage", {
        field: `${t(
          "machinePage:InstrumentMappingCode"
        )} <span class='text-decoration-underline fw-bold'>${code["testCode"]
          }</span>`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(addNewMachineMappingCodeKSFail(error))
  }
}

// update address
function* onUpdateMachineMappingCodeKS({ payload: { code, callback } }) {
  try {
    const response = yield call(updateMachineMappingCodeKSById, code)
    yield put(updateMachineMappingCodeKSSuccess(code))

    showToast(
      `${t("message:UpdatedMessage", {
        field: `${t(
          "machinePage:InstrumentMappingCode"
        )} <span class='text-decoration-underline fw-bold'>${code["testCode"]
          }</span>`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(updateMachineMappingCodeKSFail(error))
  }
}

function* onDeleteMachineMappingCodeKS({
  payload: { codes, instrumentId, callback },
}) {
  try {
    const response = yield call(
      deleteMachineMappingCodeKSById,
      codes,
      instrumentId
    )
    yield put(deleteMachineMappingCodeKSSuccess(codes))

    showToast(
      `${t("message:DeletedMessage", {
        field: `${t("machinePage:InstrumentMappingCode")}`,
      })}`
    )
    if (callback) callback()
  } catch (error) {
    console.log(error)
    yield put(deleteMachineMappingCodeKSFail(error))
  }
}

function* machineSaga() {
  yield takeEvery(GET_MACHINES, fetchMachines)
  yield takeEvery(GET_MACHINE_DETAIL, fetchMachineDetail)
  yield takeEvery(ADD_MACHINE, onAddNewMachine)
  yield takeEvery(UPDATE_MACHINE, onUpdateMachine)
  yield takeEvery(DELETE_MACHINE, onDeleteMachine)
  yield takeEvery(GET_MACHINE_CODE_MAPPING, fetchMachineCodeMappings)
  yield takeEvery(SAVE_MACHINE_CODE_MAPPING, saveMachineCodeMappings)
  yield takeEvery(ADD_NEW_MACHINE_MAPPING_CODE, onAddMachineMappingCode)
  yield takeEvery(UPDATE_MACHINE_MAPPING_CODE, onUpdateMachineMappingCode)
  yield takeEvery(DELETE_MACHINE_MAPPING_CODES, onDeleteMachineMappingCodes)

  yield takeEvery(GET_INSTRUMENT_TRANSLATORS, fetchInstrumentTranslators)
  yield takeEvery(ADD_INSTRUMENT_TRANSLATOR, onAddNewInstrumentTranslator)
  yield takeEvery(UPDATE_INSTRUMENT_TRANSLATOR, onUpdateInstrumentTranslator)
  yield takeEvery(DELETE_INSTRUMENT_TRANSLATOR, onDeleteInstrumentTranslator)
  yield takeEvery(GET_INSTRUMENT_TRANSLATORS_DETAIL, fetchInstrumentTranslatorDetail)

  yield takeEvery(GET_LA_TRANSLATORS, fetchLaTranslators)
  yield takeEvery(ADD_LA_TRANSLATOR, onAddNewLaTranslator)
  yield takeEvery(UPDATE_LA_TRANSLATOR, onUpdateLaTranslator)
  yield takeEvery(DELETE_LA_TRANSLATOR, onDeleteLaTranslator)
  yield takeEvery(GET_LA_TRANSLATORS_DETAIL, fetchLaTranslatorDetail)

  yield takeEvery(GET_MACHINE_CODE_MAPPING_VS, fetchMachineCodeMappingVS)
  yield takeEvery(SAVE_MACHINE_CODE_MAPPING_VS, saveMachineCodeMappingVS)
  yield takeEvery(ADD_MACHINE_MAPPING_CODE_VS, onAddMachineMappingCodeVS)
  yield takeEvery(UPDATE_MACHINE_MAPPING_CODE_VS, onUpdateMachineMappingCodeVS)
  yield takeEvery(DELETE_MACHINE_MAPPING_CODE_VS, onDeleteMachineMappingCodeVS)

  yield takeEvery(GET_MACHINE_CODE_MAPPING_KS, fetchMachineCodeMappingKS)
  yield takeEvery(SAVE_MACHINE_CODE_MAPPING_KS, saveMachineCodeMappingKS)
  yield takeEvery(ADD_MACHINE_MAPPING_CODE_KS, onAddMachineMappingCodeKS)
  yield takeEvery(UPDATE_MACHINE_MAPPING_CODE_KS, onUpdateMachineMappingCodeKS)
  yield takeEvery(DELETE_MACHINE_MAPPING_CODE_KS, onDeleteMachineMappingCodeKS)
}
export default machineSaga
