import { Check } from "components/Common"
import BadgeLabel from "components/Common/BadgeLabel"
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"
import { ModuleIds, permissionType } from "constant"
import { convertDateFormat, indexCalculator } from "helpers/utilities"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import ImportExportButton from "../ImportExportForm/index"
import ConfigLotQCButtons from "./ConfigLotQCButtons"
import FilterForm from "./FilterForm"
import HelpModal from "./Modals/HelpModal"
import HistoryLotModal from "./Modals/HistoryLotModal"
import { getListLotQC } from "store/actions"
import PropTypes from "prop-types"

const RESOURCE = ModuleIds.LotQC

let pageGlobal = 0
let dataGlobal = []
let rowsGlobal = []
const ConfigLotQCTable = ({
  lotQCs,
  onSort,
  onSearch,
  onRefresh,
  itemActive,
  updatedTime,
  onSubmitFilter,
  t,
  loadinglotQC,
  onEdit,
  onClone,
  onPageChange,
  paging,
  onSizePerPageChange,
  onChangeModel,
  isSelected,
  onDelete,
  onSelect,
  onSelectAll,
  onExportQCLot,
  resource,
  onGetListLotQC
}) => {
  const [dataSource, setDataSource] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(0)
  const [row, setRow] = useState({})
  const [rows, setRows] = useState([])
  const [modalHistory, setModalHistory] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const [model, setModel] = useState({
    search: "",
    testCategory: "",
    lotGroup: "",
    userId: "",
  })
  const searchQuery = useSelector(state => state.lotQCs.searchQuery)
  
  useEffect(() => {
    onChangeModel && onChangeModel(model)
  }, [model])

  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])


const onResetHandler = () => {
      const resetFields = {
        search: "",
        testCategory: "",
        lotGroup: "",
        userId: "",
        isLotExpired: false,
      };
    
      const updatedModel = {
        ...model,
        ...resetFields,
      };
    
      setModel(updatedModel);
      onGetListLotQC({...updatedModel})
  }

  useEffect(() => {
    setDataSource(lotQCs)
  }, [lotQCs])
  const [elEnd, setElEnd] = useState(
    document.getElementById(`watch_end_of_document`)
  )
  useEffect(() => {
    setDataSource(lotQCs)
  }, [dataSource])

  useEffect(() => {
    rowsGlobal = rows
  }, [rows])

  let columns = [
    {
      dataField: "id",
      text: "",
      hidden: true,
      formatter: (cellContent, item, index) => {
        return cellContent
      },
    },
    {
      dataField: "",
      text: "#",
      classes: "stt-col-sticky",
      style: {
        borderLeft: "1px solid #F0F2F7",
        minWidth: 60,
        maxWidth: 60,
        with: 60,
      },
      headerStyle: {
        borderLeft: "1px solid #F0F2F7",
        minWidth: 60,
        maxWidth: 60,
        with: 60,
      },
      headerClasses: "stt-col-header-sticky",
      formatter: (cellContent, item, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "expireDate",
      text: t("Expire Date"),
      style: { minWidth: 105, maxWidth: 105, with: 105 },
      headerStyle: { minWidth: 105, maxWidth: 105, with: 105 },
      classes: "col-one-sticky row-span",
      headerClasses: "col-one-header-sticky",
      formatter: (cellContent, item, index) => {
        return <span>{convertDateFormat(cellContent, "DD-MM-YYYY")}</span>
      },
    },
    {
      dataField: "lotId",
      text: t("Lot ID"),
      style: { minWidth: 100, maxWidth: 100, with: 100 },
      headerStyle: { minWidth: 100, maxWidth: 100, with: 100 },
      classes: "col-two-sticky row-span",
      headerClasses: "col-two-header-sticky",
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "lotName",
      text: t("Lot Name"),
      style: {
        minWidth: 210,
        color: "#3C9DEC",
        fontWeight: "bold",
        fontSize: 14,
      },
      classes: "col-three-sticky row-span",
      headerClasses: "col-three-header-sticky",
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, item, index) => {
        return (
          <label
            key={index}
            data-tip
            data-for={`lotName-${index}`}
            style={{
              color: "#5F5F5F",
              backgroundColor: "#fff",
              padding: "1px 5px",
              marginRight: "5px",
              borderRadius: "5px",
              border: `1px solid #808080`,
              minWidth: 40,
              textAlign: "center",
            }}
          >
            {cellContent}
          </label>
        )
      },
    },
    {
      dataField: "machineName",
      text: t("Machines"),
      style: { minWidth: 150, maxWidth: 150, with: 150 },
      headerStyle: { minWidth: 150, maxWidth: 150, with: 150 },
      align: "center",
      headerAlign: "center",
      formatter: cellContent => {
        const machineNames = cellContent?.split(",") || []

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {machineNames.map((name, index) => (
              <div
                key={`machine-name-${index}`}
                style={{ marginBottom: 4, fontSize: 15, color: "#333" }}
              >
                {name.trim()}
              </div>
            ))}
          </div>
        )
      },
    },
    {
      dataField: "testCategoryName",
      text: t("Test Category"),
      style: { minWidth: 150, maxWidth: 150, with: 150 },
      headerStyle: { minWidth: 150, maxWidth: 150, with: 150 },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "levels",
      text: t("Levels"),
      style: { minWidth: 130, maxWidth: 130, with: 130 },
      headerStyle: { minWidth: 130, maxWidth: 130, with: 130 },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "totalTestsInLot",
      text: t("Tests Count"),
      style: { minWidth: 130, maxWidth: 130, with: 130 },
      headerStyle: { minWidth: 130, maxWidth: 130, with: 130 },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "noOfPointPI",
      text: t("Number of PI Points"),
      style: { minWidth: 180, maxWidth: 180, with: 180 },
      headerStyle: { minWidth: 180, maxWidth: 180, with: 180 },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "noOfPointCal",
      text: t("Number of Calibration Points"),
      style: { minWidth: 180, maxWidth: 180, with: 180 },
      headerStyle: { minWidth: 180, maxWidth: 180, with: 180 },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "manufactor",
      text: t("Manufactor"),
      style: { minWidth: 130, maxWidth: 'auto', with: 200 },
      headerStyle: { minWidth: 130, maxWidth: 'auto', with: 200 },
      formatter: (cellContent, item, index) => {
        return (
          <>
            {cellContent && (
              <span
                className="manufactor-text"
                style={{
                  display: "inline-block",
                  padding: "4px 8px",
                  backgroundColor: "#f0f8ff",
                  color: "#333",
                  borderRadius: "4px",
                  fontSize: "14px",
                  fontWeight: "500",
                  textAlign: "center",
                  border: "1px solid #d1e7ff",
                }}
              >
                {cellContent}
              </span>
            )}
          </>
        )
      },
    },
    {
      dataField: "lotGroupName",
      text: t("Lot Group"),
      style: { minWidth: 210, maxWidth: 210, with: 210 },
      headerStyle: { minWidth: 210, maxWidth: 210, with: 210 },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "noOfRun",
      text: t("Number of Runs"),
      style: { minWidth: 150, maxWidth: 150, with: 150 },
      headerStyle: { minWidth: 150, maxWidth: 150, with: 150 },
      style: { fontWeight: "bold" },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "maxDayOpen",
      text: t("Max Days Open"),
      style: { minWidth: 150, maxWidth: 150, with: 150 },
      headerStyle: { minWidth: 150, maxWidth: 150, with: 150 },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "maxOpenDate",
      text: t("Open Date"),
      style: { minWidth: 180, maxWidth: 180, with: 180 },
      headerStyle: { minWidth: 180, maxWidth: 180, with: 180 },
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, item, index) => {
        return <span>{convertDateFormat(cellContent, "DD-MM-YYYY")}</span>
      },
    },
    {
      dataField: "totalLotTestInDatapoints",
      text: t("Number of QC Points Run"),
      style: { minWidth: 180, maxWidth: 180, with: 180 },
      headerStyle: { minWidth: 180, maxWidth: 180, with: 180 },
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "createdByName",
      text: t("User Created"),
      headerStyle: { whiteSpace: "nowrap" },
      style: { whiteSpace: "nowrap" },
      formatter: (cellContent, item, index) => {
        return <span>{cellContent}</span>
      },
    },
    {
      dataField: "createdDate",
      text: t("Date Created"),
      headerStyle: { whiteSpace: "nowrap" },
      style: { whiteSpace: "nowrap" },
      formatter: (cellContent, item, index) => {
        return <span>{convertDateFormat(cellContent, "HH:mm DD-MM-YYYY")}</span>
      },
    },
    {
      dataField: "updatedByName",
      text: t("User Updated"),
      headerStyle: { whiteSpace: "nowrap" },
      style: { whiteSpace: "nowrap" },
      formatter: (cellContent, item, index) => {
        return <span>{cellContent}</span>
      },
    },
    {
      dataField: "updatedDate",
      text: t("Date Updated"),
      headerStyle: { whiteSpace: "nowrap" },
      style: { whiteSpace: "nowrap" },
      formatter: (cellContent, item, index) => {
        return <span>{convertDateFormat(cellContent, "HH:mm DD-MM-YYYY")}</span>
      },
    },
    {
      dataField: "status",
      text: t("Status"),
      classes: "status-col-sticky",
      headerClasses: "status-col-header-sticky",
      style: { minWidth: "380px", textAlign: "center" },
      headerStyle: { minWidth: "380px", textAlign: "center" },
      formatter: (cellContent, item) => {
        const now = new Date()
        now.setHours(0, 0, 0, 0)

        const parseDate = dateStr => {
          if (!dateStr) return null
          const date = new Date(dateStr)
          date.setHours(0, 0, 0, 0)
          return date
        }

        const openDate = parseDate(item.maxOpenDate)
        const expireDate = parseDate(item.expireDate)
        const maxDayOpen = item.maxDayOpen
          ? parseInt(item.maxDayOpen, 10)
          : null

        let isOpenExpired = false
        let isExpireExpired = expireDate && expireDate < now

        if (maxDayOpen === null) {
          // Nếu maxDayOpen = null, kiểm tra maxOpenDate và expireDate
          if (openDate && expireDate) {
            // Nếu maxOpenDate > expireDate hoặc maxOpenDate = expireDate và expireDate < now
            isOpenExpired = openDate > expireDate || isExpireExpired
          }
        } else {
          // Nếu maxDayOpen != null, tính ngày hết hạn mở nắp
          if (openDate) {
            const openExpiryDate = new Date(openDate)
            openExpiryDate.setDate(openExpiryDate.getDate() + maxDayOpen)
            isOpenExpired = openExpiryDate <= now
          }
        }

        let status = ""
        let color = "#28a745" // Mặc định màu xanh lá (Còn hạn)
        if (isOpenExpired && isExpireExpired) {
          status = t("Opened shelf life expired / Expired") // Hết hạn mở nắp / Hết hạn sử dụng
          color = "#dc3545" // Đỏ
        } else if (!isOpenExpired && !isExpireExpired) {
          status = t("Within opened shelf life / Within expiration date")
          color = "#28a745" // Xanh lá
        } else if (!isOpenExpired && isExpireExpired) {
          status = t("Within opened shelf life / Expired") // Còn hạn mở nắp / Hết hạn sử dụng
          color = "#ffc107" // Vàng
        } else if (isOpenExpired && !isExpireExpired) {
          status = t("Opened shelf life expired / Within expiration date") // Hết hạn mở nắp / Còn hạn sử dụng
          color = "#17a2b8" // Xanh dương
        }

        return <span style={{ color, fontWeight: "bold" }}>{status}</span>
      },
    },

    {
      dataField: "action",
      text: "",
      classes: "action-col-sticky",
      headerClasses: "action-col-header-sticky",
      style: {
        width: "80px",
        margin: 0,
        padding: "5px",
        maxWidth: 80,
        minWidth: 80,
        textAlign: "center",
      },
      headerStyle: {
        width: "80px",
        margin: 0,
        padding: "5px",
        maxWidth: 80,
        minWidth: 80,
        textAlign: "center",
      },
      formatter: (cellContent, item, index) => (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            gap: "8px",
            padding: "5px",
            backgroundColor: "#f9f9f9",
            borderRadius: "4px",
            transition: "background-color 0.3s ease",
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = "#e6f7ff";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = "#f9f9f9";
          }}
        >
          <Check permission={permissionType.History} resource={resource}>
            <span
              style={{
                cursor: "pointer",
                color: "#007bff",
                fontSize: "16px",
                transition: "transform 0.2s ease",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = "scale(1.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "scale(1)";
              }}
              onClick={() => {
                setSelectedRow(item);
                setModalHistory(true);
              }}
            >
              <i className="fas fa-history" />
            </span>
          </Check>
          <Check permission={permissionType.C} resource={resource}>
            <span
              style={{
                cursor: "pointer",
                color: "#28a745",
                fontSize: "16px",
                transition: "transform 0.2s ease",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = "scale(1.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "scale(1)";
              }}
              onClick={() => onClone(item)}
            >
              <i className="bx bx-copy-alt" />
            </span>
          </Check>
          <Check permission={permissionType.U} resource={resource}>
            <span
              style={{
                cursor: "pointer",
                color: "#ffc107",
                fontSize: "16px",
                transition: "transform 0.2s ease",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = "scale(1.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "scale(1)";
              }}
              onClick={(e) => onEdit(e, item.id, item)}
            >
              <i className="mdi mdi-pencil-outline" />
            </span>
          </Check>
          <Check permission={permissionType.D} resource={resource}>
            <span
              style={{
                cursor: "pointer",
                color: "#dc3545",
                fontSize: "16px",
                transition: "transform 0.2s ease",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.transform = "scale(1.2)";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.transform = "scale(1)";
              }}
              onClick={(e) => onDelete(e, item)}
            >
              <i className="mdi mdi-trash-can" />
            </span>
          </Check>
        </div>
      ),
    },
  ]

  useEffect(() => {
    let el = document.getElementById("resizeMeAsync")
    if (el) {
      el.scrollIntoView(true)
      setTimeout(() => {
        setPage(0)
        pageGlobal = 0
      }, 100)
    }
  }, [itemActive])

  const io = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (!entry.isIntersecting) {
        return
      }
      pageGlobal++
      setPage(pageGlobal)
    })
  })

  useEffect(() => {
    if (elEnd) {
      io.observe(elEnd)
    }
  }, [elEnd])

  return (
    <React.Fragment>
      <div
        style={{
          backgroundColor: "white",
          overflow: "auto",
          minHeight: "500px",
          display: "flex",
          flexDirection: "column",
        }}
        className={`table-expand-custom result-qc-table`}
      >
        <CustomBootstrapTableAsync
          updatedTime={updatedTime}
          columns={columns}
          search
          loading={loadinglotQC}
          onReset={onResetHandler}
          data={dataSource}
          onSort={onSort}
          keyField="id"
          headerFixed={true}
          showSelectRow={true}
          onSelect={onSelect}
          onSelectAll={onSelectAll}
          searchText={model.search}
          onSearch={onSearch}
          resource={RESOURCE}
          onRefresh={onRefresh}
          filterForm={() => <FilterForm model={model} />}
          onSubmitFilter={onSubmitFilter}
          placeholder={t("Search by Code, Lot Name")}
          isEnableExport={true}
          paging={paging}
          onPageChange={onPageChange}
          onSizePerPageChange={onSizePerPageChange}
          importExportForm={() => (
            <ImportExportButton onExportQCLot={onExportQCLot} />
          )}
          isImportExportButton
          customButtonTable={() => (
            <ConfigLotQCButtons
              t={t}
              dataSource={dataSource}
              model={model}
              onChange={val => {
                setModel({ ...model, ...val })
              }}
              onSubmit={val => {
                onSubmitFilter(val)
              }}
            />
          )}
          customButtonBefore={() => (
            <>
            <span
              className="tooltip-icon-wrapper"
              data-tip
              data-for={`tooltip-custom-ex`}
              onClick={() => setIsModalOpen(true)}
              style={{ cursor: "pointer" }}
            >
              <i className="fas fa-exclamation-circle text-primary font-size-16"></i>
            </span>
          </>
          )}
        />
      </div>
      <div className="flex-1" style={{maxHeight:'1px'}}></div>
      <HistoryLotModal
        modal={modalHistory}
        toggle={() => {
          setModalHistory(prev => !prev)
        }}
        data={selectedRow || {}}
        //   config={config}
      />
      <HelpModal
       modal={isModalOpen}
      toggle={() => { setIsModalOpen(prev => !prev) }}
      />
    </React.Fragment>
  )
}

ConfigLotQCTable.propTypes = {
  onGetListLotQC: PropTypes.func,
}

const mapStateToProps = ({ lotQCs }) => ({
  loadinglotQC: lotQCs.loadinglotQC,
})

const mapDispatchToProps = dispatch => ({
    onGetListLotQC: (payload, callback) =>
      dispatch(getListLotQC(payload, callback)),
})

ConfigLotQCTable.displayName = "ConfigLotQCTable"
export default withTranslation(["managementQCPage", "common", "message"], {
  withRef: true,
})(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    ConfigLotQCTable
  )
)
