import { CustomNav, CustomNavLink } from "components/Common"
import { getUrlParamByKey } from "helpers/utilities"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Row, TabContent, TabPane } from "reactstrap"
import CategoryMTHCTab from "./Tab/CategoryMTHC/index"
import ExampleTypeTab from "./Tab/ExampleType/index"
import AntibioticResistanceTab from "./Tab/AntibioticResistances/index"
import ProgressTab from "./Tab/Progresses/index"

const OtherCategory = ({ t }) => {
  const [currentTab, setCurrentTab] = useState(getUrlParamByKey("tab") || "1")

  useEffect(() => {}, [currentTab])

  return (
    <Row style={{ backgroundColor: "white" }}>
      <CustomNav
        onToggle={e => {
          setCurrentTab(e)
        }}
        defaultTab={currentTab}
        tabs
        className="nav-tabs-custom pt-3"
        tabContents={customActiveTab => (
          <TabContent activeTab={customActiveTab}>
            <TabPane tabId="1">
              {currentTab == "1" && (
                <>
                  <CategoryMTHCTab />
                </>
              )}
            </TabPane>
            <TabPane tabId="2">
              {currentTab == "2" && (
                <>
                  <ExampleTypeTab />
                </>
              )}
            </TabPane>
            <TabPane tabId="3">
              {currentTab == "3" && (
                <>
                  <AntibioticResistanceTab />
                </>
              )}
            </TabPane>
            <TabPane tabId="4">
              {currentTab == "4" && (
                <>
                  <ProgressTab />
                </>
              )}
            </TabPane>
          </TabContent>
        )}
      >
        <CustomNavLink tabId="1">
          <span className="">{t("Category MT/HC")}</span>
        </CustomNavLink>
        <CustomNavLink tabId="2">
          <span className="">{t("Sample Type List")}</span>
        </CustomNavLink>
        <CustomNavLink tabId="3">
          <span className="">{t("KS resistance list")}</span>
        </CustomNavLink>
        <CustomNavLink tabId="4">
          <span className="">{t("Progress List")}</span>
        </CustomNavLink>
      </CustomNav>
    </Row>
  )
}

const mapStateToProps = ({ parameter }) => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    withTranslation(["otherMicroorganismsCategory", "message"])(OtherCategory)
  )
)
