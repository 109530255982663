import React from "react"
import { AvForm } from "availity-reactstrap-validation"
import { ModalBody, ModalFooter, Row, Col } from "reactstrap"

import CustomModal from "components/Common/Modals/CustomModal"
import { CustomSelect } from "components/Common/Input"
import { parameterCode, ModuleIds, permissionType, DefaultValue } from "constant/utility"

//i18n
import { withTranslation } from "react-i18next"
import { getInvalidMessageI18n, isEmptyValues } from "helpers/utilities"
import {
  CustomButton,
  CustomAvField,
  CustomCheckbox,
  Check,
} from "components/Common"

const AddressModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  formEl,
  t,
  partyId,
  onChange,
  resource,
}) => {
  if (isEdit && isEmptyValues(data)) return null

  if (isEmptyValues(data)) {
    data.country = DefaultValue.COUNTRY
  }

  const title =
    (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("common:Address")

  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody>
        <AvForm
          id="addressForm"
          onValidSubmit={onValidSubmit}
          ref={formEl}
          model={data}
          onChange={onChange}
        >
          <Row className="mb-3">
            <CustomAvField name="partyId" type="hidden" value={partyId || ""} />
            <CustomAvField name="id" type="hidden" value={data.id || ""} />

            <Col sm="12" className="mb-3">
              <CustomSelect
                label={t("partyPage:Address Type")}
                name="type"
                required
                value={data.type || ""}
                code={parameterCode.ADDRESS_TYPE}
                detected={isEdit}
              />
            </Col>
            <Col sm="12" className="mb-3">
              <CustomSelect
                name="country"
                value={data.country || ""}
                code={parameterCode.COUNTRY_CODE}
                label={t("Country")}
                detected={isEdit}
              />
            </Col>
            <Col sm="12" className="mb-3">
              <CustomSelect
                name="province"
                label={t("partyPage:Province/City")}
                value={data.province || ""}
                group={data.country}
                isDependent
                code={parameterCode.PROVINCE_CODE}
                detected={isEdit}
              />
            </Col>
            <Col sm="12" className="mb-3">
              <CustomSelect
                name="district"
                label={t("common:District")}
                value={data.district || ""}
                group={data.province}
                isDependent
                code={parameterCode.DISTRICT_CODE}
              // detected={isEdit}
              />
            </Col>
            <Col sm="12" className="mb-3">
              <CustomSelect
                name="ward"
                label={t("common:Ward")}
                value={data.ward || ""}
                group={data.district}
                isDependent
                code={parameterCode.WARD_CODE}
              // detected={isEdit}
              />
            </Col>
            <Col sm="12" className="mb-3">
              <CustomAvField
                label={t("common:Address")}
                name="address"
                type="text"
                required
                errorMessage={getInvalidMessageI18n(t, "common:Address")}
                validate={{
                  required: { value: true },
                  maxLength: { value: 100 }
                }}
                value={data.address || ""}
                detected={isEdit}
              />
            </Col>

            <Col sm="12" className="mb-3">
              <CustomCheckbox
                name="isDefault"
                checked={!!data.isDefault}
                direction={"down"}
                label={`${t("partyPage:Is Default")} ?`}
                detected={isEdit}
              />
            </Col>
            <Col sm="12" className="mb-3">
              <CustomCheckbox
                name="active"
                checked={data.active ?? true}
                direction={"down"}
                label={`${t("common:Active")} ?`}
                detected={isEdit}
              />
            </Col>
          </Row>
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        {" "}
        <Check resource={resource} permission={permissionType.R}>
          <CustomButton
            text={t("common:Cancel")}
            type="button"
            onClick={toggle}
            data-dismiss="modal"
            className="button-width"
          />
        </Check>
        <Check
          resource={resource}
          permissions={[permissionType.U, permissionType.C]}
        >
          <CustomButton
            text={t("common:Save")}
            type="submit"
            color="primary"
            onClick={() => formEl?.current?.submit()}
            className="save-user button-width"
            isEdit
            isModal
          />
        </Check>
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["profilePage", "partyPage", "common", "message"])(
  AddressModal
)
