export const createObjFixLine = (
  changeIdx,
  amountLevel,
  mean,
  sd,
  levels,
  levelsChartOption
) => {
  let condition =
    !changeIdx &&
    mean != null &&
    mean != undefined &&
    sd != null &&
    sd != undefined
  let objFixLine = {
    mean: {
      name: `Mean`,
      type: "line",
      value: 0,
      symbol: "none",
      symbolSize: 0,
      lineStyle: { color: "black", width: 1 },
      markPoint: {
        symbol: "rect",
        symbolSize: 1,
        data: [
          {
            type: "max",
            name: "Mean",
            label: {
              show: true,
              position: "left",
              formatter: () => {
                return condition ? `Mean ${mean.toFixed(2)}` : `Mean `
              },
              fontSize: 14,
              color: "black",
            },
          },
        ],
      },
    },
    _3sd: {
      name: `+3SD`,
      type: "line",
      value: 3,
      symbol: "none",
      symbolSize: 0,
      lineStyle: { color: "red", type: "solid", width: 2 },
      markPoint: {
        symbol: "rect",
        symbolSize: 1,
        data: [
          {
            type: "max",
            name: "+3SD",
            label: {
              show: true,
              position: "left",
              formatter: () => {
                return condition
                  ? `+3SD ${(mean + 3 * sd).toFixed(2)}`
                  : `+3SD `
              },
              fontSize: 14,
              color: "black",
            },
          },
        ],
      },
    },
    __3sd: {
      name: `-3SD`,
      type: "line",
      symbolSize: 0,
      value: -3,
      symbol: "none",
      lineStyle: { color: "red", type: "solid", width: 2 },
      markPoint: {
        symbol: "rect",
        symbolSize: 1,
        data: [
          {
            type: "max",
            name: "-3SD",
            label: {
              show: true,
              position: "left",
              formatter: () => {
                return condition
                  ? `-3SD ${(mean - 3 * sd).toFixed(2)}`
                  : `-3SD `
              },
              fontSize: 14,
              color: "black",
            },
          },
        ],
      },
    },
    clone: {
      name: ``,
      type: "line",
      value: -4.5,
      symbol: "none",
      symbolSize: 0,
      lineStyle: { color: "black", type: "solid", width: 1, opacity: 0.2 },
      markPoint: {
        symbol: "rect",
        symbolSize: 1,
        data: [
          {
            type: "max",
            name: "",
            label: {
              show: true,
              position: "left",
              formatter: () => {
                return ``
              },
              fontSize: 14,
              color: "black",
            },
          },
        ],
      },
    },
    _1sd: {
      name: `+1SD`,
      type: "line",
      value: 1,
      symbol: "none",
      symbolSize: 0,
      lineStyle: { color: "black", type: "dashed", width: 1 },
      markPoint: {
        symbol: "rect",
        symbolSize: 1,
        data: [
          {
            type: "max",
            name: "+1SD",
            label: {
              show: true,
              position: "left",
              formatter: () => {
                return condition ? `+1SD ${(mean + sd).toFixed(2)}` : `+1SD `
              },
              fontSize: 14,
              color: "black",
            },
          },
        ],
      },
    },
    __1sd: {
      name: `-1SD`,
      type: "line",
      value: -1,
      symbol: "none",
      symbolSize: 0,
      lineStyle: { color: "black", type: "dashed", width: 1 },
      markPoint: {
        symbol: "rect",
        symbolSize: 1,
        data: [
          {
            type: "max",
            name: "-1SD",
            label: {
              show: true,
              position: "left",
              formatter: () => {
                return condition ? `-1SD ${(mean - sd).toFixed(2)}` : `-1SD `
              },
              fontSize: 14,
              color: "black",
            },
          },
        ],
      },
    },
    _2sd: {
      name: `+2SD`,
      type: "line",
      value: 2,
      symbol: "none",
      symbolSize: 0,
      lineStyle: { color: "black", type: "dotted", width: 1 },
      markPoint: {
        symbol: "rect",
        symbolSize: 1,
        data: [
          {
            type: "max",
            name: "+2SD",
            label: {
              show: true,
              position: "left",
              formatter: () => {
                return condition
                  ? `+2SD ${(mean + 2 * sd).toFixed(2)}`
                  : `+2SD `
              },
              fontSize: 14,
              color: "black",
            },
          },
        ],
      },
    },
    __2sd: {
      name: `-2SD`,
      type: "line",
      value: -2,
      symbol: "none",
      lineStyle: { color: "black", type: "dotted", width: 1 },
      markPoint: {
        symbol: "rect",
        symbolSize: 1,
        data: [
          {
            type: "max",
            name: "-2SD",
            label: {
              show: true,
              position: "left",
              formatter: () => {
                return condition
                  ? `-2SD ${(mean - 2 * sd).toFixed(2)}`
                  : `-2SD `
              },
              fontSize: 14,
              color: "black",
            },
          },
        ],
      },
    },
  }
  return objFixLine
}

export const getIcon = levels => {
  let symbolPoint = "circle"
  switch (levels) {
    case 1:
      symbolPoint = "circle"
      break
    case 2:
      symbolPoint = "triangle"
      break
    case 3:
      symbolPoint = "rect"
      break
    case 4:
      symbolPoint = "diamond"
      break
    case 5:
      symbolPoint = "path://M50,0 L100,38 L81,100 L19,100 L0,38 Z"
      break
    default:
  }
  return symbolPoint
}

export const updateChartTitle = (title, options) => {
  return {
    ...options,
    title: {
      ...options.title,
      text: title, // Gán tiêu đề vào option
      left: "center",
      textStyle: { fontSize: 16, fontWeight: "bold", fontFamily: "Arial" },
    },
  }
}
