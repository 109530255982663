const PROGRESSES = {
  GET_LIST_PROGRESSES: "GET_LIST_PROGRESSES",
  GET_LIST_PROGRESSES_SUCCESS: "GET_LIST_PROGRESSES_SUCCESS",
  GET_LIST_PROGRESSES_FAIL: "GET_LIST_PROGRESSES_FAIL",
  GET_PROGRESSES_DETAIL: "GET_PROGRESSES_DETAIL",
  GET_PROGRESSES_DETAIL_SUCCESS: "GET_PROGRESSES_DETAIL_SUCCESS",
  GET_PROGRESSES_DETAIL_FAIL: "GET_PROGRESSES_DETAIL_FAIL",
  ADD_NEW_PROGRESSES: "ADD_NEW_PROGRESSES",
  ADD_NEW_PROGRESSES_SUCCESS: "ADD_NEW_PROGRESSES_SUCCESS",
  ADD_NEW_PROGRESSES_FAIL: "ADD_NEW_PROGRESSES_FAIL",
  UPDATE_PROGRESSES: "UPDATE_PROGRESSES",
  UPDATE_PROGRESSES_SUCCESS: "UPDATE_PROGRESSES_SUCCESS",
  UPDATE_PROGRESSES_FAIL: "UPDATE_PROGRESSES_FAIL",
  DELETE_PROGRESSES: "DELETE_PROGRESSES",
  DELETE_PROGRESSES_SUCCESS: "DELETE_PROGRESSES_SUCCESS",
  DELETE_PROGRESSES_FAIL: "DELETE_PROGRESSES_FAIL",
}

export default PROGRESSES
