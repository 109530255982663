import React from "react"
import { Col, Row } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"

import { parameterCode, ModuleIds, permissionType } from "constant/utility"

//i18n
import { withTranslation } from "react-i18next"
import { getInvalidMessageI18n } from "helpers/utilities"
import {
  CustomButton,
  CustomButtonGroup,
  CustomSelect,
  CustomAvField,
  CustomCheckbox,
  Check,
} from "components/Common"

const RESOURCE = ModuleIds.ParameterDetail

const EditMode = ({ onValidSubmit, parameter, onCancel, t, isEdit }) => {
  return (
    <Row>
      <Col sm="12">
        <AvForm onValidSubmit={onValidSubmit} model={parameter}>
          <Row>
            <Col sm="6">
              <div className="mb-3">
                <div className="d-none">
                  <CustomAvField
                    name="id"
                    type="hidden"
                    value={parameter.id || ""}
                  />
                </div>
                <CustomAvField
                  name="parameterKey"
                  id="parameterKey2"
                  type="text"
                  required
                  errorMessage={getInvalidMessageI18n(t, "Parameter Code")}
                  validate={{
                    required: { value: true },
                  }}
                  value={parameter.parameterKey || ""}
                  label={t("Parameter Code")}
                  detected={isEdit}
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="mb-3">
                <CustomAvField
                  name="name"
                  type="text"
                  required
                  errorMessage={getInvalidMessageI18n(t, "common:Name")}
                  validate={{
                    required: { value: true },
                  }}
                  value={parameter.name || ""}
                  label={t("common:Name")}
                  detected={isEdit}
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="mb-3">
                <CustomSelect
                  required
                  name="type"
                  errorMessage={getInvalidMessageI18n(t, "Parameter Type")}
                  validate={{
                    required: { value: true },
                  }}
                  value={parameter.type || ""}
                  code={parameterCode.PARAMETER_TYPE}
                  label={t("Parameter Type")}
                  detected={isEdit}
                />
              </div>
            </Col>
            <Col sm="6">
              <div className="mb-3">
                <CustomAvField
                  name="length"
                  type="text"
                  value={parameter.length || ""}
                  label={t("common:Length")}
                  detected={isEdit}
                />
              </div>
            </Col>

            <Col className="col-6">
              <div className="mb-3">
                <CustomAvField
                  name="description"
                  label={t("common:Description")}
                  type="textarea"
                  rows="3"
                  value={parameter.description || ""}
                  detected={isEdit}
                />
              </div>
            </Col>
            <Col className="col-6">
              <div className="mb-3">
                <CustomCheckbox
                  name="isSystem"
                  checked={parameter.isSystem}
                  direction={"down"}
                  label={`${t("common:Is System")} ?`}
                  detected={isEdit}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <CustomButtonGroup className="justify-content-center">
                <Check resource={RESOURCE} permission={permissionType.R}>
                  <CustomButton
                    text={t("common:Cancel")}
                    type="button"
                    onClick={onCancel}
                    className="button-width"
                  />
                </Check>
                <Check resource={RESOURCE} permission={permissionType.C}>
                  <CustomButton
                    text={t("common:Save")}
                    type="submit"
                    color="primary"
                    className="save-user button-width"
                    isEdit
                    isModal
                  />
                </Check>
              </CustomButtonGroup>
            </Col>
          </Row>
        </AvForm>
      </Col>
    </Row>
  )
}

export default withTranslation(["parameterPage", "common", "message"])(EditMode)
