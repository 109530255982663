import {
    TitleAndTable
} from "components/Common"
import { ModuleIds } from "constant"
import {
    getUrlParamByKey,
    getUrlParams
} from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { addMachineQC, deleteMachineQC, updateMachineQC } from "store/connector/instrumentQC/actions"
import { getMachines, resetMachineSearchQuery } from "store/connector/instruments/actions"
import MachineTable from "./MachineTable"
import { exportExcelMachineQC } from "helpers/app-backend/machines_backend_helper"

const RESOURCE = ModuleIds.MachineTestiQC

const Machines = ({
    machines,
    paging,
    onGetMachines,
    onCreateMachineQC,
    onDeleteMachineQC,
    onUpdateMachineQC,
    onResetMachineSearchQuery,
    loadingMachines,
    updateMachineTime,
    t,
}) => {
    const [loading, setLoading] = useState(false)
    const [queryParam, setQueryParam] = useState({ search: "", sort: "", inUse: true, status: 'Active' })

    useEffect(() => {
        let params = getUrlParams();
        let paramPage = getUrlParamByKey("page");
        if (paramPage) {
            onGetMachineList(params)
        } else {
            onGetMachines({ inUse: true, status: 'Active' })
        }
    }, [])

    const onGetMachineList = (payload) => {
        onGetMachines({ ...payload, inUse: true, status: 'Active' })
    }

    const fetchMachines = () => {
        onGetMachineList({ page: 1, inUse: true, status: 'Active' })
    }

    const onRefreshHandler = () => {
        fetchMachines()
    }

    const onSearch = searchText => {
        setQueryParam({ ...queryParam, search: searchText })
        onGetMachineList({ page: 1, search: searchText })
    }

    const onSizePerPageChange = size => {
        onGetMachineList({ page: 1, size })
    }

    const onPageChange = page => {
        onGetMachineList({ page })
    }

    const onSortHandler = (field, order) => {
        const sortString = `${field}:${order}`
        setQueryParam({ ...queryParam, sort: sortString })
        onGetMachineList({ page: 1, sort: sortString })
    }

    const onQCChange = (data, isQc) => {
        data.instrumentID = data.insID
        setLoading(true)
        let params = getUrlParams()
        if (isQc) {
            onCreateMachineQC(data, () => {
                onGetMachineList(params)
                setLoading(false)
            })
        } else {
            onDeleteMachineQC(data, () => {
                onGetMachineList(params)
                setLoading(false)
            })
        }
    }
    const onChangeSIDQC = (data, isChangeSIDQC) => {
        setLoading(true);
        data.instrumentID = data.insID;
        data.isChangeSIDQC = isChangeSIDQC
        let params = getUrlParams()
        onUpdateMachineQC(data,
            () => {
                onGetMachineList(params)
                setLoading(false)
            })
    };
    

    const onExportExcelMachineQC = async () => {
        try {
            const fileData = await exportExcelMachineQC(queryParam)
            const blob = new Blob([fileData])
            saveAs(blob, "Danh_sach_may_QC.xlsx")
        }
        catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        onResetMachineSearchQuery()
    }, [])

    return (
        <React.Fragment>
            {loading &&
                <div className="position-absolute h-100 w-100" style={{ zIndex: 10, backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                    <div className="w-100 h-100"><div className="text-center" style={{ marginTop: 50 }}>
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    </div>
                </div>
            }
            <TitleAndTable
                table={() => (
                    <MachineTable
                        machines={machines}
                        onSearch={onSearch}
                        onSort={onSortHandler}
                        onRefresh={onRefreshHandler}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSizePerPageChange={onSizePerPageChange}
                        loading={loadingMachines}
                        updatedTime={updateMachineTime}
                        onQCChange={onQCChange}
                        onChangeSIDQC={onChangeSIDQC}
                        onExportExcel={onExportExcelMachineQC}
                    />
                )}
                resource={RESOURCE}
                isDisableClone
                isHideEdit
                isDropDown
                title={t("sidebar:MasterData Entry IQC")}
                subtitle={t("Instrument List") + ' QC'}
            />
        </React.Fragment>
    )
}

Machines.propTypes = {
    machines: PropTypes.array,
    onGetMachines: PropTypes.func,
    onUpdateMachine: PropTypes.func,
    onUpdateMachineQC: PropTypes.func,
    onResetMachineSearchQuery: PropTypes.func,
    paging: PropTypes.object,
    loadingMachines: PropTypes.bool,
    updateMachineTime: PropTypes.any,
    t: PropTypes.any,
}

const mapStateToProps = ({ machine }) => {
    return ({
        machines: machine.machines,
        machine: machine.machine,
        paging: machine.paging,
        loadingMachines: machine.loadingMachine,
        updateMachineTime: machine.updateMachineTime,
    })
}

const mapDispatchToProps = dispatch => ({
    onGetMachines: payload => dispatch(getMachines(payload)),
    onCreateMachineQC: (machine, callback) => dispatch(addMachineQC(machine, callback)),
    onDeleteMachineQC: (machine, callback) => dispatch(deleteMachineQC(machine, callback)),
    onResetMachineSearchQuery: () => dispatch(resetMachineSearchQuery()),
    onUpdateMachineQC: (machine, callback) => dispatch(updateMachineQC(machine, callback)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["machinePage", "message", "common"])(Machines)))
