import { Check, CustomBreadcrumb, PageContent } from "components/Common";
import { ModuleIds, permissionType } from "constant";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import CaculationQCTab from "./CalculationQCTab";

const RESOURCE = ModuleIds.CalculationiQC

const CaculationQCContainer = ({ t }) => {
    const titleTag = `Quality Management`
    return (
        <PageContent title={t(titleTag)}>
            <CustomBreadcrumb
                breadcrumbItem={t("sidebar:Calculation iQC")}
                resource={RESOURCE}
            />
            <Check permission={permissionType.R} resource={RESOURCE}>
                <CaculationQCTab />
            </Check>
        </PageContent >
    )
}

CaculationQCContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["calculationQCPage","sidebar"])(CaculationQCContainer)