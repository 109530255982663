import PropTypes from "prop-types"
import { Row } from "reactstrap"
//i18n
import { CustomAvField, CustomSelect } from "components/Common"
import { WESTGARD_TYPE } from "constant"
import { getSigmaFilter } from "helpers/app-backend"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

const FilterForm = ({ model, t, dataSource, levels, config }) => {
    const [sigmaName, setSigmaName] = useState(model._6sigmaName || '')
    const [dataSigmas, setDataSigmas] = useState([])

    useEffect(() => {
        fetchSigmaRules()
    }, [config])


    const fetchSigmaRules = async () => {
        if (config && config.idqc) {
            const query = { lotId: config.idqc, type: WESTGARD_TYPE.WESTGARD_SIGMA, }
            const res = await getSigmaFilter(query)
            let data = []
            if (res && res) {
                data = res.map(_item => {
                    _item.value = _item.id == 0 ? `${_item.code}` : `${_item.id}`
                    _item.label = `${_item.name}`
                    return _item
                })
            }
            setDataSigmas(data)
        }
    }

    return (
        <>
            <Row>
                <CustomAvField name="_6sigmaName" type="hidden" value={sigmaName || ""} />
                <div className="col-12 mb-3">
                    <CustomSelect
                        name="_6sigma"
                        portal
                        value={model._6sigma || ""}
                        // code={parameterCode.QC_LOT_TEST_LIS_SIGMA}
                        // valueNameNotSearch={sigmaName}
                        options={dataSigmas || []}
                        label={t("6 sigma")}
                        maxLength={155}
                        onChange={(name, value, label, item) => {
                            setSigmaName((item.length > 0 && item[0]?.name) || '')
                        }}
                    />
                </div>
                <div className="col-12 mb-3">
                    <CustomSelect
                        label={t("Levels")}
                        name="levels"
                        value={model.levels || ''}
                        options={levels || []}
                    />
                </div>
            </Row>

        </>
    )
}

FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any
}

const mapDispatchToProps = dispatch => ({
})

export default withTranslation(["managementQCPage", "common"])(connect(
    null,
    mapDispatchToProps
)(FilterForm))