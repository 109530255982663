import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../../api_helper2"
const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/vs/example-types`

const createExampleTypes = req => {
    return post(`${BASE_API_URL}`, req)
}

const updateExampleTypes = req => {
  const { id } = req
  return put(`${BASE_API_URL}/${id}`, req)
}

const deleteExampleTypes = req => {
  let ids = ""
  req.forEach(_item => (ids += `id=${_item.id}&`))
  return del(`${BASE_API_URL}?${ids}`)
}

const getExampleTypesList = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_API_URL}?${q}`)
}

const getExampleTypesById = id => {
  return get(`${BASE_API_URL}/${id}`)
}

export {
  createExampleTypes,
  updateExampleTypes,
  deleteExampleTypes,
  getExampleTypesList,
  getExampleTypesById,
}