import EXAMPLE_TYPES from "./actionTypes"

const INIT_STATE = {
  exampleTypes: [],
  exampleTypesDetail: null,
  loading: false,
  loadingDetail: false,
  updating: false,
  updateTime: new Date(),
  error: null,
}

const ExampleTypes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case EXAMPLE_TYPES.GET_LIST_EXAMPLE_TYPES: {
      return { ...state, loading: true, error: {} }
    }
    case EXAMPLE_TYPES.GET_LIST_EXAMPLE_TYPES_SUCCESS: {
      const { data, totalElements, ...rest } = action.payload
      return {
        ...state,
        exampleTypes: data,
        paging: { ...rest, dataSize: totalElements },
        loading: false,
        updatedTime: new Date(),
      }
    }
    case EXAMPLE_TYPES.GET_LIST_EXAMPLE_TYPES_FAIL: {
      return { ...state, loading: false, error: action.payload }
    }
    case EXAMPLE_TYPES.GET_EXAMPLE_TYPES_DETAIL: {
      return { ...state, loadingDetail: true, exampleTypesDetail: {}, error: {} }
    }
    case EXAMPLE_TYPES.GET_EXAMPLE_TYPES_DETAIL_SUCCESS: {
      return { ...state, exampleTypesDetail: action.payload, loadingDetail: false }
    }
    case EXAMPLE_TYPES.GET_EXAMPLE_TYPES_DETAIL_FAIL: {
      return { ...state, loadingDetail: false, error: action.payload }
    }
    case EXAMPLE_TYPES.ADD_NEW_EXAMPLE_TYPES: {
      return { ...state, error: {} }
    }
    case EXAMPLE_TYPES.ADD_NEW_EXAMPLE_TYPES_SUCCESS: {
      return { ...state }
    }
    case EXAMPLE_TYPES.ADD_NEW_EXAMPLE_TYPES_FAIL: {
      return { ...state, error: action.payload }
    }
    case EXAMPLE_TYPES.UPDATE_EXAMPLE_TYPES: {
      return { ...state, updating: true, error: {} }
    }
    case EXAMPLE_TYPES.UPDATE_EXAMPLE_TYPES_SUCCESS: {
      return { ...state, updating: false }
    }
    case EXAMPLE_TYPES.UPDATE_EXAMPLE_TYPES_FAIL: {
      return { ...state, updating: false, error: action.payload }
    }
    case EXAMPLE_TYPES.DELETE_EXAMPLE_TYPES: {
      return { ...state }
    }
    case EXAMPLE_TYPES.DELETE_EXAMPLE_TYPES_SUCCESS: {
      return { ...state }
    }
    case EXAMPLE_TYPES.DELETE_EXAMPLE_TYPES_FAIL: {
      return { ...state, error: action.payload }
    }
    default:
      return state
  }
}

export default ExampleTypes
