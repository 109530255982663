
import { ModalBody, ModalFooter } from "reactstrap"

import {
    CustomButton,
    CustomModal
} from "components/Common"

//i18n
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import InputCalculateInfo from "./InputCalculateInfo"
import { updateCvPeer } from "store/actions"

const AddCVInfoModal = ({
    modal,
    toggle,
    data,
    t,
    onInputInfo,
    onCVPeerUpdate,
    machine
}) => {

    const [cvs, setCVs] = useState(data || [])

    const cvPeer = useSelector(state => state.calculationQC.cvPeer)

    useEffect(() => {
        if (cvPeer.length > 0) {
            data.forEach(item => {
                item.lottests.forEach(x => {
                    x.cv = cvPeer.find(cv => cv.id == x.id)?.cv
                })
            })
            setCVs(data)
        } else {
            data.forEach(item => {
                item.lottests.forEach(x => {
                    x.cv = null
                })
            })
            setCVs(data)
        }
    }, [data])

    const styleTd = (isLast) => {
        if (isLast) {
            return {
                minHeight: '56px',
            }
        } else {
            return {
                borderBottom: '2px solid #f0f2f7',
                minHeight: '56px',
            }
        }

    }

    const columns = [
        {
            dataField: "id",
            text: "",
            hidden: true,
            formatter: (cellContent, item, index) => {
                return cellContent
            },
        },
        {
            dataField: "SEQ",
            text: "#",
            style: { width: "50px", verticalAlign: 'top' },
            formatter: (cellContent, item, index) => {
                return index + 1
            },
        },
        {
            dataField: "insId",
            text: t("common:Instrument"),
            style: { width: "150px", verticalAlign: 'top' },
            formatter: (cellContent, item, index) => {
                return <div className="d-flex flex-column">
                    {machine.label}
                </div >
            },
        },
        {
            dataField: "testCode",
            text: t("Test Code"),
            style: { width: "150px", verticalAlign: 'top' },
        },
        {
            dataField: "testName",
            text: t("Test Name"),
            style: { width: "430px", verticalAlign: 'top' },
        },
        {
            dataField: "levels",
            text: t("Level"),
            style: { width: "100px", padding: "0px" },
            formatter: (cellContent, item, index) => {
                return <div className="d-flex flex-column ">
                    {item?.lottests?.map((x, position) => (
                        <div key={position} className="p-2" style={styleTd(item.lottests.length == position + 1)}>
                            {x.levels}
                            {/* <hr className="my-2" /> */}
                        </div>
                    ))}
                </div>
            },
        },
        {
            dataField: "cv",
            text: t("%CV ngoại kiểm"),
            style: { width: "150px", padding: "0px" },
            formatter: (cellContent, item, index) => {
                return <div className="d-flex flex-column ">
                    {item?.lottests?.map((x, position) => (
                        <div key={position} className="p-2" style={styleTd(item.lottests.length == position + 1)}>
                            <InputCalculateInfo
                                data={x}
                                index={position}
                                defaultValue={x.cv}
                                onChange={(value) => {
                                    cvs[index].lottests[position].cv = parseFloat(value)
                                    setCVs([...cvs])
                                }}
                            />
                        </div>
                    ))}
                </div>
            },
        }
    ]


    return (
        <React.Fragment>
            <CustomModal modal={modal} title={t("Add CV Info")} onToggle={toggle} size="xl">
                <ModalBody>
                    <div className="calculation-table">
                        <CustomBootstrapTableAsync
                            columns={columns}
                            showSelectRow={false}
                            data={cvs}
                            keyField="id"
                            paging={{
                                dataSize: cvs.length
                            }}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <CustomButton
                        text={t("common:Save")}
                        type="buttom"
                        onClick={() => {
                            setCVs([...cvs])
                            toggle()
                            onInputInfo([...cvs])
                            let newCVPeer = []
                            cvs.forEach(item => { newCVPeer = [...newCVPeer, ...item.lottests] })
                            onCVPeerUpdate([...newCVPeer])
                        }}
                        color="primary"
                        className="save-user button-width"
                        isEdit
                    />
                </ModalFooter>
            </CustomModal>
        </React.Fragment>
    )
}

AddCVInfoModal.defaultProps = {

}

const mapDispatchToProps = dispatch => ({
    onCVPeerUpdate: (data) => (dispatch(updateCvPeer(data)))
})

export default connect(
    null,
    mapDispatchToProps
)(withTranslation(["calculationQCPage", "common"])(AddCVInfoModal))
