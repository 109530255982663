import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Label } from "reactstrap"

import AvField from "availity-reactstrap-validation/lib/AvField"
import RollbackButton from "./RollbackButton"
import { useDetectedChanges } from "helpers/hooks"

import Editor from 'react-simple-code-editor';
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism.css";

const CustomJsonInput = props => {
    const {
        required,
        name,
        value,
        onChangeHandler: onChange,
        tooltip,
        label,
        readOnly,
        detected,
    } = props

    const [defaultValue, setDefaultValue] = useState()
    const [valueInput, isChanged] = useDetectedChanges(value, defaultValue,name)

    const onChangeHandler = values => {
        setDefaultValue(values)

        if (onChange) {
            onChange({
                values,
                name,
            })
        }

    }

    const onUndoHandler = () => {
        setDefaultValue(valueInput)
    }

    useEffect(() => {
        setDefaultValue(value)
    }, [value])

    return (
        <>
            <div className="label-group-relative position-relative">
                {label && (
                    <Label for={name}>
                        {label}
                        {required && <span className="text-danger">*</span>}
                    </Label>
                )}
                <RollbackButton
                    display={isChanged && detected}
                    onClick={onUndoHandler}
                />
            </div>
            {/* <NumberFormat
                className="form-control"
                value={defaultValue}
                thousandSeparator={true}
                onValueChange={values => onChangeHandler(values.value)}
                readOnly={readOnly}
                // disabled={disabled}
                placeholder={tooltip}
            /> */}
            {
                defaultValue && <Editor
                    value={defaultValue}
                    onValueChange={(code) => onChangeHandler(code)}
                    highlight={(code) => highlight(code, languages.js)}
                    padding={10}
                    style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 12,
                        border: "1px solid #ced4da",
                        minHeight: "120px",
                        borderRadius: "0.25rem",
                        transition: "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out"
                    }}
                />
            }

            <AvField type="hidden" name={name} value={defaultValue} />
        </>
    )
}

CustomJsonInput.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChangeHandler: PropTypes.func,
    required: PropTypes.bool,
    label: PropTypes.any,
    detected: PropTypes.bool,
    readOnly: PropTypes.bool,
}

export default CustomJsonInput
