import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../../api_helper2"
const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/qc/lot-test`
const BASE_MS_API_URL = `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/global`
const GetLotQCListFromLotTest = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}/GetLotQCList?${q}`)
}
const createQCLotTest = req => {
    return post(`${BASE_API_URL}`, req)
}
const GetQCLotTestList = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}
const updateQCLotTest = req => {
    return post(`${BASE_API_URL}/UpdateQCLotTest`, req)
}
const copyQCLotTest = req => {
    return post(`${BASE_API_URL}/CopyQCLotTest`, req)
}
const updateQCLotTestSigma = req => {
    return post(`${BASE_API_URL}/UpdateQCLotTestSigma`, req)
}
const getRuleSigmaByLotTestId = id => {
    return get(`${BASE_API_URL}/GetRuleSigmaByLotTestId/${id}`)
}
const getInstrumentLogByIdentifier = identifier => {
    return get(`${BASE_MS_API_URL}/auditlog/GetInstrumentLogByIdentifier?identifier=${identifier}`)
}

const getListInstrumentIdByLotId = id => {
    return get(`${BASE_API_URL}/GetListInstrumentIdByLotId?lotId=${id}`)
}

const GetQCLotTestImportResult = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}/GetQCLotTestImportResult?${q}`)
}

const getSigmaFilter = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}/GetSigmaFilter?${q}`)
}

const getCountTestInLot = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}/GetCountTestInLot?${q}`)
}

const exportQCLotTest = (payload) => {
    let q = spreadSearchQuery(payload)
    return post(`${BASE_API_URL}/ExportQCLotTest?${q}`, null, {
        responseType: 'arraybuffer',
        headers:
        {
            'Content-Disposition': "attachment; filename=template.xlsx",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
    })
}

export {
    GetLotQCListFromLotTest,
    createQCLotTest,
    GetQCLotTestList,
    updateQCLotTest,
    copyQCLotTest,
    updateQCLotTestSigma,
    getRuleSigmaByLotTestId,
    getInstrumentLogByIdentifier,
    getListInstrumentIdByLotId,
    GetQCLotTestImportResult,
    getSigmaFilter,
    getCountTestInLot,
    exportQCLotTest
}