import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../../api_helper2"
const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/qc/lot`
const createQCLot = req => {
    return post(`${BASE_API_URL}`, req)
}
const updateQCLotById = req => {
    const { id } = req
    return put(`${BASE_API_URL}/${id}`, req)
}
const deleteQCLotById = req => {
    let ids = ""
    req.forEach(_item => (ids += `id=${_item.id}&`))
    return del(`${BASE_API_URL}?${ids}`)
}
const getQCLotList = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}
const getQCLotById = id => {
    return get(`${BASE_API_URL}/${id}`)
}
const getQCLotListOrigin = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}/GetQCLotListOrigin?${q}`)
}

const GetQCLotInLotTestList = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}/GetQCLotInLotTestList?${q}`)
}

const exportQCLot = (payload) => {
    let q = spreadSearchQuery(payload)
    return post(`${BASE_API_URL}/export-qc-lot?${q}`, null, {
        responseType: 'arraybuffer',
        headers:
        {
            'Content-Disposition': "attachment; filename=ExportUserList.xlsx",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
    })
}

const getQCLotGroup = () => {
    return get(`${BASE_API_URL}/GetQCLotGroup`)
}

const ImportLots = req => {
    return post(`${BASE_API_URL}/ImportLot`, req)
}

export {
    createQCLot,
    deleteQCLotById,
    getQCLotById,
    getQCLotList,
    updateQCLotById,
    getQCLotListOrigin,
    exportQCLot,
    GetQCLotInLotTestList,
    getQCLotGroup,
    ImportLots
}
