import REAGENTS from "./actionTypes"

export const getListReagents = payload => {
  return {
    type: REAGENTS.GET_LIST_REAGENTS,
    payload: payload || {},
  }
}

export const getListReagentsSuccess = payload => ({
  type: REAGENTS.GET_LIST_REAGENTS_SUCCESS,
  payload: payload,
})

export const getListReagentsFail = error => ({
  type: REAGENTS.GET_LIST_FAIL,
  error: error,
})

export const getReagentsDetail = (id, callback) =>({
    type: REAGENTS.GET_REAGENTS_DETAIL,
    id,
    callback,
  }
)

export const getReagentsDetailSuccess = payload => ({
  type: REAGENTS.GET_REAGENTS_DETAIL_SUCCESS,
  payload: payload,
})

export const getReagentsDetailFail = error => ({
  type: REAGENTS.GET_REAGENTS_DETAIL_FAIL,
  payload: error,
})

export const addNewReagents = payload => ({
    type: REAGENTS.ADD_NEW_REAGENTS,
    payload: payload,
  }
)

export const addNewReagentsSuccess = payload => ({
  type: REAGENTS.ADD_NEW_SUCCESS,
  payload: payload,
})

export const addNewReagentsFail = error => ({
  type: REAGENTS.ADD_NEW_FAIL,
  error: error,
})

export const updateReagents = payload => ({
    type: REAGENTS.UPDATE_REAGENTS,
    payload: payload,
  }
)

export const updateReagentsSuccess = payload => ({
  type: REAGENTS.UPDATE_SUCCESS,
  payload: payload,
})

export const updateReagentsFail = error => ({
  type: REAGENTS.UPDATE_FAIL,
  error: error,
})

export const deleteReagents = payload => ({
    type: REAGENTS.DELETE_REAGENTS,
    payload: payload,
  }
)

export const deleteReagentsSuccess = payload => ({
  type: REAGENTS.DELETE_REAGENTS_SUCCESS,
  payload: payload,
})

export const deleteReagentsFail = error => ({
  type: REAGENTS.DELETE_REAGENTS_FAIL,
  error: error,
})
