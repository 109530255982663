import { AvForm } from "availity-reactstrap-validation"
import {
  Accordion,
  AccordionWrapper,
  CustomAvField,
  CustomButton,
  CustomModal,
  CustomSelect,
  CustomSelectAsync
} from "components/Common"
import { parameterCode } from "constant/utility"
import { getCodesByParameterId } from "helpers/app-backend"
import { getInvalidMessageI18n } from "helpers/utilities"
import { isEmpty } from "lodash"
import { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { ModalBody, ModalFooter } from "reactstrap"

const ChangeSIDQCModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  t,
  isClone
}) => {
  const formEl = useRef()
  const [maxLevels, setMaxLevels] = useState(0)
  const [allLevels, setAllLevels] = useState([])
  const [filteredLevels, setFilteredLevels] = useState([])
  //const [lotName, setLotName] = useState("")
  //const [lotId, setLotId] = useState("")
  const [lot, setLot] = useState({ id: "", name: "" })
  const [levelLot, setLevelLot] = useState("")
  const [levels, setLevels] = useState("")
  const [sIDQCChg, setSIDQCChg] = useState("")
  const [instrument, setInstrument] = useState(null)
  const fetchLevels = async () => {
    const query = { size: 0 }
    let res = await getCodesByParameterId(parameterCode.LEVEL_QC, query)
    res = res.filter(r => r.inUse)
    res = res?.map(_item => {
      _item.value = _item.code
      _item.label = _item.message
      return _item
    })
    setAllLevels(res)
  }

  useEffect(() => {
    if (!allLevels?.length) return;
    const threshold = Number(levelLot ?? maxLevels);
    if (isNaN(threshold)) return;
    setFilteredLevels(allLevels.filter(level => Number(level.value) <= threshold));
  }, [allLevels, maxLevels, levelLot, isEdit, modal]);


  useEffect(() => {
    if (data?.sidqcChg) {
      setSIDQCChg(data.sidqcChg);
    }
    if (data?.levels) {
      setLevels(data.levels);
    }
    if (data?.lotName) {
      setLot({ ...lot, name: data?.lotName });
      //setLotName(data.lotName);
    }
    if (data?.insId) {
      setInstrument(data?.insId);
    }
    if (data?.levelLot || data?.levels) {
      setLevelLot(data?.levelLot ?? data?.levels);
    }
  }, [data]);


  useEffect(() => {
    fetchLevels()
  }, [])

  useEffect(() => {
    if (lot.name || levels) {
      setSIDQCChg(`${lot.name}${levels}`);
    }
  }, [lot.name, levels]);

  useEffect(() => {
    if (!isEdit && !isClone) {
      setSIDQCChg('');
    }

    if (modal && !isClone && !isEdit) {
      setLevels('');
    }
  }, [modal, isClone, isEdit]);

  const title =
    (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("Change SID QC")

  if (isEdit && isEmpty(data)) return null
  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody>
        <AvForm
          ref={formEl}
          id="changeSIDForm"
          onValidSubmit={(e, values) => {
            let dataSubmit = {
              id: values.id || "",
              insId: values.insId || null,
              lotId: values.lotId || "",
              sIDQCIn: values.sIDQCIn || "",
              levels: values.levels || "",
              sIDQCChg: sIDQCChg || values.sIDQCChg,
            }
            onValidSubmit(e, dataSubmit)
          }}
          model={data}
        >
          <AccordionWrapper defaultTab={"1"}>
            <Accordion tabId={"1"} title={t("SID QC Information")}>
              <CustomAvField name="id" type="hidden" value={data.id || ""} />
              <div className="row px-3">
                <div className="col-md-6">
                  <div className="mb-3">
                    <CustomSelect
                      required
                      name="insId"
                      value={data.insId || null}
                      showID
                      errorMessage={getInvalidMessageI18n(t, "Instrument")}
                      searchQuery={{ lotId: lot.id || data?.lotId }}
                      code={parameterCode.INSTRUMENT_QC_CHANGESID}
                      label={t("Instrument Name")}
                      detected={isEdit}
                      onChange={(name, value, label, item) => {
                        setInstrument(value)
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <CustomSelect
                      required
                      name="lotId"
                      value={`${data?.lotId || ""}`}
                      errorMessage={getInvalidMessageI18n(t, "Lot Name")}
                      code={parameterCode.LOT_IN_LOT_TEST_QC}
                      label={t("Lot Name")}
                      searchQuery={instrument?.[0] !== undefined ? { instrumentId: instrument } : { instrumentId: data?.insId }}
                      onChange={(name, value, label, item) => {
                        // setLotId(item[0]?.lotId || "")
                        // setLotName(item[0]?.lotName || "")
                        setLot({
                          id: item[0]?.lotId || "",
                          name: item[0]?.lotName || ""
                        })
                        setLevelLot(null)
                        setLevels("")
                        if (item[0]?.levels) {
                          setMaxLevels(item[0]?.levels)
                        }
                        if (item[0]?.levels === undefined || item[0]?.levels === "") {
                          setMaxLevels(item[0]?.levels ?? 5)
                          setSIDQCChg(``)
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <CustomAvField
                      required
                      name="sIDQCIn"
                      type="text"
                      value={`${data.sidqcIn || ""}`}
                      errorMessage={getInvalidMessageI18n(t, "SID QC Instrument")}
                      label={t("SID QC Instrument")}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <CustomSelect
                      label={t("Levels")}
                      name="levels"
                      required
                      isClearable={false}
                      errorMessage={getInvalidMessageI18n(t, "Levels")}
                      value={levels || null}
                      options={filteredLevels}
                      // code={parameterCode.LEVEL_QC}
                      onChange={(name, value, label) => {
                        setLevels(value[0])
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <CustomAvField
                      name="sIDQCChg"
                      type="text"
                      value={sIDQCChg}
                      label={t("SID QC Change")}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </Accordion>
          </AccordionWrapper>
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          className="button-width"
        />
        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          onClick={() => {
            formEl?.current?.submit()
          }}
          className="save-user button-width"
          isEdit
          isModal
        />
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["changeSIDQCPage", "common"])(ChangeSIDQCModal)
