import { AvField } from "availity-reactstrap-validation"
import classnames from "classnames"
import PropTypes from "prop-types"
import {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react"
import Select from "react-select"

import _, { debounce, forEach } from "lodash"


import {
  getAllCompanies,
  getAllDeliveries,
  getAllDepartments,
  getAllIndividuals,
  getAllOrganizations,
  getAllParameters,
  getAllParties,
  getAllPatients,
  getAllPatientsByPatientId,
  getAllPatientsByPatientName,
  getAllPatientsByPatientPhone,
  getAllPatientsByPatientPin,
  getAllPrintersName,
  getAllRoles,
  getAllTestProfiles,
  getAllTests,
  getAllUsers,
  getCodesByParameterId,
  getCodesByParameterIdWithPage,
  getOrganismsGroupList,
  getOrganismsList,
  getQCLotList,
  getQCLotListOrigin,
  GetQCLotTestList,
  getRequestSamples,
  getTestIQCList,
  pingToPrintService
} from "helpers/app-backend"

import { getAllReport } from "helpers/app-backend/reports_backend_helper"
import { useDetectedChanges } from "helpers/hooks"
import { getI18nextLng, isEmptyArray, SelectPopupRatio125 } from "helpers/utilities"

import { InlineEditType, parameterCode, PROFILE_TYPE, ProfileId, RequestPatientCondition, TestProfileStatus, TestProfileTypeText, USER_ROLE, VS_ORGM_GROUP_TYPE, WESTGARD_TYPE } from "constant/utility"
import { getWestgardSigmaQCList } from "helpers/app-backend/IQC/QC_Westgard_Sigma_backend_helper"
import { getAllMachine, getInstrumentTranslatorTestCode } from "helpers/app-backend/machines_backend_helper"
import { getAllFullRequests, getMicrobialOrder } from "helpers/app-backend/testRequest_backend_helper"
import { getPrinters } from "helpers/fakebackend_helper"
import { isEmpty } from "lodash"
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Label } from "reactstrap"
import { RollbackButton } from "."
import { getQCExpressionList } from "helpers/app-backend/IQC/QCExpression_backend_helper"

let valueMultiSelect = [];
const CustomSelectAsync = ({
  value,
  name,
  required,
  errorMessage,
  code,
  group,
  onChange,
  isDependent,
  isMulti,
  portal,
  label,
  detected,
  options,
  readOnly,
  valueName,
  showDefaultAll,
  customSearchQuery,
  placeholder,
  langDefault,
  isWarning,
  valueNameNotSearch,
  isClearable = true,
  isInsurance = false,
  isTestCode = false,
  autoFocus,
  customQuery,
  defaultOption,
  onOptionsChange,
  keyGetValue,
  t
}) => {
  const [defaultValue, setDefaultValue] = useState([])
  const [inputValue, setInputValue] = useState()
  const inputId = new Date().getTime()

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    { loading: false, items: [] }
  )
  const { testProfileTests } = useSelector(state => ({
    testProfileTests: state.testProfile.testProfileTests,
  }))

  const inputRef = useRef()
  const [valueInput, isChanged] = useDetectedChanges(
    value,
    isMulti ? defaultValue : defaultValue?.[0],
    name
  )
  const onUndoHandler = () => {
    onInputChangeHandler(inputValue)
    onChangeHandler(
      isMulti ? (isEmpty(valueInput) ? [] : valueInput) : { value: valueInput }
    )
  }

  const isTouched = inputRef.current?.FormCtrl.isTouched()
  const isDirty = inputRef.current?.FormCtrl.isDirty()
  const isInvalid =
    !readOnly &&
    required &&
    isEmptyArray(defaultValue) &&
    (isTouched || isDirty || isWarning)
  let lang = getI18nextLng()

  // COMMON CODES
  const fetchCodes = async (code, group) => {
    const query = { lang, group }
    const res = await getCodesByParameterId(code, query)
    res?.map(_item => {
      _item.value = _item.code
      _item.label = isInsurance ? _item.code : _item.message
      return _item
    })
    if (isInsurance) {
      if (valueName && valueName !== "" && res.findIndex(x => x.value === value) < 0) {
        res.unshift({
          value: value,
          label: valueName
        })
      }
    }
    return res
  }

  const fetchCodesByParameterId = async (id, text) => {
    const query = { lang: "*", search: text }
    const res = await getCodesByParameterId(id, query)
    res?.map(_item => {
      _item.value = _item.code
      _item.label = `${_item.message} (${_item.code})`
      return _item
    })
    return res
  }

  const fetchCodesByParameterIdWithLanguage = async (id, text) => {
    const query = { lang: 'vi', search: text }
    const res = await getCodesByParameterId(id, query)
    res?.map(_item => {
      _item.value = _item.code
      _item.label = `${_item.message} (${_item.code})`
      return _item
    })
    return res
  }

  const fetchCodesByParameterIdWithLanguagePage = async (id, text) => {
    const query = { lang: 'vi', search: text, size: 15 }
    const res = await getCodesByParameterIdWithPage(id, query)
    const data = res?.data || []
    data.map(_item => {
      _item.value = _item.code
      _item.label = `${_item.message} (${_item.code})`
      return _item
    })
    if (valueName && valueName !== "" && data.findIndex(x => x.value === value) < 0) {
      res.data.unshift({
        value: value,
        label: valueName
      })
    }
    return data
  }

  const fetchTestProfileMapping = async keySearch => {
    if (keySearch === valueName)
      keySearch = ''

    let query = {
      status: TestProfileStatus.ACTIVE,
      search: keySearch,
      size: 15
    }
    if (langDefault) {
      query.lang = langDefault
    }
    const res = await getAllTestProfiles(query)
    const result = []
    if (res.data) {
      res.data.map(_prof =>
        result.push(
          {
            label: `${_prof.profileName} (${_prof.code})`,
            value: _prof.code
          })
      )
    }
    if (valueName && valueName !== "" && result.findIndex(x => x.value === value) < 0) {
      result.unshift({
        value: value,
        label: isInsurance ? `${valueName} (${value})` : valueName
      })
    }

    return result
  }

  const fetchTests = async keySearch => {
    if (keySearch === valueName)
      keySearch = ''
    let query = {
      size: 15,
      search: keySearch,
      inUse: true,
    }
    if (langDefault) {
      query.lang = langDefault
    }
    const res = await getAllTests(query)

    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.testCode
        _item.label = `${_item.testName} (${_item.testCode})`
        return _item
      })
    if (valueName && valueName !== "" && data.findIndex(x => x.value === value) < 0) {
      data.unshift({
        value: value,
        label: isInsurance ? `${valueName} (${value})` : valueName
      })
    }
    return data
  }

  // COMPANIES
  const fetchCompanies = async (value, group) => {
    let query = { size: 5, search: value }
    const res = await getAllCompanies(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = _item.name
        return _item
      })
    return data
  }

  // DEPARTMENTS
  const fetchDepartments = async (value, group) => {
    let query = { size: 5, search: value }
    if (group && group != 'null' && group != 'undefined') {
      query.companyId = group
    }
    const res = await getAllDepartments(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label =
          lang === "vi" ? _item.name : _item.englishName || _item.name
        return _item
      })

    return data
  }

  // ROLES
  const fetchRoles = async () => {
    const query = { size: 100 }
    const res = await getAllRoles(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = _item.roleName
        return _item
      })

    return data
  }

  // USERS
  const fetchUsers = async keySearch => {
    const query = { size: 15, search: keySearch }
    const res = await getAllUsers(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = _item.familyName + " " + _item.givenName
        return _item
      })
    if (valueName && valueName !== "" && data.findIndex(x => x.value === value) < 0) {
      data.unshift({
        value: value,
        label: valueName
      })
    }

    if (valueNameNotSearch != "undefined" && valueNameNotSearch != undefined && valueNameNotSearch !== "" && data.findIndex(x => x.value === value) < 0) {
      data.unshift({
        value: value,
        label: valueNameNotSearch
      })
    }
    const result = [...new Map(data.map(x => [`${x['value']}`, x])).values()];
    return result;
  }
  const fetchUserRolePhisicians = async (keySearch) => {
    const query = { page: 1, size: 0, search: keySearch, role: USER_ROLE.BSXN };
    const res = await getAllUsers(query);
    let data = [];

    if (res.data) {
      data = res.data.map((_item) => {
        _item.value = _item.id;
        _item.label = `${_item.familyName} ${_item.givenName}`;
        return _item;
      });
    }


    if (data.some((x) => x.value === defaultOption?.value)) {
      data.unshift(defaultOption);
    }

    const result = [...new Map(data.map((x) => [`${x['value']}`, x])).values()];
    if (onOptionsChange) {
      const isDefaultOptionPresent = result.some(item => item.value === defaultOption?.value);
      onOptionsChange(result, isDefaultOptionPresent ? defaultOption : null);
    }
    return result;
  };


  // PROFILES
  const fetchParties = async (profileId, value) => {
    const query = { size: 5, profileId, search: value }
    const res = await getAllParties(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = _item.name
        return _item
      })

    return data
  }

  // TESTPROFILES
  const fetchTestProfiles = async keySearch => {
    if (keySearch === valueName)
      keySearch = ''
    const query = {
      size: 100,
      search: keySearch,
      type: TestProfileTypeText.PROFILE,
      status: TestProfileStatus.ACTIVE,
    }
    const res = await getAllTestProfiles(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = _item.profileName
        return _item
      })
    if (valueName && valueName !== "" && data.findIndex(x => x.value === value) < 0) {
      data.unshift({
        value: value,
        label: valueName
      })
    }
    if (showDefaultAll) {
      data.unshift({
        value: 0,
        label: 'All'
      })
    }
    return data
  }

  //tests
  const fetchAllTests = async (keySearch, group) => {
    if (keySearch === valueName)
      keySearch = ''
    if (keySearch && keySearch.indexOf(' - ') >= 0) {
      const tmp = keySearch.split('- ')
      if (tmp)
        keySearch = tmp[1]
    }
    const query = {
      size: 999,
      search: keySearch,
      inUse: true,
      sampleType: group
    }
    const res = await getAllTests(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        if (isTestCode) {
          _item.value = _item.testCode
          _item.label = `${_item.testCode} - ${_item.testName}`
        } else {
          _item.value = _item.id
          _item.label = `${_item.testCode} - ${_item.testName}`
        }
        return _item
      })
    if (valueName && valueName !== "" && data.findIndex(x => x.value === value) < 0) {
      data.unshift({
        value: value,
        label: valueName
      })
    }
    if (showDefaultAll) {
      data.unshift({
        value: 0,
        label: 'All'
      })
    }
    return data
  }

  const fetchAllTestInModalProfile = async (keySearch, group) => {
    if (keySearch === valueName)
      keySearch = ''
    const query = {
      size: 999,
      search: keySearch,
      inUse: true,
      sampleType: group
    }
    const res = await getAllTests(query)
    let data = []
    if (res.data) {
      data = res.data.filter(x => testProfileTests.findIndex(z => z.testId == x.id) < 0).map(_item => {
        _item.value = _item.id
        _item.label = `${_item.testCode} - ${_item.testName}`
        return _item
      })
    }
    if (valueName && valueName !== "" && data.findIndex(x => x.value === value) < 0) {
      data.unshift({
        value: value,
        label: valueName
      })
    }
    if (showDefaultAll) {
      data.unshift({
        value: 0,
        label: 'All'
      })
    }
    return data
  }

  // PHYSICIANS
  const fetchPhysicians = async (keySearch) => {
    // if (keySearch === valueName)
    //   keySearch = ''
    const query = {
      size: 0,
      search: keySearch || '',
      profileId: PROFILE_TYPE.Physician,
    }
    const res = await getAllPatients(query)
    let data = []
    if (res.data) {
      //department
      let arrDepartment = [];
      res.data.map(x => {
        if (x.furtherValue && x.furtherValue != "") {
          const tmp = JSON.parse(x.furtherValue);
          const index = tmp.findIndex(x => x.FieldCode == "Department")
          if (index >= 0) {
            arrDepartment.push(tmp[index]['FurtherValue']);
          }
        }
      });
      let resDepartment = [];
      arrDepartment = arrDepartment.filter(x => !isEmpty(x))
      if (arrDepartment.length > 0) {
        const deptValues = await getAllDepartments({ id: arrDepartment })
        resDepartment = deptValues.data || []
      }
      //company
      const arrCompanyId = res.data.map(x => x.managementCompanyId) || [];
      const arrCompanyIdDistinct = arrCompanyId.filter((x, index) => !arrCompanyId.includes(x, index + 1)) || []
      let resCompany = [];
      if (arrCompanyIdDistinct.length > 0)
        resCompany = await getAllCompanies({ id: arrCompanyIdDistinct })
      data = res.data.map(_item => {
        let name = `${lang === "vi" ? _item.name : _item.englishName || _item.name}`;
        //department
        if (resDepartment.length > 0) {
          const further = JSON.parse(_item.furtherValue)?.find(x => x.FieldCode == 'Department')
          if (further) _item.departmentName = resDepartment?.find(x => `${x.id}` == `${further.FurtherValue}`)?.name
        }
        //company
        _item.managementCompanyId = resCompany.data?.find(x => x.id == _item.managementCompanyId)?.shortName || ''
        if (_item.dobString) {
          name = `${name}`
        }
        let arr = []
        _item.value = _item.id
        if (_item.fields && _item.fields != '[{}]' && _item.fields != '') {
          arr = SortFieldIndividuals(_item)
        }
        arr.unshift(name);
        let label = arr.filter(Boolean).join(' • ')
        _item.label = `${label}`
        return _item
      })
    }

    return data
  }

  const fetchPatientDelivery = async (keySearch) => {
    if (keySearch != null && keySearch != '')
      return fetchPatients(keySearch)
    return []
  }
  // PATIENT
  const fetchPatients = async (keySearch) => {
    // if (keySearch === valueName)
    //   keySearch = ''
    const query = {
      search: keySearch || '',
      profileId: PROFILE_TYPE.PATIENT,
    }
    const res = await getAllPatients(query)
    let data = []
    if (res.data) {
      //department
      let arrDepartment = [];
      res.data.map(x => {
        x.fullAddress = (x.address || "") + " " + (x.wardName ? x.wardName + ", " : "") + (x.districtName ? x.districtName + ", " : "") + (x.provinceName ? x.provinceName + ", " : "")
        if (x.furtherValue && x.furtherValue != "") {
          const tmp = JSON.parse(x.furtherValue);
          const index = tmp.findIndex(x => x.FieldCode == "Department")
          if (index >= 0) {
            arrDepartment.push(tmp[index]['FurtherValue']);
          }
        }
      });
      let resDepartment = [];
      arrDepartment = arrDepartment.filter(x => !isEmpty(x))
      if (arrDepartment.length > 0) {
        const deptValues = await getAllDepartments({ id: arrDepartment })
        resDepartment = deptValues.data || []
      }
      //company
      const arrCompanyId = res.data.map(x => x.managementCompanyId) || [];
      const arrCompanyIdDistinct = arrCompanyId.filter((x, index) => !arrCompanyId.includes(x, index + 1)) || []
      let resCompany = [];
      if (arrCompanyIdDistinct.length > 0)
        resCompany = await getAllCompanies({ id: arrCompanyIdDistinct })
      data = res.data.map(_item => {
        let name = `${lang === "vi" ? _item.name : _item.englishName || _item.name}`;
        //department
        if (resDepartment.length > 0) {
          const further = JSON.parse(_item.furtherValue)?.find(x => x.FieldCode == 'Department')
          if (further) _item.departmentName = resDepartment?.find(x => `${x.id}` == `${further.FurtherValue}`)?.name
        }
        //company
        _item.managementCompanyId = resCompany.data?.find(x => x.id == _item.managementCompanyId)?.shortName || ''
        if (_item.dobString) {
          name = `${name}`
        }
        let arr = []
        _item.value = _item.patientId
        if (_item.fields && _item.fields != '[{}]' && _item.fields != '') {
          arr = SortFieldIndividuals(_item)
        }
        arr.unshift(name);
        let label = arr.filter(Boolean).join(' • ')
        _item.label = `${label}`
        return _item
      })
    }
    return data
  }

  // INDIVIDUALS
  const fetchIndividuals = async (keySearch) => {
    if (keySearch === valueName)
      keySearch = ''
    const query = {
      size: 5,
      search: keySearch,
    }
    const res = await getAllIndividuals(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.patientId
        _item.label =
          lang === "vi" ? _item.name : _item.englishName || _item.name
        return _item
      })

    return data
  }

  const SortFieldIndividuals = (item) => {
    let arr = [];
    let _item = item;
    let fields = JSON.parse(_item.fields) || []
    fields.forEach(eField => {
      let fieldCode = eField.FieldCode;
      let addressFields = [];
      if (_item.addressFields && _item.addressFields != '[{}]' && _item.addressFields != '') {
        addressFields = JSON.parse(_item.addressFields)
      }
      let contactFields = [];
      if (_item.contactFields && _item.contactFields != '[{}]' && _item.contactFields != '') {
        contactFields = JSON.parse(_item.contactFields)
      }
      if (fieldCode.includes("Contact.")) {
      }
      else if (fieldCode.includes("Address.")) {
        if (addressFields.length > 0) {
          const field = fieldCode.split('.')[1]
          if (field?.toUpperCase() == "TYPE") {
            arr.push(addressFields[0]['TypeName'])
          }
          else if (field?.toUpperCase() == "Address") {
            arr.push(_item.fullAddress)
          }
          else {
            if (_item.fullAddress != undefined && _item.fullAddress != null && _item.fullAddress != '') {
              arr.push(_item.fullAddress)
            } else {
              arr.push(addressFields[0][field])
            }
          }
        }
      }
      else {
        if (fieldCode?.toUpperCase() === 'dob'.toUpperCase()) {
          arr.push(_item.dobName)
        }
        else {
          fieldCode = fieldCode.charAt(0).toLowerCase() + fieldCode.slice(1)
          if (_item.hasOwnProperty(fieldCode + "Name")) {
            arr.push(_item[fieldCode + "Name"])
          } else {
            arr.push(_item[fieldCode])
          }
        }
      }
    });
    return arr;
  }

  //parameters
  const fetchAllParametersConnector = async (keySearch, group) => {
    if (keySearch === valueName)
      keySearch = ''
    const query = {
      size: 15,
      search: keySearch,
      type: group
    }
    const res = await getAllParameters(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.parameterKey
        _item.label = `${_item.name}`
        return _item
      })
    if (data.findIndex(x => x.value === value) < 0 && value) {
      data.unshift({
        value: value,
        label: valueName
      })
    }
    return data
  }

  const fetchAllPrinters = async (keySearch, type) => {
    if (keySearch === valueName)
      keySearch = ''
    const query = {
      size: 100,
      search: keySearch,
      type: type,
    }
    const res = await getPrinters(query)
    let data = []
    if (res)
      data = res.map(_item => {
        _item.value = _item.id
        _item.label = _item.name
        return _item
      })
    if (valueName && valueName !== "" && data.findIndex(x => x.value === value) < 0) {
      data.unshift({
        value: value,
        label: valueName
      })
    }
    return data
  }

  const getAllPrinters = async (value, group) => {
    let query = { size: 5, search: value }
    let data = []
    try {
      const getPingPrintService = await pingToPrintService();
      if (getPingPrintService.pingTime) {
        const res = await getAllPrintersName(query)
        if (res)
          data = res.map(_item => ({
            value: _item,
            label: _item
          }))
      } else {
        data = [{ value: "RICOH Aficio MP 6002[0026735D89CB]", label: "RICOH Aficio MP 6002[0026735D89CB]" }, { value: "RICOH Aficio MP 6002 [0026734A491B]", label: "RICOH Aficio MP 6002 [0026734A491B]" }]
      }
    }
    catch {
      data = [{ value: "RICOH Aficio MP 6002[0026735D89CB]", label: "RICOH Aficio MP 6002[0026735D89CB]" }, { value: "RICOH Aficio MP 6002 [0026734A491B]", label: "RICOH Aficio MP 6002 [0026734A491B]" }]
    }
    return data
  }

  const fetchAllReports = async (keySearch, type) => {
    if (keySearch === valueName)
      keySearch = ''
    const query = {
      size: 100,
      search: keySearch,
      type: type,
    }
    const res = await getAllReport(query)

    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = _item.name
        return _item
      })
    if (valueName && valueName !== "" && data.findIndex(x => x.value === value) < 0) {
      data.unshift({
        value: value,
        label: valueName
      })
    }
    return data
  }

  const fetchAllParameters = async (keySearch, group) => {
    if (keySearch === valueName)
      keySearch = ''
    const query = {
      size: 15,
      search: keySearch,
    }

    if (group !== parameterCode.ALL_GROUP) query.type = group

    const res = await getAllParameters(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.parameterKey
        _item.label = `${_item.name} (${_item.parameterKey})`
        return _item
      })
    if (data.findIndex(x => x.value === value) < 0 && value) {
      data.unshift({
        value: value,
        label: valueName
      })
    }
    return data
  }

  const fetchAllMappingCode = async () => {
    const query = {
      size: 100,
      search: 'mapping',
      type: 'M'
    }
    const res = await getAllParameters(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.parameterKey
        _item.label = `${_item.name} (${_item.parameterKey})`
        return _item
      })
    // if (data.findIndex(x => x.value === value) < 0 && value) {
    //   data.unshift({
    //     value: value,
    //     label: valueName
    //   })
    // }
    return data
  }

  const fetchTestRequests = async (keySearch) => {
    if (keySearch === valueName)
      keySearch = ''

    const query = {
      search: keySearch,
      size: 5,
      page: 1
    }

    const res = await getAllFullRequests(query)
    const result = []
    if (res.data) {
      res.data.map(_prof => {
        let sid = _prof.sampleIds ? JSON.parse(_prof.sampleIds) : [];
        let uniqueSids = [...new Set(sid.map(x => x.SID))];
        let sidText = uniqueSids.length > 0 ? ` ${uniqueSids.join(', ')}` : '';
        return result.push(
          {
            label: `${sidText}` + ` (R${_prof.id})`,
            value: _prof.id
          })
      }
      )
    }
    if (valueName != undefined && valueName !== "" && result.findIndex(x => x.value === value) < 0 && value != "") {
      result.unshift({
        value: value,
        label: valueName
      })
    }
    return result
  }

  const fetchMachines = async (keySearch, group) => {
    if (keySearch === valueName)
      keySearch = ''

    const query = {
      protocolId: group,
      search: keySearch,
      size: 15,
      status: 1
    }

    const res = await getAllMachine(query)
    const result = []
    if (res.data) {
      res.data.map(_prof =>
        result.push(
          {
            label: `${_prof.name}`,
            value: _prof.id
          })
      )
    }
    if (!valueName && valueName !== "" && result.findIndex(x => x.value === value) < 0) {
      result.unshift({
        value: value,
        label: valueName
      })
    }

    return result
  }

  const fetchMachinesQC = async (keySearch, group) => {
    if (keySearch === valueName)
      keySearch = ''

    const query = {
      protocolId: group,
      search: keySearch,
      size: 15,
      status: 1
    }

    const res = await getAllMachine(query)
    const result = []
    if (res.data) {
      res.data.map(_prof => {
        if (_prof.instrumentQC && _prof.instrumentQC !== '') {
          result.push(
            {
              label: `${_prof.name}`,
              value: _prof.id
            })
        }
      }
      )
    }
    if (!valueName && valueName !== "" && result.findIndex(x => x.value === value) < 0) {
      result.unshift({
        value: value,
        label: valueName
      })
    }

    return result
  }

  const fetchInstrumentId = async (keySearch, group) => {
    if (keySearch === valueName)
      keySearch = ''

    const query = {
      protocolId: group,
      search: keySearch,
      size: 15,
      status: 1
    }

    const res = await getAllMachine(query)
    const result = []
    if (res.data) {
      res.data.map(_prof =>
        result.push(
          {
            label: `${_prof.name}`,
            value: _prof.insID
          })
      )
    }
    if (!valueName && valueName !== "" && result.findIndex(x => x.value === value) < 0) {
      result.unshift({
        value: value,
        label: valueName
      })
    }

    return result
  }

  const fetchMachineTranslator = async (keySearch, group) => {
    if (keySearch === valueName)
      keySearch = ''

    const query = {
      protocolId: group,
      search: keySearch,
      size: 15,
      status: 1
    }

    const res = await getAllMachine(query)
    const result = []
    if (res.data) {
      res.data.map(_prof =>
        result.push(
          {
            label: `${_prof.name}`,
            value: _prof.insID,
            id: _prof.id,
            code: `${_prof.manageCode}`,
          })
      )
    }
    if (!valueName && valueName !== "" && result.findIndex(x => x.value === value) < 0) {
      result.unshift({
        value: value,
        label: valueName
      })
    }

    return result
  }

  const fetchCollector = async keySearch => {
    if (keySearch === valueName)
      keySearch = ''
    const query = {
      size: 0,
      search: keySearch?.trim(),
    }

    const res = await getAllUsers(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = `${_item.familyName} ${_item.givenName}`
        return _item
      })
    if (valueName && valueName !== "" && data.findIndex(x => `${x.value}` === `${value}`) < 0) {
      data.unshift({
        value: value,
        label: valueName
      })
    }
    return data;
  }

  const fetchSampleForDelivery = async (searchQuery) => {
    const query = { ...customSearchQuery, ...searchQuery }
    const res = await getRequestSamples(query)
    let data = []
    if (res)
      data = res.map(_item => {
        _item.value = _item.sid
        _item.label = `${_item.sid} • ${_item.sampleType} • ${_item.patientName} `
        return _item
      })
    if (valueName && valueName.trim() !== "" && data.findIndex(x => `${x.value}` === `${value}`) < 0) {
      data.unshift({
        value: value,
        label: valueName
      })
    }
    return data
  }

  const fetchAllDeliveryModal = async (searchQuery, id) => {
    const query = { size: 20, search: searchQuery }
    const res = await getAllDeliveries(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = `${_item.deliveryDate}`
        return _item
      })
    data = data.filter(x => `${x.id}` != `${id}`)
    return data
  }

  // COMMON_PHYSICIANS
  const fetchCommonPhysicians = async (keySearch) => {
    if (keySearch === valueName)
      keySearch = ''
    const query = {
      size: 5,
      search: keySearch,
      profileId: PROFILE_TYPE.Physician,
    }

    const res = await getAllIndividuals(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label =
          lang === "vi" ? _item.name : _item.englishName || _item.name
        return _item
      })

    return data
  }

  const fetchAllDelivery = async (searchQuery, id) => {
    let query = { size: 100, ...customSearchQuery, id: _.union(customSearchQuery.id, valueMultiSelect) }
    if (query.id && isNaN(query.id)) {
      delete query.id
    }
    let res = await getAllDeliveries(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = `${_item.deliveryDate}`
        return _item
      })
    if (!searchQuery.id) {
      query = { size: 100, search: searchQuery }
      res = await getAllDeliveries(query)
      let dataWithSearch = []
      if (res.data)
        dataWithSearch = res.data.map(_item => {
          _item.value = _item.id
          _item.label = `${_item.deliveryDate}`
          return _item
        })
      if (dataWithSearch.length > 0)
        data = data.concat(dataWithSearch.filter(x => data.findIndex(z => `${z.id}` == `${x.id}`) < 0))
    }
    let currentId = customSearchQuery.currentId ? customSearchQuery.currentId : 0;
    data = data.filter(x => `${x.id}` != `${id}` && `${x.id}` != `${currentId}`)
    return data
  }

  const fetchPatientsByCondition = async (keySearch, type) => {
    const query = {
      search: keySearch || '',
      profileId: PROFILE_TYPE.PATIENT,
      size: 5
    }
    let res = null;
    if (type == RequestPatientCondition.PatientId) {
      res = await getAllPatientsByPatientId(query)
    } else if (type == RequestPatientCondition.PatientName) {
      res = await getAllPatientsByPatientName(query)
    } else if (type == RequestPatientCondition.Phone) {
      res = await getAllPatientsByPatientPhone(query)
    } else //if (type == RequestPatientCondition.PIN) {
      res = await getAllPatientsByPatientPin(query)

    let data = []
    if (res.data) {
      let arrDepartment = [];
      res.data.map(x => {
        if (x.furtherValue && x.furtherValue != "") {
          const tmp = JSON.parse(x.furtherValue);
          const index = tmp.findIndex(x => x.FieldCode == "Department")
          if (index >= 0) {
            arrDepartment.push(tmp[index]['FurtherValue']);
          }
        }
      });
      let resDepartment = [];
      arrDepartment = arrDepartment.filter(x => !isEmpty(x))
      if (arrDepartment.length > 0) {
        const deptValues = await getAllDepartments({ id: arrDepartment })
        resDepartment = deptValues.data || []
      }
      //company
      const arrCompanyId = res.data.map(x => x.managementCompanyId) || [];
      const arrCompanyIdDistinct = arrCompanyId.filter((x, index) => !arrCompanyId.includes(x, index + 1)) || []
      let resCompany = [];
      if (arrCompanyIdDistinct.length > 0)
        resCompany = await getAllCompanies({ id: arrCompanyIdDistinct })
      data = res.data.map(_item => {
        let name = `${lang === "vi" ? _item.name : _item.englishName || _item.name}`;
        //department
        if (resDepartment.length > 0) {
          const further = JSON.parse(_item.furtherValue)?.find(x => x.FieldCode == 'Department')
          if (further) _item.departmentName = resDepartment?.find(x => `${x.id}` == `${further.FurtherValue}`)?.name
        }
        //company
        _item.managementCompanyId = resCompany.data?.find(x => x.id == _item.managementCompanyId)?.shortName || ''
        if (_item.dobString) {
          name = `${name}`
        }
        let arr = []
        _item.value = _item.patientId
        if (_item.fields && _item.fields != '[{}]' && _item.fields != '') {
          arr = SortFieldIndividuals(_item)
        }
        arr.unshift(name);
        let label = arr.filter(Boolean).join(' • ')
        _item.label = `${label}`
        return _item
      })
    }
    return data
  }

  const fetchGroupPatientOrganizations = async (value) => {
    let query = { size: 15, search: value, profileId: ProfileId.GROUP }
    const res = await getAllOrganizations(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.organizationCode
        _item.label = `${_item.organizationCode} - ${_item.name}`
        return _item
      })

    return data
  }

  const fetchPartnerPatientOrganizations = async (value) => {
    let query = { size: 15, search: value, profileId: ProfileId.PARTNER }
    const res = await getAllOrganizations(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.organizationCode
        _item.label = `${_item.organizationCode} - ${_item.name}`
        return _item
      })

    return data
  }

  const fetchOrganizations = async (value, group) => {
    let query = { size: 5, search: value, managementCompanyId: group, ...customQuery }
    const res = await getAllOrganizations(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = _item.name
        return _item
      })

    return data
  }

  const fetchOrganizationsByCode = async (value, group) => {
    let query = { size: 5, search: value, managementCompanyId: group, ...customQuery }
    const res = await getAllOrganizations(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.organizationCode
        _item.label = _item.name
        return _item
      })

    return data
  }

  const fetchTestProfileCode = async searchQuery => {
    let query = { size: 15, status: 1 }
    if (typeof (searchQuery) === typeof ({})) {
      query = { ...query, ...searchQuery }
    }
    else {
      query = { ...query, search: searchQuery }
    }
    const res = await getAllTestProfiles(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = customSearchQuery?.isId ? _item.id : _item.code
        _item.label = _item.profileName
        return _item
      })
    if (isMulti == true) {
      if (typeof ([]) == typeof (value) && value?.length > 0) {
        let names = valueNameNotSearch?.split(',') || ''
        value.forEach((element, index) => {
          let indexData = data.findIndex(x => `${x.value}` == `${element}`)
          if (indexData < 0 && names.length > index) {
            data.push({ value: element, label: names[index] })
          }
        });
      }
    }
    return data
  }

  const fetchTestCode = async searchQuery => {
    let query = { size: 15, inUse: true }
    if (typeof (searchQuery) === typeof ({})) {
      query = { ...query, ...searchQuery }
    }
    else {
      query = { ...query, search: searchQuery }
    }
    const res = await getAllTests(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.testCode
        _item.label = _item.testCode + " - " + _item.testName
        return _item
      })
    return data
  }

  const fetchTestCodeQC = async searchQuery => {
    let query = { size: 15, inUse: true, isQC: true }
    if (typeof (searchQuery) === typeof ({})) {
      query = { ...query, ...searchQuery }
    }
    else {
      query = { ...query, search: searchQuery }
    }
    const res = await getAllTests(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.testCode
        _item.label = _item.testCode + " - " + _item.testName
        return _item
      })
    return data
  }

  const fetchExpression = async searchQuery => {
    let query = { size: 0, inUse: true }
    if (typeof (searchQuery) === typeof ({})) {
      query = { ...query, ...searchQuery }
    }
    else {
      query = { ...query, search: searchQuery }
    }
    const res = await getQCExpressionList(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.manageCode
        _item.label = _item.formulaName
        return _item
      })
    return data
  }

  const fetchLotQC = async searchQuery => {
    let query = { size: 50, inUse: true, sort: 'createdDate:desc' }
    if (typeof (searchQuery) === typeof ({})) {
      query = { ...query, ...searchQuery }
    }
    const res = await getQCLotList(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.lotId
        _item.label = _item.lotName
        return _item
      })
    return data
  }

  const fetchLotQCCaculation = async searchQuery => {
    if (customSearchQuery && (!customSearchQuery?.insId)) {
      return [];
    }

    let query = { size: 0, inUse: true, sort: 'createdDate:desc' }
    if (typeof (searchQuery) === typeof ({})) {
      query = { ...query, ...searchQuery }
    }
    else {
      query = { ...query, ...customSearchQuery }
    }

    const res = await getQCLotList(query)

    if (searchQuery?.hasPoint) {
      res.data = res.data.filter(x => x.firstPointTime)
    }
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.lotId
        _item.label = _item.lotName
        return _item
      })
    return data
  }

  const fetchTestCodeQCCaculation = async searchQuery => {
    if (customSearchQuery && (!customSearchQuery?.lotId || !customSearchQuery?.insId || !customSearchQuery?.levels)) {
      return [];
    }
    const query = {
      size: 0,
      inUse: true,
      isQC: true,
      isPosNeg: false,
      ...(typeof searchQuery === 'object' ? searchQuery : { search: searchQuery }),
      ...customSearchQuery
    };
    try {
      const res = await GetQCLotTestList(query);
      return Array.isArray(res) ? res.map(item => ({
        value: item.testCode,
        label: `${item.testCode} - ${item.testName}`
      })) : [];
    } catch (error) {
      console.error("Error fetching QC test codes:", error);
      return [];
    }
  }

  const fetchWestgardSigma = async searchQuery => {
    let query = { page: 1, size: 0, inUse: true, type: WESTGARD_TYPE.WESTGARD_RULES }
    if (typeof (searchQuery) === typeof ({})) {
      query = { ...query, ...searchQuery }
    }
    else {
      query = { ...query, search: searchQuery }
    }
    const res = await getWestgardSigmaQCList(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.code
        _item.label = _item.name
        return _item
      })
    return data
  }

  const fetchProfileAndTestCode = async searchQuery => {
    let dataTest = await fetchTestCode(searchQuery)
    let dataProfile = await fetchTestProfileCode(searchQuery)
    let dataTestAndProfile = [...dataTest, ...dataProfile]
    return dataTestAndProfile
  }

  const fetchInstrumentTranslatorTestCodes = async (keySearch, group) => {
    if (keySearch === valueName)
      keySearch = ''

    const query = {
      search: keySearch,
      size: 15
    }
    if (group == undefined || group == null || group == '')
      return []
    const res = await getInstrumentTranslatorTestCode(group, query)
    const result = []
    if (res) {
      res.map(_prof =>
        result.push(
          {
            label: `${_prof.instrumentCode} - ${_prof.testCode} - ${_prof.testName || ''}`,
            value: _prof.instrumentCode
          })
      )
    }
    if (valueNameNotSearch != "undefined - undefined - undefined" && valueNameNotSearch != undefined && valueNameNotSearch !== "" && result.findIndex(x => x.value === value) < 0) {
      result.unshift({
        value: value,
        label: valueNameNotSearch
      })
    }

    return result
  }

  const fetchLaTranslatorTestCodes = async (keySearch, group) => {
    if (keySearch === valueName)
      keySearch = ''

    const query = {
      search: keySearch,
      size: 15
    }
    if (group == undefined || group == null || group == '')
      return []
    const res = await getInstrumentTranslatorTestCode(group, query)
    const result = []
    if (res) {
      res.map(_prof =>
        result.push(
          {
            label: `${_prof.instrumentCode} - ${_prof.testCode} - ${_prof.testName || ''}`,
            value: _prof.instrumentCode
          })
      )
    }

    if (valueNameNotSearch != "undefined" && valueNameNotSearch != undefined && valueNameNotSearch !== "" && result.findIndex(x => x.value === value) < 0) {
      result.unshift({
        value: value,
        label: valueNameNotSearch
      })
    }

    return result
  }

  const fetchQCLotLisFromLotTest = async (searchQuery, group) => {
    let query = { size: 15 }
    query = { ...query, search: searchQuery }
    if (group) {
      query = { ...query, ...group }
      if (group.machine == '-1')
        return []
    }
    const res = await getQCLotListOrigin(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.lotId
        _item.label = _item.lotName
        return _item
      })
    if (valueNameNotSearch != "undefined" && valueNameNotSearch != undefined && valueNameNotSearch !== "" && data.findIndex(x => x.value === value) < 0) {
      data.unshift({
        value: value,
        label: valueNameNotSearch//valueNameNotSearch
      })
    }
    return data
  }

  const fetchQCLotLisFromLotTestWithFullLabel = async searchQuery => {
    let query = { size: 0, inUse: true }
    if (typeof (searchQuery) === typeof ({})) {
      query = { ...query, ...searchQuery }
    }
    else {
      query = { ...query, search: searchQuery, instrumentId: value }
    }
    const res = await getQCLotList(query)
    let data = []
    if (res.data) {
      const now = new Date()
      data = res.data
        .filter(_item => new Date(_item.expireDate) >= now)
        .map(_item => {
          _item.value = _item.lotId
          _item.label = `${_item.lotId} - ${_item.lotName}`
          return _item
        })
    }
    return data
  }


  const fetchQCLotLisFromLotTestNotExpired = async (searchQuery, group) => {
    let query = { size: 15, isExpired: false }
    query = { ...query, search: searchQuery }
    if (group) {
      query = { ...query, machine: group }
      if (group == '-1')
        return []
    }
    const res = await getQCLotListOrigin(query)
    let data = []
    if (res.data)
      data = res.data.map(_item => {
        _item.value = _item.lotId
        _item.label = _item.lotName
        return _item
      })
    if (valueNameNotSearch != "undefined" && valueNameNotSearch != undefined && valueNameNotSearch !== "" && data.findIndex(x => x.value === value) < 0) {
      data.unshift({
        value: value,
        label: valueNameNotSearch//valueNameNotSearch
      })
    }
    return data
  }

  const fetchSigmaRules = async (keySearch) => {
    if (keySearch === valueName)
      keySearch = ''
    const query = { size: 15, inUse: true, type: WESTGARD_TYPE.WESTGARD_SIGMA, search: keySearch, }
    const res = await getWestgardSigmaQCList(query)
    let data = []
    if (res && res.data) {
      data = res.data.map(_item => {
        _item.value = `${_item.id}`
        _item.label = `${_item.name}`
        return _item
      })
    }
    if (valueNameNotSearch != "undefined" && valueNameNotSearch != undefined && valueNameNotSearch !== "" && data.findIndex(x => x.value === value) < 0) {
      data.unshift({
        value: value,
        label: valueNameNotSearch
      })
    }
    return data
  }

  const fetchMicrobialOrder = async searchQuery => {
    let query = {}
    if (typeof (searchQuery) === typeof ({})) {
      query = { ...query, ...searchQuery }
    }

    const res = await getMicrobialOrder(query)
    let data = []
    if (res)
      data = res.map(_item => {
        return {
          value: _item,
          label: _item
        }
      })
    return data
  }
  const fetchOriganismInMapping = async (keySearch, group) => {
    if (keySearch === valueName)
      keySearch = ''
    let query = { size: 15, inUse: true, search: keySearch }
    if (group) {
      query = { ...query, genusCode: group }
    }
    const res = await getOrganismsList(query)
    let data = []
    if (res && res.data) {
      data = res.data.map(_item => {
        _item.value = `${_item.id}`
        _item.label = `${_item.name}`
        if (keyGetValue) {
          _item.value = _item[keyGetValue]
          _item.label = _item.name;
        }
        return _item
      })
    }
    if (valueNameNotSearch != "undefined" && valueNameNotSearch != undefined && valueNameNotSearch !== "" && data.findIndex(x => x.value === value) < 0) {
      data.unshift({
        value: value,
        label: valueNameNotSearch
      })
    }
    return data
  }

  const fetchOriganismsGenus = async (keySearch) => {
    if (keySearch === valueName)
      keySearch = ''
    const query = { size: 15, inUse: true, type: VS_ORGM_GROUP_TYPE.Genus, search: keySearch, }
    const res = await getOrganismsGroupList(query)
    let data = []
    if (res && res.data) {
      data = res.data.map(_item => {
        _item.value = `${_item.id}`
        _item.label = `${_item.name}`
        if (keyGetValue) {
          _item.value = _item[keyGetValue]
          _item.label = _item.name;
        }
        return _item
      })
    }
    if (valueNameNotSearch != "undefined" && valueNameNotSearch != undefined && valueNameNotSearch !== "" && data.findIndex(x => x.value === value) < 0) {
      data.unshift({
        value: value,
        label: valueNameNotSearch
      })
    }
    return data
  }

  const fetchOriganismsGenusMultiSelect = async (keySearch) => {
    if (keySearch === valueName)
      keySearch = ''
    const query = { size: 15, inUse: true, type: VS_ORGM_GROUP_TYPE.Genus, search: keySearch, showSub: false }
    const res = await getOrganismsGroupList(query)
    let data = []
    if (res && res.data) {
      data = res.data.map(_item => {
        _item.value = _item.id
        _item.label = `${_item.name}`
        return _item
      })
    }
    if (value && value.length > 0 && isMulti == true) {
      let ids = value.filter(x => data.findIndex(z => z.value === `${x}`) < 0)
      if (ids.length > 0) {
        const resVals = await getOrganismsGroupList({ size: 0, inUse: true, type: VS_ORGM_GROUP_TYPE.Genus, id: ids })
        if (resVals && resVals.data) {
          resVals.data.forEach(_item => {
            data.unshift({
              value: _item.id,
              label: `${_item.name}`
            })
          });
        }
      }
    }
    return data
  }
  const fetchOriganismsGroupMultiSelect = async (keySearch) => {
    if (keySearch === valueName)
      keySearch = ''
    const query = { size: 15, inUse: true, type: VS_ORGM_GROUP_TYPE.Family, search: keySearch, showSub: false }
    const res = await getOrganismsGroupList(query)
    let data = []
    if (res && res.data) {
      data = res.data.map(_item => {
        _item.value = `${_item.id}`
        _item.label = `${_item.name}`
        return _item
      })
    }
    if (value && value.length > 0 && isMulti == true) {
      let ids = value.filter(x => data.findIndex(z => z.value === `${x}`) < 0)
      if (ids.length > 0) {
        const resVals = await getOrganismsGroupList({ size: 0, inUse: true, type: VS_ORGM_GROUP_TYPE.Family, id: ids })
        if (resVals && resVals.data) {
          resVals.data.forEach(_item => {
            data.unshift({
              value: `${_item.id}`,
              label: `${_item.name}`
            })
          });
        }
      }
    }
    return data
  }

  const fetchOriganismsGroup = async (keySearch) => {
    if (keySearch === valueName)
      keySearch = ''
    const query = { size: 15, inUse: true, type: VS_ORGM_GROUP_TYPE.Family, search: keySearch, }
    const res = await getOrganismsGroupList(query)
    let data = []
    if (res && res.data) {
      data = res.data.map(_item => {
        _item.value = `${_item.id}`
        _item.label = `${_item.name}`
        if (keyGetValue) {
          _item.value = _item[keyGetValue]
          _item.label = _item.name;
        }
        return _item
      })
    }
    if (valueNameNotSearch != "undefined" && valueNameNotSearch != undefined && valueNameNotSearch !== "" && data.findIndex(x => x.value === value) < 0) {
      data.unshift({
        value: value,
        label: valueNameNotSearch
      })
    }
    return data
  }

  const fetchOptions = useCallback(async (code, group, value) => {
    setState({ loading: true })
    let res = []
    if (code === parameterCode.COMPANIES) {
      res = await fetchCompanies(value, group)
    } else if (code === parameterCode.ORGANIZATIONS) {
      res = await fetchOrganizations(value, group)
    } else if (code === parameterCode.ORGANIZATIONS_CODE) {
      res = await fetchOrganizationsByCode(value, group)
    } else if (code === parameterCode.DEPARTMENTS) {
      res = await fetchDepartments(value, group)
    } else if (code === parameterCode.ROLES) {
      res = await fetchRoles()
    } else if (code === parameterCode.USERS_ROLE_PHYSICIAN) {
      res = await fetchUserRolePhisicians(value)
    } else if (code === parameterCode.USERS) {
      res = await fetchUsers(value)
    } else if (code === parameterCode.TESTPROFILE) {
      res = await fetchTestProfiles(value)
    } else if (code === parameterCode.TESTPROFILE_TEST) {
      res = await fetchAllTests(value, group)
    }
    else if (code === parameterCode.TESTPROFILE_TEST_NOT_IN_LIST) {
      res = await fetchAllTestInModalProfile(value, group)
    } else if (code === parameterCode.HISCONNECTOR) {
      res = await fetchAllParametersConnector(value, group)
    } else if (code === parameterCode.ALL_PARAMETER) {
      res = await fetchAllParameters(value, group)
    }
    else if (code === parameterCode.CONNECTOR_MAPPING_CODE) {
      res = await fetchAllMappingCode()
    }
    else if (code === parameterCode.PHYSICIANS) {
      res = await fetchPhysicians(value)
    } else if (code === parameterCode.GROUP_PATIENT_ORGANIZATION) {
      res = await fetchGroupPatientOrganizations(value)
    } else if (code === parameterCode.PARTNER_ORGANIZATION) {
      res = await fetchPartnerPatientOrganizations(value)
    } else if (code === parameterCode.INDIVIDUALS) {
      res = await fetchIndividuals(value)
    } else if (code === parameterCode.COMMON_PHYSICIANS) {
      res = await fetchCommonPhysicians(value)
    } else if (code === parameterCode.PATIENTS) {
      res = await fetchPatients(value)
    } else if (code === parameterCode.PATIENTS_BY_CONDITION) {
      res = await fetchPatientsByCondition(value, group)
    } else if (code === parameterCode.PATIENTS_DELIVERY) {
      res = await fetchPatientDelivery(value)
    } else if (group === parameterCode.HIS_MAPPING_KEY_WITH_LANG) {
      res = await fetchCodesByParameterIdWithLanguage(code, value)
    } else if (group === parameterCode.HIS_MAPPING_KEY_WITH_LANG_PAGE) {
      res = await fetchCodesByParameterIdWithLanguagePage(code, value)
    } else if (group === parameterCode.HIS_MAPPING_KEY) {
      res = await fetchCodesByParameterId(code, value)
    } else if (code === parameterCode.LIS_MAPPING_KEY) {
      res = await fetchTests(value)
    } else if (code === parameterCode.SAMPLES_FOR_DELIVERY) {
      res = await fetchSampleForDelivery(value)
    } else if (code === parameterCode.LIS_PROFILE_MAPPING_KEY) {
      res = await fetchTestProfileMapping(value)
    } else if (code === parameterCode.DELIVERY) {
      res = await fetchAllDelivery(value, group)
    } else if (code === parameterCode.DELIVERY_MODAL) {
      res = await fetchAllDeliveryModal(value, group)
    } else if (code === parameterCode.INSTRUMENT_TRANSLATOR_TESTCODE) {
      res = await fetchInstrumentTranslatorTestCodes(value, group)
    } else if (code === parameterCode.INSTRUMENT_LA_TESTCODE) {
      res = await fetchLaTranslatorTestCodes(value, group)
    } else if (code === parameterCode.INSTRUMENT) {
      res = await fetchMachines(value, group)
    } else if (code === parameterCode.INSTRUMENT_QC) {
      res = await fetchMachinesQC(value, group)
    } else if (code === parameterCode.INSTRUMENT_ID) {
      res = await fetchInstrumentId(value, group)
    } else if (code === parameterCode.INSTRUMENT_TRANSLATOR) {
      res = await fetchMachineTranslator(value, group)
    } else if (code === parameterCode.TEST_REQUEST_LIST) {
      res = await fetchTestRequests(value)
    } else if (code === InlineEditType.TESTREQUEST_SAMPLE_COLLECTOR) {
      res = await fetchCollector(value, group)
    } else if (code === parameterCode.REPORT_TEMPLATE) {
      res = await fetchAllReports(value, group)
    } else if (code === parameterCode.PROFILEANDTEST_CODE) {
      res = await fetchProfileAndTestCode(value)
    } else if (code === parameterCode.WESTGARD_SIGMA) {
      res = await fetchWestgardSigma(value)
    } else if (code === parameterCode.QC_LOT_LIST_FROM_LOT_TEST) {
      res = await fetchQCLotLisFromLotTest(value, group)
    } else if (code === parameterCode.QC_LOT_LIST_FROM_LOT_TEST_FULL_LABEL) {
      res = await fetchQCLotLisFromLotTestWithFullLabel(value)
    } else if (code === parameterCode.QC_LOT_LIST_FROM_LOT_TEST_NOT_EXPIRED) {
      res = await fetchQCLotLisFromLotTestNotExpired(value, group)
    } else if (code === parameterCode.QC_LOT_TEST_LIS_SIGMA) {
      res = await fetchSigmaRules(value)
    } else if (code === parameterCode.PRINTER) {
      res = await getAllPrinters(value, group)
    } else if (code === parameterCode.TESTPROFILE_CODE) {
      res = await fetchTestProfileCode(value)
    } else if (code === parameterCode.TEST_CODE) {
      res = await fetchTestCode(value)
    } else if (code === parameterCode.TEST_CODE_QC) {
      res = await fetchTestCodeQC(value)
    } else if (code === parameterCode.EXPRESSION_IQC) {
      res = await fetchExpression(value)
    } else if (code === parameterCode.VS_ORGANISM_IN_MAPPING) {
      res = await fetchOriganismInMapping(value, group)
    } else if (code === parameterCode.VS_ORGM_GENUS) {
      res = await fetchOriganismsGenus(value)
    } else if (code === parameterCode.VS_ORGM_GENUS_MULTI_SELECT) {
      res = await fetchOriganismsGenusMultiSelect(value)
    } else if (code === parameterCode.VS_ORGM_GROUP) {
      res = await fetchOriganismsGroup(value)
    } else if (code === parameterCode.VS_ORGM_GROUP_MULTI_SELECT) {
      res = await fetchOriganismsGroupMultiSelect(value)
    } else if (code === parameterCode.LOT_QC) {
      res = await fetchLotQC(value)
    }
    else if (code === parameterCode.LOT_QC_CALCULATION) {
      res = await fetchLotQCCaculation(value)
    }
    else if (code === parameterCode.TEST_CODE_QC_CALCULATION) {
      res = await fetchTestCodeQCCaculation(value)
    }
    else if (code === parameterCode.MICROBIAL_ORDER) {
      res = await fetchMicrobialOrder(value)
    }
    else if (code.indexOf("_") >= 0 || code === parameterCode.PROFILE) {
      const profileId = code.substring(code.indexOf("_") + 1)
      if (profileId > 0) res = await fetchParties(profileId, value)
      else res = await fetchParties(undefined, value)
    } else {
      res = await fetchCodes(code, group)
    }

    // setItems(data)
    setState({ items: res, loading: false })
  }, [customSearchQuery])

  const onChangeHandler = (e, b) => {
    const element = document.getElementById(name + "" + inputId)
    var event = new Event("change", { bubbles: true })

    const value =
      (isMulti
        ? (Array.isArray(e) ? e?.map(_value => {
          return _value.value
        }) : e?.split(','))
        : [e?.value]) || []
    element.value = value || ""
    element.dispatchEvent(event)
    setDefaultValue(value || "")
    valueMultiSelect = value
    onChange(name, value, state.items?.find(x => `${x.value}` == `${value}`)?.label, state.items?.filter(x => value.includes(x.value) || `${x.value}` == `${value}`))
  }

  const isValidStyle = isInvalid && {
    borderColor: "#f46a6a !important",
    backgroundImage: `url(
    "data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23f46a6a'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='%23f46a6a' stroke='none'/></svg>"
  )`,
    backgroundPosition: "right 2.75rem center, center right 0.5rem",
    backgroundSize: "17px 18px, calc(0.75em + 0.47rem) calc(0.75em + 0.47rem)",
    backgroundRepeat: "no-repeat",
  }

  const colourStyles = {
    control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isDisabled ? "#edf1f2 !important" : "white",
      fontSize: "13px",
      cursor: isDisabled ? "not-allowed" : "default",
      ...isValidStyle,
      // ...isVaild,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      return {
        ...styles,
        fontSize: "13px",
        // color: "black",
        // backgroundColor: isDisabled ? null : isSelected ? data.color : null,
        cursor: isDisabled ? "not-allowed" : "default",
      }
    },
    singleValue: (provided, state) => {
      const opacity =
        state.isDisabled || !state.data.value || state.data.value === "0"
          ? 0.7
          : 1
      const transition = "opacity 300ms"

      return { ...provided, opacity, transition }
    },

    menuPortal: provided => ({
      ...provided,
      zIndex: 9999,
    }),
    menu: provided => ({ ...provided, zIndex: 9999 }),
  }

  const onInputChangeHandler = debounce(value => {
    if (value?.length > 0 && code && (!isDependent || (isDependent && group))) {
      if (options && options.length > 0) {
        //to do
      }
      else {
        setState({ loading: true })
        fetchOptions(code, group, value)
      }
    }
  }, 600)

  useEffect(() => {
    if (code && isDependent && group) fetchOptions(code, group)
    else setState({ items: [] })
  }, [code, group, isDependent])

  useEffect(() => {
    onInputChangeHandler(inputValue)
  }, [inputValue, code, group, isDependent])

  useEffect(() => {
    setDefaultValue(isMulti ? value : [value])
    setInputValue(valueName)
  }, [value, valueName])

  const CustomCheckboxOption = ({ innerProps, label, ...rest }) => {
    return (
      <div {...innerProps} >
        <div className="check-option px-2" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'space-between', minHeight: '35px', alignItems: 'center' }}>
          <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
            <span>{label}</span>
            {rest?.data?.type == TestProfileTypeText.TEST &&
              <div className="px-2 d-flex badge-test">
                <span style={{ fontSize: '10px' }}>Test</span>
              </div>
            }
            {rest?.data?.type === TestProfileTypeText.PROFILE &&
              <div className="px-2 d-flex badge-profile">
                <span style={{ fontSize: '10px' }}>Profile</span>
              </div>
            }
            {/* {rest?.data?.type === TestProfileTypeText.GROUP &&
              <div className="px-2 d-flex badge-group">
                <span style={{ fontSize: '10px' }}>Group</span>
              </div>
            } */}
          </div>
        </div>
      </div >)
  };
  useEffect(() => {
    if (!isEmpty(options)) {
      if (valueName && valueName !== "" && options.findIndex(x => x.value === value) < 0) {
        options.unshift({
          value: value,
          label: valueName
        })
      }
      setState({ items: options })
    }
  }, [options])
  useEffect(() => {
    if (code)
      fetchOptions(code, group, customSearchQuery)
    else setState({ items: [] })
  }, [customSearchQuery])

  return (
    <>
      <div className="label-group-relative position-relative">
        {label && (
          <Label for={name}>
            {label}
            {required && <span className="text-danger">*</span>}
          </Label>
        )}

        <RollbackButton
          display={isChanged && detected}
          onClick={onUndoHandler}
        />
      </div>
      <Select
        autoFocus={autoFocus}
        isClearable={isClearable}
        placeholder={placeholder || ''}
        isLoading={state.loading}
        onMenuOpen={() => {
          SelectPopupRatio125('.select2-selection__menu-portal')
        }}
        name="select-customasync"
        openMenuOnFocus
        id={name + "" + "select-customasync"}
        onInputChange={onInputChangeHandler}
        noOptionsMessage={() => t("No options")}
        loadingMessage={() => t("Loading...")}
        menuPlacement="auto"
        maxMenuHeight={150}
        menuPortalTarget={!portal && document.body}
        styles={colourStyles}
        isDisabled={readOnly}
        isMulti={isMulti}
        value={state.items.filter(
          _item =>
            defaultValue?.findIndex(
              _defaultValue =>
                JSON.stringify(_defaultValue + "") ===
                JSON.stringify(_item.value + "")
            ) >= 0
        )}
        onChange={onChangeHandler}
        options={state.items}
        components={{
          Option: ({ innerProps, isSelected, ...rest }) => (<CustomCheckboxOption {...rest} innerProps={innerProps} />)
        }}
        classNamePrefix="select2-selection"
        className={classnames(
          { "has-changed": isChanged && detected },
          "form-select2 is-touched is-dirty av-invalid is-invalid"
        )}
      />
      {isInvalid && (
        <div className="text-danger form-group">
          <div className="is-touched is-dirty av-invalid is-invalid"></div>
          <div className="invalid-feedback">{errorMessage}</div>
        </div>
      )}
      <div className="d-none">
        <AvField
          multiple={isMulti}
          name={name}
          id={name + "" + inputId}
          type="select"
          className={`form-select`}
          value={isMulti ? defaultValue : defaultValue?.[0]}
          errorMessage={errorMessage}
          required={!readOnly && required}
          ref={inputRef}
          readOnly={readOnly}
        >
          <option value="">--Select--</option>
          {state.items.map((_item, idx) => (
            <option key={idx} value={_item.value}>
              {_item.label}
            </option>
          ))}
        </AvField>
      </div>
    </>
  )
}

CustomSelectAsync.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  isDependent: PropTypes.bool,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  value: PropTypes.any,
  valueName: PropTypes.string.isRequired,
  group: PropTypes.string,
  code: PropTypes.string,
  isMulti: PropTypes.bool,
  portal: PropTypes.bool,
  label: PropTypes.any.isRequired,
  detected: PropTypes.bool,
  options: PropTypes.array,
  readOnly: PropTypes.bool,
  showDefaultAll: PropTypes.bool,
  t: PropTypes.any,
  defaultOption: PropTypes.any,
  onOptionsChange: PropTypes.any
}

CustomSelectAsync.defaultProps = {
  name: "",
  onChange: () => { },
  isDependent: false,
  errorMessage: "This field is invalid",
  portal: false,
  valueName: ""
}

export default withTranslation(["common"])(CustomSelectAsync)
