import { CustomBootstrapTable } from "components/Common"
import BadgeLabel from "components/Common/BadgeLabel"
import { ModuleIds, RESULT_STATE } from "constant"
import { convertDateIOSToDateFormat, indexCalculator } from "helpers/utilities"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import FilterForm from "./FilterForm"

const RESOURCE = ModuleIds.LoginHistory

const LoginHistoryTable = ({
  onSelect,
  onSelectAll,
  loginHistories,
  onSearch,
  onSort,
  onRefresh,
  onPageChange,
  paging,
  onSizePerPageChange,
  onSubmitFilter,
  loading,
  t,
}) => {
  const [data, setData] = useState([])
  const [model, setModel] = useState({
    search: "",
  })
  const searchQuery = useSelector(state => state.loginHistory.searchQuery)
  const onResetHandler = () => {
    const initModel = {
      search: "",
    }
    setModel(initModel)
  }

  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])

  useEffect(() => {
    setData(loginHistories)
  }, [loginHistories])

  const columns = [
    {
      dataField: "Id",
      text: "#",
      hidden: true,
      formatter: (cellContent, loginHistory, index) => {
        return cellContent
      },
    },
    {
      dataField: "SEQ",
      text: "#",
      headerStyle: { width: "30px" },
      formatter: (cellContent, loginHistory, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "userName",
      text: t("User Name"),
      formatter: (cellContent, loginHistory, index) => {
        return <Link to={`/User/${loginHistory.id}/view?tab=1`} target="_blank">{`${loginHistory.userName}`}</Link>
      },
    },
    {
      dataField: "role",
      text: t("Role"),
    },
    {
      dataField: "physician",
      text: t("User IP"),
      formatter: (cellContent, loginHistory, index) => {
        const getRandomNumber = () => Math.floor(Math.random() * 256);
        const ipAddress = `${getRandomNumber()}.${getRandomNumber()}.${getRandomNumber()}.${getRandomNumber()}`;
        return ipAddress
      },
    },
    {
      dataField: "loginTime",
      text: t("Login Time"),
      formatter: (cellContent, loginHistory, index) => {
        return convertDateIOSToDateFormat(cellContent, "DD-MM-yyyy HH:mm:ss")
      },
    },
    {
      dataField: "expirationTime",
      text: t("Expiration Time"),
      formatter: (cellContent, loginHistory, index) => {
        return convertDateIOSToDateFormat(cellContent, "DD-MM-yyyy HH:mm:ss")
      },
    },
    {
      dataField: "state",
      headerStyle: { width: "120px" },
      sort: true,
      text: t("Status"),
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, loginHistory, index) => {
        const loginTime = convertDateIOSToDateFormat(loginHistory.loginTime, "yyyy-MM-DD HH:mm:ss");
        const expirationTime = convertDateIOSToDateFormat(loginHistory.expirationTime, "yyyy-MM-DD HH:mm:ss");
        const now = convertDateIOSToDateFormat(new Date().now, "yyyy-MM-DD HH:mm:ss");
        return (
          <BadgeLabel
            value={t(`${(loginTime > expirationTime || expirationTime < now) ? "Expired" : "Logged In"}`)}
            type={(loginTime > expirationTime || expirationTime < now) ? RESULT_STATE[0] : RESULT_STATE[5]}
            className="badge-width"
          />
        )
      },
    },
  ]

  return (
    <CustomBootstrapTable
      columns={columns}
      search
      data={data}
      showSelectRow={false}
      paging={paging}
      onSelect={onSelect}
      onSelectAll={onSelectAll}
      onSearch={onSearch}
      onSort={onSort}
      onRefresh={onRefresh}
      filterForm={() => <FilterForm model={model} />}
      onReset={onResetHandler}
      onPageChange={onPageChange}
      onSizePerPageChange={onSizePerPageChange}
      onSubmitFilter={onSubmitFilter}
      searchText={model.search}
      loading={loading}
      resource={RESOURCE}
      keyField="Id"
    />
  )
}

export default withTranslation(["loginHistoryPage"])(LoginHistoryTable)
