import { AvForm } from "availity-reactstrap-validation"
import { CustomSelectAsync } from "components/Common"
import { parameterCode } from "constant"
import { useEffect, useState } from "react"

const MicrobialOrder = ({
    data,
    defaultValue,
    index,
    onMicrobialOrderChange
}) => {

    const [info, setInfo] = useState('')

    useEffect(() => {
        if (defaultValue) {
            setInfo(defaultValue)
        }
    }, [defaultValue])

    return (
        <div
            onClick={e => {
                document.getElementById(`sttvs-${index}`).style.display = ""
                document.getElementById(`sttvs-${index}`).focus()
                document.getElementById(`sttvs-text-${index}`).style.display = "none"
            }}
        >
            <a
                href="javascript:void(0)"
                id={`sttvs-text-${index}`}
            >{info || '---'}</a>
            <AvForm>
                <div id={`sttvs-${index}`}
                    style={{ display: 'none' }}
                    onBlur={() => {
                        document.getElementById(`sttvs-${index}`).style.display = "none"
                        document.getElementById(`sttvs-text-${index}`).style.display = ""
                    }}
                >
                    <CustomSelectAsync
                        autoFocus
                        isClearable={false}
                        name="sttvs"
                        id={`sttvs-${index}`}
                        value={info || ''}
                        code={parameterCode.MICROBIAL_ORDER}
                        customSearchQuery={{ pid: data.patientId }}
                        onChange={(name, value) => {
                            if (value[0] != info) {
                                setInfo(value[0])
                                onMicrobialOrderChange(value[0])
                            }
                            document.getElementById(`sttvs-${index}`).style.display = "none"
                            document.getElementById(`sttvs-text-${index}`).style.display = ""
                        }}
                    />
                </div>
            </AvForm>
        </div>
    )
}

MicrobialOrder.defaultProps = {

}

export default MicrobialOrder
