import { AvForm } from "availity-reactstrap-validation"
import { Check, CustomSelect, FilterButton } from "components/Common"
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"
import CustomPopover from "components/Common/Input/CustomPopover"
import { InlineEditType, ModuleIds, permissionType } from "constant"
import { getListInstrumentIdByLotId } from "helpers/app-backend"
import { getAllMachine } from "helpers/app-backend/machines_backend_helper"
import { convertDateFormat, roundTo, selectCheckboxHandler } from "helpers/utilities"
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import Editable from "react-bootstrap-editable"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import FilterForm from "./FilterForm"
import ConfigSigmaModal from "./Modal/ConfigSigmaModal"
import HistoryLotTestModal from "./Modal/HistoryLotTestModal"
import ExportQCLotTestButton from "./Export/index"

const RESOURCE = ModuleIds.ManagementQC

let itemActiveGlobal = {}
let pageGlobal = 0
let dataGlobal = []
let rowsGlobal = []
let dataOrigin = []
const ConfigLotTestDetailTable = forwardRef(
    ({
        data,
        onSort,
        onSearch,
        onRefresh,
        itemActive,
        updatedTime,
        loadingTests,
        model,
        t,
        loadingLotTests,
        onResetFilter,
        onSubmitSigma,
        config,
        onChangeData,
        onChangeRows,
        onChangeDeleteRows
    }, ref) => {
        const size = 50;
        const [dataSource, setDataSource] = useState([])
        const [page, setPage] = useState(0)
        const [row, setRow] = useState({})
        const [rows, setRows] = useState([])
        const [rowDeletes, setRowDeletes] = useState([])
        const [modalHistory, setModalHistory] = useState(false)
        const [selectedRow, setSelectedRow] = useState({})
        const [dataMachine, setDataMachine] = useState([])
        const [dataMachineDisplay, setDataMachineDisplay] = useState([])
        const [dataLevelsDisplay, setDataLevelsDisplay] = useState([])
        const RESOURCE = ModuleIds.LotTestQC
        useImperativeHandle(ref, () => ({
            onGetData: () => {
                return {
                    data: dataGlobal.filter(x => rowsGlobal.map(z => z.id).includes(x.id)) || [],
                    deletes: rowDeletes || []
                }
            },
        }))

        useEffect(() => {
            fetchMachines()
        }, [])


        useEffect(() => {
            setRow({})
            setRows([])
            setRowDeletes([])
            setDataSource(data)
            dataOrigin = JSON.parse(JSON.stringify(data))
        }, [data])
        const [elEnd, setElEnd] = useState(document.getElementById(`watch_end_of_document`))
        // let elEnd = document.getElementById(`watch_end_of_document`)
        useEffect(() => {
            dataGlobal = dataSource
            if (elEnd == undefined || elEnd == null) {
                setElEnd(document.getElementById(`watch_end_of_document`))
                setTimeout(() => {
                    try {
                        io.observe(document.getElementById(`watch_end_of_document`));
                    } catch (e) { }
                }, 500);
            }
        }, [dataSource])

        useEffect(() => {
            rowsGlobal = rows
            onChangeRows(rows)
        }, [rows])
        useEffect(() => {
            onChangeDeleteRows(rowDeletes)
        }, [rowDeletes])
        useEffect(() => {
            getIns()

        }, [config])

        const getIns = async () => {
            let res = await getListInstrumentIdByLotId(config.lotId || '')
            if (res && res.instrumentIds.length > 0) {
                let tmp = dataMachine;
                tmp = tmp.filter(x => res.instrumentIds.includes(x.insID))
                setDataMachineDisplay(tmp)
            }
            else {
                setDataMachineDisplay([])
            }

            if (res && res.levels.length > 0) {
                setDataLevelsDisplay(res.levels.map(x => ({ value: x, label: x })))
            }
            else {
                setDataLevelsDisplay([])
            }
        }


        const fetchMachines = async () => {
            const query = { size: 0 }
            const res = await getAllMachine(query)
            let data = []
            if (res.data) {
                data = res.data.map(_item => {
                    _item.value = _item.insID
                    _item.label = _item.name
                    return _item
                })
            }
            setDataMachine(data)
        }
        const checkAndTab = (e, idHTML, key) => {
            if (e.key === 'Enter' || e.key === "Tab") {
                const fields =
                    Array.from(document.getElementsByClassName(key)) ||
                    []
                const position = fields.findIndex(x => x.id == `${idHTML}`)
                if (position >= 0) {
                    setTimeout(() => {
                        let el = fields[position + 1]
                        if (el) {
                            const tmpClick = $(`#${el.id} form a`)
                            if (tmpClick && tmpClick.length > 0)
                                tmpClick[0].click()
                        }
                    }, 0);

                }
            }
        }

        const arrNum = ['mean', 'sd', 'deCOdd', 'meanPI', 'sdpi', 'meanPeer', 'tEa', 'temperature', 'lowI', 'highI']
        const arrNumPos = ['mean', 'sd', 'deCOdd', 'meanPI', 'tEa', 'temperature']
        const renderEditField = (item, key, maxLength, isNotEmpty, fieldName) => {
            const idHTML = `qcLotTestBlurItemFocus${item.id}-${key}`
            return <React.Fragment>
                <div
                    className={"qcLotTestBlurItemFocus"}
                    id={idHTML}
                    onKeyDown={e => {
                        checkAndTab(e, idHTML, 'qcLotTestBlurItemFocus')
                        if (key == 'temperature') {
                            if (!/[0-9.]/.test(e.key) && !["Backspace", "ArrowLeft", "ArrowRight", "Delete"].includes(e.key)) {
                                e.preventDefault();
                            }
                        }
                    }}
                    style={{ width: '100%', margin: "0 auto" }}
                    onClick={e => {
                        e.stopPropagation()
                    }}
                    onBlur={() => {
                        $(`#${item.id}-${key}`).click()
                    }}
                >
                    <Editable
                        alwaysEditing={false}
                        disabled={false}
                        editText={(item[key] != null && `${item[key]}` != '') ? `${item[key]}` : "---"}
                        initialValue={(item[key] != null && `${item[key]}` != '') ? `${item[key]}` : ""}
                        id={item[key]}
                        isValueClickable={false}
                        mode="inline"
                        placement="top"
                        showText={false}
                        type="textfield"
                        renderConfirmElement={confirmElement(`${item.id}-${key}`)}
                        renderCancelElement={cancelElement}
                        onSubmit={(value) => {
                            if ((value == null || value == '') && isNotEmpty) {
                                return
                            }
                            if (maxLength) {
                                if (value.length > maxLength) {
                                    return
                                }
                            }
                            if (arrNum.includes(key)) { //xử lý số
                                if (Number(value) || value == '' || value == '0') {
                                    if (item[key] != value && !(item[key] == null && value == '')) {
                                        if (arrNumPos.includes(key)) {
                                            if (Number(value) >= 0)
                                                onSubmitRule(item, value, [key])
                                        } else {
                                            onSubmitRule(item, value, [key])
                                        }
                                    }
                                }
                            }
                            else {
                                if (item[key] != value && !(item[key] == null && value == ''))
                                    onSubmitRule(item, value, [key])
                            }
                        }}
                        validate={(value) => {
                            if ((value == null || value == '') && isNotEmpty) {
                                return `${t("message:InvalidError", { field: `${fieldName}` })}`
                            }
                            if (maxLength) {
                                if (value.length > maxLength) {
                                    return t("Max length is") + ` ${maxLength}`
                                }
                            }
                            if (arrNum.includes(key)) {
                                if (!Number(value) && value != '' && value != '0') {
                                    return t("Numbers only");
                                }
                                else {
                                    if (arrNumPos.includes(key) && Number(value) < 0) {
                                        return t("Positive numbers only");
                                    }
                                }
                            }

                        }}
                    />
                </div>
            </React.Fragment>
        }

        const onDelete = (item) => {
            //thêm row delete
            let dels = rowDeletes || []
            if (!dels.includes(x => x.id == item.id)) {
                dels.push(item)
                setRowDeletes(dels);
            }

            //update data source
            let tmp = dataGlobal;
            tmp = tmp.filter(x => x.id !== item.id)
            setDataSource([...tmp])

            //update data selected nếu có
            let tmpSelected = rowsGlobal;
            tmpSelected = tmpSelected.filter(x => x.id !== item.id)
            setRows([...tmpSelected])

        }


        let columns = [
            {
                dataField: "",
                text: "#",
                classes: 'stt-col-sticky',
                style: { borderLeft: '1px solid #F0F2F7', minWidth: 60, maxWidth: 60, with: 60 },
                headerStyle: { borderLeft: '1px solid #F0F2F7', minWidth: 60, maxWidth: 60, with: 60 },
                headerClasses: 'stt-col-header-sticky',
                formatter: (cellContent, item, index) => {
                    return index + 1
                }
            },
            {
                dataField: "testCode",
                text: t("Test Code"),
                classes: 'testCode-col-sticky',
                headerClasses: 'testCode-col-header-sticky',
                style: { minWidth: 100, maxWidth: 100, with: 100 },
                headerStyle: { minWidth: 100, maxWidth: 100, with: 100 },
                formatter: (cellContent, item, index) => {
                    if (item.id == -1)
                        return <span id={`watch_end_of_document`}>End of document</span>
                    else
                        return <span>{cellContent}</span>
                }
            },
            {
                dataField: "testName",
                text: t("Test Name"),
                classes: 'testName-col-sticky',
                headerClasses: 'testName-col-header-sticky',
                style: { minWidth: 250, maxWidth: 250, with: 250 },
                headerStyle: { minWidth: 250, maxWidth: 250, with: 250 },
            },
            {
                dataField: "machineName",
                text: t("Machine"),
                headerStyle: { minWidth: 100, maxWidth: 100 },
                style: { minWidth: 100, maxWidth: 100 },
            },
            {
                dataField: "unit",
                text: t("Unit"),
                headerStyle: { minWidth: 80, maxWidth: 80 },
                style: { minWidth: 80, maxWidth: 80 },
            },
            {
                dataField: "levels",
                text: t("Levels"),
                headerStyle: { minWidth: 80, maxWidth: 80 },
            },
            {
                dataField: "mean",
                text: t("TB (Mean)"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 120, maxWidth: 120 },
                // events: {
                //     onClick: () => { },
                // },
                formatter: (cellContent, item, index) => {
                    return renderEditField(item, 'mean', null, item.isPosNeg != true, t("TB (Mean)"))
                },
            },
            {
                dataField: "sd",
                text: t("Độ lệch chuẩn (SD)"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 120, maxWidth: 120 },
                // events: {
                //     onClick: () => { },
                // },
                formatter: (cellContent, item, index) => {
                    return renderEditField(item, 'sd', null, item.isPosNeg != true, t("Độ lệch chuẩn (SD)"))
                },
            },
            {
                dataField: "deCOdd",
                text: t("Odd"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 120, maxWidth: 120 },
                // events: {
                //     onClick: () => { },
                // },
                formatter: (cellContent, item, index) => {
                    return renderEditField(item, 'deCOdd')
                },
            },
            {
                dataField: "meanPI",
                text: t("Mean NSX"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 120, maxWidth: 120 },
                // events: {
                //     onClick: () => { },
                // },
                formatter: (cellContent, item, index) => {
                    return renderEditField(item, 'meanPI', null, item.isPosNeg != true, t("Mean NSX"))
                },
            },
            {
                dataField: "sdpi",
                text: t("SD NSX"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 120, maxWidth: 120 },
                // events: {
                //     onClick: () => { },
                // },
                formatter: (cellContent, item, index) => {
                    return renderEditField(item, 'sdpi', null, item.isPosNeg != true, t("SD NSX"))
                },
            },
            {
                dataField: "meanPeer",
                text: t("Mean Peer"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 120, maxWidth: 120 },
                // events: {
                //     onClick: () => { },
                // },
                formatter: (cellContent, item, index) => {
                    return renderEditField(item, 'meanPeer')
                },
            },
            {
                dataField: "tEa",
                text: t("TEA (%)"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 120, maxWidth: 120 },
                // events: {
                //     onClick: () => { },
                // },
                formatter: (cellContent, item, index) => {
                    return renderEditField(item, 'tEa')
                },
            },
            {
                dataField: "_6Sigma",
                text: t("6 Sigma"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 120, maxWidth: 120 },
                formatter: (cellContent, item, index) => {
                    return <CustomPopover
                        code={InlineEditType.QC_LOT_TEST_LIS_SIGMA}
                        title={item._6SigmaCode ? `${item._6SigmaName || ''}` : ''}
                        zIndex={99}
                        type={'selectasync'}
                        valueName={item._6SigmaName}
                        value={`${item._6Sigma}`}
                        onChange={(value, detail) => {
                            if (item['_6Sigma'] != value[0] && !(item['_6Sigma'] == null && value[0] == '')) {
                                onSubmitRule(item, value[0], '_6Sigma')
                                onSubmitRule(item, (detail.length > 0 && detail[0]?.name) || '', '_6SigmaName')
                                onSubmitRule(item, (detail.length > 0 && detail[0]?.code) || '', '_6SigmaCode')
                            }
                        }}
                    />
                },
            },
            {
                dataField: "lowI",
                text: t("LowI"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 120, maxWidth: 120 },
                // events: {
                //     onClick: () => { },
                // },
                formatter: (cellContent, item, index) => {
                    if (item.isPosNeg == true)
                        return renderEditField(item, 'lowI', null, item.isPosNeg == true, t("LowI"))
                    return <></>
                },
            },
            {
                dataField: "highI",
                text: t("HighI"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 120, maxWidth: 120 },
                // events: {
                //     onClick: () => { },
                // },
                formatter: (cellContent, item, index) => {
                    if (item.isPosNeg == true)
                        return renderEditField(item, 'highI', null, item.isPosNeg == true, t("LowI"))
                    return <></>
                },
            },
            {
                dataField: "temperature",
                text: t("Temperature"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 120, maxWidth: 120 },
                // events: {
                //     onClick: () => { },
                // },
                formatter: (cellContent, item, index) => {
                    return renderEditField(item, 'temperature', 10)
                },
            },
            {
                dataField: "eval",
                text: t("Eval"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 150, maxWidth: 150 },
                // events: {
                //     onClick: () => { },
                // },
                formatter: (cellContent, item, index) => {
                    if (item.isPosNeg == true)
                        return renderEditField(item, 'eval', 25, item.isPosNeg == true, t("Eval"))
                    return <></>
                },
            },
            {
                dataField: "method",
                text: t("Method"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { minWidth: 150, maxWidth: 150 },
                // events: {
                //     onClick: () => { },
                // },
                formatter: (cellContent, item, index) => {
                    return renderEditField(item, 'method')
                },
            },
            {
                dataField: "isPosNeg",
                text: t("IsPosNeg"),
                headerStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
                style: { minWidth: 80, maxWidth: 80, textAlign: 'center' },
                formatter: (cellContent, item, index) => {
                    return <span>{item.isPosNeg == true && <i className="fa fa-check-circle text-success"></i>}</span>
                },
            },
            {
                dataField: "createdByName",
                text: t("User Created"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { whiteSpace: 'nowrap' },
                formatter: (cellContent, item, index) => {
                    return <span>{cellContent}</span>
                },
            },
            {
                dataField: "createdDate",
                text: t("Date Created"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { whiteSpace: 'nowrap' },
                formatter: (cellContent, item, index) => {
                    return <span>{convertDateFormat(cellContent, "HH:mm DD-MM-YYYY")}</span>
                },
            },
            {
                dataField: "updatedByName",
                text: t("User Updated"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { whiteSpace: 'nowrap' },
                formatter: (cellContent, item, index) => {
                    return <span>{cellContent}</span>
                },
            },
            {
                dataField: "updatedDate",
                text: t("Date Updated"),
                headerStyle: { whiteSpace: 'nowrap' },
                style: { whiteSpace: 'nowrap' },
                formatter: (cellContent, item, index) => {
                    return <span>{convertDateFormat(cellContent, "HH:mm DD-MM-YYYY")}</span>
                },
            },
            {
                dataField: "action",
                text: "",
                classes: 'status-col-sticky',
                headerClasses: 'status-col-header-sticky',
                style: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 50, minWidth: 50, textAlign: 'center' },
                headerStyle: { width: "50px", margin: 0, paddingLeft: "0px", paddingRight: "0px", maxWidth: 50, minWidth: 50, textAlign: 'center' },
                formatter: (cellContent, item, index) => (
                    <div className="d-flex align-items-center">
                        <Check permission={permissionType.History} resource={RESOURCE}>
                            <span style={{ marginRight: 5 }} onClick={() => {
                                setSelectedRow(item)
                                setModalHistory(true)
                            }}>
                                <i className="fas fa-history font-size-14 me-1" />
                            </span>
                        </Check>
                        <Check permission={permissionType.D} resource={RESOURCE}>
                            <span onClick={() => {
                                onDelete(item)
                                onChangeData && onChangeData(true)
                            }}>
                                <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />
                            </span>
                        </Check>
                    </div>
                ),
            },
        ]

        const confirmElement = id => (
            <button
                id={id}
                type="submit"
                className="btn btn-outline-primary editable-submit btn-sm ms-2 me-1 d-none"
            >
                <i className="mdi mdi-check"></i>
            </button>
        )

        /** Cancel button */
        const cancelElement = (
            <button
                type="button"
                className="btn btn-outline-primary editable-cancel btn-sm me-3 d-none"
            >
                <i className="mdi mdi-close"></i>
            </button>
        )

        const onSubmitRule = (item, value, name) => {
            if (name.length > 0 && (name[0] == 'mean' || name[0] == 'sd' || name[0] == 'meanPI' || name[0] == 'sdpi')) {
                value = roundTo(value, `${item.deCOdd}`)
            }
            if (name.length > 0 && name[0] == 'deCOdd') {
                if (item.mean) {
                    item.mean = roundTo(item.mean, `${value}`)
                    item.sd = roundTo(item.sd, `${value}`)
                    item.meanPI = roundTo(item.meanPI, `${value}`)
                    item.sdpi = roundTo(item.sdpi, `${value}`)
                }
            }
            item[name] = `${value}`
            const dataReplace = dataGlobal.find(obj => obj.id === item.id)
            Object.assign(dataReplace, item)
            // onGetTestsSuccess(data)
            setDataSource([...dataGlobal])
            onChangeData && onChangeData(true)
        }


        useEffect(() => {
            itemActiveGlobal = itemActive
            //reset table
            let el = document.getElementById("resizeMeAsync")
            if (el) {
                el.scrollIntoView(true)
                setTimeout(() => {
                    setPage(0)
                    pageGlobal = 0
                }, 100);
            }
        }, [itemActive])

        const io = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (!entry.isIntersecting) {
                    return;
                }
                pageGlobal++;
                setPage(pageGlobal)
            })
        })

        useEffect(() => {
            if (elEnd) {
                io.observe(elEnd);
            }
        }, [elEnd])

        const getDataDisplay = () => {
            if (dataSource?.length > 0) {
                let res = [...dataSource].slice(0, size * (page + 1))
                res.push({ id: -1, testCode: '' })
                return res
            }
        }

        const onSelectCheckbox = (row, isSelected) => {
            const { rowsState, currentRow } = selectCheckboxHandler(
                rows,
                row,
                isSelected
            )
            setRows(rowsState)
            setRow(currentRow)
        }

        const onSelectAllCheckbox = (rows, isSelected) => {
            if (isSelected !== undefined) {
                if (rows.length < 1) {
                    setRow({})
                    setRows([])
                }
                else {
                    setRows(dataSource)
                    setRow(dataSource[dataSource.length - 1])
                }
            }
        }

        return (
            <React.Fragment>
                <div style={{ backgroundColor: 'white' }} className={`table-right-qc-lot-test table-right-qc-lot-test-checkbox-change table-expand-custom ${dataSource.length === 0 ? '' : 'table-rule-management'}`}>
                    {loadingTests &&
                        <div className="position-absolute" style={{ width: '98%', height: '93%', zIndex: 10, backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                            <div className="w-100 h-100"><div className="text-center">
                                <div className="spinner-border text-primary" role="status" style={{ marginTop: 150 }}>
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                            </div>
                        </div>
                    }
                    <CustomBootstrapTableAsync
                        updatedTime={updatedTime}
                        columns={columns}
                        search
                        loading={loadingLotTests}
                        isEnableLoadInfo={true}
                        data={getDataDisplay()}
                        onSort={onSort}
                        keyField="id"
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        searchText={model.search}
                        onSearch={(val) => onSearch({ search: val || '' })}
                        resource={RESOURCE}
                        onRefresh={onRefresh}
                        striped={true}
                        isScrollable={true}
                        showSelectRow={true}
                        isHover={false}
                        placeholder={t("Search by Test Code, Name")}
                        isEnableExport={true}
                        isImportExportButton={true}
                        importExportForm={() => <ExportQCLotTestButton model={model} />}
                        paging={{
                            page: page == 0 ? 1 : page,
                            dataSize: dataSource.length,
                            size: size,
                            totalPages: dataSource.length / size
                        }}
                        customButtonRight={() => (
                            <ConfigSigmaModal onSubmitSigma={onSubmitSigma}
                                data={(dataOrigin || []).find(x => x.id == row.id) || {}}
                                rows={rows}
                            // row={(dataOrigin || []).find(x => x.id == row.id) || {}} 
                            />
                        )}
                        customButtonTable={() =>
                            <>
                                <AvForm>
                                    <div style={{ width: '200px', marginLeft: '4px' }}>
                                        <CustomSelect
                                            name="machine"
                                            label={""}
                                            onChange={(e, vals) => {
                                                onSearch({ insId: vals[0] || '' })
                                            }}
                                            placeholder={t("Machine")}
                                            value={model.insId || ''}
                                            // code={parameterCode.INSTRUMENT}
                                            options={dataMachineDisplay || []}
                                        />
                                    </div>
                                </AvForm>
                                <FilterButton
                                    disableToggle={true}
                                    onReset={() => {
                                        onResetFilter()
                                    }}
                                    onSubmit={(e, vals) => {
                                        onSearch(vals)
                                    }}
                                >
                                    <FilterForm model={model} levels={dataLevelsDisplay || []} dataSource={dataSource || []}
                                        config={config} />
                                </FilterButton>
                            </>
                        }
                    />
                </div>
                <HistoryLotTestModal
                    modal={modalHistory}
                    toggle={() => { setModalHistory(prev => !prev) }}
                    data={selectedRow || {}}
                    config={config}
                />

            </React.Fragment>
        )
    })

const mapStateToProps = ({ lotQCs }) => ({
    loadingLotTests: lotQCs.loadingLotTests,
})

const mapDispatchToProps = dispatch => ({
    // onGetTestsSuccess: payload => dispatch(getFullTestSuccess(payload)),
    // onGetPositionScroll: payload => dispatch(getPositionScroll(payload)),
    // onUpdateNormalRangeSub: (payload, callback) => dispatch(updateNormalRangeSub(payload, callback)),
})

ConfigLotTestDetailTable.displayName = 'ConfigLotTestDetailTable';
export default withTranslation(["managementQCPage", "common", "message"], { withRef: true })(connect(
    mapStateToProps,
    mapDispatchToProps, null, { forwardRef: true }
)(ConfigLotTestDetailTable))
