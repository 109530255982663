import { ButtonDropdownGroup, Check, CustomButton } from "components/Common"
import { permissionType } from "constant"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
const HeaderButtons = ({ resource, onChangeSIDQCClick, onReloadClick, data, isChangeSIDQC, t }) => {
    const isSIDQC = true;
    const [dataChange, setDataToChange] = useState(true)

    const isEmptyObject = (obj) => {
        return Object.values(obj).every(value => value == null || value === "");
    };

    useEffect(() => {

        setDataToChange(isEmptyObject(data))

    }, [data])

    return (
        <>
            <ButtonDropdownGroup onDeleteClick={() => { }} onCloneClick={() => { }}>
                {/* <Check permission={permissionType.U} resource={resource}>
                    <CustomButton color="primary" onClick={onReloadClick} outline>
                        {t("qcResultPage:Reload")}
                    </CustomButton>
                </Check> */}
                <Check permission={permissionType.C} resource={resource}>
                    <CustomButton
                        color="primary"
                        onClick={() => onChangeSIDQCClick(isSIDQC)}
                        outline
                        disabled={dataChange || isChangeSIDQC}
                    >
                        {t("resultPage:Change SID QC")}
                    </CustomButton>
                </Check>
            </ButtonDropdownGroup>
        </>
    )
}

HeaderButtons.propTypes = {
    resource: PropTypes.string.isRequired,
    onReloadClick: PropTypes.func.isRequired,
}

export default withTranslation(["common", "qcResultPage"])(HeaderButtons)
