import { showToast } from "components/Common"
import {
  createReagents,
  getReagentsList,
  getReagentsById,
  updateReagents,
  deleteReagents,
} from "helpers/app-backend/VS/VSReagents_backend_helper"
import i18n from "i18n"
import { call, put, takeEvery } from "redux-saga/effects"
import REAGENTS from "./actionTypes"
import {
  addNewReagentsFail,
  addNewReagentsSuccess,
  deleteReagentsFail,
  deleteReagentsSuccess,
  getListReagentsFail,
  getListReagentsSuccess,
  getReagentsDetailFail,
  getReagentsDetailSuccess,
  updateReagentsFail,
  updateReagentsSuccess,
} from "./actions"
const t = (msg, param) => i18n.t(msg, param)

function* fetchGetListReagents({ payload }) {
  try {
    let response = yield call(getReagentsList, payload)
    yield put(getListReagentsSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getListReagentsFail(error))
  }
}

function* fetchReagentsDetail({ id, callback }) {
  console.log("🚀 ~ function*fetchReagentsDetail ~ callback:", callback)
  try {
    const response = yield call(getReagentsById, id)
    yield put(getReagentsDetailSuccess(response))
    callback && callback(response)
  } catch (error) {
    console.log(error)
    yield put(getReagentsDetailFail(error))
  }
}

function* onAddNewReagents({ payload: { reagents, callback } }) {
  try {
    const response = yield call(createReagents, reagents)
    yield put(addNewReagentsSuccess(reagents))
    showToast(
      `${t("message:CreatedMessage", {
        field: `${t("")} <span class='text-decoration-underline fw-bold'>${
          reagents["code"]
        }</span>`,
      })}`
    )

    callback && callback()
  } catch (error) {
    console.log(error)
    yield put(addNewReagentsFail(error))
  }
}

function* onUpdateReagents({ payload: { reagents, callback } }) {
  try {
    yield call(updateReagents, reagents)
    yield put(updateReagentsSuccess(reagents))
    showToast(`${t("message:UpdatedMessage")}`)
    callback && callback()
  } catch (error) {
    console.log(error)
    yield put(updateReagentsFail(error))
  }
}

function* onDeleteReagents({ payload: { reagents, callback } }) {
  try {
    yield call(deleteReagents, reagents)
    yield put(deleteReagentsSuccess())
    showToast(`${t("message:DeletedMessage")}`)
    callback && callback()
  } catch (error) {
    yield put(deleteReagentsFail(error))
  }
}

function* ReagentsSaga() {
  yield takeEvery(REAGENTS.GET_LIST_REAGENTS, fetchGetListReagents)
  yield takeEvery(REAGENTS.GET_REAGENTS_DETAIL, fetchReagentsDetail)
  yield takeEvery(REAGENTS.ADD_NEW_REAGENTS, onAddNewReagents)
  yield takeEvery(REAGENTS.UPDATE_REAGENTS, onUpdateReagents)
  yield takeEvery(REAGENTS.DELETE_REAGENTS, onDeleteReagents)
}

export default ReagentsSaga
