const REAGENTS = {
  GET_LIST_REAGENTS: "GET_LIST_REAGENTS",
  GET_LIST_REAGENTS_SUCCESS: "GET_LIST_REAGENTS_SUCCESS",
  GET_LIST_FAIL: "GET_LIST_REAGENTS_FAIL",
  GET_REAGENTS_DETAIL: "GET_REAGENTS_DETAIL",
  GET_REAGENTS_DETAIL_SUCCESS: "GET_REAGENTS_DETAIL_SUCCESS",
  GET_REAGENTS_DETAIL_FAIL: "GET_REAGENTS_DETAIL_FAIL",
  ADD_NEW_REAGENTS: "ADD_NEW_REAGENTS",
  ADD_NEW_SUCCESS: "ADD_NEW_REAGENTS_SUCCESS",
  ADD_NEW_FAIL: "ADD_NEW_REAGENTS_FAIL",
  UPDATE_REAGENTS: "UPDATE_REAGENTS",
  UPDATE_SUCCESS: "UPDATE_REAGENTS_SUCCESS",
  UPDATE_FAIL: "UPDATE_REAGENTS_FAIL",
  DELETE_REAGENTS: "DELETE_REAGENTS",
  DELETE_REAGENTS_SUCCESS: "DELETE_REAGENTS_SUCCESS",
  DELETE_REAGENTS_FAIL: "DELETE_REAGENTS_FAIL",
}

export default REAGENTS
