import React, { useEffect, useState } from "react"
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation"
import {
  ModalBody,
  ModalFooter,
  Row,
  Col,
  Label,
} from "reactstrap"

import CustomModal from "components/Common/Modals/CustomModal"
import { CustomSelect } from "components/Common/Input"
import { parameterCode, ModuleIds, permissionType } from "constant/utility"

//i18n
import { withTranslation } from "react-i18next"
import { getInvalidMessageI18n } from "helpers/utilities"
import {
  CustomButton,
  CustomAvField,
  CustomCheckbox,
  Check,
} from "components/Common"

const RESOURCE = ModuleIds.Profile

const FieldModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  formEl,
  t,
  profileId,
}) => {
  const title =
    (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("Field")
  const [fieldName, setFieldName] = useState(data.fieldName || '');
  const [typeName, setTypeName] = useState(data.typeName || '');
  useEffect(() => {
    setFieldName(data.fieldName || '');
    setTypeName(data.typeName || '');
  }, [data])
  const submit = (e, values) => {
    values.fieldName = fieldName;
    values.typeName = typeName;
    onValidSubmit(e, values)
  }
  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody>
        <AvForm
          id="parameterForm"
          onValidSubmit={submit}
          ref={formEl}
          model={data}
        >
          <Row className="mb-3">
            <div className="d-none">
              <CustomAvField
                name="profileId"
                type="hidden"
                value={profileId || ""}
              />
              <CustomAvField name="id" type="hidden" value={data.id || ""} />
            </div>
            <Col sm="6" className="mb-3">
              <CustomSelect
                label={t("Field Name")}
                name="fieldCode"
                required
                value={data.fieldCode || ""}
                code={parameterCode.FIELD_CODE}
                // searchQuery={{ group: ["all", "ind"] }}
                onChange={(code, values, names) => {
                  if (names[0]) {
                    setFieldName(names[0])
                  }
                }}
              />
            </Col>
            <Col sm="6" className="mb-3">
              <CustomSelect
                label={t("Field Type")}
                name="type"
                value={data.type || ""}
                code={parameterCode.FIELD_TYPE}
                onChange={(code, values, names) => {
                  if (names[0]) {
                    setTypeName(names[0])
                  }
                }}
              />
            </Col>
            <Col sm="6" className="mb-3">
              <CustomAvField
                label={t("Format")}
                name="format"
                type="text"
                value={data.format || ""}
              />
            </Col>
            <Col sm="6" className="mb-3">
              <CustomSelect
                label={t("Parameter")}
                name="parameterKey"
                value={data.parameterKey || ""}
                code={parameterCode.PARAMETER}
              />
            </Col>
            <Col sm="6">
              <CustomAvField
                label={t("Remark")}
                name="remark"
                type="textarea"
                value={data.remark || ""}
              />
            </Col>
            <Col sm="6">
              <CustomCheckbox
                name="active"
                checked={data.active}
                direction={"down"}
                label={`${t("common:Active")} ?`}
                detected={isEdit}
              />
            </Col>
          </Row>
          <div className="accordion custom-accordion px-0">
            <a className="accordion-list mb-2">
              <div className="accordion-title">
                {t("common:Display Setting")}
              </div>
            </a>
            <Row className="mb-3">
              <Col sm="6" className="mb-3">
                <CustomAvField
                  label={t("Tooltip")}
                  name="tooltip"
                  type="text"
                  value={data.tooltip || ""}
                />
              </Col>
              <Col sm="6" className="mb-3">
                <CustomAvField
                  label={t("Initial Value")}
                  name="initialValue"
                  type="text"
                  value={data.initialValue || ""}
                />
              </Col>
              <Col sm="6" className="mb-3">
                <CustomAvField
                  label={t("Quick Look")}
                  name="lookSequence"
                  type="number"
                  value={data.lookSequence || 0}
                />
                {/* <NumberInput
                  name="lookSequence"
                  label={t("QuickLook")}
                  value={value}
                /> */}
              </Col>
              <Col sm="6">
                <CustomCheckbox
                  name="readonly"
                  checked={data.readonly}
                  direction={"down"}
                  label={`${t("Readonly")} ?`}
                  detected={isEdit}
                />
              </Col>
              <Col sm="6">
                <CustomCheckbox
                  name="require"
                  checked={data.require}
                  direction={"down"}
                  label={`${t("Require")} ?`}
                  detected={isEdit}
                />
              </Col>
            </Row>
          </div>
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        {" "}
        <Check resource={RESOURCE} permission={permissionType.R}>
          <CustomButton
            text={t("common:Cancel")}
            type="button"
            onClick={toggle}
            data-dismiss="modal"
            className="button-width"
          />
        </Check>
        <Check
          resource={RESOURCE}
          permissions={[permissionType.U, permissionType.C]}
        >
          <CustomButton
            text={t("common:Save")}
            type="submit"
            color="primary"
            onClick={() => formEl?.current?.submit()}
            className="save-user button-width"
            isEdit
            isModal
          />
        </Check>
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["profilePage", "common", "message"])(FieldModal)
