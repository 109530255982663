import {
    ADD_NEW_LOT_QC,
    ADD_NEW_LOT_QC_FAIL,
    ADD_NEW_LOT_QC_SUCCESS,
    ADD_NEW_LOT_TEST_QC,
    ADD_NEW_LOT_TEST_QC_FAIL,
    ADD_NEW_LOT_TEST_QC_SUCCESS,
    DELETE_LOT_QC_FAIL,
    DELETE_LOT_QC_SUCCESS,
    GET_LIST_LOT_LIST_FROM_LOT_TEST,
    GET_LIST_LOT_LIST_FROM_LOT_TEST_FAIL,
    GET_LIST_LOT_LIST_FROM_LOT_TEST_SUCCESS,
    GET_LIST_LOT_QC,
    GET_LIST_LOT_QC_FAIL,
    GET_LIST_LOT_QC_SUCCESS,
    GET_LOT_QC_DETAIL,
    GET_LOT_QC_DETAIL_FAIL,
    GET_LOT_QC_DETAIL_SUCCESS,
    GET_QC_LOT_TESTS,
    GET_QC_LOT_TESTS_FAIL,
    GET_QC_LOT_TESTS_SUCCESS,
    RESET_LOT_QC_SEARCH_QUERY,
    SET_LOT_QC_SEARCH_QUERY,
    UPDATE_LOT_QC,
    UPDATE_LOT_QC_FAIL,
    UPDATE_LOT_QC_SUCCESS,
    UPDATE_LOT_TEST_COUNT_TEST_QC,
    UPDATE_LOT_TEST_COUNT_TEST_QC_FAIL,
    UPDATE_LOT_TEST_COUNT_TEST_QC_SUCCESS,
    UPDATE_LOT_TEST_QC,
    UPDATE_LOT_TEST_QC_FAIL,
    UPDATE_LOT_TEST_QC_SUCCESS,
} from "./actionTypes"
const INIT_STATE = {
    lotQCs: [],
    paging: {},
    loadinglotQC: false,
    updatinglotQC: true,
    updatedTime: new Date(),
    updatedTimeListFromLotTest: new Date(),
    lotQC: {},
    searchQuery: {},
    errorLotQc: {},
    error: {},
    loadinglotListFromLotTest: false,
    lotListFromLotTests: [],
    pagingListFromLotTest: {},
    lotTests: [],
    loadingLotTests: false,
    updatingLotTests: false,
    updatedTimeLotTests: new Date(),
}
const LotQCs = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LIST_LOT_QC:
            return {
                ...state,
                error: {},
                loadinglotQC: true,
            }
        case GET_LIST_LOT_QC_SUCCESS:
            const { data, totalElements, ...rest } = action.payload
            return {
                ...state,
                lotQCs: data,
                paging: { ...rest, dataSize: totalElements },
                loadinglotQC: false,
                updatedTime: new Date(),
            }
        case GET_LIST_LOT_QC_FAIL:
            return {
                ...state,
                error: action.payload,
                loadinglotQC: false,
            }
        case GET_LOT_QC_DETAIL:
            return {
                ...state,
                lotQC: {},
                error: {},
                updatinglotQC: true,
            }
        case GET_LOT_QC_DETAIL_SUCCESS:
            const lotQC = action.payload
            return {
                ...state,
                lotQC: lotQC,
                updatinglotQC: false,
            }
        case GET_LOT_QC_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case ADD_NEW_LOT_QC:
            return {
                ...state,
                updatinglotQC: true,
                errorCode: {},
            }
        case ADD_NEW_LOT_QC_SUCCESS:
            return {
                ...state,
                lotQCs: [...state.lotQCs, action.payload],
            }
        case ADD_NEW_LOT_QC_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case UPDATE_LOT_QC:
            return {
                ...state,
                updatinglotQC: true,
                error: {},
            }
        case UPDATE_LOT_QC_SUCCESS:
            return {
                ...state,
                lotQCs: state.lotQCs.map(item =>
                    item.id?.toString() === action.payload.id.toString()
                        ? { ...item, ...action.payload }
                        : item
                ),
                updatinglotQC: false,
            }
        case UPDATE_LOT_QC_FAIL:
            return {
                ...state,
                error: action.payload,
                updatinglotQC: false,
            }
        case DELETE_LOT_QC_SUCCESS:
            const payload = action.payload
            const lotQCsUpdate = state.lotQCs.filter(
                item => payload.findIndex(_payload => item.id === _payload.id) < 0
            )
            const statePaging = { ...state.paging }
            statePaging.dataSize = statePaging.dataSize - payload.length
            if (statePaging.dataSize < 0) statePaging = 0
            return {
                ...state,
                lotQCs: lotQCsUpdate,
                paging: statePaging,
            }
        case DELETE_LOT_QC_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case SET_LOT_QC_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: { ...action.payload },
            }
        case RESET_LOT_QC_SEARCH_QUERY:
            return {
                ...state,
                searchQuery: {},
            }
        //get lot list from lot test
        case GET_LIST_LOT_LIST_FROM_LOT_TEST:
            return {
                ...state,
                error: {},
                loadinglotListFromLotTest: true,
            }
        case GET_LIST_LOT_LIST_FROM_LOT_TEST_SUCCESS:
            {
                const { data, totalElements, ...rest } = action.payload
                return {
                    ...state,
                    lotListFromLotTests: data,
                    pagingListFromLotTest: { ...rest, dataSize: totalElements },
                    loadinglotListFromLotTest: false,
                    updatedTimeListFromLotTest: new Date(),
                }
            }
        case GET_LIST_LOT_LIST_FROM_LOT_TEST_FAIL:
            return {
                ...state,
                error: action.payload,
                loadinglotListFromLotTest: false,
            }
        //add new lot test
        case ADD_NEW_LOT_TEST_QC:
            return {
                ...state,
                errorCode: {},
            }
        case ADD_NEW_LOT_TEST_QC_SUCCESS:
            return {
                ...state
            }
        case ADD_NEW_LOT_TEST_QC_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        //get lot tests
        case GET_QC_LOT_TESTS:
            return {
                ...state,
                error: {},
                loadingLotTests: true,
            }
        case GET_QC_LOT_TESTS_SUCCESS:
            {
                return {
                    ...state,
                    lotTests: action.payload,
                    loadingLotTests: false,
                    updatedTimeLotTests: new Date(),
                }
            }
        case GET_QC_LOT_TESTS_FAIL:
            return {
                ...state,
                error: action.payload,
                loadingLotTests: false,
            }
        //UPDATE lot tests
        case UPDATE_LOT_TEST_QC:
            return {
                ...state,
                error: {},
                updatingLotTests: true,
            }
        case UPDATE_LOT_TEST_QC_SUCCESS:
            {
                return {
                    ...state,
                    updatingLotTests: false,
                }
            }
        case UPDATE_LOT_TEST_QC_FAIL:
            return {
                ...state,
                error: action.payload,
                updatingLotTests: false,
            }

        case UPDATE_LOT_TEST_COUNT_TEST_QC:
            return {
                ...state,
                error: {},
            }
        case UPDATE_LOT_TEST_COUNT_TEST_QC_SUCCESS:
            {
                return {
                    ...state,
                    lotListFromLotTests: action.payload,
                }
            }
        case UPDATE_LOT_TEST_COUNT_TEST_QC_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        default:
            return state
    }
}
export default LotQCs