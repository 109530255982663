// import { AvField, AvRadio, AvRadioGroup } from "availity-reactstrap-validation"
import { AvForm } from "availity-reactstrap-validation"
import { CustomAvField, CustomAvInput, CustomButton, CustomDatePicker, CustomSelect, CustomSelectAsync, DatePicker } from "components/Common"
import { ReportTypes, parameterCode } from "constant/utility"
import { convertDateFormat, getInvalidMessageI18n } from "helpers/utilities"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
//i18n
import { withTranslation } from "react-i18next"
import { Col, Label, Row } from "reactstrap"

const FilterForm = ({ model, t, formEl, onChangeModel, onViewReport }) => {
    const [filterModel, setFilterModel] = useState(model)
    const [reportId, setReportId] = useState(143)
    const [fromDateTime, setFromDateTime] = useState(convertDateFormat(new Date(), 'YYYY-MM-DD 00:00'))
    const [toDateTime, setToDateTime] = useState(convertDateFormat(new Date(), 'YYYY-MM-DD HH:mm'))
    const [instrumentId, setInstrumentId] = useState()
    const formRef = formEl ? formEl : useRef();
    const loadingDataExport = true

    useEffect(() => {
        onChangeModel && onChangeModel(filterModel)
    }, [filterModel])

    const getReportTestVendor = () => {
        return <Row className="px-2">
            <Col lg={2} className="my-2">
                <Row className="align-items-center">
                    <Label for="VendorCode" className="font-weight-semibold">
                        {t("Test/Profile Code")}
                    </Label>
                </Row>
                <Row>
                    <CustomSelectAsync
                        name="Code"
                        value={filterModel.code || ""}
                        code={parameterCode.PROFILEANDTEST_CODE}
                        //customQuery={{ profileId: 5, size: 0 }}
                        onChange={(e, vals, a, values) => {
                            setFilterModel({ ...filterModel, code: vals[0] || '' });
                        }}
                        placeholder={t("common:Select Custom")}
                    />
                </Row>

            </Col>
            <Col lg={2} className="my-2">
                <Row className="align-items-center">
                    <Label for="Category" className="font-weight-semibold">
                        {t("Category")}
                    </Label>
                </Row>
                <Row>
                    <CustomSelect
                        name="Category"
                        value={filterModel.category}
                        code={parameterCode.TEST_CATEGORIES}
                        onChange={(e, values) => {
                            setFilterModel({ ...filterModel, category: values[0] ? values[0] : '' });
                        }}
                    />
                </Row>

            </Col>
            <Col lg={2} className="my-2">
                <Row className="align-items-center">
                    <Label for="Search" className="font-weight-semibold">
                        {t("Search")}
                    </Label>
                </Row>
                <Row>
                    <CustomAvInput
                        name={'Search'}
                        placeholder={t("Search Vendor")}
                        value={filterModel.searchText}
                        onChange={e => {
                            setFilterModel({ ...filterModel, search: e.target.value || '' })
                        }}
                    />
                </Row>
            </Col>

            <Col lg={2} className="my-2">
                <Row className="align-items-center">
                    <Label for="ServiceType" className="font-weight-semibold">
                        {t("Service Type")}
                    </Label>
                </Row>
                <Row>
                    <CustomSelect
                        name="ServiceType"
                        value={filterModel.serviceType}
                        code={parameterCode.SERVICE_TYPE}
                        onChange={(e, vals, a, values) => {
                            setFilterModel({ ...filterModel, serviceType: values[0]?.code || '' });
                        }}
                    />
                </Row>

            </Col>
            <Col lg={2} className="my-2">
                <Row className="align-items-center">
                    <Label for="State" className="font-weight-semibold">
                        {t("State")}
                    </Label>
                </Row>
                <Row>
                    <CustomSelectAsync
                        name="State"
                        value={filterModel.state}
                        options={[
                            {
                                label: t('Transferred'),
                                value: 4
                            },
                            {
                                label: t('Sent'),
                                value: 5,
                            },
                            {
                                label: t('Completed'),
                                value: 8
                            },
                            {
                                label: t('Confirmed'),
                                value: 9
                            },
                            {
                                label: t('Validated'),
                                value: 90
                            },
                            {
                                label: t('Released'),
                                value: 99
                            }
                        ]}
                        onChange={(e, values) => {
                            setFilterModel({ ...filterModel, state: values[0] ? values[0] : '' });
                        }}
                        // code={parameterCode.TESTRESULT_STATE_KEY}
                        placeholder={t("common:Select Custom")}
                    />
                </Row>
            </Col>

            <Col lg={2} className="my-2">
                <Row className="align-items-center">
                    <Label for="VendorCode" className="font-weight-semibold">
                        {t("Vendor Code")}
                    </Label>
                </Row>
                <Row>
                    <CustomSelectAsync
                        name="VendorCode"
                        value={filterModel.vendorCode || ""}
                        code={parameterCode.ORGANIZATIONS}
                        customQuery={{ profileId: 5, size: 0 }}
                        onChange={(e, vals, a, values) => {
                            setFilterModel({ ...filterModel, vendorId: values[0].organizationCode || '' });
                        }}
                        placeholder={t("common:Select Custom")}
                    />
                </Row>

            </Col>
        </Row>
    }

    const getReportSampleBySampleType = () => {
        return <Row className="px-2">
            <Col lg={4} className="my-2">
                <Row className="align-items-center">
                    <Label for="Search" className="font-weight-semibold">
                        {t("Search")}
                    </Label>
                </Row>
                <Row style={{ marginLeft: '0px', width: '100%' }}>
                    <CustomAvInput
                        name={'Search'}
                        placeholder={t("Search Details")}
                        value={filterModel.searchText}
                        onChange={e => {
                            setFilterModel({ ...filterModel, search: e.target.value || '' })
                        }}
                    />
                </Row>
            </Col>

            <Col lg={2} className="my-2">
                <Row className="align-items-center">
                    <Label for="Category" className="font-weight-semibold">
                        {t("Category")}
                    </Label>
                </Row>
                <Row>
                    <CustomSelect
                        name="Category"
                        value={filterModel.category}
                        code={parameterCode.TEST_CATEGORIES}
                        onChange={(e, values) => {
                            setFilterModel({ ...filterModel, category: values[0] ? values[0] : '' });
                        }}
                    />
                </Row>

            </Col>

            <Col lg={2} className="my-2">
                <Row className="align-items-center">
                    <Label for="VendorCode" className="font-weight-semibold">
                        {t("Test/Profile Code")}
                    </Label>
                </Row>
                <Row>
                    <CustomSelectAsync
                        name="Code"
                        value={filterModel.code || ""}
                        code={parameterCode.PROFILEANDTEST_CODE}
                        //customQuery={{ profileId: 5, size: 0 }}
                        onChange={(e, vals, a, values) => {
                            setFilterModel({ ...filterModel, code: vals[0] || '' });
                        }}
                        placeholder={t("common:Select Custom")}
                    />
                </Row>

            </Col>

            <Col lg={2} className="my-2">
                <Row className="align-items-center">
                    <Label for="Instrument" className="font-weight-semibold">
                        {t("Instrument")}
                    </Label>
                </Row>
                <Row>
                    <CustomSelect
                        name="Instrument"
                        value={instrumentId || ""}
                        code={parameterCode.INSTRUMENT}
                        onChange={(e, vals, a, values) => {
                            setInstrumentId(vals[0] != 'undefined' ? vals[0] : '')
                            setFilterModel({ ...filterModel, instrumentId: values[0]?.insID || '' });
                        }}
                    />
                </Row>

            </Col>

            <Col lg={2} className="my-2">
                <Row className="align-items-center">
                    <Label for="State" className="font-weight-semibold">
                        {t("State")}
                    </Label>
                </Row>
                <Row>
                    <CustomSelectAsync
                        name="State"
                        value={filterModel.resultState}
                        options={[
                            // {
                            //     label: t('All'),
                            //     value: 1
                            // },
                            {
                                label: t('Completed'),
                                value: 2,
                            },
                            {
                                label: t('UnCompleted'),
                                value: 3
                            },

                        ]}
                        onChange={(e, vals, a, values) => {
                            setFilterModel({ ...filterModel, resultState: vals[0] || '' });
                        }}
                        // code={parameterCode.TESTRESULT_STATE_KEY}
                        placeholder={t("common:Select Custom")}
                    />
                </Row>
            </Col>
        </Row>
    }

    return (
        <>
            <AvForm ref={formRef}
                // onValidSubmit={(e, vals) => {
                //     onValidSubmit && onValidSubmit(model)
                // }}
                style={{ overflowX: 'hidden', }}
            >
                <Row className="px-2">
                    <Col lg={8} className="my-2">
                        <Row className="align-items-center">

                            <Label for="report" className="font-weight-semibold">
                                {t("Report")}
                            </Label>
                        </Row>
                        <Row>
                            <CustomSelectAsync
                                name="report"
                                value={reportId}
                                options={[
                                    {
                                        label: t('Tổng hợp xét nghiệm gửi'),
                                        value: 135
                                    },
                                    {
                                        label: t('Thống kê chi tiết xét nghiệm'),
                                        value: 111
                                    },
                                    {
                                        label: t('Thống kê xét nghiệm chạy trên máy'),
                                        value: 112
                                    },
                                    {
                                        label: t('Thống kê từ chối mẫu'),
                                        value: 132
                                    },
                                    {
                                        label: t('Thống kê nhận mẫu theo loại mẫu'),
                                        value: 116
                                    }

                                ]}
                                onChange={(e, vals, a, values) => {
                                    const resetModel = Object.keys(filterModel).reduce((acc, key) => {
                                        acc[key] = (key === "fromDate" || key === "toDate") ? model[key] : "";
                                        return acc;
                                    }, {});
                                    setFilterModel(resetModel)
                                    setReportId(vals[0])
                                }}
                                required={true}
                                errorMessage={getInvalidMessageI18n(t, "Report Error")}
                            // code={parameterCode.TESTRESULT_STATE_KEY}
                            // placeholder={t("Select State")}
                            />
                        </Row>
                    </Col>

                    <Col lg={2} className="my-2">
                        <Row className="align-items-center">
                            <Label for="fromDate" className="font-weight-semibold">
                                {t("From Date")}
                            </Label>
                        </Row>
                        <Row>
                            {/* <DatePicker
                                name="FromDate"
                                label={t("")}
                                value={filterModel.fromDate}
                                placeholder={"YYYY-MM-DD"}
                                format={"YYYY-MM-DD"}
                                closeOnSelect={true}
                                onChange={date => {
                                    setFilterModel({ ...filterModel, fromDate: convertDateFormat(date.value, "YYYY-MM-DD") })
                                }}
                            /> */}
                            <CustomDatePicker
                                conjunction=" - "
                                name="fromDate"
                                label={""}
                                enableTime={true}
                                // checkSameTime={true}
                                // format={"YYYY-MM-DD HH:mm"}
                                dateFormat={"H:i d-m-Y"}
                                placeholder={"HH:mm DD-MM-YYYY"}
                                value={convertDateFormat(filterModel?.fromDate || new Date(), "00:00 DD-MM-YYYY")}
                                closeOnSelect={true}
                                onClose={(date) => {
                                    let value = moment(date[0]).format("YYYY-MM-DD HH:mm")
                                    setFilterModel({
                                        ...filterModel, fromDate: value
                                    })
                                }}
                                onChangeHandler={date => {
                                    setFromDateTime(date?.value)
                                    let value = moment(date.time[0]).format("YYYY-MM-DD HH:mm")
                                    setFilterModel({
                                        ...filterModel, fromDate: value
                                    })
                                }}
                                maxDate={new Date()}
                                required={true}
                                errorMessage={getInvalidMessageI18n(t, "From Date Err")}
                            // minDate={minDate}
                            // mode="range"
                            />
                        </Row>

                    </Col>
                    <Col lg={2} className="my-2">
                        <Row className="align-items-center">
                            <Label for="toDate" className="font-weight-semibold">
                                {t("To Date")}
                            </Label>
                        </Row>
                        <Row>
                            {/* <DatePicker
                                name="ToDate"
                                label={t("")}
                                value={filterModel.toDate}
                                placeholder={"YYYY-MM-DD"}
                                format={"YYYY-MM-DD"}
                                closeOnSelect={true}
                                onChange={date => {
                                    setFilterModel({ ...filterModel, toDate: convertDateFormat(date.value, "YYYY-MM-DD") })
                                }}
                            /> */}
                            <CustomDatePicker
                                conjunction=" - "
                                name="toDate"
                                label={""}
                                enableTime={true}
                                // checkSameTime={true}
                                // format={"YYYY-MM-DD HH:mm"}
                                dateFormat={"H:i d-m-Y"}
                                placeholder={"HH:mm DD-MM-YYYY"}
                                value={convertDateFormat(filterModel?.toDate || new Date(), "HH:mm DD-MM-YYYY")}
                                closeOnSelect={true}
                                onClose={(date) => {
                                    let value = moment(date[0]).format("YYYY-MM-DD HH:mm")
                                    setFilterModel({
                                        ...filterModel, toDate: value
                                    })
                                }}
                                onChangeHandler={date => {
                                    setToDateTime(date?.value)
                                    let value = moment(date.time[0]).format("YYYY-MM-DD HH:mm")
                                    setFilterModel({
                                        ...filterModel, toDate: value
                                    })
                                }}
                                minDate={convertDateFormat(filterModel?.fromDate || new Date(), "00:00 DD-MM-YYYY")}
                                maxDate={new Date()}
                                required={true}
                                errorMessage={getInvalidMessageI18n(t, "To Date Err")}
                            // minDate={minDate}
                            // mode="range"
                            />
                        </Row>
                    </Col>
                </Row>

                {reportId === 135 && getReportTestVendor()}

                {reportId === 111 && getReportSampleBySampleType()}

                {reportId === 109 && <Row className="px-2">
                    <Col lg={4} className="my-2">
                        <Row className="align-items-center">
                            <Label for="Search" className="font-weight-semibold">
                                {t("Search")}
                            </Label>
                        </Row>
                        <Row style={{ marginLeft: '0px', width: '100%' }}>
                            <CustomAvInput
                                name={'Search'}
                                placeholder={t("Search Report")}
                                value={filterModel.searchText}
                                onChange={e => {
                                    setFilterModel({ ...filterModel, search: e.target.value || '' })
                                }}
                            />
                        </Row>
                    </Col>
                    <Col lg={2} className="my-2">
                        <Row className="align-items-center">
                            <Label for="VendorCode" className="font-weight-semibold">
                                {t("Test/Profile Code")}
                            </Label>
                        </Row>
                        <Row>
                            <CustomSelectAsync
                                name="Code"
                                value={filterModel.code || ""}
                                code={parameterCode.PROFILEANDTEST_CODE}
                                //customQuery={{ profileId: 5, size: 0 }}
                                onChange={(e, vals, a, values) => {
                                    setFilterModel({ ...filterModel, code: vals[0] || '' });
                                }}
                                placeholder={t("common:Select Custom")}
                            />
                        </Row>

                    </Col>
                    <Col lg={2} className="my-2">
                        <Row className="align-items-center">
                            <Label for="Category" className="font-weight-semibold">
                                {t("Category")}
                            </Label>
                        </Row>
                        <Row>
                            <CustomSelect
                                name="Category"
                                value={filterModel.category}
                                code={parameterCode.TEST_CATEGORIES}
                                onChange={(e, values) => {
                                    setFilterModel({ ...filterModel, category: values[0] ? values[0] : '' });
                                }}
                            />
                        </Row>

                    </Col>

                    <Col lg={2} className="my-2">
                        <Row className="align-items-center">
                            <Label for="Instrument" className="font-weight-semibold">
                                {t("Instrument")}
                            </Label>
                        </Row>
                        <Row>
                            <CustomSelect
                                name="Instrument"
                                value={filterModel.instrumentId || ""}
                                code={parameterCode.INSTRUMENT}
                                onChange={(e, vals, a, values) => {
                                    setFilterModel({ ...filterModel, instrumentId: vals[0] || '' });
                                }}
                            />
                        </Row>
                    </Col>
                    <Col lg={2} className="my-2">
                        <Row className="align-items-center">
                            <Label for="DateType" className="font-weight-semibold">
                                {t("Date Type")}
                            </Label>
                        </Row>
                        <Row>
                            <CustomSelect
                                name="DateType"
                                value={filterModel.dateType || ""}
                                code={parameterCode.DATE_TYPE_RESULT_INPUT_LIST}
                                // label={t("Date Type")}

                                onChange={(e, values) => {
                                    setFilterModel({ ...filterModel, dateType: vals[0] || '' })
                                }}
                            // readOnly={isUpdate
                            />
                        </Row>
                    </Col>

                </Row>}

                {reportId === 112 && <Row className="px-2">
                    <Col lg={4} className="my-2">
                        <Row className="align-items-center">
                            <Label for="Search" className="font-weight-semibold">
                                {t("Search")}
                            </Label>
                        </Row>
                        <Row style={{ marginLeft: '0px', width: '100%' }}>
                            <CustomAvInput
                                name={'Search'}
                                placeholder={t("Search Instrument")}
                                value={filterModel.searchText}
                                onChange={e => {
                                    setFilterModel({ ...filterModel, search: e.target.value || '' })
                                }}
                            />
                        </Row>
                    </Col>
                    <Col lg={2} className="my-2">
                        <Row className="align-items-center">
                            <Label for="VendorCode" className="font-weight-semibold">
                                {t("Test/Profile Code")}
                            </Label>
                        </Row>
                        <Row>
                            <CustomSelectAsync
                                name="Code"
                                value={filterModel.code || ""}
                                code={parameterCode.PROFILEANDTEST_CODE}
                                //customQuery={{ profileId: 5, size: 0 }}
                                onChange={(e, vals, a, values) => {
                                    setFilterModel({ ...filterModel, code: vals[0] || '' });
                                }}
                                placeholder={t("common:Select Custom")}
                            />
                        </Row>

                    </Col>
                    <Col lg={2} className="my-2">
                        <Row className="align-items-center">
                            <Label for="Category" className="font-weight-semibold">
                                {t("Category")}
                            </Label>
                        </Row>
                        <Row>
                            <CustomSelect
                                name="Category"
                                value={filterModel.category}
                                code={parameterCode.TEST_CATEGORIES}
                                onChange={(e, values) => {
                                    setFilterModel({ ...filterModel, category: values[0] ? values[0] : '' });
                                }}
                            />
                        </Row>

                    </Col>

                    <Col lg={2} className="my-2">
                        <Row className="align-items-center">
                            <Label for="Instrument" className="font-weight-semibold">
                                {t("Instrument")}
                            </Label>
                        </Row>
                        <Row>
                            <CustomSelect
                                name="Instrument"
                                value={instrumentId || ""}
                                code={parameterCode.INSTRUMENT}
                                onChange={(e, vals, a, values) => {
                                    setInstrumentId(vals[0] != 'undefined' ? vals[0] : '')
                                    setFilterModel({ ...filterModel, instrumentId: values[0]?.insID || '' });
                                }}
                            />
                        </Row>

                    </Col>

                    <Col lg={2} className="my-2">
                        <Row className="align-items-center">
                            <Label for="ResultState" className="font-weight-semibold">
                                {t("Result State")}
                            </Label>
                        </Row>
                        <Row>
                            <CustomSelectAsync
                                name="ResultState"
                                value={filterModel.resultStateIns}
                                options={[
                                    // {
                                    //     label: t('Tất cả'),
                                    //     value: 1
                                    // },
                                    {
                                        label: t('Có chỉ định'),
                                        value: 2,
                                    },
                                    {
                                        label: t('Không có chỉ định'),
                                        value: 3
                                    },
                                    {
                                        label: t('QC'),
                                        value: 4
                                    },
                                ]}
                                onChange={(e, vals, a, values) => {
                                    setFilterModel({ ...filterModel, resultStateIns: vals[0] || '' });
                                }}
                                // code={parameterCode.TESTRESULT_STATE_KEY}
                                placeholder={t("common:Select Custom")}
                            />
                        </Row>
                    </Col>
                </Row>}

                {reportId === 116 && <Row className="px-2">
                    <Col lg={8} className="my-2">
                        <Row className="align-items-center">
                            <Label for="Search" className="font-weight-semibold">
                                {t("Search")}
                            </Label>
                        </Row>
                        <Row style={{ marginLeft: '0px', width: '100%' }}>
                            <CustomAvInput
                                name={'Search'}
                                placeholder={t("Search Sample")}
                                value={filterModel.searchText}
                                onChange={e => {
                                    setFilterModel({ ...filterModel, search: e.target.value || '' })
                                }}
                            />
                        </Row>
                    </Col>
                    <Col lg={2} className="my-2">
                        <Row className="align-items-center">
                            <Label for="SampleType" className="font-weight-semibold">
                                {t("Sample Type")}
                            </Label>
                        </Row>
                        <Row>
                            <CustomSelect
                                name="SampleType"
                                value={filterModel.sampleType}
                                code={parameterCode.SAMPLE_TYPES}
                                onChange={(e, vals, a, values) => {
                                    setFilterModel({ ...filterModel, sampleType: vals[0] || '' });
                                }}
                            />
                        </Row>

                    </Col>
                    <Col lg={2} className="my-2">
                        <Row className="align-items-center">
                            <Label for="ReceiverName" className="font-weight-semibold">
                                {t("Receiver Name")}
                            </Label>
                        </Row>
                        <Row>
                            <CustomSelectAsync
                                name="ReceiverName"
                                // value={filterModel.vendorCode || ""}
                                code={parameterCode.USERS}
                                // customQuery={{ profileId: 5, size: 0 }}
                                onChange={(e, vals, a, values) => {
                                    setFilterModel({ ...filterModel, receiverId: vals[0] || '' });
                                }}
                                placeholder={t("common:Select Custom")}
                            />
                        </Row>

                    </Col>
                </Row>}

                {reportId === 132 && <Row className="px-2">
                    <Col lg={8} className="my-2">
                        <Row className="align-items-center">
                            <Label for="Search" className="font-weight-semibold">
                                {t("Search")}
                            </Label>
                        </Row>
                        <Row style={{ marginLeft: '0px', width: '100%' }}>
                            <CustomAvInput
                                name={'Search'}
                                placeholder={t("Search Return Sample")}
                                value={filterModel.searchText}
                                onChange={e => {
                                    setFilterModel({ ...filterModel, search: e.target.value || '' })
                                }}
                            />
                        </Row>
                    </Col>

                    <Col lg={2} className="my-2">
                        <Row className="align-items-center">
                            <Label for="SampleType" className="font-weight-semibold">
                                {t("Sample Type")}
                            </Label>
                        </Row>
                        <Row>
                            <CustomSelect
                                name="SampleType"
                                // value={filterModel.sampleType}
                                code={parameterCode.SAMPLE_TYPES}
                                onChange={(e, vals, a, values) => {
                                    setFilterModel({ ...filterModel, sampleType: vals[0] || '' });
                                }}
                            />
                        </Row>

                    </Col>
                </Row>}

                <Row className="px-2" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <Col lg={4} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                        <CustomButton
                            color="success"
                            // style={{ height: '50%' }}
                            onClick={() => {
                                onViewReport && onViewReport(reportId)
                            }}
                            disabled={!reportId || !(fromDateTime !== '') || !(toDateTime !== '')}
                        >
                            <div className="d-flex">
                                <div><i className="fa fa-search"></i></div>
                                <div style={{ marginLeft: 10, marginRight: 10 }}>{t("common:View Report")}</div>
                            </div>
                        </CustomButton>
                    </Col>
                </Row>
            </AvForm>
        </>
    )
}
FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any,
}
export default withTranslation(["reportPage", "common"])(FilterForm)