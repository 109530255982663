const ANTIBIOTIC_RESISTANCES = {
  GET_LIST_ANTIBIOTIC_RESISTANCES: "GET_LIST_ANTIBIOTIC_RESISTANCES",
  GET_LIST_ANTIBIOTIC_RESISTANCES_SUCCESS:
    "GET_LIST_ANTIBIOTIC_RESISTANCES_SUCCESS",
  GET_LIST_ANTIBIOTIC_RESISTANCES_FAIL: "GET_LIST_ANTIBIOTIC_RESISTANCES_FAIL",
  GET_ANTIBIOTIC_RESISTANCES_DETAIL: "GET_ANTIBIOTIC_RESISTANCES_DETAIL",
  GET_ANTIBIOTIC_RESISTANCES_DETAIL_SUCCESS:
    "GET_ANTIBIOTIC_RESISTANCES_DETAIL_SUCCESS",
  GET_ANTIBIOTIC_RESISTANCES_DETAIL_FAIL:
    "GET_ANTIBIOTIC_RESISTANCES_DETAIL_FAIL",
  ADD_NEW_ANTIBIOTIC_RESISTANCES: "ADD_NEW_ANTIBIOTIC_RESISTANCES",
  ADD_NEW_ANTIBIOTIC_RESISTANCES_SUCCESS:
    "ADD_NEW_ANTIBIOTIC_RESISTANCES_SUCCESS",
  ADD_NEW_ANTIBIOTIC_RESISTANCES_FAIL: "ADD_NEW_ANTIBIOTIC_RESISTANCES_FAIL",
  UPDATE_ANTIBIOTIC_RESISTANCES: "UPDATE_ANTIBIOTIC_RESISTANCES",
  UPDATE_ANTIBIOTIC_RESISTANCES_SUCCESS:
    "UPDATE_ANTIBIOTIC_RESISTANCES_SUCCESS",
  UPDATE_ANTIBIOTIC_RESISTANCES_FAIL: "UPDATE_ANTIBIOTIC_RESISTANCES_FAIL",
  DELETE_ANTIBIOTIC_RESISTANCES: "DELETE_ANTIBIOTIC_RESISTANCES",
  DELETE_ANTIBIOTIC_RESISTANCES_SUCCESS:
    "DELETE_ANTIBIOTIC_RESISTANCES_SUCCESS",
  DELETE_ANTIBIOTIC_RESISTANCES_FAIL: "DELETE_ANTIBIOTIC_RESISTANCES_FAIL",
}

export default ANTIBIOTIC_RESISTANCES
