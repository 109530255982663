import { Check, CustomBreadcrumb, PageContent } from "components/Common";
import { ModuleIds, permissionType } from "constant";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import ChangeSIDQC from "./ChangeSIDQC";

const RESOURCE = ModuleIds.ChangeSIDQC
const ChangeSIDQCConfigContainer = ({ t }) => {
    const titleTag = t(`Change SID QC`)
    return (
        <PageContent title={titleTag}>
            <CustomBreadcrumb
                breadcrumbItem={t("Change SID QC")}
                resource={RESOURCE}
            />
            <Check permission={permissionType.R} resource={RESOURCE}>
                <ChangeSIDQC />
            </Check>
        </PageContent>
    );
}

ChangeSIDQCConfigContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["changeSIDQCPage"])(ChangeSIDQCConfigContainer)