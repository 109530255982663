import { CustomNav, CustomNavLink } from "components/Common"
import { getUrlParamByKey } from "helpers/utilities"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Row, TabContent, TabPane } from "reactstrap"
import GenusTab from "./Tab/Genus/index"
import GroupTab from "./Tab/Group/index"
import OrganismsTab from "./Tab/Organisms/index"

const Organisms = ({
    t,
}) => {
    const [currentTab, setCurrentTab] = useState(getUrlParamByKey("tab") || "1")

    useEffect(() => {

    }, [currentTab])

    return (
        <Row style={{ backgroundColor: 'white' }}>
            <CustomNav
                onToggle={e => {
                    setCurrentTab(e)
                }}
                defaultTab={currentTab}
                tabs
                className="nav-tabs-custom pt-3"
                tabContents={customActiveTab => (
                    <TabContent
                        activeTab={customActiveTab}
                    >
                        <TabPane tabId="1">
                            {currentTab == '1' &&
                                <>
                                    <GroupTab />
                                </>
                            }
                        </TabPane>
                        <TabPane tabId="2">
                            {currentTab == '2' &&
                                <>
                                    <GenusTab />
                                </>
                            }
                        </TabPane>
                        <TabPane tabId="3">
                            {currentTab == '3' &&
                                <>
                                    <OrganismsTab />
                                </>
                            }
                        </TabPane>
                    </TabContent>
                )}
            >
                <CustomNavLink tabId="1">
                    <span className="">{t("Microorganism Group List")}</span>
                </CustomNavLink>
                <CustomNavLink tabId="2">
                    <span className="">{t("Microorganism Genus List")}</span>
                </CustomNavLink>
                <CustomNavLink tabId="3">
                    <span className="">{t("Microorganism List Tab")}</span>
                </CustomNavLink>
            </CustomNav>
        </Row>
    )
}


// export default (withTranslation(["testProfilePage", "testPage", "message"])(Organisms))

const mapStateToProps = ({ parameter }) => ({

})

const mapDispatchToProps = dispatch => ({

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["organismsPage", "message"])(Organisms)))
