import { showToast } from "components/Common"
import {
    createOrganisms, createOrganismsGroup, deleteOrganisms, deleteOrganismsGroup, getOrganismsById, getOrganismsGroupById, getOrganismsGroupList, getOrganismsList, updateOrganisms,
    updateOrganismsGroup
} from "helpers/app-backend/VS/VSOrganisms_backend_helper"
import i18n from "i18next"
import { call, put, takeEvery } from "redux-saga/effects"
import {
    ADD_NEW_ORGANISMS,
    ADD_NEW_ORGANISMS_GENUS,
    ADD_NEW_ORGANISMS_GROUP,
    DELETE_ORGANISMS,
    DELETE_ORGANISMS_GENUS,
    DELETE_ORGANISMS_GROUP,
    GET_LIST_ORGANISMS,
    GET_LIST_ORGANISMS_GENUS,
    GET_LIST_ORGANISMS_GROUP,
    GET_ORGANISMS_DETAIL,
    GET_ORGANISMS_GENUS_DETAIL,
    GET_ORGANISMS_GROUP_DETAIL,
    UPDATE_ORGANISMS,
    UPDATE_ORGANISMS_GENUS,
    UPDATE_ORGANISMS_GROUP
} from "./actionTypes"
import {
    addNewOrganismsFail,
    addNewOrganismsGenusFail,
    addNewOrganismsGenusSuccess,
    addNewOrganismsGroupFail,
    addNewOrganismsGroupSuccess,
    addNewOrganismsSuccess,
    deleteOrganismsFail,
    deleteOrganismsSuccess,
    getListOrganismsFail,
    getListOrganismsGenusFail,
    getListOrganismsGenusSuccess,
    getListOrganismsGroupFail,
    getListOrganismsGroupSuccess,
    getListOrganismsSuccess,
    getOrganismsDetailFail,
    getOrganismsDetailSuccess,
    getOrganismsGenusDetailFail,
    getOrganismsGenusDetailSuccess,
    getOrganismsGroupDetailFail,
    getOrganismsGroupDetailSuccess,
    updateOrganismsFail,
    updateOrganismsGenusFail,
    updateOrganismsGenusSuccess,
    updateOrganismsGroupFail,
    updateOrganismsGroupSuccess,
    updateOrganismsSuccess
} from "./actions"
const t = (msg, param) => i18n.t(msg, param)

function* fetchGetListOrganisms({ payload }) {
    try {
        let response = yield call(getOrganismsList, payload)
        yield put(getListOrganismsSuccess(response))
    } catch (error) {
        console.log(error)
        yield put(getListOrganismsFail(error))
    }
}

function* fetchOrganismsDetail({ id, callback }) {
    try {
        const response = yield call(getOrganismsById, id)
        yield put(getOrganismsDetailSuccess(response))
        callback && callback(response)
    } catch (error) {
        yield put(getOrganismsDetailFail(error))
    }
}
function* onUpdateOrganisms({ payload: { organisms, callback } }) {
    try {
        yield call(updateOrganisms, organisms)
        yield put(updateOrganismsSuccess(organisms))
        showToast(
            `${t("message:UpdatedMessage")}`
        )
        callback && callback()
    } catch (error) {
        yield put(updateOrganismsFail(error))
    }
}
function* onDeleteOrganisms({ payload: { organisms, callback } }) {
    try {
        yield call(deleteOrganisms, organisms)
        yield put(deleteOrganismsSuccess())
        showToast(
            `${t("message:DeletedMessage")}`
        )
        callback && callback()
    } catch (error) {
        yield put(deleteOrganismsFail(error))
    }
}
function* onAddNewOrganisms({ payload: { organisms, callback } }) {
    try {
        const response = yield call(createOrganisms, organisms)
        yield put(addNewOrganismsSuccess(organisms))
        showToast(
            `${t("message:CreatedMessage", {
                field: `${t(
                    ""
                )} <span class='text-decoration-underline fw-bold'>${organisms["organismsCode"]
                    }</span>`,
            })}`
        )
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(addNewOrganismsFail(error))
    }
}

/////////
function* fetchGetListOrganismsGroup({ payload }) {
    try {
        let response = yield call(getOrganismsGroupList, payload)
        yield put(getListOrganismsGroupSuccess(response))
    } catch (error) {
        console.log(error)
        yield put(getListOrganismsGroupFail(error))
    }
}

function* fetchOrganismsGroupDetail({ id, callback }) {
    try {
        const response = yield call(getOrganismsGroupById, id)
        yield put(getOrganismsGroupDetailSuccess(response))
        callback && callback(response)
    } catch (error) {
        yield put(getOrganismsGroupDetailFail(error))
    }
}
function* onUpdateOrganismsGroup({ payload: { organisms, callback } }) {
    try {
        yield call(updateOrganismsGroup, organisms)
        yield put(updateOrganismsGroupSuccess(organisms))
        showToast(
            `${t("message:UpdatedMessage")}`
        )
        callback && callback()
    } catch (error) {
        yield put(updateOrganismsGroupFail(error))
    }
}
function* onDeleteOrganismsGroup({ payload: { organisms, callback } }) {
    try {
        yield call(deleteOrganismsGroup, organisms)
        yield put(deleteOrganismsSuccess())
        showToast(
            `${t("message:DeletedMessage")}`
        )
        callback && callback()
    } catch (error) {
        yield put(deleteOrganismsFail(error))
    }
}
function* onAddNewOrganismsGroup({ payload: { organisms, callback } }) {
    try {
        const response = yield call(createOrganismsGroup, organisms)
        yield put(addNewOrganismsGroupSuccess(organisms))
        showToast(
            `${t("message:CreatedMessage")}`
        )
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(addNewOrganismsGroupFail(error))
    }
}

///////////////////////
function* fetchGetListOrganismsGenus({ payload }) {
    try {
        let response = yield call(getOrganismsGroupList, payload)
        yield put(getListOrganismsGenusSuccess(response))
    } catch (error) {
        console.log(error)
        yield put(getListOrganismsGenusFail(error))
    }
}

function* fetchOrganismsGenusDetail({ id, callback }) {
    try {
        const response = yield call(getOrganismsGroupById, id)
        yield put(getOrganismsGenusDetailSuccess(response))
        callback && callback(response)
    } catch (error) {
        yield put(getOrganismsGenusDetailFail(error))
    }
}
function* onUpdateOrganismsGenus({ payload: { organisms, callback } }) {
    try {
        yield call(updateOrganismsGroup, organisms)
        yield put(updateOrganismsGenusSuccess(organisms))
        showToast(
            `${t("message:UpdatedMessage")}`
        )
        callback && callback()
    } catch (error) {
        yield put(updateOrganismsGenusFail(error))
    }
}
function* onDeleteOrganismsGenus({ payload: { organisms, callback } }) {
    try {
        yield call(deleteOrganismsGroup, organisms)
        yield put(deleteOrganismsSuccess())
        showToast(
            `${t("message:DeletedMessage")}`
        )
        callback && callback()
    } catch (error) {
        yield put(deleteOrganismsFail(error))
    }
}
function* onAddNewOrganismsGenus({ payload: { organisms, callback } }) {
    try {
        const response = yield call(createOrganismsGroup, organisms)
        yield put(addNewOrganismsGenusSuccess(organisms))
        showToast(
            `${t("message:CreatedMessage")}`
        )
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(addNewOrganismsGenusFail(error))
    }
}

function* OrganismsSaga() {
    yield takeEvery(GET_LIST_ORGANISMS, fetchGetListOrganisms)
    yield takeEvery(GET_ORGANISMS_DETAIL, fetchOrganismsDetail)
    yield takeEvery(ADD_NEW_ORGANISMS, onAddNewOrganisms)
    yield takeEvery(UPDATE_ORGANISMS, onUpdateOrganisms)
    yield takeEvery(DELETE_ORGANISMS, onDeleteOrganisms)
    yield takeEvery(GET_LIST_ORGANISMS_GROUP, fetchGetListOrganismsGroup)
    yield takeEvery(GET_ORGANISMS_GROUP_DETAIL, fetchOrganismsGroupDetail)
    yield takeEvery(ADD_NEW_ORGANISMS_GROUP, onAddNewOrganismsGroup)
    yield takeEvery(UPDATE_ORGANISMS_GROUP, onUpdateOrganismsGroup)
    yield takeEvery(DELETE_ORGANISMS_GROUP, onDeleteOrganismsGroup)
    yield takeEvery(GET_LIST_ORGANISMS_GENUS, fetchGetListOrganismsGenus)
    yield takeEvery(GET_ORGANISMS_GENUS_DETAIL, fetchOrganismsGenusDetail)
    yield takeEvery(ADD_NEW_ORGANISMS_GENUS, onAddNewOrganismsGenus)
    yield takeEvery(UPDATE_ORGANISMS_GENUS, onUpdateOrganismsGenus)
    yield takeEvery(DELETE_ORGANISMS_GENUS, onDeleteOrganismsGenus)
}
export default OrganismsSaga