import { getUrlParamByKey, getUrlParams, insertUrlParam, trimObjectValues } from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import {
    Check,
    ConfirmModal2,
    CustomButton,
    TitleAndTable,
    WarningModal
} from "components/Common"
import {
    onDelete,
    onDeleteToggle,
    selectCheckboxHandler,
} from "helpers/utilities"

import {
    addNewWestgardSigmaQC,
    deleteWestgardSigmaQCs,
    getFullWestgardSigmaQC,
    getFullWestgardSigmaQCSuccess,
    getWestgardSigmaQCDetail,
    resetSearchQuery,
    updateWestgardSigmaQC
} from "store/laboratoryIQC/WestgardSigmaQC/actions"

import { ModuleIds, permissionType, WESTGARD_TYPE } from "constant"
import { withTranslation } from "react-i18next"
import WestgardRulesQCModal from "./Modal/WestgardRulesQCModal"
import WestgardRulesQCTable from "./WestgardRulesQCTable"

const RESOURCE = ModuleIds.WestgardiQC

const WestgardRulesQC = ({
    westgardSigmaQCs,
    paging,
    onGetWestgardSigmaQC,
    onAddNewWestgardSigmaQC,
    onUpdateWestgardSigmaQC,
    onDeleteWestgardSigmaQC,
    onResetWestgardSigmaQCSearchQuery,
    westgardSigmaQC,
    loadingWestgardSigmaQC,
    updatedWestgardSigmaQCTime,
    onGetWestgardSigmaQCSuccess,
    t,
    userPermissions,
    onGetWestgardSigmaQCDetail
}) => {
    useEffect(() => {
        onGetWestgardSigmaQCSuccess([])
    }, [])
    const [confirmModal, setConfirmModal] = useState(false)
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(true)
    const [isClone, setIsClone] = useState(false)
    const [row, setRow] = useState(false)
    const [rowDelete, setRowDelete] = useState({})
    const [rows, setRows] = useState([])
    const [warningModal, setWarningModal] = useState(false)

    const formEl = useRef(null)

    const popStateChange = (event) => {
        let params = getUrlParams()
        let paramPage = getUrlParamByKey("page")
        if (paramPage) {
            onGetWestgardSigmaQCList(params)
        }
        else {
            fetchWestgardSigmaQC()
        }
    }


    const fetchWesgardQC = () => {
        onGetWestgardSigmaQC({
            page: 1,
            sort: "createdDate:desc",
        })
    }

    const afterUpdate = () => {
        toggle()
        popStateChange()
    }

    const toggle = () => {
        setModal(prev => !prev)
    }

    const addWestgardSigmaQCClicks = () => {
        setIsEdit(false)
        setIsClone(false)
        toggle()
    }

    const handleValidWestgardSigmaQCSubmit = (e, values) => {
        if (isEdit) {
            values.type = WESTGARD_TYPE.WESTGARD_RULES
            onUpdateWestgardSigmaQC({ westgardSigmaQC: trimObjectValues(values), callback: afterUpdate })
        } else {
            const newWestgardSigmaQC = {
                ...values,
                type: WESTGARD_TYPE.WESTGARD_RULES
            }
            onAddNewWestgardSigmaQC({
                westgardSigmaQC: trimObjectValues(newWestgardSigmaQC), callback: afterUpdate
            })
        }
    }

    const onCloneHandler = (param) => {
        const id = param?.id || row?.id
        if (id) {
            setIsEdit(false)
            setIsClone(true)
            toggle()
            onGetWestgardSigmaQCDetail(id)
        } else {
            setWarningModal(true)
        }

    }

    const onEditHandler = (e, westgardSigmaQCId, param) => {
        const id = westgardSigmaQCId || row?.id;
        if (id) {
            setIsEdit(true)
            toggle()
            onGetWestgardSigmaQCDetail(id)
        } else {
            setWarningModal(true)
        }
    }

    const resetState = () => {
        setRow({})
        setRows([])
        onGetWestgardSigmaQCList({ ...paging })
    }

    const onDeleteToggleHandler = (e, westgardSigmaQC) => {
        onDeleteToggle({
            rows,
            row: westgardSigmaQC || rowDelete,
            setConfirmModal,
            setWarningModal,
            setRowDelete
        })
        if (confirmModal) {
            setRowDelete({})
        }
    }

    const onDeleteMultipleRows = rowsState => {
        onDeleteWestgardSigmaQC({ westgardSigmaQCs: rowsState, type: WESTGARD_TYPE.WESTGARD_RULES, callback: resetState })
    }

    const onDeleteSingleRow = rowState => {
        onDeleteWestgardSigmaQC({
            westgardSigmaQCs: rowState,
            type: WESTGARD_TYPE.WESTGARD_RULES,
            callback: () => {
                onGetWestgardSigmaQCList({ ...paging })
            }
        })
        setRowDelete({})
        setRow({})
    }

    const onDeleteWestgardSigmaQCHandler = () => {
        onDelete({
            rowDelete,
            rows,
            onDeleteSingleRow,
            onDeleteMultipleRows,
        })
        setConfirmModal(false)
    }

    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const onGetWestgardSigmaQCList = (payload) => {
        payload.type = WESTGARD_TYPE.WESTGARD_RULES
        payload.sort = "createdDate:desc"
        insertUrlParam(payload)
        onGetWestgardSigmaQC(payload)
    }

    const fetchWestgardSigmaQC = () => {
        onGetWestgardSigmaQCList({ page: 1 })
    }

    const onRefreshHandler = () => {
        resetState()
        fetchWesgardQC()
    }

    const onSearch = searchText => {
        onGetWestgardSigmaQCList({ page: 1, search: searchText })
    }

    const onSizePerPageChange = size => {
        onGetWestgardSigmaQCList({ page: 1, size })
    }

    const onPageChange = page => {
        onGetWestgardSigmaQCList({ page })
    }

    const onSubmitFilter = values => {
        onGetWestgardSigmaQCList({ page: 1, ...values })
    }

    const onSortHandler = (field, order) => {
        const sortString = `${field}:${order}`
        onGetWestgardSigmaQCList({ page: 1, sort: sortString })
    }

    useEffect(() => {
        onResetWestgardSigmaQCSearchQuery()
    }, [])

    useEffect(() => {
        let params = getUrlParams()
        let paramPage = getUrlParamByKey("page")
        if (paramPage) {
            onGetWestgardSigmaQCList(params)
        } else {
            fetchWestgardSigmaQC()
        }
    }, [])

    return (
        <React.Fragment>
            <TitleAndTable
                table={() => (
                    <WestgardRulesQCTable
                        userPermissions={userPermissions}
                        westgardSigmaQCs={westgardSigmaQCs}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        onSearch={onSearch}
                        onSort={onSortHandler}
                        onRefresh={onRefreshHandler}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSizePerPageChange={onSizePerPageChange}
                        onSubmitFilter={onSubmitFilter}
                        onEdit={onEditHandler}
                        onDelete={onDeleteToggleHandler}
                        onClone={onCloneHandler}
                        loading={loadingWestgardSigmaQC}
                        updatedTime={updatedWestgardSigmaQCTime}
                        onUpdateWestgardSigmaQC={onUpdateWestgardSigmaQC}
                    />
                )}
                resource={RESOURCE}
                buttons={() => (
                    <>
                        <Check permission={permissionType.C} resource={RESOURCE}>
                            <CustomButton color="primary" onClick={addWestgardSigmaQCClicks} outline>
                                {t("common:Add")}
                            </CustomButton>
                        </Check>
                        <Check permission={permissionType.D} resource={RESOURCE}>
                            <CustomButton
                                color="primary"
                                onClick={e => {
                                    onDeleteToggleHandler(e, rows.id)
                                }}
                                outline
                            >
                                {t("common:Delete")}
                            </CustomButton>
                        </Check>
                    </>
                )}
                onEdit={onEditHandler}
                onDelete={onDeleteToggleHandler}
                isDisableClone
                isHideEdit
                isDropDown
                title={t("sidebar:MasterData Entry IQC")}
                subtitle={t("WestgardRulesQCList")}
            />
            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            <ConfirmModal2
                modal={confirmModal}
                title={`${t("common:Delete")} ${t("WestgardRulesQC")}`}
                message={t("message:DeleteConfirm")}
                onToggle={onDeleteToggleHandler}
                onConfirm={onDeleteWestgardSigmaQCHandler}
                isEdit
            />
            <WestgardRulesQCModal
                formEl={formEl}
                modal={modal}
                isEdit={isEdit}
                onValidSubmit={handleValidWestgardSigmaQCSubmit}
                toggle={toggle}
                data={!isEdit ? (isClone ? westgardSigmaQC : {}) : westgardSigmaQC}
            />
        </React.Fragment>
    )
}

WestgardRulesQC.propTypes = {
    westgardSigmaQCs: PropTypes.array,
    paging: PropTypes.object,
    onGetWestgardSigmaQC: PropTypes.func,
    onAddNewWestgardSigmaQC: PropTypes.func,
    onUpdateWestgardSigmaQC: PropTypes.func,
    onDeleteWestgardSigmaQC: PropTypes.func,
    onResetWestgardSigmaQCSearchQuery: PropTypes.func,
    onGetWestgardSigmaQCDetail: PropTypes.func,
    westgardSigmaQC: PropTypes.object,
    loadingWestgardSigmaQCs: PropTypes.bool,
    updatedWestgardSigmaQCTime: PropTypes.any,
    t: PropTypes.any,
}

const mapStateToProps = ({ westgardSigmaQC, Authorization }) => ({
    westgardSigmaQCs: westgardSigmaQC.westgardSigmaQCs,
    westgardSigmaQC: westgardSigmaQC.westgardSigmaQC,
    paging: westgardSigmaQC.paging,
    loadingWestgardSigmaQC: westgardSigmaQC.loadingWestgardSigmaQCs,
    updatedWestgardSigmaQCTime: westgardSigmaQC.updatedTime,
    userPermissions: Authorization.permissions || [],
})

const mapDispatchToProps = dispatch => ({
    onGetWestgardSigmaQC: payload => dispatch(getFullWestgardSigmaQC(payload)),
    onAddNewWestgardSigmaQC: ({ westgardSigmaQC, callback }) => dispatch(addNewWestgardSigmaQC(westgardSigmaQC, callback)),
    onUpdateWestgardSigmaQC: ({ westgardSigmaQC, callback }) => dispatch(updateWestgardSigmaQC(westgardSigmaQC, callback)),
    onDeleteWestgardSigmaQC: westgardSigmaQC => dispatch(deleteWestgardSigmaQCs(westgardSigmaQC)),
    onGetWestgardSigmaQCDetail: westgardSigmaQCId => dispatch(getWestgardSigmaQCDetail(westgardSigmaQCId)),
    onResetWestgardSigmaQCSearchQuery: () => dispatch(resetSearchQuery()),
    onGetWestgardSigmaQCSuccess: payload => dispatch(getFullWestgardSigmaQCSuccess(payload))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["westgardSigmaQCPage", "message"])(WestgardRulesQC)))