
import { withTranslation } from "react-i18next";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CustomDatePicker, CustomSelect } from "components/Common";
import { parameterCode } from "constant";
import { AvForm } from "availity-reactstrap-validation";
import { convertDateFormat, getInvalidMessageI18n } from "helpers/utilities";
import { getCurrentReportNumber } from "helpers/app-backend";

const KPHModal = ({
    modal,
    toggle,
    isEdit,
    t,
    data = {},
    reportCode,
    oldData
}) => {
    const [causes, setCauses] = useState([])
    const [sources, setSources] = useState([])

    return (
        <React.Fragment>
            <div className="report-result-qc-title pt-2 mb-2">
                <div className="report-result-qc-title-content" style={{ flex: 1 }}>
                    <div className="report-result-qc-title-content-left">{t("Report With")}:</div>
                    <div>{data.testCode} - {data.testName}</div>
                </div>
                <div className="report-result-qc-title-content">
                    <div className="report-result-qc-title-content-left">{t("Machine")}:</div>
                    <div>{data.machineName}</div>
                </div>
                <div className="report-result-qc-title-content">
                    <div className="report-result-qc-title-content-left">{t("Lot")}:</div>
                    <div>{data.lotCode} - {data.lotName}</div>
                </div>
                <div className="report-result-qc-title-content">
                    <div className="report-result-qc-title-content-left">{t("Level")}: </div>
                    <div>{data.levels}</div>
                </div>
                <div className="report-result-qc-title-content">
                    <div className="report-result-qc-title-content-left">{t("Single Rule")}:</div>
                    <div>{data.rule}</div>
                </div>
                <div className="report-result-qc-title-content" style={{ width: '225px' }}>
                    <div className="report-result-qc-title-content-left">{t("Report Code")}:</div>
                    <div>{data.reportCode || reportCode}</div>
                </div>
            </div>
            <div className="report-result-qc-title-content-left mb-2">{t("Content Causal")}</div>

            <div className="row">
                <div className="col-md-6 d-flex mb-3">
                    <div className="report-result-qc-title-content-input">{t("Source KPH")}</div>
                    <div className="flex-1">
                        <CustomSelect
                            required
                            readOnly={isEdit}
                            errorMessage={getInvalidMessageI18n(t, "Source KPH")}
                            name="source"
                            value={oldData.source || []}
                            isMulti={true}
                            code={parameterCode.SOURCE_KPH}
                            label={""}
                            onChange={(e, vals) => {
                                setSources(vals)
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-6 d-flex mb-3">
                    <div className="report-result-qc-title-content-input">{t("Error Type")}</div>
                    <div className="flex-1">
                        <CustomSelect
                            required
                            readOnly={isEdit}
                            errorMessage={getInvalidMessageI18n(t, "Error Type")}
                            name="errorType"
                            value={oldData.errorType || ""}
                            code={parameterCode.ERROR_TYPE}
                            label={""}
                            onChange={(e, vals) => {
                                // setReportTypes(vals)
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-6 d-flex mb-3">
                    <div className="report-result-qc-title-content-input">{t("Discoverer")}</div>
                    <div className="flex-1">
                        <CustomSelect
                            required
                            readOnly={isEdit}
                            errorMessage={getInvalidMessageI18n(t, "Discoverer")}
                            name="discoverer"
                            value={oldData.discoverer || ""}
                            code={parameterCode.USERS}
                            label={""}
                            onChange={(e, vals) => {
                                // setReportTypes(vals)
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-6 d-flex mb-3">
                    <div className="report-result-qc-title-content-input">{t("Discover Time")}</div>
                    <div className="flex-1">
                        <CustomDatePicker

                            disabled={isEdit}
                            name="discovertTime"
                            placeholder={"HH:mm DD-MM-YYYY "}
                            format={"HH:mm DD-MM-YYYY "}
                            required
                            errorMessage={getInvalidMessageI18n(t, "Discover Time")}
                            value={
                                convertDateFormat(oldData.discovertTime || new Date(), 'HH:mm DD-MM-YYYY ')
                            }
                            enableTime={true}
                            dateFormat={"H:i d-m-Y"}
                            onChangeHandler={e => {
                                // let value = moment(e.time[0]).format(
                                //     "HH:mm YYYY-MM-DD "
                                // )
                                // onInputDebounce("fromDate", value)
                            }}
                        />
                    </div>
                </div>
                <div className="col-md-6 d-flex mb-3">
                    <div className="report-result-qc-title-content-input">{t("Location")}</div>
                    <div className="flex-1">
                        <CustomSelect
                            required
                            readOnly={isEdit}
                            errorMessage={getInvalidMessageI18n(t, "Location")}
                            name="location"
                            value={oldData.location || ""}
                            code={parameterCode.QC_LOCATION}
                            label={""}
                            onChange={(e, vals) => {
                                // setReportTypes(vals)
                            }}
                        />
                    </div>
                </div>
                <div className="col-12 d-flex">
                    <div className="report-result-qc-title-content-input">{t("Causes KPH")}</div>
                    <div className="flex-1">
                        <CustomSelect
                            required
                            readOnly={isEdit}
                            errorMessage={getInvalidMessageI18n(t, "Causes KPH")}
                            isMulti={true}
                            name="causes"
                            value={oldData.causes || []}
                            code={parameterCode.QC_RESULT_RULE_CAUSES}
                            label={""}
                            onChange={(e, vals) => {
                                setCauses(vals)
                            }}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

KPHModal.defaultProps = {}

const mapStateToProps = ({ testResult }) => ({
})

const mapDispatchToProps = dispatch => ({
    // onGetNotifications: payload => dispatch(getNotifications(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["resultIQCPage", "common"])(KPHModal))
