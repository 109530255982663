import { showToast } from "components/Common"
import { createQCChange, deleteQCChange, getQCChangeById, getQCChangeList, updateQCChange } from "helpers/app-backend"
import i18n from "i18next"
import { call, put, select, takeEvery } from "redux-saga/effects"
import {
    ADD_NEW_CHANGE_QC,
    DELETE_CHANGE_QC,
    GET_CHANGE_QC_DETAIL,
    GET_LIST_CHANGE_QC,
    UPDATE_CHANGE_QC
} from "./actionTypes"
import {
    addNewChangeSIDQCFail,
    addNewChangeSIDQCSuccess,
    deleteChangeQCFail,
    deleteChangeQCSuccess,
    getListChangeQCDetailFail,
    getListChangeQCDetailSuccess,
    getListChangeQCFail,
    getListChangeQCSuccess,
    setSearchQuery,
    updateChangeQCFail,
    updateChangeQCSuccess
} from "./actions"
import { getAllMachine } from "helpers/app-backend/machines_backend_helper"
const t = (msg, param) => i18n.t(msg, param)

function* fetchGetListChangeQC({ payload }) {
    try {
        const searchQuery = yield select(state => {
             return state.changeQCs.searchQuery
        })
        payload = { ...searchQuery, ...payload }
        let response = yield call(getQCChangeList, payload)
        let newData = response.data || []
        if (newData.length > 0) {
            let ins = newData.filter(x => x.insId != null && x.insId != '').map(x => x.insId)
            if (ins.length > 0) {
                let resIns = yield call(getAllMachine, { insID: ins, size: 0 })
                if (resIns.data && resIns.data.length > 0) {
                    newData.forEach(element => {
                        element.machineName = resIns.data.find(x => x.insID == element.insId)?.name || ''
                    });
                }
            }
        }
        response.data = newData
        yield put(getListChangeQCSuccess(response))
        yield put(setSearchQuery(payload))
    } catch (error) {
        console.log(error)
        yield put(getListChangeQCFail(error))
    }
}

function* fetchChangeDetail({ id, callback }) {
    try {
        const response = yield call(getQCChangeById, id)
        yield put(getListChangeQCDetailSuccess(response))
        callback && callback(response)
    } catch (error) {
        yield put(getListChangeQCDetailFail(error))
    }
}
function* onUpdateChangeQC({ payload: { changeQC, callback } }) {
    try {
        yield call(updateQCChange, changeQC)
        yield put(updateChangeQCSuccess(changeQC))
        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    ""
                )} <span class='text-decoration-underline fw-bold'>${changeQC["sIDQCIn"]}</span>`,
            })}`
        )
        callback && callback()
    } catch (error) {
        yield put(updateChangeQCFail(error))
    }
}
function* onDeleteQCChange({ payload: { changeQC, callback } }) {
    try {
        yield call(deleteQCChange, changeQC)
        yield put(deleteChangeQCSuccess())
        showToast(
            `${t("message:DeletedMessage", {
                field: `${t(
                    ""
                )} <span class='text-decoration-underline fw-bold'></span>`,
            })}`
        )
        callback && callback()
    } catch (error) {
        yield put(deleteChangeQCFail(error))
    }
}
function* onAddNewQCChangeSIDQC({ payload: { changeQC, callback } }) {
    try {
        const response = yield call(createQCChange, changeQC)
        yield put(addNewChangeSIDQCSuccess(changeQC))
        showToast(
            `${t("message:CreatedMessage", {
                field: `${t(
                    ""
                )} <span class='text-decoration-underline fw-bold'>${changeQC["sIDQCIn"]
                    }</span>`,
            })}`
        )
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(addNewChangeSIDQCFail(error))
    }
}

function* ChangeQCsSaga() {
    yield takeEvery(GET_LIST_CHANGE_QC, fetchGetListChangeQC)
    yield takeEvery(GET_CHANGE_QC_DETAIL, fetchChangeDetail)
    yield takeEvery(ADD_NEW_CHANGE_QC, onAddNewQCChangeSIDQC)
    yield takeEvery(UPDATE_CHANGE_QC, onUpdateChangeQC)
    yield takeEvery(DELETE_CHANGE_QC, onDeleteQCChange)
}
export default ChangeQCsSaga