import PropTypes from "prop-types"
import {
  CustomBreadcrumb,
  CustomNav,
  CustomNavLink,
  PageContent,
} from "components/Common"
import { ModuleIds, permissionType } from "constant"
import { Suspense, useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Card, CardBody, TabContent, TabPane } from "reactstrap"
import ConfigLotQC from "./ConfigLotQC"
import ConfigLotTestQC from "./ConfigLotTestQC"
import { connect } from "react-redux"

const RESOURCE = ModuleIds.ManagementQC


const ManagementQCContainer = ({ t, userPermissions }) => {
  const titleTag = t(`Management QC`)
  const [currentTab, setCurrentTab] = useState("")

  const checkPermission = (resource, permission) => {
    const userPermission = userPermissions[resource]?.permissions || []
    return userPermission.includes(permission)
  }

  const setScreenAccept = () => {
    const hasLotQCPermission = checkPermission(ModuleIds.LotQC, permissionType.R)
    const hasLotTestQCPermission = checkPermission(ModuleIds.LotTestQC, permissionType.R)

    if (hasLotTestQCPermission) {
      setCurrentTab("2")
    } else if (hasLotQCPermission) {
      setCurrentTab("1")
    }
  }

  useEffect(() => {
    setScreenAccept()
  }, [userPermissions])

  return (
    <PageContent
      title={titleTag}
      className="page-content-management-qc"
      style={{ height: window.devicePixelRatio * 100 + "vh" }}
    >
      <CustomBreadcrumb
        breadcrumbItem={t("Management QC")}
        resource={RESOURCE}
      />
      <Card>
        <CardBody className="card-body-management-qc">
          <CustomNav
            onToggle={setCurrentTab}
            defaultTab={currentTab}
            tabs
            className="nav-tabs-custom"
            tabContents={customActiveTab => (
              <TabContent
                activeTab={customActiveTab}
                className="p-0 text-muted nav-tabs-custom-management-qc"
              >
                {checkPermission(ModuleIds.LotQC, permissionType.R) && (
                  <TabPane tabId="1">
                    {currentTab === "1" && (
                      <Suspense fallback={<div>Loading...</div>}>
                        <span className="config-lot-page-content">
                        <ConfigLotQC />
                        </span>
                      </Suspense>
                    )}
                  </TabPane>
                )}
                {checkPermission(ModuleIds.LotTestQC, permissionType.R) && (
                  <TabPane tabId="2">
                    {currentTab === "2" && (
                      <Suspense fallback={<div>Loading...</div>}>
                        <ConfigLotTestQC />
                      </Suspense>
                    )}
                  </TabPane>
                )}
              </TabContent>
            )}
          >
            {checkPermission(ModuleIds.LotQC, permissionType.R) && (
              <CustomNavLink tabId="1">
                <span>{t("Config Lot QC")}</span>
              </CustomNavLink>
            )}
            {checkPermission(ModuleIds.LotTestQC, permissionType.R) && (
              <CustomNavLink tabId="2">
                <span>{t("Config Lot Test QC")}</span>
              </CustomNavLink>
            )}
          </CustomNav>
        </CardBody>
      </Card>
    </PageContent>
  )
}

ManagementQCContainer.propTypes = {
  t: PropTypes.any,
  userPermissions: PropTypes.object.isRequired,
}

const mapStateToProps = ({ Authorization }) => {
  return {
    userPermissions: Authorization.permissions,
  }
}

export default connect(
  mapStateToProps,
  null
)(withTranslation(["managementQCPage", "message", "common"])(ManagementQCContainer))
