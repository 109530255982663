import {
    TitleAndTable
} from "components/Common"
import { ModuleIds } from "constant"
import { getUrlParamByKey, getUrlParams } from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { updateTestQC } from "store/connector/testQC/actions"
import {
    getFullTestSuccess,
    getTests,
    resetSearchQuery
} from "store/laboratory/tests/actions"
import TestTable from "./TestTable"
import { exportExcelTestList } from "helpers/app-backend"

const RESOURCE = ModuleIds.MachineTestiQC

const Tests = ({
    tests,
    paging,
    onGetTests,
    onResetTestSearchQuery,
    loadingTests,
    updatedTestTime,
    onUpdateTestQC,
    onGetTestsSuccess,
    t
}) => {
    const [loading, setLoading] = useState(false)
    const [queryParam, setQueryParam] = useState({ search: "", sort: "", inUse: true })
    const [loadingDownload, setLoadingDownload] = useState(false)

    useEffect(() => {
        onGetTestsSuccess([])
    }, [])

    const popStateChange = (event) => {
        let params = getUrlParams()
        let paramPage = getUrlParamByKey("page")
        if (paramPage) {
            onGetTestList(params)
        }
        else {
            fetchTests()
        }
    }

    const onGetTestList = (payload) => {
        onGetTests({ ...payload, inUse: true })
    }

    const fetchTests = () => {
        onGetTestList({ page: 1 })
    }

    const onRefreshHandler = () => {
        popStateChange();
    }

    const onSearch = searchText => {
        onGetTestList({ page: 1, search: searchText })
        setQueryParam({ ...queryParam, search: searchText })
    }

    const onSizePerPageChange = size => {
        onGetTestList({ page: 1, size })
    }

    const onPageChange = page => {
        onGetTestList({ page })
    }

    const onSortHandler = (field, order) => {
        const sortString = `${field}:${order}`
        onGetTestList({ page: 1, sort: sortString })
        setQueryParam({ ...queryParam, sort: sortString })
    }


    const onExportExcelTestList = async () => {
        try {
            setLoadingDownload(true)
            const blobData = await exportExcelTestList(queryParam);
            const blob = new Blob([blobData])
            saveAs(blob, "Danh_sach_xet_nghiem_QC.xlsx")
        } finally {
            setLoadingDownload(false)
        }
    }

    useEffect(() => {
        onResetTestSearchQuery()
    }, [])

    useEffect(() => {
        let params = getUrlParams();
        let paramPage = getUrlParamByKey("page")
        if (paramPage) {
            onGetTestList(params)
        } else {
            onGetTestList({ page: 1 })
        }
    }, [])

    const updateTestQC = (test, value) => {
        setLoading(true)
        onUpdateTestQC({ testCode: test.testCode, isQC: value }, () => {
            setLoading(false)
            onGetTestList({ page: paging.page, size: paging.size })
        })
    }

    return (
        <React.Fragment>
            {loading &&
                <div className="position-absolute h-100 w-100" style={{ zIndex: 10, backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                    <div className="w-100 h-100"><div className="text-center" style={{ marginTop: 50 }}>
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    </div>
                </div>
            }
            <TitleAndTable
                table={() => (
                    <TestTable
                        tests={tests}
                        onSearch={onSearch}
                        onSort={onSortHandler}
                        onRefresh={onRefreshHandler}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSizePerPageChange={onSizePerPageChange}
                        loading={loadingTests}
                        updatedTime={updatedTestTime}
                        updateTestQC={updateTestQC}
                        onExportExcel={onExportExcelTestList}
                        loadingDownload={loadingDownload}
                    />
                )}
                resource={RESOURCE}
                isDisableClone
                isHideEdit
                isDropDown
                title={t("sidebar:MasterData Entry IQC")}
                subtitle={t("Test List") + ' QC'}
            />
        </React.Fragment>
    )
}

Tests.propTypes = {
    tests: PropTypes.array,
    paging: PropTypes.object,
    onResetTestSearchQuery: PropTypes.func,
    onGetTestDetail: PropTypes.func,
    test: PropTypes.object,
    loadingTests: PropTypes.bool,
    updatedTestTime: PropTypes.any,
    t: PropTypes.any,
}

const mapStateToProps = ({ test }) => ({
    tests: test.tests,
    test: test.test,
    paging: test.paging,
    loadingTests: test.loadingTests,
    updatedTestTime: test.updatedTime,
})

const mapDispatchToProps = dispatch => ({
    onGetTests: payload => dispatch(getTests(payload)),
    onUpdateTestQC: (test, callback) => dispatch(updateTestQC(test, callback)),
    onResetTestSearchQuery: () => dispatch(resetSearchQuery()),
    onGetTestsSuccess: payload => dispatch(getFullTestSuccess(payload))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["testPage", "testProfilePage", "message"])(Tests)))