import { withTranslation } from "react-i18next"

import { CustomButton } from "components/Common"
import { exportQCLotTest } from "helpers/app-backend"

const ExportQCLotTestButton = ({ t, model }) => {
    const ExportResults = async () => {
        const fileData = await exportQCLotTest(model)
        const blob = new Blob([fileData])
        saveAs(blob, "ExportQCTest.xlsx")
    }

    return (
        <>
            <div className="tooltip-custom-result-qc-export-button">
                <div className="tooltip-custom">
                    <CustomButton
                        isEdit
                        color="secondary"
                        outline
                        disabled={model.lotId == null || model.lotId == ''}
                        onClick={() => {
                            ExportResults({ model })
                        }}
                    >
                        <i className={"fas fa-download"}></i>
                    </CustomButton>
                    <span className="tooltiptext-custom-button-icon-left">
                        {t("Export All")}
                    </span>
                </div>
            </div>
        </>
    )
}

export default withTranslation(["resultIQCPage", "common"])(ExportQCLotTestButton)
