import PropTypes from "prop-types"
import { Row } from "reactstrap"
//i18n
import { AvField } from "availity-reactstrap-validation"
import { CustomAvField, CustomSelect, CustomSelectAsync } from "components/Common"
import { parameterCode } from "constant"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { getInvalidMessageI18n } from "helpers/utilities"

const FilterForm = ({ model, t }) => {
    const [userName, setUserName] = useState('')
    const [users, setUsers] = useState([])

    useEffect(() => {
        if (model.userNameValid && model.userNameValid != userName)
            setUserName(model.userNameValid || '')
        if (model.userValid) {
            setUsers(model.userValid || [])
        }

    }, [model])

    return (
        <>
            <Row>
                <AvField name="userNameValid" type="hidden" value={userName || ""} />
                <div className="col-12">
                    <CustomAvField
                        name="runSeq"
                        type="number"
                        value={model.runSeq || ''}
                        label={t("Number Of Run")}
                        errorMessage={getInvalidMessageI18n(t, "Number Of Run")}
                        // onChange={onSidCurrentChangeHandler}
                        min={1}
                        max={9}
                    />
                </div>
                <div className="col-12 mb-3 select-user-valid-result-qc">
                    <CustomSelect
                        name="valState"
                        value={model.valState || ''}
                        valueName={""}
                        code={parameterCode.RESULTIQC_STATE}
                        // options={[
                        //     {
                        //         value: "UNVALID",
                        //         label: "Chưa duyệt",
                        //     },
                        //     {
                        //         value: "VALID",
                        //         label: "Đã duyệt",
                        //     },
                        //     {
                        //         value: "DEL",
                        //         label: "Đã xóa",
                        //     },
                        //     {
                        //         value: "REF",
                        //         label: "Đã loại",
                        //     }
                        // ]}
                        placeholder={t("State")}
                        label={t("State")}
                        onChange={(a, b, c, d) => {
                            model.valState = b[0]
                        }}
                    />
                </div>
                <div className="col-12 mb-3 select-user-valid-result-qc">
                    <CustomSelect
                        name="userValid"
                        placeholder={t("User Valid")}
                        value={users || []}
                        // valueNameNotSearch={userName || ''}
                        code={parameterCode.USERS_QC_RESULT_VALID}
                        label={t("User Valid")}
                        onChange={(a, b, c, d) => {
                            setUsers(b)
                        }}
                        isMulti={true}
                    />
                </div>
            </Row>

        </>
    )
}

FilterForm.propTypes = {
    model: PropTypes.object,
    t: PropTypes.any
}

const mapDispatchToProps = dispatch => ({
})

export default withTranslation(["resultIQCPage", "common"])(connect(
    null,
    mapDispatchToProps
)(FilterForm))