export const GET_LOT_TEST_QC_LIST = "GET_LOT_TEST_QC_LIST"
export const GET_LOT_TEST_QC_LIST_SUCCESS = "GET_LOT_TEST_QC_LIST_SUCCESS"
export const GET_LOT_TEST_QC_LIST_FAIL = "GET_LOT_TEST_QC_LIST_FAIL"

export const SET_LOT_TEST_QC_SEARCH_QUERY = "SET_LOT_TEST_QC_SEARCH_QUERY"

export const RESET_LOT_TEST_QC_SEARCH_QUERY = "RESET_LOT_TEST_QC_SEARCH_QUERY"

export const UPDATE_LOT_TEST_CALCULATION_QC = "UPDATE_LOT_TEST_CALCULATION_QC"
export const UPDATE_LOT_TEST_CALCULATION_QC_SUCCESS = "UPDATE_LOT_TEST_CALCULATION_QC_SUCCESS"
export const UPDATE_LOT_TEST_CALCULATION_QC_FAIL = "UPDATE_LOT_TEST_CALCULATION_QC_FAIL"

export const UPDATE_APPLY_SIGMA_TE_CALCULATION_QC = "UPDATE_APPLY_SIGMA_TE_CALCULATION_QC"
export const UPDATE_APPLY_SIGMA_TE_CALCULATION_QC_SUCCESS = "UPDATE_APPLY_SIGMA_TE_CALCULATION_QC_SUCCESS"
export const UPDATE_APPLY_SIGMA_TE_CALCULATION_QC_QC_FAIL = "UPDATE_APPLY_SIGMA_TE_CALCULATION_QC_QC_FAIL"

export const UPDATE_MEAN_PEER_QC = "UPDATE_MEAN_PEER_QC"

export const UPDATE_CV_PEER_QC = "UPDATE_CV_PEER_QC"

export const UPDATE_MEAN_EQC_QC = "UPDATE_MEAN_EQC_QC"

