import { AvForm } from "availity-reactstrap-validation"
import { Col, Row } from "reactstrap"
import { GeneralInformation } from "../Form"
import { Check, CustomButton, SystemInformation } from "components/Common"
import { ModuleIds, permissionType } from "constant"
import { isEmpty } from "lodash"
import { withTranslation } from "react-i18next"

const RESOURCE = ModuleIds.Role

const EditMode = ({ onValidSubmit, role, onCancel, t, isEdit }) => {
  if (isEdit && isEmpty(role)) return null
  return (
    <Row>
      <Col sm="12">
        <AvForm onValidSubmit={onValidSubmit} model={role}>
          {/* GeneralInformation */}
          <GeneralInformation t={t} data={role} isEdit={isEdit} />

          {/* SystemInformation */}
          <SystemInformation t={t} data={role} />
          {/* Buttons */}
          <Row>
            <Col>
              <div className="d-flex flex-wrap gap-2 modal-footer justify-content-center">
                <Check resource={RESOURCE} permission={permissionType.R}>
                  <CustomButton
                    type="button"
                    onClick={onCancel}
                    text={t("common:Cancel")}
                    className="button-width"
                  />
                </Check>
                <Check resource={RESOURCE} permission={permissionType.U}>
                  <CustomButton
                    type="submit"
                    color={"primary"}
                    className="save-user  button-width"
                    text={t("common:Save")}
                    isEdit
                    isModal
                  />
                </Check>
              </div>
            </Col>
          </Row>
        </AvForm>
      </Col>
    </Row>
  )
}

export default withTranslation(["rolePage", "common"])(EditMode)
