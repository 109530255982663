import { AvForm } from "availity-reactstrap-validation"
import {
  CustomAvField,
  CustomButton,
  CustomModal,
  CustomSelectAsync
} from "components/Common"
import FormSwitchForm from "components/Common/FormSwitchForm"
import { parameterCode } from "constant"
import { getInvalidMessageI18n } from "helpers/utilities"
import { isEmpty } from "lodash"
import { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { Col, Label, ModalBody, ModalFooter, Row } from "reactstrap"

let rules = []

const CausalActionQCModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  t,
  type
}) => {

  const message = type == 1 ? t('Causal') : t('Corrective Action')

  const title =
    (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + message
  if (isEdit && isEmpty(data)) return null

  useEffect(() => {
    return () => {
      rules = []
    }
  }, [])

  useEffect(() => {
    if (data?.rules?.length > 0) {
      rules = data?.rules?.split(',')
    }
  }, [data])

  const formEl = useRef(null)

  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody>
        <AvForm
          ref={formEl}
          id="CausalActionQCForm"
          onValidSubmit={onValidSubmit}
        >
          <CustomAvField name="id" type="hidden" value={data.id || ""} />
          <Row className="px-2">
            <Col className="col-6">
              <div className="mb-3">
                <CustomAvField
                  name="code"
                  type="text"
                  required
                  errorMessage={getInvalidMessageI18n(t, "Code")}
                  validate={{
                    required: { value: true },
                    maxLength: { value: 20 },
                  }}
                  value={data.code || ""}
                  disabled={isEdit}
                  label={t("Code")}
                  detected={isEdit}
                />
              </div>
            </Col>

            <Col className="col-6">
              <div className="mb-3">
                <CustomAvField
                  name="name"
                  type="text"
                  required
                  errorMessage={getInvalidMessageI18n(t, "Name")}
                  validate={{
                    required: { value: true },
                    maxLength: { value: 50 },
                  }}
                  value={data.name || ""}
                  label={t("Name")}
                  detected={isEdit}
                />
              </div>
            </Col>
            <Col className="col-6">
              {(!isEdit || rules.length > 0) && <div className="mb-3">
                <CustomSelectAsync
                  required
                  isMulti
                  name="rules"
                  errorMessage={getInvalidMessageI18n(t, "Rule")}
                  code={parameterCode.WESTGARD_SIGMA}
                  value={rules}
                  label={t("Rule")}
                  detected={isEdit}
                />
              </div>}
            </Col>

            <Col className="col-6">
              <div className="mb-3">
                <CustomSelectAsync
                  required
                  name="errorType"
                  errorMessage={getInvalidMessageI18n(t, "Error Type")}
                  code={parameterCode.ERROR_TYPE}
                  value={data.errorType}
                  label={t("Error Type")}
                  detected={isEdit}
                />
              </div>
            </Col>

            <Col className="col-6">
              <div className="mb-3">
                <CustomAvField
                  name="description"
                  type="text"
                  errorMessage={getInvalidMessageI18n(t, "Description")}
                  validate={{
                    maxLength: { value: 255 },
                  }}
                  value={data.description || ""}
                  label={t("Description")}
                  detected={isEdit}
                />
              </div>
            </Col>

            {/* <Col className="col-6">
              <div className="mb-3">
                <CustomSelectAsync
                  required
                  name="language"
                  errorMessage={getInvalidMessageI18n(t, "Language")}
                  value={data.language || ""}
                  code={parameterCode.LANGUAGE_CODE}
                  label={t("Language")}
                  detected={isEdit}
                />
              </div>
            </Col> */}

            <Col className="col-6">
              <Label for="inUse">{t("common:In Use")}</Label>
              <FormSwitchForm
                style={{ marginLeft: 2 }}
                name="inUse"
                label=""
                value={data.inUse || true}
              />
            </Col>
          </Row>
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          className="button-width"
        />

        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          onClick={() => formEl?.current?.submit()}
          className="save-user button-width"
          isEdit
          isModal
        />

      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["causalActionQCPage", "common"])(CausalActionQCModal)
