import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../../api_helper2"
const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/vs/reagents`

const createReagents = req => {
  return post(`${BASE_API_URL}`, req)
}

const updateReagents = req => {
  const { id } = req
  return put(`${BASE_API_URL}/${id}`, req)
}

const deleteReagents = req => {
  let ids = ""
  req.forEach(_item => (ids += `id=${_item.id}&`))
  return del(`${BASE_API_URL}?${ids}`)
}

const getReagentsList = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_API_URL}?${q}`)
}

const getReagentsById = id => {
  return get(`${BASE_API_URL}/${id}`)
}

export {
  createReagents,
  updateReagents,
  deleteReagents,
  getReagentsList,
  getReagentsById,
}
