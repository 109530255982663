import ANTIBIOTIC_RESISTANCES from "./actionTypes"

export const getListAntibioticResistances = payload => {
  return {
    type: ANTIBIOTIC_RESISTANCES.GET_LIST_ANTIBIOTIC_RESISTANCES,
    payload: payload || {},
  }
}

export const getListAntibioticResistancesSuccess = payload => ({
  type: ANTIBIOTIC_RESISTANCES.GET_LIST_ANTIBIOTIC_RESISTANCES_SUCCESS,
  payload: payload,
})

export const getListAntibioticResistancesFail = error => ({
  type: ANTIBIOTIC_RESISTANCES.GET_LIST_ANTIBIOTIC_RESISTANCES_FAIL,
  error: error,
})

export const getAntibioticResistancesDetail = (id, callback) => ({
  type: ANTIBIOTIC_RESISTANCES.GET_ANTIBIOTIC_RESISTANCES_DETAIL,
  id,
  callback,
})

export const getAntibioticResistancesDetailSuccess = payload => ({
  type: ANTIBIOTIC_RESISTANCES.GET_ANTIBIOTIC_RESISTANCES_DETAIL_SUCCESS,
  payload: payload,
})

export const getAntibioticResistancesDetailFail = error => ({
  type: ANTIBIOTIC_RESISTANCES.GET_ANTIBIOTIC_RESISTANCES_DETAIL_FAIL,
  payload: error,
})

export const addNewAntibioticResistances = payload => ({
  type: ANTIBIOTIC_RESISTANCES.ADD_NEW_ANTIBIOTIC_RESISTANCES,
  payload: payload,
})

export const addNewAntibioticResistancesSuccess = payload => ({
  type: ANTIBIOTIC_RESISTANCES.ADD_NEW_ANTIBIOTIC_RESISTANCES_SUCCESS,
  payload: payload,
})

export const addNewAntibioticResistancesFail = error => ({
  type: ANTIBIOTIC_RESISTANCES.ADD_NEW_ANTIBIOTIC_RESISTANCES_FAIL,
  error: error,
})

export const updateAntibioticResistances = payload => ({
  type: ANTIBIOTIC_RESISTANCES.UPDATE_ANTIBIOTIC_RESISTANCES,
  payload: payload,
})

export const updateAntibioticResistancesSuccess = payload => ({
  type: ANTIBIOTIC_RESISTANCES.UPDATE_ANTIBIOTIC_RESISTANCES_SUCCESS,
  payload: payload,
})

export const updateAntibioticResistancesFail = error => ({
  type: ANTIBIOTIC_RESISTANCES.UPDATE_ANTIBIOTIC_RESISTANCES_FAIL,
  error: error,
})

export const deleteAntibioticResistances = payload => ({
  type: ANTIBIOTIC_RESISTANCES.DELETE_ANTIBIOTIC_RESISTANCES,
  payload: payload,
})

export const deleteAntibioticResistancesSuccess = payload => ({
  type: ANTIBIOTIC_RESISTANCES.DELETE_ANTIBIOTIC_RESISTANCES_SUCCESS,
  payload: payload,
})

export const deleteAntibioticResistancesFail = error => ({
  type: ANTIBIOTIC_RESISTANCES.DELETE_ANTIBIOTIC_RESISTANCES_FAIL,
  error: error,
})
