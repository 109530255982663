import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { CustomBootstrapInlineTable, CustomBootstrapTable } from "components/Common";
import CustomTooltipButton from "components/Common/Button/CustomTooltipButton";
import IconStatus from "components/Common/IconStatus";
import { ModuleIds, statusTypes } from "constant";
import { indexCalculator } from "helpers/utilities";
import { withTranslation } from "react-i18next";

const RESOURCE = ModuleIds.MicroorganismList;
let expandedRowGlobals = [];
const GenusTable = ({
    onSelect,
    onSelectAll,
    dataConfigs,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onEdit,
    onDelete,
    loading,
    t,
    updatedTime,
    onClone
}) => {

    const [data, setData] = useState([]);
    const [expandedRows, setExpandedRows] = useState([])
    const [model, setModel] = useState({
        search: "",
    });
    const searchQuery = useSelector(state => state.sttGPBConfig.searchQuery);

    const onResetHandler = () => {
    }

    useEffect(() => {
        setModel(searchQuery)
    }, [searchQuery])


    useEffect(() => {
        setData([...dataConfigs])
    }, [dataConfigs])

    useEffect(() => {
        expandedRowGlobals = expandedRows
    }, [expandedRows])

    const getIndicator = (id, expands, row) => {
        let el = document.getElementById(id);
        if (el) {
            const isExpanded = expands.includes(row.id);
            el.className = isExpanded == true ? "fas fa-angle-up rotate180" : "fas fa-angle-up rotate-0";
        }

    }

    const columns = [
        {
            dataField: "id",
            text: "#",
            style: { width: 50, textAlign: 'center' },
            headerStyle: { width: "50px", textAlign: 'center' },
            formatter: (cellContent, test, index) => {
                return indexCalculator(paging.page, paging.size, index + 1)
            },
        },
        {
            dataField: "expand",
            text: "",
            headerStyle: { width: "50px", textAlign: 'center' },
            style: { width: "50px", textAlign: 'center' },
            formatter: (cellContent, row, index) => {
                if (!row.subCodes || row.subCodes.length == 0) {
                    return <></>
                }
                return <div className="table-column-indicator" style={{ color: '#556ee6' }} onClick={() => {
                    let newExpands = expandedRowGlobals;
                    newExpands = newExpands.includes(row.id) ? newExpands.filter((id) => id !== row.id) : [...newExpands, row.id]
                    setExpandedRows(newExpands)
                    getIndicator(`indicator-organism-group-${index}`, newExpands, row)
                }}>
                    <i id={`indicator-organism-group-${index}`} className="fas fa-angle-up"></i>
                </div>;
            },


        },
        {
            dataField: "code",
            text: t("Code"),
            style: { width: 150 },
            formatter: (cellContent, row) => {
                return <span style={{ color: (row.subCodes && row.subCodes.length > 0) ? '#556ee6' : '' }}>{cellContent}</span>
            }
        },
        {
            dataField: "name",
            text: t("Name"),
            style: { width: 300 },
            formatter: (cellContent, data) => {
                return <span>{cellContent}</span>
            }
        },
        {
            dataField: "organismsCode",
            text: t("Organisms Code"),
            style: { width: 150 },
            formatter: (cellContent, data) => {
                return <span>{cellContent}</span>
            }
        },
        {
            dataField: "organismsName",
            text: t("Organisms Name"),
            style: { width: 250 },
            formatter: (cellContent, data) => {
                return <span>{cellContent}</span>
            }
        },
        {
            dataField: "infectivityName",
            text: t("Infectivity"),
            formatter: (cellContent, data) => {
                return <span>{cellContent}</span>
            }
        },
        {
            dataField: "inUse",
            text: t("common:Status"),
            align: 'center',
            headerAlign: 'center',
            style: { width: 100 },
            formatter: (cellContent, item, index) => {
                return <IconStatus
                    isRound={true}
                    type={cellContent == true ? statusTypes.ACTIVE : statusTypes.INACTIVE}
                />
            },
        },
        {
            dataField: "action",
            text: "",
            style: { width: 140 },
            formatter: (cellContent, data, index) => {
                return (
                    <CustomTooltipButton
                        index={index}
                        component={data}
                        onClone={onClone}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        t={t}
                        resource={RESOURCE}
                    />
                )
            },
        },
    ]

    const columnChilds = [
        {
            dataField: "id",
            text: "#",
            style: { width: 50 },
            formatter: (cellContent, test, index) => {
                return <span></span>
            },
        },
        {
            dataField: "expand",
            text: "",
            headerStyle: { width: "50px", textAlign: 'center' },
            style: { width: "50px", textAlign: 'center' },
            formatter: (cellContent, data, index) => {
                return <span></span>
            }
        },
        {
            dataField: "code",
            text: t("Code"),
            style: { width: 150 },
            formatter: (cellContent, data) => {
                return <span></span>
            }
        },
        {
            dataField: "name",
            text: t("Name"),
            style: { width: 300 },
            formatter: (cellContent, data) => {
                return <span></span>
            }
        },
        {
            dataField: "genusCode",
            text: t("Genus Code"),
            style: { width: 150 },
            formatter: (cellContent, data) => {
                return <span>{data.code}</span>
            }
        },
        {
            dataField: "genusName",
            text: t("Genus Name"),
            style: { width: 250 },
            formatter: (cellContent, data) => {
                return <span>{data.name}</span>
            }
        },
        {
            dataField: "infectivityName",
            text: t("Infectivity"),
            formatter: (cellContent, data) => {
                return <span></span>
            }
        },
        {
            dataField: "inUse",
            text: t("common:Status"),
            align: 'center',
            headerAlign: 'center',
            style: { width: 100 },
            formatter: (cellContent, item, index) => {
                return <span></span>
            },
        },
        {
            dataField: "action",
            text: "",
            style: { width: 140 },
            formatter: (cellContent, data, index) => {
                return <span></span>
            },
        },
    ]

    const getDataExport = () => {
        let dt = data || [];
        if (dt.length > 0) {
            return dt.filter(x => x.id != -1).map((item, index) => {
                // return [
                //     { value: "H1", style: { font: { sz: "24", bold: true, name: "Times New Roman" } } },
                //     { value: "Bold", style: { font: { bold: true } } },
                //     { value: "Red", style: { fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } } } },
                // ]
                return [
                    { value: index + 1 },
                    { value: item.manageCode },
                    { value: item.formulaName },
                    { value: item.applicableName },
                    { value: item.parameters },
                    { value: item.formula },
                    { value: item.decOdd },
                    { value: item.inUse == true }
                ]
            });
        }
        return dt
    }

    const expandRow = {
        renderer: (row, rowIndex) => {
            if (!row.subCodes || row.subCodes.length == 0)
                return null
            return (<React.Fragment>
                <div className="table-expand-custom-header tableinline-scrollable table-expand-custom-child table-expand-custom-child-organism-list">
                    <CustomBootstrapInlineTable
                        columns={columnChilds}
                        data={[...row.subCodes] || []}
                        isScrollable
                        searchText={""}
                        tableHeaderClass={"col-hidden"}
                        keyField={"id"}
                        isHover={false}
                        showSelectRow={true}
                    />
                </div>
            </React.Fragment>)
        },
        expanded: expandedRows || [],
    };

    const getColumnExport = () => {
        return [
            { title: "#", width: { wpx: 50 } },
            { title: t("Expression Code"), width: { wpx: 120 } },
            { title: t("Expression Name"), width: { wpx: 120 } },
            { title: t("Applicable"), width: { wpx: 100 } },
            { title: t("Parameter"), width: { wpx: 100 } },
            { title: t("Formula"), width: { wpx: 200 } },
            { title: t("Numeric Round"), width: { wpx: 100 } },
            { title: t("Active/InActive"), width: { wpx: 160 } },
        ]
    }
    return (
        <div>
            <CustomBootstrapTable
                columns={columns}
                search
                data={data}
                paging={paging}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                onSearch={onSearch}
                onSort={onSort}
                onRefresh={onRefresh}
                onReset={onResetHandler}
                onPageChange={onPageChange}
                onSizePerPageChange={onSizePerPageChange}
                searchText={model.search}
                loading={loading}
                updatedTime={updatedTime}
                resource={RESOURCE}
                isEnableExport={false}
                isEnableCustomExportExcel={true}
                dataExport={getDataExport()}
                columnExport={getColumnExport()}
                excelConfig={{
                    font: "Times New Roman",
                    fileName: t("Formula"),
                    fontSize: "11",
                    fontSizeHeader: "11"
                }}
                expandRow={expandRow}
                showSelectRow={true}
            />

        </div>
    )
}

export default withTranslation(["organismsPage"])(GenusTable)