import { AvForm } from "availity-reactstrap-validation"

import { ModalBody, ModalFooter } from "reactstrap"

import {
  CustomAvField,
  CustomButton,
  CustomModal,
  CustomSelect
} from "components/Common"
import { parameterCode} from "constant/utility"

//i18n
import FormSwitchForm from "components/Common/FormSwitchForm"
import { getInvalidMessageI18n } from "helpers/utilities"
import { isEmpty } from "lodash"
import { useRef } from "react"
import { withTranslation } from "react-i18next"

let isChange = false;
const ExampleTypeModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  t,
}) => {

  const formEl = useRef()

  const title =
    (!!isEdit ? t("common:Edit") : t("common:Add")) + " " + t("MT/HC")
  if (isEdit && isEmpty(data)) return null

  return (
    <CustomModal modal={modal} title={title} onToggle={() => { toggle() }}>
      <ModalBody>
        <AvForm
          ref={formEl}
          id="machineForm"
          onValidSubmit={(e, values) => {
            onValidSubmit(values)
          }}
          model={data}
        >
          <CustomAvField name="id" type="hidden" value={data.id || ""} />
          <div className="row px-3">
            <div className="col-md-6">
              <div className="mb-3">
                <CustomAvField
                  name="code"
                  type="text"
                  required
                  errorMessage={getInvalidMessageI18n(t, "Code")}
                  validate={{
                    required: { value: true },
                  }}
                  value={data.code || ""}
                  label={t("Code")}
                  detected={isEdit}
                  disabled={isEdit}
                  maxLength={15}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <CustomAvField
                  name="name"
                  type="text"
                  required
                  errorMessage={getInvalidMessageI18n(t, "Name")}
                  validate={{
                    required: { value: true },
                  }}
                  value={data.name || ""}
                  label={t("Name")}
                  detected={isEdit}
                  maxLength={250}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="mb-3">
                <CustomAvField
                  name="hisCode"
                  type="text"
                  required
                  errorMessage={getInvalidMessageI18n(t, "HisCode")}
                  validate={{
                    required: { value: true },
                  }}
                  value={data.hisCode || ""}
                  label={t("HisCode")}
                  detected={isEdit}
                  maxLength={250}
                />
              </div>
            </div>
            <div className="mb-3">
              <FormSwitchForm
                name="inUse"
                label={`${t("common:Active")}: `}
                value={data.inUse == true}
              />
            </div>
          </div>
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={() => {
            isChange = false;
            toggle()
          }}
          data-dismiss="modal"
          className="button-width"
        />

        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          // disabled={disableSave}
          onClick={() => {
            formEl?.current?.submit()
          }}
          className="save-user button-width"
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["otherMicroorganismsCategory", "common"])(ExampleTypeModal)
