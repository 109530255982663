import { CustomButton } from "components/Common"
import { withTranslation } from "react-i18next"
const ImportExportButton = ({
    t, onExportQC, isExport
}) => {
    return (
        <>
            <div className="tooltip-custom">
                <CustomButton
                    isEdit
                    color="secondary"
                    outline
                    onClick={() => { onExportQC && onExportQC() }}
                    disabled={isExport}
                >
                    <i className={"fas fa-download"}></i>
                </CustomButton>
                <span className="tooltiptext-custom-button-icon-left">
                    {t("common:Export All")}
                </span>
            </div>
        </>
    )
}
export default withTranslation(["userPage", "common"])(ImportExportButton)