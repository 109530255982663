const EXAMPLE_TYPES = {
  GET_LIST_EXAMPLE_TYPES: "GET_LIST_EXAMPLE_TYPES",
  GET_LIST_EXAMPLE_TYPES_SUCCESS: "GET_LIST_EXAMPLE_TYPES_SUCCESS",
  GET_LIST_EXAMPLE_TYPES_FAIL: "GET_LIST_EXAMPLE_TYPES_FAIL",
  GET_EXAMPLE_TYPES_DETAIL: "GET_EXAMPLE_TYPES_DETAIL",
  GET_EXAMPLE_TYPES_DETAIL_SUCCESS: "GET_EXAMPLE_TYPES_DETAIL_SUCCESS",
  GET_EXAMPLE_TYPES_DETAIL_FAIL: "GET_EXAMPLE_TYPES_DETAIL_FAIL",
  ADD_NEW_EXAMPLE_TYPES: "ADD_NEW_EXAMPLE_TYPES",
  ADD_NEW_EXAMPLE_TYPES_SUCCESS: "ADD_NEW_EXAMPLE_TYPES_SUCCESS",
  ADD_NEW_EXAMPLE_TYPES_FAIL: "ADD_NEW_EXAMPLE_TYPES_FAIL",
  UPDATE_EXAMPLE_TYPES: "UPDATE_EXAMPLE_TYPES",
  UPDATE_EXAMPLE_TYPES_SUCCESS: "UPDATE_EXAMPLE_TYPES_SUCCESS",
  UPDATE_EXAMPLE_TYPES_FAIL: "UPDATE_EXAMPLE_TYPES_FAIL",
  DELETE_EXAMPLE_TYPES: "DELETE_EXAMPLE_TYPES",
  DELETE_EXAMPLE_TYPES_SUCCESS: "DELETE_EXAMPLE_TYPES_SUCCESS",
  DELETE_EXAMPLE_TYPES_FAIL: "DELETE_EXAMPLE_TYPES_FAIL",
}

export default EXAMPLE_TYPES
