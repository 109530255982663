import { AvForm } from "availity-reactstrap-validation"
import {
  Accordion,
  AccordionBody,
  AccordionWrapper,
  Check,
  CustomButton,
  CustomDatePicker,
  CustomSelectAsync
} from "components/Common"
import FormSwitchForm from "components/Common/FormSwitchForm"
import { CV_FORMULA, MEAN_FORMULA, parameterCode, permissionType } from "constant"
import { convertDateFormat, getInvalidMessageI18n } from "helpers/utilities"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { getLottestListQCSuccess, updateCvPeer, updateMeanPeer } from "store/actions"
import AddCVInfoModal from "./AddCVInfoModal"
import AddMeanPeerInfoModal from "./AddMeanPeerInfoModal"

let cvCalculateName = ''
let meanCalculateName = ''

let newDataCalculate = []

const ConfigCalculateModal = ({
  t,
  submitConfig,
  model,
  data,
  onGetLottestListQCSuccess,
  lot,
  onMeanPeerUpdate,
  onCVPeerUpdate,
  machine,
  resource
}) => {
  const ref = useRef()
  const [height, setHeight] = useState(window.innerHeight)
  const [isRender, setIsRender] = useState(false)
  const [isCV, setIsCV] = useState(false)
  const [isLot, setIsLot] = useState(false)
  const [isMean, setIsMean] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const [cvCalculation, setCVcalCulation] = useState('')
  const [meanCalculation, setMeanCalculation] = useState('')
  const [cvDate, setCVDate] = useState([convertDateFormat(new Date(), "DD-MM-YYYY"), convertDateFormat(new Date(), "DD-MM-YYYY")])
  const [meanDate, setMeanDate] = useState([convertDateFormat(new Date(), "DD-MM-YYYY"), convertDateFormat(new Date(), "DD-MM-YYYY")])
  const [lots, setLots] = useState([])
  const [cvModal, setCVModal] = useState(false)
  const [meanModal, setMeanModal] = useState(false)

  // useEffect(() => {
  //   setTimeout(() => {
  //     getHeight()
  //   }, 500)
  // }, [])

  // const getHeight = () => {
  //   let top = document
  //     .getElementById("rule-sigma-config-button")
  //     .getBoundingClientRect().top
  //   setHeight((window.innerHeight - top - 65) * window.devicePixelRatio)
  // }

  useEffect(() => {
    if (lot?.firstPointTime) {
      cvDate[0] = convertDateFormat(lot?.firstPointTime || new Date(), "DD-MM-YYYY")
      setCVDate([...cvDate])
      meanDate[0] = convertDateFormat(lot?.firstPointTime || new Date(), "DD-MM-YYYY")
      setMeanDate([...meanDate])
    }
  }, [lot])

  useEffect(() => {
    setLots([])
  }, [model?.insId])

  const onInputInfo = (values) => {

    values.forEach(item => {
      item.lottests.forEach(x => {
        if (x?.cv != null && (x?.mean != null || x?.meanPeer != null)) {
          if (x.meanPeer != null && x.mean == null) {
            x.mean = x.meanPeer
          }
          x.sd = parseFloat(((x.cv * x.mean) / 100)?.toFixed(6))
        }

      })
    })
    newDataCalculate = values
  }

  const firstPointTime = lot?.firstPointTime ? moment(lot.firstPointTime, 'YYYY-MM-DD').format("DD-MM-YYYY 00:00:00") : new Date()

  return (
    <React.Fragment>
      <Check
        resource={resource}
        permission={permissionType.CalculationSetting}>
        <div className="rule-sigma-config">
          <CustomButton
            color="secondary"
            outline
            disabled={data.length == 0}
            id="rule-sigma-config-button"
            onClick={() => {
              setIsRender(true)
              if (isRender) {
                ref.current.click()
              }
            }}
          >
            <i style={{ fontSize: 16 }} className="bx bx-cog"></i>
          </CustomButton>

          {isRender && (
            <div
              className={`settings-config-modal ${isRender ? 'active' : 'inactive'}`}
              style={{ height: "auto", maxHeight: `${height}px` }}
            >
              <div className="settings-config-modal-loading">
                <div className="col-12">
                  <AccordionWrapper defaultTab={["1"]}>
                    <Accordion tabId={"1"} title={t("Calculate Setting")}>
                      <AccordionBody>
                        <AvForm
                          onValidSubmit={(e, values) => {
                            values.cvCalculateName = cvCalculateName
                            values.meanCalculateName = meanCalculateName
                            submitConfig(values)
                            setIsRender(prev => !prev)
                            if (newDataCalculate?.length > 0) {
                              onGetLottestListQCSuccess(newDataCalculate)
                            } else {
                              onGetLottestListQCSuccess(data)
                            }
                            newDataCalculate = []
                          }}
                        >
                          <div className="d-flex mx-3">
                            <FormSwitchForm
                              name="isCV"
                              label={t("CV Calculate Method")}
                              value={isCV}
                              onChange={(value) => {
                                setIsCV(value)
                                onCVPeerUpdate([])
                                if (!value) {
                                  setIsLot(false)
                                  setCVcalCulation('')
                                  if (lot?.firstPointTime) {
                                    setCVDate([convertDateFormat(lot?.firstPointTime || new Date(), "DD-MM-YYYY"), convertDateFormat(new Date(), "DD-MM-YYYY")])
                                  } else {
                                    setCVDate([convertDateFormat(new Date(), "DD-MM-YYYY"), convertDateFormat(new Date(), "DD-MM-YYYY")])
                                  }
                                  setLots([])
                                }
                                cvCalculateName = ''
                              }}
                            />
                          </div>
                          <div className="d-flex gap-1 mx-3 mb-2">
                            <div className="col-sm-6">
                              <CustomSelectAsync
                                name="cvCalculation"
                                portal
                                isEdit
                                readOnly={!isCV}
                                required={isCV}
                                value={cvCalculation}
                                customSearchQuery={{ applicable: 'CV' }}
                                code={parameterCode.EXPRESSION_IQC}
                                errorMessage={getInvalidMessageI18n(t, "CV Calculate Method")}
                                onChange={(name, value, label, item) => {
                                  if (!value[0]) {
                                    setCVcalCulation('')
                                  } else {
                                    setCVcalCulation(value)
                                  }
                                  cvCalculateName = label
                                  if (item[0]?.manageCode == CV_FORMULA.CVNGK) {
                                    setCVModal(true)
                                  } else {
                                    onCVPeerUpdate([])
                                  }
                                }}
                              />
                            </div>
                            <div className="col-sm-6">
                              <CustomDatePicker
                                name="cvDate"
                                value={cvDate}
                                isEdit
                                disabled={!(isCV && !isLot)}
                                placeholder={"DD-MM-YYYY"}
                                dateFormat={"d-m-Y"}
                                onChangeHandler={(date, value) => {
                                  if (date.time.length == 2) {
                                    setCVDate([
                                      convertDateFormat(date.time[0] || new Date(), "DD-MM-YYYY"),
                                      convertDateFormat(date.time[1] || new Date(), "DD-MM-YYYY")
                                    ])
                                  }
                                }}
                                mode="range"
                                minDate={lot?.firstPointTime && !isLot ? convertDateFormat(lot?.firstPointTime, "DD-MM-YYYY") : null}
                                maxDate={new Date()}
                                closeOnSelect={true}
                              />
                            </div>
                          </div>

                          <div className="d-flex mx-3">
                            <FormSwitchForm
                              name="lotQcs"
                              label={t("Lot Calculate")}
                              value={isLot}
                              disabled={!isCV}
                              onChange={(value) => {
                                setIsLot(value)
                                if (!value) {
                                  setLots([])
                                  cvDate[0] = convertDateFormat(lot?.firstPointTime || new Date(), "DD-MM-YYYY")
                                  setCVDate([...cvDate])
                                }
                              }}
                            />
                          </div>
                          <div className="gap-1 mx-3 mb-5 ">
                            <div className="col-sm-12">
                              <CustomSelectAsync
                                isMulti
                                name="LotIds"
                                portal
                                isEdit
                                readOnly={!isLot}
                                required={isLot}
                                customSearchQuery={{
                                  ...model,
                                  instrumentId: model.insId,
                                  hasPoint: true,
                                  firstPointDate: moment(firstPointTime, 'DD-MM-YYYY').format("YYYY-MM-DD 00:00:00")
                                }}
                                errorMessage={getInvalidMessageI18n(t, "Lot Calculate")}
                                value={lots}
                                code={parameterCode.LOT_QC_CALCULATION}
                                onChange={(name, value, label, item) => {
                                  setLots(value)
                                  const firstPointTimes = item.map(x => x.firstPointTime)
                                  const date = Math.min(...firstPointTimes.map(date => new Date(date)))
                                  if (date && date != 'Infinity') {
                                    cvDate[0] = moment(date).format('DD-MM-YYYY HH:mm:ss')
                                    setCVDate([...cvDate])
                                  } else {
                                    cvDate[0] = convertDateFormat(lot?.firstPointTime || new Date(), "DD-MM-YYYY")
                                    setCVDate([...cvDate])
                                  }
                                }}
                              />
                            </div>
                          </div>

                          <div className="mx-3">
                            <FormSwitchForm
                              name="isMean"
                              label={t("Mean Calculate Method")}
                              value={isMean}
                              onChange={(value) => {
                                setIsMean(value)
                                onMeanPeerUpdate([])
                                if (!value) {
                                  setMeanCalculation('')
                                  if (lot?.firstPointTime) {
                                    setMeanDate([convertDateFormat(lot?.firstPointTime || new Date(), "DD-MM-YYYY"), convertDateFormat(new Date(), "DD-MM-YYYY")])
                                  } else {
                                    setMeanDate([convertDateFormat(new Date(), "DD-MM-YYYY"), convertDateFormat(new Date(), "DD-MM-YYYY")])
                                  }
                                }
                                meanCalculateName = ''
                              }}
                            />
                          </div>
                          <div className="d-flex mx-3 mb-5 gap-1">
                            <div className="col-sm-6">
                              <CustomSelectAsync
                                name="meanCalculation"
                                customSearchQuery={{ applicable: 'MEAN' }}
                                portal
                                value={meanCalculation}
                                isEdit
                                errorMessage={getInvalidMessageI18n(t, "Mean Calculate Method")}
                                required={isMean}
                                readOnly={!isMean}
                                code={parameterCode.EXPRESSION_IQC}
                                onChange={(name, value, label, item) => {
                                  if (!value[0]) {
                                    setMeanCalculation('')
                                  } else {
                                    setMeanCalculation(value)
                                  }
                                  meanCalculateName = label

                                  if (item[0]?.manageCode == MEAN_FORMULA.MEAN_PEER) {
                                    setMeanModal(true)
                                  } else {
                                    onMeanPeerUpdate([])
                                  }
                                }}
                              />
                            </div>
                            <div className="col-sm-6">
                              <CustomDatePicker
                                name="meanDate"
                                disabled={!isMean}
                                value={meanDate}
                                isEdit
                                placeholder={"DD-MM-YYYY"}
                                dateFormat={"d-m-Y"}
                                onChangeHandler={(date, value) => {
                                  if (date.time.length == 2) {
                                    setMeanDate([
                                      convertDateFormat(date.time[0] || new Date(), "DD-MM-YYYY"),
                                      convertDateFormat(date.time[1] || new Date(), "DD-MM-YYYY")
                                    ])
                                  }
                                }}
                                mode="range"
                                closeOnSelect={true}
                                minDate={lot?.firstPointTime ? convertDateFormat(lot?.firstPointTime, "DD-MM-YYYY") : null}
                                maxDate={new Date()}
                              />
                            </div>
                          </div>

                          <div className="mx-3 mb-3">
                            <FormSwitchForm
                              name="isValid"
                              label={t("Point Valid Calculate")}
                              value={isValid}
                              onChange={(value) => {
                                setIsValid(value)
                              }}
                            />
                          </div>
                          <div>
                            <button type="submit" className="d-none" ref={ref} />
                            <CustomButton
                              text={t("common:Save")}
                              type="submit"
                              className="m-3"
                              color="primary"
                              isEdit
                            />
                          </div>
                        </AvForm>
                      </AccordionBody>
                    </Accordion>
                  </AccordionWrapper>
                </div>
              </div>
            </div>
          )}

          {meanModal && <AddMeanPeerInfoModal
            modal={meanModal}
            toggle={() => { setMeanModal(prev => !prev) }}
            data={data}
            machine={machine}
            onInputInfo={onInputInfo}
          />}

          {cvModal && <AddCVInfoModal
            modal={cvModal}
            toggle={() => { setCVModal(prev => !prev) }}
            data={data}
            machine={machine}
            onInputInfo={onInputInfo}
          />}
        </div>
      </Check>
    </React.Fragment>

  )
}

ConfigCalculateModal.defaultProps = {}

const mapStateToProps = ({ testResult }) => ({})

const mapDispatchToProps = dispatch => ({
  onGetLottestListQCSuccess: (payload) => dispatch(getLottestListQCSuccess(payload)),
  onMeanPeerUpdate: (data) => (dispatch(updateMeanPeer(data))),
  onCVPeerUpdate: (data) => (dispatch(updateCvPeer(data)))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["calculationQCPage", "common"])(ConfigCalculateModal))
