import { ModalFooter, Table } from "reactstrap"
import { CustomButton, CustomModal } from "components/Common"
import React from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

const HelpModal = ({ modal, toggle, t }) => {
  const tableData = [
    { 
      field: "Expire Date", 
      description: "Ngày hết hạn của lô kiểm tra chất lượng." 
    },
    { 
      field: "Lot ID", 
      description: "Mã định danh duy nhất của lô kiểm tra chất lượng." 
    },
    { 
      field: "Lot Name", 
      description: "Tên của lô kiểm tra chất lượng." 
    },
    { 
      field: "Machines", 
      description: "Danh sách các máy liên quan đến lô kiểm tra." 
    },
    { 
      field: "Test Category", 
      description: "Danh mục kiểm tra của lô." 
    },
    { 
      field: "Levels", 
      description: "Các mức độ kiểm tra trong lô." 
    },
    { 
      field: "Tests Count", 
      description: "Tổng số lần kiểm tra trong lô." 
    },
    { 
      field: "Number of PI Points", 
      description: "Số điểm kiểm tra PI trong lô." 
    },
    { 
      field: "Number of Calibration Points", 
      description: "Số điểm hiệu chuẩn trong lô." 
    },
    { 
      field: "Manufactor", 
      description: "Nhà sản xuất của lô kiểm tra." 
    },
    { 
      field: "Lot Group", 
      description: "Nhóm lô kiểm tra." 
    },
    { 
      field: "Number of Runs", 
      description: "Số lần chạy kiểm tra trong lô." 
    },
    { 
      field: "Max Days Open", 
      description: "Số ngày tối đa lô có thể mở." 
    },
    { 
      field: "Open Date", 
      description: "Ngày mở lô kiểm tra." 
    },
    { 
      field: "Number of QC Points Run", 
      description: "Số điểm QC đã chạy trong lô." 
    },
    { 
      field: "User Created", 
      description: "Người tạo lô kiểm tra." 
    },
    { 
      field: "Date Created", 
      description: "Ngày tạo lô kiểm tra." 
    },
    { 
      field: "User Updated", 
      description: "Người cập nhật lô kiểm tra." 
    },
    { 
      field: "Date Updated", 
      description: "Ngày cập nhật lô kiểm tra." 
    },
    { 
      field: "Status", 
      description: "Trạng thái của lô kiểm tra." 
    }
  ];

  const styles = {
    tableCustom: {
      width: "100%",
      borderCollapse: "collapse",
      marginBottom: "1rem",
      backgroundColor: "#fff",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
      overflow: "hidden",
    },
    thTd: {
      padding: "0.75rem",
      verticalAlign: "top",
      borderTop: "1px solid #dee2e6",
      fontSize: "14px",
      color: "#333",
      textAlign: "left",
    },
    theadTh: {
      verticalAlign: "bottom",
      borderBottom: "2px solid #dee2e6",
      backgroundColor: "#f1f3f5",
      color: "#495057",
      fontWeight: "bold",
      textAlign: "left",
      fontSize: "15px",
    },
    stripedRow: {
      backgroundColor: "#f9f9f9",
    },
    hoverRow: {
      transition: "background-color 0.3s ease, transform 0.2s ease",
    },
    hoverRowEffect: {
      backgroundColor: "#e6f7ff",
      transform: "scale(1.02)",
    },
    buttonWidth: {
      width: "120px",
      transition: "background-color 0.3s ease, color 0.3s ease, transform 0.2s ease",
      fontSize: "14px",
      fontWeight: "bold",
    },
    buttonHover: {
      backgroundColor: "#007bff",
      color: "#fff",
      transform: "scale(1.05)",
    },
    modalBody: {
      padding: "20px",
    },
    scrollableBody: {
      maxHeight: "400px",
      overflowY: "auto",
      padding: "20px",
      border: "1px solid #dee2e6",
      borderRadius: "8px",
      backgroundColor: "#f8f9fa",
    },
    tableWrapper: {
      display: "block",
      width: "100%",
      overflowX: "auto",
    },
    separator: {
      borderLeft: "1px solid #dee2e6",
      height: "100%",
      margin: "0 10px",
    },
  };

  return (
    <React.Fragment>
      <CustomModal modal={modal} title={t("Help Modal")} onToggle={toggle} size="xl">
        <div style={styles.scrollableBody}>
          <div style={styles.tableWrapper}>
            <Table style={styles.tableCustom}>
              <thead>
                <tr>
                  <th style={styles.theadTh}>{t("Field")}</th>
                  <th style={styles.theadTh}>{t("Description")}</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr
                    key={index}
                    style={{
                      ...styles.thTd,
                      ...(index % 2 === 0 ? styles.stripedRow : {}),
                      ...styles.hoverRow,
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = styles.hoverRowEffect.backgroundColor;
                      e.currentTarget.style.transform = styles.hoverRowEffect.transform;
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = index % 2 === 0 ? styles.stripedRow.backgroundColor : "transparent";
                      e.currentTarget.style.transform = "scale(1)";
                    }}
                  >
                    <td style={styles.thTd}>{t(row.field)}</td>
                    <td style={styles.thTd}>{t(row.description)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
        <ModalFooter>
          <CustomButton
            text={t("common:Đóng")}
            type="button"
            onClick={toggle}
            data-dismiss="modal"
            style={styles.buttonWidth}
            onMouseEnter={(e) => {
              e.currentTarget.style.backgroundColor = styles.buttonHover.backgroundColor;
              e.currentTarget.style.color = styles.buttonHover.color;
              e.currentTarget.style.transform = styles.buttonHover.transform;
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.backgroundColor = "";
              e.currentTarget.style.color = "";
              e.currentTarget.style.transform = "scale(1)";
            }}
          />
        </ModalFooter>
      </CustomModal>
    </React.Fragment>
  );
};

HelpModal.defaultProps = {};

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  null,
  mapDispatchToProps
)(withTranslation(["managementQCPage", "common"])(HelpModal))