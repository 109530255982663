import { CustomBootstrapTable, CustomButton } from "components/Common"
import { ModuleIds } from "constant"
import { convertDateFormat } from "helpers/utilities"
import { isEmpty } from "lodash"
import ConfigCalculateModal from "pages/LaboratoryIQC/CalculationQC/CalculationIndex/Modal/ConfigCalculateModal"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import ReactTooltip from "react-tooltip"
import CalculationIndexButtons from "./CalculationIndexTableButtons"
import { getQCExpressionList } from "helpers/app-backend/IQC/QCExpression_backend_helper"

const RESOURCE = ModuleIds.CalculationiQC

const CalculationIndexTable = ({
  onSelect,
  lottests,
  config,
  onSearch,
  onRefresh,
  onSubmitFilter,
  loading,
  t,
  updatedTime,
  onChangeModel,
  submitConfig,
  onLottestExport,
  onLottestChange,
  onLotChange,
  lot
}) => {
  const [data, setData] = useState([])
  const [model, setModel] = useState({})
  const [machine, setMachine] = useState({})
  const [isCVEnable, setIsCVEnable] = useState(null)
  const [isMeanEnable, setIsMeanEnable] = useState(null)

  useEffect(() => {
    fetchExpression()
  }, [])

  useEffect(() => {
    onChangeModel && onChangeModel(model)
  }, [model])

  useEffect(() => {
    if (lottests) {
      let lottestData = []
      lottests?.forEach(item => {
        if (item.lottests) {
          item.lottests?.forEach(x => {
            x.testName = item.testName
          })
          lottestData.push(...item.lottests)
        }
      })

      setData([...lottestData])
      onLottestChange(lottests)
    }
  }, [lottests])

  const fetchExpression = async () => {
    let query = { size: 0, inUse: true }
    const res = await getQCExpressionList(query)

    const cv = res.data.find(item => item.manageCode == 'CV')
    const mean = res.data.find(item => item.manageCode == 'MEAN')

    if (cv) {
      setIsCVEnable(cv)
    }

    if (mean) {
      setIsMeanEnable(mean)
    }
  }

  const columns = [
    {
      dataField: "id",
      text: "",
      hidden: true,
      formatter: (cellContent, item, index) => {
        return cellContent
      },
    },
    {
      dataField: "",
      text: t(""),
      headerStyle: { width: "30px" },
      formatter: (cellContent, item, index) => {
        return <div className="d-flex flex-column gap-3">
          {/* {item?.lottests?.map((x, position) => (
            <div key={position}>
              <input
                type="checkbox"
                className="form-check-input"
                onChange={(e) => { onSelect(item, e.target.checked) }}
              />
            </div>
          ))} */}
          <input
            type="checkbox"
            className="form-check-input"
            onChange={(e) => { onSelect(item, e.target.checked) }}
          />
        </div>
      },
    },
    {
      dataField: "SEQ",
      text: "#",
      headerStyle: { width: "30px" },
      formatter: (cellContent, item, index) => {
        return index + 1
      },
    },

    {
      dataField: "testCode",
      text: t("Test Code"),
      style: { width: "70px" },
    },
    {
      dataField: "testName",
      text: t("Test Name"),
      style: { width: "300px" },
    },
    {
      dataField: "unit",
      text: t("Unit"),
      style: { width: "80px" }
    },
    {
      dataField: "levels",
      text: t("Level"),
      style: { width: "30px" },
      // formatter: (cellContent, item, index) => {
      //   return <div className="d-flex flex-column gap-3">
      //     {item?.lottests?.map((x, position) => (
      //       <div key={position}>
      //         {x.levels}
      //         {/* <hr className="my-2" /> */}
      //       </div>
      //     ))}
      //   </div>
      // },
    },
    {
      dataField: "numPoint",
      text: t("Number of Runs"),
      headerStyle: { width: "150px" },
      style: { width: "150px" },
      // formatter: (cellContent, item, index) => {
      //   return <div className="d-flex flex-column gap-3">
      //     {item?.lottests?.map((x, position) => (
      //       <div key={position}>
      //         {x?.noOfRun || '1'}
      //         {/* <hr className="my-2" /> */}
      //       </div>
      //     ))}
      //   </div>
      // },
    },
    {
      dataField: "mean",
      text: t("Mean Old"),
      style: { width: "100px" },
      formatter: (cellContent, item, index) => {
        // return <div className="d-flex flex-column gap-3">
        //   {item?.lottests?.map((x, position) => (
        //     <div key={position}>
        //       {parseFloat(x?.oldMean)}
        //     </div>
        //   ))}
        // </div>
        return <div className="d-flex flex-column gap-3">
          {parseFloat(item?.oldMean)}
        </div>
      },
    },
    {
      dataField: "sd",
      text: t("SD Old"),
      style: { width: "100px" },
      // formatter: (cellContent, item, index) => {
      //   return <div className="d-flex flex-column gap-3">
      //     {item?.lottests?.map((x, position) => (
      //       <div key={position}>
      //         {parseFloat(x?.oldSD)}
      //       </div>
      //     ))}
      //   </div>
      // },
      formatter: (cellContent, item, index) => {
        return <div className="d-flex flex-column gap-3">
          {parseFloat(item?.oldSD)}
        </div>
      },
    },
    {
      dataField: "mean",
      text: t("Mean"),
      style: { width: "100px" },
      // formatter: (cellContent, item, index) => {
      //   return <div className="d-flex flex-column gap-3">
      //     {item?.lottests?.map((x, position) => (
      //       !!x?.mean && <div key={position}>
      //         {parseFloat(x?.mean)}
      //       </div>
      //     ))}
      //   </div>
      // },

      formatter: (cellContent, item, index) => {
        return <div className="d-flex flex-column gap-3">
          {!!item?.mean && parseFloat(item?.mean)}
        </div>
      },
    },
    {
      dataField: "sd",
      text: t("SD"),
      style: { width: "100px" },
      // formatter: (cellContent, item, index) => {
      //   return <div className="d-flex flex-column gap-3">
      //     {item?.lottests?.map((x, position) => (
      //       !!x?.sd && <div key={position}>
      //         {parseFloat(x?.sd)}
      //         {/* <hr className="my-2" /> */}
      //       </div>
      //     ))}
      //   </div>
      // },

      formatter: (cellContent, item, index) => {
        return <div className="d-flex flex-column gap-3">
          {!!item?.sd && parseFloat(item?.sd)}
        </div>
      },
    },
    {
      dataField: "cv",
      text: t("%CV Old"),
      style: { width: "100px" },
      // formatter: (cellContent, item, index) => {
      //   console.log(item)
      //   return <div className="d-flex flex-column gap-3">
      //     {item?.lottests?.map((x, position) => (
      //       !!x?.cv && <div key={position}>
      //         {parseFloat(x?.cv)}
      //       </div>
      //     ))}
      //   </div>
      // },

      formatter: (cellContent, item, index) => {
        return <div className="d-flex flex-column gap-3">
          {!!item?.cv && parseFloat(item?.cv)}
        </div>
      },
    },
    {
      dataField: "evaluation",
      text: t("Evaluation"),
      style: { width: "100px" },
      formatter: (cellContent, item, index) => {
        const twoSD = parseFloat(2 * item?.sd)
        const twoSDPI = parseFloat(2 * item?.sdpi)
        const diffTwoSD = parseFloat(-2 * item?.sd)
        const diffTwoSDPI = parseFloat(-2 * item?.sdpi)
        const SD = parseFloat(item?.sd)
        const SDPI = parseFloat(item?.sdpi)

        const isAchieved = diffTwoSD > diffTwoSDPI && twoSD < twoSDPI && SD < SDPI

        if (isAchieved) {
          item.evaluation = t('Achieved')
        } else {
          item.evaluation = t('Unachieved')
        }
        return <div className="d-flex flex-column gap-3">
          {item.numPoint > 0 ? <div >
            {item?.sd != null ? <div
              data-tip
              data-for={`evaluation-${index}`}
              id={`evaluation-${index}`}
            >
              {(item?.sd && isAchieved) ? <span className="text-success"> {t('Achieved')}</span> : ''
              }
              {(item?.sd && !isAchieved) ? <span className="text-danger">{t('Unachieved')}</span> : ''
              }
              {!(isAchieved) &&
                <ReactTooltip place="left" id={`evaluation-${index}`} effect="solid">
                  <div>  {`Không đạt vì: `}  </div>
                  <div>
                    {!(diffTwoSD > diffTwoSDPI) ? `${t('2SD PXN').replace('2 SD', '-2 SD') + (diffTwoSD) + ' < ' + t('2SD NSX').replace('2 SD', '-2 SD') + (diffTwoSDPI)}` : ''}
                  </div>
                  <div>
                    {!(twoSD < twoSDPI) ? `${t('2SD PXN') + (twoSD) + ' > ' + t('2SD NSX') + (twoSDPI)}` : ''}
                  </div>
                  <div>
                    {!(SD < SDPI) ? `${t('Range (SD PXN)') + (SD) + ' > ' + t('Range (SD NSX)') + (SDPI)}` : ''}
                  </div>
                </ReactTooltip>}
            </div> : ''}
          </div> : ''}
        </div>
      },
    },
  ]

  return (
    <div className={`calculation-table`}>
      <CustomBootstrapTable
        columns={columns}
        data={data}
        onRefresh={onRefresh}
        showSelectRow={false}
        isEnableRefresh={false}
        onSubmitFilter={onSubmitFilter}
        loading={loading}
        paging={{
          dataSize: data.length,
        }}
        updatedTime={updatedTime}
        resource={RESOURCE}
        keyField="id"
        isImportExportButton={true}
        importExportForm={() => (
          <div className="tooltip-custom">
            <CustomButton
              isEdit
              color="secondary"
              outline
              disabled={data.length == 0}
              onClick={onLottestExport}
            >
              <i className={"fas fa-download"}></i>
            </CustomButton>
            <span className="tooltiptext-custom-button-icon-left">
              {t("common:Export")}
            </span>
          </div>
        )}
        customButtonRight={() => (
          <ConfigCalculateModal
            submitConfig={submitConfig}
            model={model}
            data={lottests}
            lot={lot}
            machine={machine}
            resource={RESOURCE}
          />
        )}
        customButtonTable={() => (
          <CalculationIndexButtons
            t={t}
            model={model}
            searchText={model.search}
            onSearch={onSearch}
            loading={loading}
            onMachineChange={data => {
              setMachine(data[0])
            }}
            onLotChange={onLotChange}
            onChange={val => {
              setModel({ ...model, ...val })
            }}
            onSubmit={val => {
              onSubmitFilter(val)
            }}
          />
        )}
        infoExtension={
          <div className="d-flex gap-2 mt-1 font-size-15 justify-content-end">
            <div className="tooltip-custom">
              <i
                data-tip
                data-for={`tooltip-calculate`}
                className="fas fa-exclamation-circle text-primary"
              ></i>
              <ReactTooltip
                id={`tooltip-calculate`}
                place="top"
                effect="solid"
              >
                <div>
                  {t("Calculate %CV", {
                    date: `${config?.isCV
                      ? (Array.isArray(config?.cvDate) ? config?.cvDate?.join(" - ") : config?.cvDate)
                      : !isEmpty(lot)
                        ? `${convertDateFormat(
                          (lot?.firstPointTime || new Date()),
                          "DD/MM/YYYY"
                        )} - ${convertDateFormat(new Date(), "DD/MM/YYYY")}`
                        : ""
                      }`,
                  })}
                </div>
                <div>
                  {t("Calculate Mean", {
                    date: `${config?.isMean
                      ? typeof config?.meanDate == "string"
                        ? config?.meanDate
                        : config?.meanDate?.join(" - ")
                      : !isEmpty(lot)
                        ? `${convertDateFormat(
                          (lot?.firstPointTime || new Date()),
                          "DD/MM/YYYY"
                        )} - ${convertDateFormat(new Date(), "DD/MM/YYYY")}`
                        : ""
                      }`,
                  })}
                </div>
              </ReactTooltip>
            </div>
            <span>
              <b>{t("%CV Calculate")}:</b>{" "}
              <b className="text-primary">
                {config.isCV ? config.cvCalculateName : (isCVEnable ? isCVEnable.formulaName : '')}
              </b>
            </span>
            -
            <span>
              <b>{t("Resoure Mean")}:</b>{" "}
              <b className="text-primary">
                {config.isMean ? config.meanCalculateName : (isMeanEnable ? isMeanEnable.formulaName : '')}
              </b>
            </span>
            -
            <span>
              <b>{t("Point Valid Calculate")}: </b>
              {config.isValid ? (
                <i className="font-size-15 fas fa-check-circle text-success" />
              ) : (
                <i className="font-size-15 fas fa-times-circle text-secondary" />
              )}
            </span>
          </div>
        }
      />
    </div>
  )
}

export default withTranslation(["calculationQCPage", "message"])(CalculationIndexTable)