import { AvForm } from "availity-reactstrap-validation"
import {
  CustomAvField,
  CustomButton,
  CustomCheckbox,
  CustomSelectAsync,
  CustomTagsInput,
} from "components/Common"
import CustomModal from "components/Common/Modals/CustomModal"
import { parameterCode } from "constant"
import { getInvalidMessageI18n, isEmptyValues } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import { Col, ModalBody, ModalFooter, Row } from "reactstrap"

const CodeMappingModal = ({
  modal,
  toggle,
  isEdit,
  onValidSubmit,
  data,
  formEl,
  instrumentId,
  onChange,
  t,
}) => {
  if (isEdit && isEmptyValues(data)) return null

  const title =
    (!!isEdit ? t("common:Edit") : t("common:Add")) +
    " " +
    t("machinePage:MappingCode")
  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody>
        <AvForm
          id="machineMappingForm"
          onValidSubmit={onValidSubmit}
          ref={formEl}
          model={data}
          onChange={onChange}
        >
          <Row form>
            <CustomAvField
              name="instrumentId"
              type="hidden"
              value={instrumentId || ""}
            />
            <CustomAvField
              name="instrumentMapId"
              type="hidden"
              value={data.instrumentMapId || ""}
            />

            <Col sm="12" className="mb-3">
              <CustomSelectAsync
                name="testCode"
                portal
                value={data.testCode || ""}
                code={parameterCode.LIS_MAPPING_KEY}
                required
                errorMessage={getInvalidMessageI18n(t, "machinePage:TestCode")}
                validate={{
                  required: { value: true },
                }}
                label={t("machinePage:Test Code")}
                detected={isEdit}
                valueName={data.testCode || ""}
              />
            </Col>

            <Col sm="12" className="mb-3">
              <CustomAvField
                label={t("machinePage:Instrument Code")}
                name="instrumentCode"
                type="text"
                required
                errorMessage={getInvalidMessageI18n(
                  t,
                  "machinePage:Instrument Code"
                )}
                validate={{
                  required: { value: true },
                }}
                value={data.instrumentCode || ""}
                detected={isEdit}
              />
            </Col>

            <Col sm="12" className="mb-3">
              <CustomTagsInput
                label={t("machinePage:Instrument Code") + " (Sub)"}
                name="instrumentSubCode"
                type="text"
                tagsValue={data.instrumentSubCode || ""}
                detected={isEdit}
              />
            </Col>
            <Col sm="12" className="mb-3">
              <CustomCheckbox
                name="downloadRejected"
                checked={!!data.downloadRejected}
                direction={"down"}
                label={`${t("machinePage:Download Rejected")} ?`}
                detected={isEdit}
              />
            </Col>
            <Col sm="12" className="mb-3">
              <CustomCheckbox
                name="downloadEnhanced"
                checked={!!data.downloadEnhanced}
                direction={"down"}
                label={`${t("machinePage:Download Enhanced")} ?`}
                detected={isEdit}
              />
            </Col>
            {/* <Col sm="12" className="mb-3">
              <CustomCheckbox
                name="activeTestCode"
                checked={!!data.activeTestCode}
                direction={"down"}
                label={`${t("machinePage:Active Test Code")}`}
                detected={isEdit}
              />
            </Col> */}
          </Row>
          <button type="submit" className="d-none" />
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
        />
        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          onClick={() => formEl?.current?.submit()}
          className="save-user button-width"
          isEdit
          isModal
        />
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["machinePage", "common", "message"])(
  CodeMappingModal
)
