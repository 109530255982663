import { CustomBootstrapTable } from "components/Common";
import { ModuleIds } from "constant";
import { convertDateFormat, getColorBookmark, indexCalculator } from "helpers/utilities";
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next";
import FilterForm from "./FilterForm";
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync";

const RESOURCE = ModuleIds.ChartiQC;

const TestQCTable = ({
    configs,
    onSearch,
    onRefresh,
    paging,
    onSubmitFilter,
    loading,
    t,
    updatedTime,
    onChangeModel,
    model,
    onReset,
    onSelected
}) => {
    const [testCode, setTestCode] = useState('')
    const [isPosNeg, setIsPosNeg] = useState(false)
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(configs)
        setTestCode('')
        setIsPosNeg(false)
    }, [configs])
    const activeStyle = {
        fontWeight: 'bold'
    }
    const checkActiveRow = (row) => {
        if (row.testCode === testCode && row.isPosNeg == isPosNeg) {
            return activeStyle;
        }
        return {}
    }

    let columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
            formatter: (cellContent, row, index) => {
                return <span style={checkActiveRow(row)}>{cellContent}</span>
            },
        },
        {
            dataField: "SEQ",
            text: "#",
            headerStyle: { width: "30px" },
            formatter: (cellContent, row, index) => {
                return <span style={checkActiveRow(row)}>{indexCalculator(paging.page, paging.size, index + 1)}</span>
            },
        },
        {
            dataField: "testCode",
            text: t("Test Code"),
            style: { whiteSpace: 'nowrap' },
            headerStyle: { whiteSpace: 'nowrap' },
            formatter: (cellContent, row, index) => {
                return <span style={{ color: "#556ee6", ...checkActiveRow(row) }}
                    id={`left-table-chart-id-${row.testCode}`}
                >{cellContent}</span>
            },
        },
        {
            dataField: "testName",
            text: t("Test Name"),
            headerStyle: { whiteSpace: 'nowrap' },
            style: { whiteSpace: 'nowrap' },
            formatter: (cellContent, row, index) => {
                return <span style={checkActiveRow(row)}>{cellContent}</span>
            },
        },
        {
            dataField: "isPosNeg",
            hidden: true,
        },
    ]

    return (
        <div className={`lottest-chart-table table-patient-visit table-expand-custom`} >
            <CustomBootstrapTableAsync
                columns={columns}
                data={data}
                style={{ height: '300px' }}
                onSearch={onSearch}
                onRefresh={onRefresh}
                paging={{ dataSize: data?.length, size: data?.length }}
                filterForm={() => <FilterForm model={model} onChange={onChangeModel} />}
                onReset={onReset}
                onSubmitFilter={onSubmitFilter}
                loading={loading}
                updatedTime={updatedTime}
                resource={RESOURCE}
                keyField="id"
                showSelectRow={false}
                isEnableExport={false}
                disableToggle={true}
                TableTitle={t("ListTest")}
                isScrollable
                onRowClick={(e, row, index) => {
                    if (data?.length > 0) {
                        onSelected(row)
                        setTestCode(row.testCode)
                        setIsPosNeg(row.isPosNeg)
                        setData(data.map(x => ({ ...x, selected: x.testCode === row.testCode && x.isPosNeg == row.isPosNeg })))
                    }
                }}
                headerClasses={'table-light result-test-color'}
            />
        </div>
    )
}
TestQCTable.propTypes = {
    datapoints: PropTypes.object,
    lstTest: PropTypes.array,
}
const mapStateToProps = ({ qcDatapoints }) => ({
    datapoints: qcDatapoints.datapoints,
    lstTest: qcDatapoints.lstTest,
    loadingDatapoints: qcDatapoints.loadingDatapoints,
    error: qcDatapoints.error,
})
const mapDispatchToProps = dispatch => ({
    onGetTestForChart: payload => dispatch(getTestForChart(payload)),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withRouter(
        withTranslation(["chartiQCPage", "message", "common", "sidebar"])(TestQCTable)
    )
)

