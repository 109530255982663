import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Col, Label, Row } from "reactstrap"

import { ModuleIds, parameterCode, permissionType, textFieldTypes } from "constant/utility"
import {
  getTypeByDelimiter,
  isRequiredError,
  replaceId
} from "helpers/utilities"
import {
  CustomAvField,
  CustomAvInput,
  CustomCheckbox,
  CustomDatePicker,
  CustomSelect,
  CustomSelectAsync,
  CustomSelectButton
} from "."

//i18n
import { AvRadio, AvRadioGroup } from "availity-reactstrap-validation"
import { withTranslation } from "react-i18next"
import { Accordion, AccordionBody, AccordionWrapper, Check } from ".."
import Area from "./Area"
import HisConnectForm from "./HisConnectForm"

const DynamicFormForPersonalSetting = ({ items, isEdit, t, resource, needContact = false }) => {
  const [rawItems, setRawItems] = useState([])

  let parentId = 0,
    renderedIds = []

  const itemsHandler = items => {
    const newItems = JSON.parse(JSON.stringify(items))
    newItems.map(_item => {
      if (_item.type === textFieldTypes.GROUP) parentId = _item.id
      else {
        _item.parent = parentId
      }

      return _item
    })

    setRawItems(newItems)
  }

  useEffect(() => {
    itemsHandler(items)
  }, [items])

  const renderSelect = ({
    name,
    value,
    code,
    require,
    label,
    readonly,
    isMulti,
  }) => (
    <Col sm={6} className='px-3 pt-3'>
      <CustomSelect
        isMulti={isMulti}
        name={name}
        value={value}
        code={code}
        required={require}
        label={label}
        readOnly={readonly}
        detected={false}
      />
    </Col>
  )

  const editItemHandler = ({ item, index, items }) => {
    const {
      id,
      fieldCode: name,
      fieldName,
      type,
      parameterKey,
      require,
      readonly,
      format,
      initialValue,
      tooltip,
      displayOrder,
      remark,
      resourceCode
    } = item
    let key = item.name + index || index

    let input = "",
      group = "",
      code = parameterKey,
      fieldType = getTypeByDelimiter(type)

    let value = item.value || initialValue || ""
    let valueName = item[replaceId(name)] || ""
    // const displayName = insertSpaces(name)
    const displayName = t(`personalSettingPage:${name}`)
    const sm = 12
    switch (fieldType) {
      case textFieldTypes.GROUP:
        group = (
          <Col sm={sm} className='px-3'>
            <AccordionWrapper defaultTab={1} className="px-2">
              <Accordion className="p-0" tabId={1} title={displayName}>
                <AccordionBody>
                  <Row className="px-2">
                    {renderItem(
                      rawItems.filter(_subItem => _subItem.parent === id)
                    )}
                  </Row>
                </AccordionBody>
              </Accordion>
            </AccordionWrapper>
          </Col>
        )
        break
      case textFieldTypes.MULTIPLE:
      case textFieldTypes.SINGLE:
        input = renderSelect({
          name,
          value,
          code,
          require,
          label: displayName,
          readonly,
          isMulti: fieldType === textFieldTypes.MULTIPLE,
        })
        break
      case textFieldTypes.TEXTAREA:
        input = (
          <Col sm={6} className='px-3 '>
            <CustomAvField
              name={name}
              type="textarea"
              rows="3"
              {...isRequiredError(displayName, require, t)}
              value={value}
              label={displayName}
              readOnly={readonly}
              detected={false}
            />
          </Col>
        )
        break
      case textFieldTypes.CHECKBOX:
        input = (
          <Col
            sm={sm} className='px-3 '
          >
            <div className="d-flex form-check form-switch" >
              <Label className="form-check-label">{t(`personalSetting:${name}`)}</Label>
              <CustomCheckbox
                type="checkbox"
                direction={"down"}
                name={name}
                value={value === 'True'}
                detected={false}
              />
              {remark && <span>{t(`personalSetting:${remark}`)}</span>}
            </div>
          </Col>
        )
        break

      case textFieldTypes.BUTTONGROUP:
        input = (
          <Col sm={6} className='px-3 '>
            <CustomSelectButton
              name={name}
              value={value}
              valueName={valueName}
              code={code}
              required={require}
              label={t(`personalSetting:${displayName}`)}
              readOnly={readonly}
              detected={false}
              type="button"
            />
          </Col>
        )
        break
      case textFieldTypes.NUMBER:
        input = (
          <Col sm={6} className='px-3 '>
            <CustomAvInput
              name={name}
              label={displayName}
              type="number"
              value={value}
              readOnly={readonly}
              detected={false}
              min={0}
            />
          </Col>
        )
        break
      case textFieldTypes.DATE:
        input = (
          <Col sm={6} className='px-3 '>
            <CustomDatePicker
              name={name}
              label={displayName}
              required={require}
              placeholder={format}
              format={format}
              value={value || new Date()}
              readOnly={readonly}
              detected={false}
              closeOnSelect={true}
              maxDate={new Date()}
              disabled={readonly}
            />
          </Col>
        )
        break
      case textFieldTypes.TEXT:
        input = (
          <Col sm={6} className='px-3 '>
            <CustomAvField
              name={name}
              type="text"
              {...isRequiredError(displayName, require, t)}
              value={value}
              label={displayName}
              readOnly={readonly}
              detected={false}
            />
          </Col>
        )
        break
      case textFieldTypes.RADIO:
        input =
          (
            <Col sm={6} className='px-3 '>
              <Label>{t(`personalSetting:${displayName}`)}</Label>
              <AvRadioGroup value={value} inline name={name}{...isRequiredError(displayName, require, t)}>
                <Row >
                  {code.split("-").map((item, index) =>
                    <Col key={index} xs="4" sm="4">
                      <AvRadio width={100} label={t(`generalSetting:${item}`)} value={item} />
                    </Col>
                  )}
                </Row>
              </AvRadioGroup>
            </Col>
          )
        break
      case textFieldTypes.AREA:
        input = (
          <Check permission={permissionType.U} resource={ModuleIds.PatientVisit}>
            <Col sm={12} className='px-3 '>
              <Area data={value} />
            </Col>
          </Check>

        )
        break
      case textFieldTypes.PRINTERSELECT:
        input = (
          <Col sm={6} className='px-3 mb-3'>
            <CustomSelectAsync
              name={name}
              value={value}
              isClearable={false}
              valueName={valueName}
              code={parameterCode.PRINTER}
              label={name === 'Printer' ? t("common:Printer Barcode select") : t("common:Printer Result select")}
            />
          </Col>
        )
        break
      case textFieldTypes.SELECTBOX:
        input = (
          <Col sm={6} className='px-3 ' >
            <div style={{ width: '220px' }}>
              <CustomCheckbox
                name={name}
                checked={value === 'True'}
                direction={'left'}
                label={t(`personalSetting:${displayName}`)}
                value={value}
              />
            </div>
          </Col>
        )
        break
      case textFieldTypes.FILETYPE:
        input = (
          <Col sm={6} className='px-3 ' >
            <CustomSelect
              name={name}
              isClearable={false}
              value={value || "pdf"}
              options={[
                {
                  value: "pdf",
                  label: "pdf",
                },
                {
                  value: "xlsx",
                  label: "xlsx",
                },
                {
                  value: "docx",
                  label: "docx",
                },
              ]}
              label={t("common:Export Format")}
            />
          </Col>
        )
        break
      case textFieldTypes.HISCONNECT:
        input = (
          <Col sm={12} className='mb-3 '>
            <HisConnectForm
              name={name}
              value={value}
            />
          </Col>
        )
        break
      default:
        break
    }
    return (
      <React.Fragment key={key}>
        {!input && group}
        {((input && !(item.fieldCode?.includes('.') && !!isEdit)) || needContact) && (
          input
        )}
      </React.Fragment>
    )
  }

  const renderItem = items => {
    let formUI = items.map((item, index) => {
      const { id } = item

      const exists = renderedIds.indexOf(id) >= 0
      if (exists) return true

      renderedIds.push(id)
      return editItemHandler({ item, index, items })
    })
    return formUI
  }

  return <Row>{renderItem(rawItems)}</Row>
}

DynamicFormForPersonalSetting.propTypes = {
  items: PropTypes.array.isRequired,
  t: PropTypes.func,
  isEdit: PropTypes.bool,
  isView: PropTypes.bool.isRequired,
  resource: PropTypes.string,
}

DynamicFormForPersonalSetting.defaultProps = {
  items: [],
  isView: false,
}

export default withTranslation(["common", "personalSettingPage"])(DynamicFormForPersonalSetting)
