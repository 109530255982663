import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../../api_helper2"
const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/vs/antibiotic-resistances`

const createAntibioticResistances = req => {
  return post(`${BASE_API_URL}`, req)
}

const updateAntibioticResistances = req => {
  const { id } = req
  return put(`${BASE_API_URL}/${id}`, req)
}

const deleteAntibioticResistances = req => {
  let ids = ""
  req.forEach(_item => (ids += `id=${_item.id}&`))
  return del(`${BASE_API_URL}?${ids}`)
}

const getAntibioticResistancesList = payload => {
  let q = spreadSearchQuery(payload)
  return get(`${BASE_API_URL}?${q}`)
}

const getAntibioticResistancesById = id => {
  return get(`${BASE_API_URL}/${id}`)
}

export {
  createAntibioticResistances,
  updateAntibioticResistances,
  deleteAntibioticResistances,
  getAntibioticResistancesList,
  getAntibioticResistancesById,
}
