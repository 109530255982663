import { withTranslation } from "react-i18next"
import { Row } from "reactstrap"
import WestgardSigmaIQC from "./WestgardSigmaQC"

const WestgardSigmaIQCTab = ({ t }) => {
    return (
        <Row style={{ backgroundColor: 'white' }}>
            <WestgardSigmaIQC />
        </Row>
    )
}

export default withTranslation(["westgardSigmaQCPage", "message"])(WestgardSigmaIQCTab)
