import { AvForm } from "availity-reactstrap-validation"
import {
  Accordion,
  AccordionWrapper,
  CustomAvField,
  CustomButton,
  CustomButtonGroup,
  CustomSelect,
} from "components/Common"
import FormSwitchForm from "components/Common/FormSwitchForm"
import { parameterCode } from "constant"
import { getInvalidMessageI18n } from "helpers/utilities"
import { useState } from "react"
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"

const EditMode = ({ onValidSubmit, data, onCancel, t, isEdit }) => {
  const [protocol, setProtocol] = useState("")
  const [containerPort, setContainerPort] = useState()
  return (
    <Row>
      <Col sm="12">
        <AvForm onValidSubmit={onValidSubmit} model={data}>
          <CustomAvField name="id" type="hidden" value={data.id || ""} />
          <AccordionWrapper defaultTab={"1"}>
            <Accordion tabId={"1"} title={t("Interface Setting")}>
              <Row>
              <Col xs="6">
                  <div className="mb-3">
                    <CustomAvField
                      name="name"
                      type="text"
                      value={data.name || ""}
                      label={t("common:Name")}
                      errorMessage={getInvalidMessageI18n(t, "common:Name")}
                      validate={{
                        required: { value: true },
                      }}
                      detected={isEdit}
                      required
                    />
                  </div>
                </Col>
                <Col xs="6">
                  <div className="mb-3">
                    <CustomAvField
                      name="manageCode"
                      type="text"
                      required
                      errorMessage={getInvalidMessageI18n(t, "Manage Code")}
                      validate={{
                        required: { value: true },
                      }}
                      value={data.manageCode || ""}
                      valueName={""}
                      label={t("Manage Code")}
                      detected={isEdit}
                    />
                  </div>
                </Col>
                <Col xs="6">
                  <div className="mb-3">
                    <CustomAvField
                      name="computerName"
                      type="text"
                      value={data.computerName}
                      label={t("common:Computer Name")}
                      errorMessage={getInvalidMessageI18n(t, "Computer Name")}
                      validate={{
                        required: { value: true },
                      }}
                      detected={isEdit}
                      required
                      rows="3"
                    />
                  </div>
                </Col>
                <Col xs="6">
                  <div className="mb-3">
                    <CustomAvField
                      name="moduleName"
                      type="text"
                      required
                      errorMessage={getInvalidMessageI18n(t, "Module Name")}
                      validate={{
                        required: { value: true },
                      }}
                      value={data.moduleName || "Onelis.Interface.Winservice"}
                      valueName={""}
                      label={t("Module Name")}
                      detected={isEdit}
                    />
                  </div>
                </Col>
                <Col xs="6">
                  <div className="mb-3">
                    <CustomAvField
                      name="remoteIP"
                      type="text"
                      value={data.remoteIP}
                      label={t("common:Remote IP")}
                      errorMessage={getInvalidMessageI18n(t, "Remote IP")}
                      // validate={{
                      //   pattern: {
                      //     value: remoteIPAndPortRegex,
                      //   },
                      // }}
                      // placeholder={"___.___.___."}
                      helpMessage="ex: 192.168.61.96"
                      detected={isEdit}
                      required
                    />
                  </div>
                </Col>
                <Col xs="6">
                  <div className="mb-3">
                    <FormSwitchForm
                      name="statuss"
                      label={`${t("common:Active")}: `}
                      value={data.status ?? true}
                    />
                  </div>
                </Col>
                <Col xs="6">
                  <div className="mb-3">
                    <CustomAvField
                      name="serialNum"
                      type="text"
                      value={data.serialNum}
                      label={t("common:Serial Num")}
                      required
                      errorMessage={getInvalidMessageI18n(t, "Serial Num")}
                      validate={{
                        required: { value: true },
                      }}
                      detected={isEdit}
                      rows="3"
                    />
                  </div>
                </Col>
                <Col xs="6">
                  <div className="mb-3">
                    <CustomAvField
                      name="crackNum"
                      type="text"
                      value={data.crackNum}
                      label={t("common:Crack Num")}
                      errorMessage={getInvalidMessageI18n(t, "CrackNum")}
                      validate={{
                        required: { value: true },
                      }}
                      detected={isEdit}
                      required
                      rows="3"
                    />
                  </div>
                </Col>
                
              </Row>
            </Accordion>
          </AccordionWrapper>
          <AccordionWrapper defaultTab={"2"}>
          <Accordion tabId={"1"} title={t("Analyser Settings")}>
              <Row>
                <Col xs="6">
                  <div className="mb-3">
                    <CustomSelect
                      name="instrument"
                      value={data.instrumentID >= 0 ? data.instrumentID  : ""}
                      code={parameterCode.INSTRUMENT}
                      label={t("Instrument Name")}
                      detected={isEdit}
                      onChange={(e, values, label, item) => {
                        setProtocol(item[0].protocolName)
                      }}
                      isShowParameterCode
                      errorMessage={getInvalidMessageI18n(t, "Instrument Name")}
                      required
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                </Col>
                <Col xs="6">
                  <div className="mb-3">
                    <CustomSelect
                      name="protocol"
                      value={protocol ? protocol : data.protocol || ""}
                      code={parameterCode.PROTOCOL}
                      label={t("Protocol")}
                      detected={isEdit}
                      required
                      errorMessage={getInvalidMessageI18n(t, "Protocol")}
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                </Col>
                <Col xs="6">
                  <div className="mb-3">
                    <CustomAvField
                      name="remotePort"
                      type="number"
                      value={data.remotePort}
                      label={t("common:Remote Port")}
                      onChange={(e) => {
                        setContainerPort(e)
                      }}
                      helpMessage="ex: 9000"
                      detected={isEdit}
                      required
                      errorMessage={getInvalidMessageI18n(t, "Remote Port")}
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                </Col>
                <Col xs="6">
                  <div className="mb-3">
                    <CustomAvField
                      name="containerPort"
                      type="number"
                      value={containerPort ? containerPort : data.containerPort}
                      label={t("common:Container Port")}
                      helpMessage="ex: 9000"
                      detected={isEdit}
                      required
                      errorMessage={getInvalidMessageI18n(t, "Comtainer Port")}
                      validate={{
                        required: { value: true },
                      }}
                    />
                  </div>
                </Col>
                <Col xs="6">
                  <div className="mb-3">
                    <CustomAvField
                      name="analyzerSerial"
                      type="text"
                      value={data.analyzerSerial}
                      label={t("common:Analyzer Serial")}
                      errorMessage={getInvalidMessageI18n(t, "Analyzer Seria")}
                      validate={{
                        required: { value: true },
                      }}
                      detected={isEdit}
                      required
                      rows="3"
                    />
                  </div>
                </Col>

                <Col xs="6">
                  <div className="mb-3">
                    <CustomAvField
                      name="unlockCode"
                      type="text"
                      value={data.unlockCode}
                      label={t("common:Unlock Code")}
                      errorMessage={getInvalidMessageI18n(t, "Unlock Code")}
                      validate={{
                        required: { value: true },
                      }}
                      detected={isEdit}
                      required
                      rows="3"
                    />
                  </div>
                </Col>
              </Row>
            </Accordion>
            </AccordionWrapper>
          <button type="submit" className="d-none" />
          <Row>
            <Col>
              <CustomButtonGroup className="justify-content-center">
                <CustomButton
                  text={t("common:Cancel")}
                  type="button"
                  onClick={onCancel}
                  className="button-width"
                />
                <CustomButton
                  text={t("common:Save")}
                  type="submit"
                  color="primary"
                  className="save-user button-width"
                  isEdit
                  isModal
                />
              </CustomButtonGroup>
            </Col>
          </Row>
        </AvForm>
      </Col>
    </Row>
  )
}

export default withTranslation(["interfacePage", "common", "message"])(EditMode)
