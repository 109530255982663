import {
  CustomAutoComplete,
  CustomCheckbox,
  CustomSelect,
  CustomSelectGroup
} from "components/Common"
import { parameterCode } from "constant/utility"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Row } from "reactstrap"

const FilterForm = ({ model, t }) => {
  const [lotGroup, setLotGroup] = useState(model?.lotGroup || "")

  useEffect(() => {
    setLotGroup(model?.lotGroup || "")
  }, [model?.lotGroup])

  return (
    <>
      <Row>
        <div className="mb-3">
          <CustomSelect
            portal
            name="testCategory"
            value={model?.testCategory || ""}
            code={parameterCode.TESTPROFILE_SUB_CATEGORY_CODE}
            label={t("Test Category")}
            onChange={(name, value, label, item) => {
              model.testCategory = value[0]
            }}
          />
        </div>
      </Row>
      <Row>
        <div className="mb-3">
          <CustomAutoComplete
            name="lotGroup"
            emptySelect
            value={lotGroup} 
            isClearable={false}
            code={parameterCode.LOT_GROUP_QC}
            label={t("Lot Group")}
            onChange={(e, value, item) => {
              setLotGroup(value ? value[0] : "")
              model.lotGroup = value ? value[0] : ""
            }}
          />
        </div>
      </Row>
      <Row>
        <div className="mb-3">
          <CustomSelect
            portal
            name="userId"
            value={model.userId || ""}
            code={parameterCode.USERS}
            label={t("User Name")}
            onChange={(name, value, item) => {
              model.userId = value[0]
            }}
          />
        </div>
      </Row>
      <Row>
        <div className="col-sm-8">
          <CustomCheckbox
            name="isLotExpired"
            label={t("Lot Expired")}
            value={model.isLotExpired ?? false}
            onChange={value => {
              model.isLotExpired = value
            }}
          />
        </div>
      </Row>
    </>
  )
}

FilterForm.propTypes = {
  model: PropTypes.object,
  t: PropTypes.any,
}
export default withTranslation(["managementQCPage", "common"])(FilterForm)
