import { ModuleIds } from "constant"
import { CustomBreadcrumb, PageContent } from "components/Common"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import OtherCategory from "./OtherCategory"

const RESOURCE = ModuleIds.MicroorganismOrther

const OtherCategoryContainer = ({ t }) => {
  const titleTag = `Other Category`
  return (
    <PageContent title={t(titleTag)}>
      <CustomBreadcrumb
        breadcrumbItem={t("Other Category")}
        resource={RESOURCE}
      />
      <OtherCategory />
    </PageContent>
  )
}

OtherCategoryContainer.propTypes = {
  t: PropTypes.any,
}

export default withTranslation(["otherMicroorganismsCategory"])(
  OtherCategoryContainer
)
