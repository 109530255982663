import i18n from "i18next"
import { call, put, select, takeEvery } from "redux-saga/effects"

// Settings Redux States
import {
    ADD_NEW_TEST,
    DELETE_TESTS,
    DELETE_TESTS_VENDOR,
    GET_FULL_TEST,
    GET_TESTS,
    GET_TEST_DETAIL,
    SAVE_DISPLAY_ORDER_TEST,
    UPDATE_INFO_TEST,
    UPDATE_TEST,
    UPDATE_TEST_VENDOR
} from "./actionTypes"

import {
    addTestFail,
    addTestSuccess,
    deleteTestVendorFail,
    deleteTestVendorSuccess,
    deleteTestsFail,
    deleteTestsSuccess,
    getFullTestFail,
    getFullTestSuccess,
    getTestDetailFail,
    getTestDetailSuccess,
    getTestsFail,
    getTestsSuccess,
    saveDisplayOrderTestFail,
    saveDisplayOrderTestSuccess,
    setTestSearchQuery,
    updateInfoTestFail,
    updateInfoTestSuccess,
    updateTestFail,
    updateTestSuccess,
    updateTestVendorFail,
    updateTestVendorSuccess
} from "./actions"


import {
    createTest,
    deleteTestByIds,
    deleteTestVendor,
    getAllTests,
    getFullTest,
    getTestByCode,
    getTestById,
    updateInfoTestById,
    updateOrderDisplayTest,
    updateTestById,
    updateTestVendor
} from "helpers/app-backend/tests_backend_helper"

import { showToast } from "components/Common"

const t = (msg, param) => i18n.t(msg, param)

function* fetchTestDetail({ id, callback }) {
    try {
        const ruleTest = yield select(state => {
            return state.RuleManagement.ruleTest;
        })
        const response = yield call(getTestById, id)
        yield put(getTestDetailSuccess({ ...ruleTest, ...response }))
        if (callback) {
            callback(response)
        }
    } catch (error) {
        yield put(getTestDetailFail(error))
    }
}

function* fetchTests({ payload }) {
    try {
        const searchQuery = yield select(state => {
            return state.test.searchQuery;
        })
        payload = { ...searchQuery, ...payload }

        const response = yield call(getAllTests, payload)

        yield put(getTestsSuccess({ ...response }))

        yield put(setTestSearchQuery(payload))
    } catch (error) {
        console.log(error)
        yield put(getTestsFail(error))
    }
}

function* onUpdateTest({ payload: { test, callback } }) {
    try {
        const response = yield call(updateTestById, test)
        yield put(updateTestSuccess(test))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "testPage:Test"
                )} <span class='text-decoration-underline fw-bold'>${test["testName"]
                    }</span>`,
            })}`
        )
        callback()
    } catch (error) {
        yield put(updateTestFail(error))
    }
}

function* onUpdateInfoTest({ payload, callback }) {
    try {
        const response = yield call(updateInfoTestById, payload)
        yield put(updateInfoTestSuccess(payload))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "testPage:Test"
                )} <span class='text-decoration-underline fw-bold'>${payload["testName"]
                    }</span>`,
            })}`
        )
        callback()
    } catch (error) {
        yield put(updateInfoTestFail(error))
    }
}

function* onUpdateTestVendor({ payload, callback }) {
    try {
        const response = yield call(updateTestVendor, payload)
        yield put(updateTestVendorSuccess(response))

        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t(
                    "testPage:Test"
                )}`,
            })}`
        )
        callback()
    } catch (error) {
        yield put(updateTestVendorFail(error))
    }
}

function* onDeleteTest({ payload: { tests, callback } }) {
    try {
        const response = yield call(deleteTestByIds, tests)
        yield put(deleteTestsSuccess(tests))

        showToast(
            `${t("message:DeletedMessage", {
                field: `${t("testPage:Test")}`,
            })}`
        )
        callback()
    } catch (error) {
        yield put(deleteTestsFail(error))
    }
}

function* onDeleteTestVendor({ payload, callback }) {
    try {
        const response = yield call(deleteTestVendor, payload)
        yield put(deleteTestVendorSuccess(response))

        showToast(
            `${t("message:DeletedMessage", {
                field: `${t("testPage:Test")}`,
            })}`
        )
        if (callback)
            callback()
    } catch (error) {
        yield put(deleteTestVendorFail(error))
    }
}

function* onAddNewTest({ payload: { test, history } }) {
    try {
        const response = yield call(createTest, test)
        const id = response?.id
        yield put(addTestSuccess(id))

        const url = `/Test/${id}/view`
        showToast(
            `${t("message:CreatedMessage", {
                field: `${t(
                    "testPage:Test"
                )} <span class='text-decoration-underline fw-bold'>${test["testName"]
                    }</span>`,
            })}`
        )
        history.push(url)
    } catch (error) {
        console.log(error)
        yield put(addTestFail(error))
    }
}


function* fetchFullTest({ payload }) {
    try {
        const searchQuery = yield select(state => {
            return state.test.searchQuery;
        })
        payload = { ...searchQuery, ...payload }

        const response = yield call(getFullTest, payload)

        yield put(getFullTestSuccess(response))

        yield put(setTestSearchQuery(payload))
    } catch (error) {
        console.log(error)
        yield put(getFullTestFail(error))
    }
}


function* onUpdateDisplayOrderTest({ payload, callback }) {
    try {
        const response = yield call(updateOrderDisplayTest, payload)
        yield put(saveDisplayOrderTestSuccess(response))
        showToast(
            `${t("message:UpdatedMessage", {
                field: `${t("testProfilePage:Test")}`,
            })}`
        )
        if (callback) callback()
    } catch (error) {
        console.log(error)
        yield put(saveDisplayOrderTestFail(error))
    }
}

function* testsSaga() {
    /* TEST */
    yield takeEvery(GET_TEST_DETAIL, fetchTestDetail)
    yield takeEvery(GET_TESTS, fetchTests)
    yield takeEvery(ADD_NEW_TEST, onAddNewTest)
    yield takeEvery(UPDATE_TEST, onUpdateTest)
    yield takeEvery(UPDATE_TEST_VENDOR, onUpdateTestVendor)
    yield takeEvery(DELETE_TESTS, onDeleteTest)
    yield takeEvery(DELETE_TESTS_VENDOR, onDeleteTestVendor)
    yield takeEvery(GET_FULL_TEST, fetchFullTest)
    yield takeEvery(SAVE_DISPLAY_ORDER_TEST, onUpdateDisplayOrderTest)
    yield takeEvery(UPDATE_INFO_TEST, onUpdateInfoTest)
}

export default testsSaga