import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del, getWithReport } from "../api_helper2"
import { get as getWithoutError } from "../api_helper_withoutError"

const BASE_API_URL = `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/users`

// COMPANIES
// Get All Users Method
const getAllUsers = payload => {
  let q = spreadSearchQuery(payload)

  return get(`${BASE_API_URL}?${q}`)
}

const getAllUsersWithoutError = payload => {
  let q = spreadSearchQuery(payload)

  return getWithoutError(`${BASE_API_URL}?${q}`)
}

// Get User By Id Method
const getUserById = id => {
  return get(`${BASE_API_URL}/${id}`)
}

// Delete User By Id Method
const deleteUserById = users => {
  let ids = ""
  users.forEach(_user => (ids += `id=${_user.id}&`))

  return del(`${BASE_API_URL}?${ids}`)
}

// Update User By Id Method
const updateUserById = req => {
  const { id } = req
  return put(`${BASE_API_URL}/${id}`, req)
}

// Update User By Id Method
const updateUserProfilesById = req => {
  const { id } = req
  return put(`${BASE_API_URL}/${id}/user-profiles`, req)
}
//Change password Method
const changPasswordUser = req => {
  const { id } = req
  return put(`${BASE_API_URL}/change-password/${id}`, req)
}

//Change password Method
const getImageById = ({ type, userId }) => {
  return getWithReport(
    `${BASE_API_URL}/${userId}/image/${type}`
  )
}

// Create User By Id Method
const createUser = req => {
  return post(`${BASE_API_URL}`, req)
}

const CountNotificationByUserId = req => {
  const { id } = req
  return get(`${BASE_API_URL}/count-notifications/${id}`)
}

const exportUserList = (payload) => {
  let q = spreadSearchQuery(payload)
  return post(`${BASE_API_URL}/export-user-list?${q}`, null, {
    responseType: 'arraybuffer',
    headers:
    {
      'Content-Disposition': "attachment; filename=ExportUserList.xlsx",
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    },
  })
}

const getUserListByResouceAction = payload => {
  let q = spreadSearchQuery(payload)

  return get(`${BASE_API_URL}/GetUserListByResouceAction?${q}`)
}

export {
  getAllUsers,
  getUserById,
  updateUserById,
  deleteUserById,
  createUser,
  updateUserProfilesById,
  changPasswordUser,
  getImageById,
  CountNotificationByUserId,
  exportUserList,
  getAllUsersWithoutError,
  getUserListByResouceAction
}
