import { Collapse, Label, TabContent, TabPane } from "reactstrap"

import {
    Check,
    ConfirmModal2,
    CustomAutoComplete,
    CustomMultiSelectAsync,
    CustomNav,
    CustomNavLink,
    showErrToast,
    showToast,
    WarningModal
} from "components/Common"
import { convertDateFormat, GetDataUrlReportConfig, sidFormat } from "helpers/utilities"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import TabSample from "./TabSample/TabSample"

import {
    CustomDatePicker
} from "components/Common"

import { AvForm } from "availity-reactstrap-validation"
import BadgeLabel from "components/Common/BadgeLabel"
import { DeliveryStateText, ModuleIds, ReportResource, SAMPLES_DELIVERY_TYPE, STTVS, parameterCode, permissionType } from "constant"
import { connect } from "react-redux"
import { addDelivery, addDeliverySample, addDeliveryTest } from "store/actions"
import TabTest from "./TabTest/TabTest"
import ConfirmModal3 from "components/Common/Modals/ConfirmModal3"


import { getAuthorizePage, getFilePrint, pingToPrintService, sendDataPrint } from "helpers/app-backend"
import { isEmpty } from "lodash"
import { showWanringToast } from "components/Common/ShowWarningToastr"
import TestMicrobialModal from "./TabTest/Modal/TestMicrobialModal"

let deliveryData = []

const AddSampleDelivery = ({
    samples,
    onDelete,
    onCreateDeliverySample,
    onAddNewDelivery,
    onDeliveryTypeChange,
    onBarCodeChange,
    onRefreshHandler,
    onBarcode,
    t,
    currentTab,
    onCreateDeliveryTest,
    onLoad,
    sidTypeExcludeChange,
    sampleList,
    oldBarcode
}) => {

    const [tabActive, setTabActive] = useState("1")
    const userInfo = localStorage.getItem("userInfo")
    const { family_name, given_name, name, sub, company } = JSON.parse(userInfo)
    const [deliveryType, setDeliveryType] = useState(SAMPLES_DELIVERY_TYPE.Delivery)
    const [enableBarcode, setEnableBarcode] = useState(true)
    const [confirmedTime, setConfirmedTime] = useState(new Date())
    const [itemSample, setItemSample] = useState(samples || {})
    const [isOpen, setIsOpen] = useState(true)
    const [timeSchedual, setTimeSchedual] = useState({})
    const [handlerUserId, setHandlerUserId] = useState({ value: sub, name: `${family_name} ${given_name}` })
    const [targetUserId, setTargetUserId] = useState({ value: sub, name: `${family_name} ${given_name}` })
    const [warningReasonModal, setWarningReasonModal] = useState([])
    const [confirmModal, setConfirmModal] = useState(false)
    const [testSample, setTestSample] = useState({})
    const [dataTableTest, setDataTableTest] = useState([])
    const [printerName, setPrinterName] = useState('')
    const [reportInfos, setReportInfos] = useState({})
    const [confirmMicrobialModal, setConfirmMicrobialModal] = useState(false)

    let printConfig = localStorage.getItem("printConfig")

    const getReportResultInfos = async () => {
        const res = await getAuthorizePage(1, "_TestRequest")
        if (res) {
            setReportInfos(res._ReportInfo)
        }
    }

    useEffect(() => {
        const tmpPrinter = printConfig ? JSON.parse(printConfig) : {}
        setPrinterName(tmpPrinter?.printerName)
    }, [printConfig])

    useEffect(() => {
        getReportResultInfos()
    }, [])

    let typeSid = sampleList.map(item => ({ label: item.sampleType, value: item.sampleType }))
    typeSid = _.uniqBy(typeSid, "value")

    const ref = useRef()

    let handlerUserName = ''
    let confirmedName = ''
    let bageName = ''
    let confirmedUserId = ''
    let confirmedUserName = ''
    let targetUserName = ''
    let resource = ''

    switch (deliveryType) {
        case SAMPLES_DELIVERY_TYPE.DeliveryAndReceipt:
            handlerUserName = 'Receiver Name'
            confirmedName = 'DeliveryTime'
            bageName = 'DeliveryAndReceipt'
            targetUserName = 'Sender Name'
            confirmedUserId = handlerUserId.value
            confirmedUserName = handlerUserId?.name
            resource = ModuleIds.DeliveryManagement
            break
        case SAMPLES_DELIVERY_TYPE.Delivery:
            handlerUserName = 'Sender Name'
            confirmedName = 'DeliveryTime2'
            bageName = 'Delivery'
            targetUserName = 'Sender Name'
            confirmedUserId = targetUserId.value
            confirmedUserName = targetUserId?.name
            resource = ModuleIds.Delivery
            break
        case SAMPLES_DELIVERY_TYPE.Receipt:
            handlerUserName = 'Receiver Name'
            confirmedName = 'Receive Time'
            bageName = 'Receipt'
            targetUserName = 'Receiver Name'
            confirmedUserId = handlerUserId.value
            confirmedUserName = handlerUserId?.name
            resource = ModuleIds.Receipt
            break
        case SAMPLES_DELIVERY_TYPE.Correction:
            handlerUserName = `${t('Receiver Name')} ${t('Broken')}`
            confirmedName = 'ReturnTime'
            bageName = 'Return Sample'
            targetUserName = 'ReturnName'
            confirmedUserId = targetUserId?.value
            confirmedUserName = targetUserId?.name
            resource = ModuleIds.Correction
            break
        case SAMPLES_DELIVERY_TYPE.Cancelation:
            handlerUserName = 'CancelName'
            confirmedName = 'CancelTime'
            bageName = 'CancelSampleInfo'
            targetUserName = 'Sender Name'
            confirmedUserId = handlerUserId?.value
            confirmedUserName = handlerUserId?.name
            resource = ModuleIds.Cancelation
            break
    }

    const getTitle = () => {
        switch (currentTab) {
            case 'D':
                return 'deliveryPage:Sample Delivery';
            case 'C':
                return 'Return Sample';
            case 'U':
                return 'CancelSampleInfo';
            case 'A':
                return 'deliveryPage:DeliveryOnly';
            case 'B':
                return 'deliveryPage:ReceiptOny';
            default:
                return "";
        }
    }

    useEffect(() => {
        if (samples) {
            samples.forEach((item) => {
                item.confirmedUserId = confirmedUserId
                item.confirmedUserName = confirmedUserName
                item.quality = deliveryType === SAMPLES_DELIVERY_TYPE.Correction ? 'B' : 'G'
                item.qualityName = deliveryType === SAMPLES_DELIVERY_TYPE.Correction ? t('Not Good') : t('Good')
            })
            setItemSample(samples)
        } else {
            setItemSample([])
        }
    }, [samples])

    useEffect(() => {
        setDeliveryType(currentTab)
        deliveryData = []
    }, [currentTab])

    useEffect(() => {
        itemSample.forEach((item) => {
            item.confirmedUserId = confirmedUserId
            item.confirmedUserName = confirmedUserName
        })
        setItemSample([...itemSample])
    }, [targetUserId, handlerUserId])

    const onToggleTab = tab => {
        setTabActive(tab)
    }

    const PrintConfig = async (dataPrintSTT, testGPB) => {
        const print = JSON.parse(localStorage.getItem("printConfig"))
        let printColumns = print.columns
        let printPage = ['B1', 'B2', 'B3']

        let sttGPB = testGPB.map(_data => _data?.STTGPB.toString())
        let formattedSttGPB = sttGPB?.map(item => `'${item}'`)

        let sampleTypeLst = dataPrintSTT.map(_data => _data?.sampleType.toString())
        let formattedSampleType = sampleTypeLst?.map(item => `'${item}'`)

        let SIDLst = dataPrintSTT?.map(_data => _data?.sid)
        let subSIDLst = dataPrintSTT?.map(_data => _data?.subSID)
        const updatedArraySubSID = subSIDLst.map(item => (item === '' || item === null ? 0 : item));

        let requestDateLst = dataPrintSTT.map(_data => _data?.requestDate.toString())
        let formattedRequestDate = requestDateLst?.map(item => `'${item}'`)

        if (reportInfos.length > 0 && !isEmpty(reportInfos[0].uri)) {
            const res = await getFilePrint({
                ...GetDataUrlReportConfig(reportInfos, ReportResource.RequestId, ""),
                SID: SIDLst?.length > 1 ? `[${SIDLst}]` : SIDLst[0],
                SubSID: updatedArraySubSID?.length > 1 ? `[${updatedArraySubSID}]` : updatedArraySubSID[0],
                RequestDate: formattedRequestDate?.length > 1 ? `[${formattedRequestDate}]` : requestDateLst[0],
                SampleType: formattedSampleType?.length > 1 ? `[${formattedSampleType}]` : sampleTypeLst[0],
                STTGPB: formattedSttGPB?.length > 1 ? `[${formattedSttGPB}]` : sttGPB[0],
                // Gender: patientVisit.gender,
                Page: "B4"
            })
            try {
                const getPingPrintService = await pingToPrintService();
                if (getPingPrintService.pingTime) {
                    const dataSendPrint = await sendDataPrint({
                        ...GetDataUrlReportConfig(reportInfos, ReportResource.RequestId, ""),
                        "filePath": `${res.reportUrl}`,
                        "printerNameBarcode": printerName
                    })

                    if (dataSendPrint === 'failed') {

                        showErrToast(
                            'Vui lòng chọn máy in'
                        )

                        setTimeout(() => {
                            window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
                        }, 1500)

                    }
                } else {
                    window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
                }
            }
            catch {
                window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
            }
        }
        else {
            console.log('failed')
        }
    }

    const onCreateDelivery = (data) => {
        // let reasonIsEmpty = []
        // if (deliveryType === SAMPLES_DELIVERY_TYPE.Cancelation) {
        //     reasonIsEmpty = testSample.items.filter(item => !item.reason)
        //     setWarningReasonModal([...reasonIsEmpty])
        // }

        // if (deliveryType === SAMPLES_DELIVERY_TYPE.Correction) {
        //     reasonIsEmpty = data.filter(item => !item.reason)
        //     setWarningReasonModal([...reasonIsEmpty])
        // }

        // if (reasonIsEmpty.length > 0) {
        //     return
        // }

        let value = {
            deliveryTime: convertDateFormat(new Date(), "YYYY-MM-DD HH:mm"),//confirmedTime,
            type: deliveryType,
            state: DeliveryStateText.OPEN,
            companyId: company,
            handlerUserId: handlerUserId.value,
            handlerUserName: handlerUserId.name,
            targetUserId: targetUserId.value,
            targetUserName: targetUserId.name,
            isGetName: false
        }
        const sampleData = deliveryType === SAMPLES_DELIVERY_TYPE.Cancelation ? samples : data

        let result = sampleData.reduce((unique, o) => {
            if (!unique.some(item => item.requestId === o.requestId
                && item.sampleType === o.sampleType
                && item.sid === o.sid
                && item.subSID === o.subSID)) {
                unique.push(o);
            }
            return unique;
        }, [])

        let samps = getSampleAfterCreate(result)
        value.samples = samps;
        let tests = dataTableTest.map(x => ({
            requestId: x.requestId,
            SID: x.sid,
            testCode: x.testCode,
            STTGPB: x.sttgpb,
            isGPB: x.isGPB,
            STTVS: x.sttvs,
        }))

        let checkTestSttGPB = tests.some(_test => _test.STTGPB !== '');
        let isEmptySTTGPB = tests.some(_test => _test.isGPB == true && (_test.STTGPB === undefined || _test.STTGPB == null || _test.STTGPB == ''));

        if (isEmptySTTGPB) {
            showWanringToast(
                `${t("message:PathoLogyMessage", {
                    field: `${t(
                        "testRequestPage:STT GPB"
                    )} <span class='text-decoration-underline fw-bold'></span>`,
                })}`
            )
            return;
        }
        onAddNewDelivery({
            delivery: value, callback: (delivery) => {
                onAddSample(delivery, result, tests)
                onLoad(true)
                deliveryType === SAMPLES_DELIVERY_TYPE.Receipt && checkTestSttGPB && setTimeout(() => {
                    PrintConfig(data, tests)
                }, 1000);
            }
        })
    }

    const getSampleAfterCreate = (values) => {
        const result = []
        for (let index = 0; index < values.length; index++) {
            let res = {}
            const element = values[index]
            res.requestId = element.requestId
            res.patientId = element.patientId
            if (values[index].sampleType)
                res.sampleType = values[index].sampleType
            else
                res.sampleType = values[index]
            res.sid = element.sid
            res.confirmedUserId = confirmedUserId
            if (element?.isChangeTime) {
                res.confirmedTime = element.confirmedTime
            }
            res.reason = element.reasonName
            res.quality = element.quality
            res.remark = element.remark
            if (element.subSID)
                res.subSID = element.subSID
            res.method = element.method || ''
            result.push(res)
        }
        return result
    }

    const onAddSample = (delivery, values, tests = []) => {
        const result = []
        for (let index = 0; index < values.length; index++) {
            let res = {}
            const element = values[index]
            res.deliveryId = delivery.id
            res.requestId = element.requestId
            res.patientId = element.patientId
            if (values[index].sampleType)
                res.sampleType = values[index].sampleType
            else
                res.sampleType = values[index]
            res.sid = element.sid
            res.subSID = element.subSID ? element.subSID : null
            res.confirmedUserId = confirmedUserId
            if (element?.isChangeTime) {
                res.confirmedTime = element.confirmedTime
            }
            res.reason = element.reasonName
            res.quality = element.quality
            res.quantity = Math.abs(parseInt(element.quantity, 10)) || 1
            res.remark = element.remark
            res.method = element.method || ''
            result.push(res)
        }
        onSaveSample({ deliveryId: delivery.id, samples: result, tests }, delivery.id)
    }

    const onSaveSample = (values, deliveryId) => {
        onCreateDeliverySample(values, deliveryType, (message) => {
            if (message && message != '') {
                showToast(
                    `${t(message)}`
                )
                setConfirmModal(prev => !prev)
                afterCreateMulti(values)
            }
            if (deliveryType === SAMPLES_DELIVERY_TYPE.Cancelation) {
                onSaveTest(deliveryId)
            }

        })
    }

    const afterCreateMulti = (deliveryId) => {
        onRefreshHandler()
        onLoad(false)
    }

    const onSaveTest = values => {
        const result = {}
        result.deliveryId = values
        result.reason = testSample?.reason?.code
        // result.confirmedTime = confirmedTime
        result.confirmedUserId = handlerUserId.value
        result.ConfirmedUserId = handlerUserId.value
        result.items = testSample.items.map(x => ({
            requestId: x.requestId,
            SID: x.sid,
            requestDate: x.requestDate,
            patientId: x.patientId,
            testCode: x.testCode,
            reason: x.reasonName,
        }))
        onCreateDeliveryTest(result, () => {
            setConfirmModal(prev => !prev)
            afterCreateMulti()
        })
    }

    const onTypeChange = (data) => {
        setDeliveryType(data)
        onDeliveryTypeChange(data)
    }

    const onTimeChange = (value, index) => {
        timeSchedual[index] = value
        setTimeSchedual(timeSchedual)
    }

    const handleTest = (data) => {
        data.items.forEach(item => {
            // item.confirmedTime = confirmedTime
            item.confirmedUserName = handlerUserId?.name
        })
        setTestSample(() => ({ ...data }))
    }

    const onDeleteTest = (data) => {
        if (data.items && data.items.length > 0) {
            const sampleResult = samples.filter((item) =>
                data.items.findIndex(x => x.sid === item.sid
                    && x.sampleType === item.sampleType
                    && x.requestId === item.requestId) === -1
            )
            if (sampleResult.length > 0) {
                onDelete(sampleResult)
            }
        } else {
            onDelete(samples)
        }
    }

    const sidReasonWarning = [...new Set(warningReasonModal.map(item => sidFormat(item)))].join(', ')

    const testCount = () => {
        if (dataTableTest.length == 0) return 0;
        const uniqueSet = new Set();
        dataTableTest.forEach(o => {
            const key = `${o.sid}-${o.sampleType}-${o.requestId}-${o.profileCode || o.testCode}`;
            uniqueSet.add(key);
        });
        return uniqueSet.size;
    }

    const renderUserDelivery = () => {
        return <div className="col-xl-2">
            <div className="d-flex align-items-center gap-2">
                <div style={{ width: '100%' }}>
                    <CustomAutoComplete
                        name="targetUserId"
                        // readOnly
                        isClearable={false}
                        required
                        label={t(targetUserName)}
                        value={targetUserId.value || ''}
                        code={parameterCode.USERS}
                        onChange={(name, value, item) => {
                            if (typeof value[0] === 'string') {
                                setTargetUserId({ ...targetUserId, name: value[0] })
                            } else {
                                setTargetUserId({ name: item[0]?.label, value: item[0]?.id })
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    }
    const renderUserReceipt = () => {
        return <div className="col-xl-2">
            <div className="d-flex align-items-center gap-2">
                <div style={{ width: '100%' }}>
                    <CustomAutoComplete
                        name="handlerUserId"
                        isClearable={true}
                        // readOnly={deliveryType != SAMPLES_DELIVERY_TYPE.Correction}
                        // required
                        value={handlerUserId.value || ''}
                        code={parameterCode.USERS}
                        label={t(handlerUserName)}
                        onChange={(name, value, item) => {
                            if (typeof value[0] === 'string') {
                                setHandlerUserId({ ...handlerUserId, name: value[0] })
                            } else {
                                setHandlerUserId({ name: item[0]?.label, value: item[0]?.id })
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    }
    const renderUserDeliveryAndReceipt = () => {
        if (deliveryType == SAMPLES_DELIVERY_TYPE.DeliveryAndReceipt) {
            return <>
                {renderUserDelivery()}
                {renderUserReceipt()}
            </>
        }
        else if (deliveryType == SAMPLES_DELIVERY_TYPE.Delivery) {
            return <>
                {renderUserDelivery()}
            </>
        } else if (deliveryType == SAMPLES_DELIVERY_TYPE.Receipt) {
            return <>
                {renderUserReceipt()}
            </>
        }
        else if (deliveryType == SAMPLES_DELIVERY_TYPE.Correction) {
            return <>
                {renderUserDelivery()}
                {renderUserReceipt()}
            </>
        }
        else if (deliveryType == SAMPLES_DELIVERY_TYPE.Cancelation) {
            return <>
                {renderUserReceipt()}
            </>
        }

    }

    const onConfirmCreateDelivery = () => {
        let reasonIsEmpty = []
        if (deliveryType === SAMPLES_DELIVERY_TYPE.Cancelation) {
            reasonIsEmpty = testSample?.items?.filter(item => !item.reason)
            setWarningReasonModal([...reasonIsEmpty])
        }

        if (deliveryType === SAMPLES_DELIVERY_TYPE.Correction) {
            reasonIsEmpty = deliveryData?.filter(item => !item.reason)
            setWarningReasonModal([...reasonIsEmpty])
        }

        if (reasonIsEmpty.length > 0) {
            return
        }

        setConfirmModal(true)
    }

    let messageWarning = []
    if (deliveryType == SAMPLES_DELIVERY_TYPE.Cancelation) {
        const testWarning = [...new Set(testSample?.items?.map(item => sidFormat(item) + ` - ${item?.profileCode || item.testCode} - ${item?.profileName || item.testName}`))]
        messageWarning = testWarning
    } else {
        if (deliveryData) {
            const sidWarning = deliveryData?.map(item => sidFormat(item) + ` - ${item.sampleType} - ${item.sampleTypeName}`)
            messageWarning = sidWarning
        }
    }

    return (
        <div className="delivery-receive-nav-tabs-custom">
            <div className={`p-3 pt-1 ${!isOpen && 'pb-1'}`} style={{
                border: '1px solid #ced4da',
                borderRadius: '3.25px',
                boxShadow: '0px 10px 0px #f6f6f6'
            }}>
                <div className="row"
                    style={{
                        cursor: 'pointer',
                    }}
                    onClick={() => { setIsOpen(!isOpen) }}>
                    <div className="col-sm-10 d-flex">
                        {!isOpen &&
                            <div className="d-flex gap-3">
                                {deliveryType !== SAMPLES_DELIVERY_TYPE.Cancelation && <div >
                                    <label className="mx-1 font-size-13">
                                        {t(targetUserName)}:

                                    </label>
                                    <label className="font-size-14" style={{ color: '#556ee6' }}>
                                        <b>   {` ${targetUserId.name || ''}`}</b>
                                    </label>
                                </div>}
                                <div >
                                    <label className="mx-1 font-size-13">
                                        {t(handlerUserName)}:
                                    </label>
                                    <label className="font-size-14" style={{ color: '#556ee6' }}>
                                        <b>    {` ${handlerUserId.name || ''}`}</b>
                                    </label>
                                </div>
                                <div >
                                    <label className="mx-1 font-size-13">
                                        {t(confirmedName)}:
                                    </label>
                                    <label className="font-size-13">
                                        <b>     {` ${convertDateFormat(confirmedTime)}`}</b>
                                    </label>

                                </div>
                            </div>}

                    </div>
                    <div className="col-sm-2 d-flex gap-2"
                        style={{
                            justifyContent: 'end', cursor: 'pointer',
                        }}
                    >
                        {!isOpen && <BadgeLabel
                            value={t(bageName)}
                            className="badge-width font-size-12"
                            style={{ color: 'white', backgroundColor: '#556ee6' }}
                        />}
                        <i className={isOpen ? "dripicons-chevron-up" : "dripicons-chevron-down"} />
                    </div>
                </div>

                <Collapse isOpen={isOpen} className="accordion-collapse">
                    <AvForm>
                        <div className="d-flex gap-4 pt-0 pb-0" style={{ alignItems: 'center', display: !isOpen && 'none' }}>
                            <div>
                                <div >
                                    <div type="button" className={`px-3 btn ${(!enableBarcode) ? 'btn-outline-primary' : 'btn-primary'}`}
                                        onClick={() => {
                                            setEnableBarcode(prev => !prev)
                                            onBarCodeChange(!enableBarcode)
                                        }}
                                    >
                                        <i className="fa fa-barcode "></i>
                                        <div>{t("Barcode")}</div>
                                    </div>
                                    {enableBarcode &&
                                        <div style={{ textAlign: 'center' }} >
                                            <strong>{oldBarcode}</strong>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-xl-3" style={{ marginTop: 'auto' }}>
                                <Label className="form-label" >
                                    {t('Sample Exclue')}
                                </Label>
                                <CustomMultiSelectAsync
                                    name="sampleExclue"
                                    // isClearable={false}
                                    options={typeSid || []}
                                    label={""}
                                    onChange={(e, values, label, value, data, unSelected) => {
                                        sidTypeExcludeChange(values)
                                    }}
                                    isMulti={true}
                                    placeholder={t("Select Sample")}
                                />
                            </div>
                            {renderUserDeliveryAndReceipt()}
                            <Check permission={permissionType.DeliveryTime} resource={resource}>
                                <div className="col-xl-2">
                                    <div className="d-flex gap-2 align-items-center">
                                        <div style={{ width: '100%' }}>
                                            <CustomDatePicker
                                                label={t(confirmedName)}
                                                errorMessage=''
                                                name="confirmedTime"
                                                placeholder={t("Confirmed Time")}
                                                value={convertDateFormat(confirmedTime || new Date(), 'HH:mm DD-MM-YYYY')}
                                                readOnly={true}
                                                disabled={true}
                                                enableTime={true}
                                                dateFormat='H:i d-m-Y'
                                                onChangeHandler={(e) => {
                                                    setConfirmedTime(convertDateFormat(e.time[0], 'YYYY-MM-DD HH:mm'))
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Check>
                            {/* <div className="d-flex gap-1 align-self-end">
                                {(deliveryType === SAMPLES_DELIVERY_TYPE.DeliveryAndReceipt) &&
                                    <>
                                        <input
                                            type="checkbox"
                                            className="btn-check"
                                            id={`btn-check-outlined-cancel`}
                                            checked
                                        ></input>
                                        <label
                                            className="btn btn-outline-primary"
                                            htmlFor={`btn-check-outlined-cancel`}
                                        >
                                            {t(bageName)}
                                        </label>
                                    </>

                                }
                                {(deliveryType === SAMPLES_DELIVERY_TYPE.Cancelation || deliveryType === SAMPLES_DELIVERY_TYPE.Correction) &&
                                    <>
                                        <input
                                            type="checkbox"
                                            className="btn-check"
                                            id={`btn-check-outlined-cancel`}
                                            checked
                                        ></input>
                                        <label
                                            className="btn btn-outline-primary"
                                            htmlFor={`btn-check-outlined-cancel`}
                                        >
                                            {t(bageName)}
                                        </label>
                                    </>}
                            </div> */}
                        </div>
                    </AvForm>
                </Collapse>
            </div >
            <CustomNav
                defaultTab={tabActive}
                tabs
                className="nav-tabs-custom"
                onToggle={onToggleTab}
                tabContents={customActiveTab => (
                    <TabContent
                        activeTab={customActiveTab}
                        className="p-3 pt-0 text-muted"
                    >
                        <TabPane tabId="1">
                            {deliveryType !== SAMPLES_DELIVERY_TYPE.Cancelation &&
                                <TabSample
                                    samples={itemSample}
                                    onDelete={onDelete}
                                    deliveryType={deliveryType}
                                    onSubmit={(data) => {
                                        if ([SAMPLES_DELIVERY_TYPE.Receipt, SAMPLES_DELIVERY_TYPE.DeliveryAndReceipt].includes(deliveryType)
                                            && dataTableTest.some(item => item.mbNumType == STTVS.CH)
                                        ) {
                                            setConfirmMicrobialModal(true)
                                        } else {
                                            onConfirmCreateDelivery()
                                        }
                                        deliveryData = data
                                    }}
                                    onRefreshHandler={onRefreshHandler}
                                    isCollapse={isOpen}
                                    enableBarcode={enableBarcode}
                                    resource={resource}
                                />}
                            {deliveryType === SAMPLES_DELIVERY_TYPE.Cancelation &&
                                <TabTest
                                    handleTest={handleTest}
                                    samples={itemSample}
                                    deliveryType={deliveryType}
                                    isCollapse={isOpen}
                                    // onSubmit={onCreateDelivery}
                                    onSubmit={(data) => {
                                        deliveryData = data
                                        onConfirmCreateDelivery()
                                        // onCreateDelivery()
                                    }}
                                    onDelete={onDeleteTest}
                                    handlerUserId={handlerUserId}
                                    enableBarcode={enableBarcode}
                                    resource={resource}
                                    onChangeDataTableTest={(data) => setDataTableTest(data || [])}
                                />}
                        </TabPane>
                        <TabPane tabId="2">
                            {deliveryType !== SAMPLES_DELIVERY_TYPE.Cancelation &&
                                <TabTest
                                    handleTest={handleTest}
                                    samples={itemSample}
                                    deliveryType={deliveryType}
                                    isCollapse={isOpen}
                                    enableBarcode={enableBarcode}
                                    resource={resource}
                                    onChangeDataTableTest={(data) => setDataTableTest(data || [])}
                                />}
                            {deliveryType === SAMPLES_DELIVERY_TYPE.Cancelation &&
                                <TabSample
                                    samples={itemSample}
                                    onDelete={onDelete}
                                    deliveryType={deliveryType}
                                    onSubmit={(data) => {
                                        deliveryData = data
                                        onConfirmCreateDelivery()
                                        // onCreateDelivery()
                                    }}
                                    onRefreshHandler={onRefreshHandler}
                                    isCollapse={isOpen}
                                    ref={ref}
                                    enableBarcode={enableBarcode}
                                    resource={resource}
                                />
                            }
                        </TabPane>
                    </TabContent>
                )}
            >
                <CustomNavLink tabId="1">
                    <div className="d-flex gap-2">
                        <span >{deliveryType !== SAMPLES_DELIVERY_TYPE.Cancelation ? t("Sample") : t("Tests")}</span>
                        <span className="badge-gender"
                            style={{ width: '29px', backgroundColor: "#39a8fd", color: "white", textAlign: '-webkit-center', fontSize: '14px', alignItems: 'center' }}>
                            {deliveryType !== SAMPLES_DELIVERY_TYPE.Cancelation ? itemSample?.length : (testCount() || 0)}
                        </span>
                    </div>
                </CustomNavLink>

                <CustomNavLink tabId="2">
                    <div className="d-flex gap-2">
                        <span >{deliveryType !== SAMPLES_DELIVERY_TYPE.Cancelation ? t("Tests") : t("Sample")}</span>
                        <span
                            className="badge-gender"
                            style={{
                                width: '29px',
                                backgroundColor: "rgb(202 232 255)",
                                color: "blue",
                                textAlign: '-webkit-center',
                                fontSize: '14px',
                                borderWidth: '1.5px',
                                borderStyle: 'solid',
                                borderColor: '#6fb6ff',
                                alignItems: 'center'
                            }}>
                            {deliveryType !== SAMPLES_DELIVERY_TYPE.Cancelation ? (testCount() || 0) : itemSample?.length}
                        </span>
                    </div>
                </CustomNavLink>

            </CustomNav>
            <WarningModal
                modal={warningReasonModal.length > 0}
                onToggle={() => setWarningReasonModal([])}
                message={`${t("message:PleaseSelectError")} ${t("deliveryPage:reason")} SID: ${sidReasonWarning}`}
            />
            {/* <WarningModal
                modal={warningModal}
                onToggle={() => {
                    setWarningModal(false)

                }}
                message={`${t("message:PleaseSelectError")} ${t("deliveryPage:reason")} SID: ${sidReasonWarning}`}
            /> */}
            <ConfirmModal3
                modal={confirmModal}
                title={t(getTitle())}
                message={messageWarning.map((item, index) => (
                    <p className="mb-0" key={index}>
                        {item}
                    </p>
                ))}
                style={{ maxHeight: '230px' }}
                onToggle={() => { setConfirmModal(prev => !prev) }}
                onConfirm={() => {
                    onCreateDelivery(deliveryData)
                }}
                btnConfirmText={t("common:Save")}
                isEdit
            />
            <TestMicrobialModal
                modal={confirmMicrobialModal}
                data={dataTableTest}
                onToggle={() => { setConfirmMicrobialModal(prev => !prev) }}
                onMicrobialSubmit={(data) => {
                    setDataTableTest([...data])
                    setConfirmMicrobialModal(prev => !prev)
                    onConfirmCreateDelivery()
                }}
            />
        </div >
    )
}

const mapStateToProps = ({ sampleDelivery }) => ({
    loadingSamples: sampleDelivery.loadingSamples,
    updatedSampleTime: sampleDelivery.updatedSampleTime,
})

const mapDispatchToProps = dispatch => ({
    onCreateDeliverySample: (sample, type, callback) => dispatch(addDeliverySample({ sample, type, callback })),
    onAddNewDelivery: (delivery) =>
        dispatch(addDelivery(delivery)),
    onCreateDeliveryTest: (test, callback) =>
        dispatch(addDeliveryTest({ test, callback })),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["deliveryPage", "common", "message"])(AddSampleDelivery))