import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { CustomBootstrapTable } from "components/Common";
import { indexCalculator } from "helpers/utilities";

import CustomTooltipButton from "components/Common/Button/CustomTooltipButton";
import IconStatus from "components/Common/IconStatus";
import { ModuleIds, statusTypes } from "constant";
import { withTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

const RESOURCE = ModuleIds.MicroorganismList;

const OrganismsTable = ({
    onSelect,
    onSelectAll,
    dataConfigs,
    onSearch,
    onSort,
    onRefresh,
    onPageChange,
    paging,
    onSizePerPageChange,
    onEdit,
    onDelete,
    loading,
    t,
    updatedTime,
    onClone
}) => {

    const [data, setData] = useState([]);
    const [model, setModel] = useState({
        search: "",
    });
    const searchQuery = useSelector(state => state.sttGPBConfig.searchQuery);

    const onResetHandler = () => {
    }

    useEffect(() => {
        setModel(searchQuery)
    }, [searchQuery])


    useEffect(() => {
        setData([...dataConfigs])
    }, [dataConfigs])

    const columns = [
        {
            dataField: "id",
            text: "#",
            hidden: true,
            formatter: (cellContent, test, index) => {
                return cellContent
            },
        },
        {
            dataField: "id",
            text: "#",
            style: { width: 20 },
            formatter: (cellContent, test, index) => {
                return indexCalculator(paging.page, paging.size, index + 1)
            },
        },
        {
            dataField: "collapsible",
            text: "",
            style: { width: 60 },
            align: 'center',
            headerAlign: 'center',
            formatter: (cellContent, data, index) => {
                if ((data.familyCode != null && data.familyCode != '') || (data.genusCode != null && data.genusCode != '')) {
                    return <div key={index}>
                        <i
                            data-tip
                            data-for={`${index}-genus`}
                            className="fas fa-dot-circle"
                            style={{ color: 'green' }}
                            onClick={() => {
                            }}
                        />
                        {/* <ReactTooltip id={`${index}-genus`} place="right" effect="solid">
                        {data.genusName}
                    </ReactTooltip> */}
                    </div>
                }
                return <></>
            },
        },
        {
            dataField: "code",
            text: t("Code"),
            style: { width: 150 },
            formatter: (cellContent, data) => {
                return <span>{cellContent}</span>
            }
        },
        {
            dataField: "shortName",
            text: t("Short Name"),
            formatter: (cellContent, data) => {
                return <span>{cellContent}</span>
            }
        },
        {
            dataField: "name",
            text: t("Organisms Name"),
            formatter: (cellContent, data) => {
                return <span>{cellContent}</span>
            }
        },
        {
            dataField: "infectivityName",
            text: t("Infectivity"),
            formatter: (cellContent, data) => {
                return <span>{cellContent}</span>
            }
        },
        {
            dataField: "inUse",
            text: t("common:Status"),
            align: 'center',
            headerAlign: 'center',
            formatter: (cellContent, item, index) => {
                return <IconStatus
                    isRound={true}
                    type={cellContent == true ? statusTypes.ACTIVE : statusTypes.INACTIVE}
                />
            },
        },
        {
            dataField: "action",
            text: "",
            style: { width: 140 },
            formatter: (cellContent, data, index) => {
                return (
                    <CustomTooltipButton
                        index={index}
                        component={data}
                        onClone={onClone}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        t={t}
                        resource={RESOURCE}
                        // isDelete={((data.familyCode == null || data.familyCode == '') && (data.genusCode == null || data.genusCode == ''))}
                    />
                )
            },
        },
    ]
    const getDataExport = () => {
        let dt = data || [];
        if (dt.length > 0) {
            return dt.filter(x => x.id != -1).map((item, index) => {
                // return [
                //     { value: "H1", style: { font: { sz: "24", bold: true, name: "Times New Roman" } } },
                //     { value: "Bold", style: { font: { bold: true } } },
                //     { value: "Red", style: { fill: { patternType: "solid", fgColor: { rgb: "FFFF0000" } } } },
                // ]
                return [
                    { value: index + 1 },
                    { value: item.manageCode },
                    { value: item.formulaName },
                    { value: item.applicableName },
                    { value: item.parameters },
                    { value: item.formula },
                    { value: item.decOdd },
                    { value: item.inUse == true }
                ]
            });
        }
        return dt
    }

    const getColumnExport = () => {
        return [
            { title: "#", width: { wpx: 50 } },
            { title: t("Expression Code"), width: { wpx: 120 } },
            { title: t("Expression Name"), width: { wpx: 120 } },
            { title: t("Applicable"), width: { wpx: 100 } },
            { title: t("Parameter"), width: { wpx: 100 } },
            { title: t("Formula"), width: { wpx: 200 } },
            { title: t("Numeric Round"), width: { wpx: 100 } },
            { title: t("Active/InActive"), width: { wpx: 160 } },
        ]
    }
    return (
        <div>
            <CustomBootstrapTable
                columns={columns}
                search
                data={data}
                paging={paging}
                onSelect={onSelect}
                onSelectAll={onSelectAll}
                onSearch={onSearch}
                onSort={onSort}
                onRefresh={onRefresh}
                onReset={onResetHandler}
                onPageChange={onPageChange}
                onSizePerPageChange={onSizePerPageChange}
                searchText={model.search}
                loading={loading}
                updatedTime={updatedTime}
                resource={RESOURCE}
                isEnableExport={false}
                isEnableCustomExportExcel={true}
                dataExport={getDataExport()}
                columnExport={getColumnExport()}
                excelConfig={{
                    font: "Times New Roman",
                    fileName: t("Formula"),
                    fontSize: "11",
                    fontSizeHeader: "11"
                }}
            />

        </div>
    )
}

export default withTranslation(["organismsPage"])(OrganismsTable)