import {
  OkCancelModal,
  TitleAndTable,
  WarningModal
} from "components/Common"
import { ModuleIds, TestResultStatus } from "constant"
import HeaderButtons from "./HeaderButtons"

import {
  convertDateFormat,
  insertUrlParam,
  onActionToggle,
  onDelete,
  selectCheckboxHandler
} from "helpers/utilities"

import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import {
  deleteValidResults,
  getValidResultsList,
  resetValidResultSearchQuery,
  updateInValidResultsREV,
  updateValidResultsSampleId,
  updateValidResultSIDQC,
  updateValidResultsVAL,
} from "store/analysis-process/validresults/actions"

import { validResultExport } from "helpers/app-backend/valid_results_backend_helper"
import {
  updateEUpdateResults,
  updateResultsSampleId,
  updateStatusResults,
  updateUPLUpdateResults,
  updateVALUpdateResults,
} from "store/actions"
import {
  resetSearchQuery,
  updateWorkOrderMultiple
} from "store/analysis-process/workorders/actions"
import ChangeSIDModal from "../Modals/ChangeSIDModal"
import AnalysisProcessTable from "./AnalysisProcessTable"
import UpdateProcessBar from "./UpdateProcessBar"
import ChangeSIDQCModal from "../Modals/ChangeSIDQCModal"
// import ChangeSIDQCModal from "../Modals/ChangeSIDQCModal"

const RESOURCE = ModuleIds.AnalyticalProcess

const AnalysisProcess = ({
  history,
  paging,
  onGetValidResults,
  onUpdateValidResultsSampleId,
  onChangeSIDQC,
  onDeleteResult,
  onResetValidResultSearchQuery,
  loadingResult,
  updatingResult,
  updateResultTime,
  onUpdateValidResults,
  onUpdateInValidResults,
  onUpdateStatusUpdateResults,
  onUpdateVALUpdateResults,
  onUpdateEUpdateResults,
  onUpdateUPLUpdateResults,
  onResetWorkOrderSearchQuery,
  onUpdateWorkOrders,
  isStatusUpdating,
  results,
  result,
  t,
}) => {
  const [confirmModal, setConfirmModal] = useState(false)
  const [confirmUpdateValidModal, setConfirmUpdateValidModal] = useState(false)
  const [confirmUpdateInValidModal, setConfirmUpdateInValidModal] = useState(false)
  const [confirmUpdateVALModal, setConfirmUpdateVALModal] = useState(false)
  const [confirmUpdateUPLModal, setConfirmUpdateUPLModal] = useState(false)
  const [confirmUpdateEModal, setConfirmUpdateEModal] = useState(false)
  const [confirmReloadDownloadModal, setConfirmReloadDownloadModal] = useState(false)
  const [confirmSuspendDownloadModal, setConfirmSuspendDownloadModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [modalQC, setModalQC] = useState(false)
  const [isEdit, setIsEdit] = useState(true)
  const [isSelected, setIsSelected] = useState(false)
  const [row, setRow] = useState({})
  const [rowDelete, setRowDelete] = useState({})
  const [rows, setRows] = useState([])
  const [warningModal, setWarningModal] = useState(false)
  const formEl = useRef(null)
  const [checkSIDQC, setCheckSIDQC] = useState()
  const [checkUPL, setCheckUPL] = useState(false)
  const [isChangeSIDQC, setIsChangeSIDQC] = useState()
  const [model, setModel] = useState({
    search: "",
    page: 1,
    size: 10,
    fromDate: convertDateFormat(new Date(), "YYYY-MM-DD 00:00:00"),
    toDate: convertDateFormat(new Date(), "YYYY-MM-DD HH:mm:ss"),
  })
  useEffect(() => {
    fetchResult()
  }, [])

  useEffect(() => {
    let checkSID = new Set(rows.map(item => item.sid)).size > 1;
    let checkInsID = new Set(rows.map(item => item.instrumentId)).size > 1;
    setIsChangeSIDQC(checkSID || checkInsID);
  }, [rows])

  const onGetValidResultList = payload => {
    insertUrlParam(payload)
    onGetValidResults(payload)
  }

  useEffect(() => {
    if (results.length == 0) {
      setCheckUPL(false)
    } else if (results.length > 0 && Object.keys(row).length !== 0 && row?.status !== 'UPL') {
      setCheckUPL(true)
    } else setCheckUPL(false)
  }, [row, results])

  const searchQuery = useSelector(state => state.validResult.searchQuery)

  const toggle = () => {
    setModal(prev => !prev)
  }

  const toggleSIDQC = (isSIDQC) => {
    setCheckSIDQC(isSIDQC)
    setModalQC(prev => !prev)
  }

  const handleValidSubmit = values => {
    onUpdateValidResultsSampleId({ samples: values, callback: afterUpdate })
  }

  const handleValidSubmitSIDQC = values => {
    const newObjData = { ...values }
    // if (checkSIDQC) {
    //   newObjData.SIDQC = ""
    //   newObjData.currentSID = values.currentSID
    //   newObjData.isSID_QC = checkSIDQC;
    // } else {
    //   newObjData.SIDQC = ""
    //   newObjData.currentSID = Number(values.currentSID)
    //   newObjData.isSID_QC = checkSIDQC
    // }
    newObjData.isSID_QC = checkSIDQC;
    onChangeSIDQC({ data: newObjData, callback: afterUpdateSIDQC })
  }

  const afterUpdate = () => {
    onRefreshHandler()
    toggle()
  }

  const afterUpdateSIDQC = () => {
    onRefreshHandler()
    toggleSIDQC()
  }

  const resetState = () => {
    setRows([])
    setRow({})
  }

  // const onDeleteToggleHandler = (e, param) => {
  //   onDeleteToggle({
  //     rows,
  //     row: param || rowDelete,
  //     setConfirmModal,
  //     setWarningModal,
  //     setRowDelete,
  //   })
  //   if (confirmModal) setRowDelete({})
  // }

  const onDeleteMultipleRows = rowsState => {
    onDeleteResult({ results: rowsState, callback: resetState })
  }

  const onDeleteSingleRow = rowsState => {
    onDeleteResult({
      results: rowsState,
      callback: () => {
        setRows(prev => prev.filter(x => x.id !== rowDelete.id))
      },
    })
    setRowDelete({})
    setRow({})
  }

  const onDeleteResultHandler = () => {
    onDelete({
      rowDelete,
      rows,
      onDeleteSingleRow,
      onDeleteMultipleRows,
    })
    setConfirmModal(false)
  }

  const onUpdateVALStatusToggleHandler = (e, param) => {
    onActionToggle({
      rows,
      row: param || rowDelete,
      setConfirmUpdateModal: setConfirmUpdateVALModal,
      setWarningModal,
      isNotCheckEmpty: false,
    })
  }

  const onUpdateEStatusToggleHandler = (e, param) => {
    onActionToggle({
      rows,
      row: param || rowDelete,
      setConfirmUpdateModal: setConfirmUpdateEModal,
      setWarningModal,
      isNotCheckEmpty: false,
    })
  }

  const onUpdateUPLStatusToggleHandler = (e, param) => {
    onActionToggle({
      rows,
      row: param || rowDelete,
      setConfirmUpdateModal: setConfirmUpdateUPLModal,
      setWarningModal,
      isNotCheckEmpty: false,
    })
  }
  const ids = rows.map(item => item.id);
  // const onSelectCheckbox = (selectedRow, isSelected) => {
  //   let newRows = [...rows];

  //   if (isSelected) {
  //     const sameSIDRows = results.filter(item => item.sid === selectedRow.sid);

  //     sameSIDRows.forEach(sameSIDRow => {
  //       const isRowAlreadySelected = newRows.some(row => row.id === sameSIDRow.id);
  //       if (!isRowAlreadySelected) {
  //         newRows.push(sameSIDRow);
  //       }
  //     });
  //   } else {
  //     const sameSIDRows = results.filter(item => item.sid === selectedRow.sid);
  //     const sameSIDRowIds = sameSIDRows.map(item => item.id);

  //     newRows = newRows.filter(row => !sameSIDRowIds.includes(row.id));
  //   }

  //   setRows(newRows);

  //   if (newRows.length > 0) {
  //     setRow(newRows[newRows.length - 1]);
  //   } else {
  //     setRow({});
  //   }

  //   setIsSelected(newRows.length > 0);
  // };
  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }

  // const fetchResults = () => {
  //   onGetValidResultList({
  //     ...model,
  //     page: 1,
  //     sort: "runTime:desc",
  //   })
  // }
  const fetchResult = () => {
    onGetValidResultList({
      ...model,
      sort: "runTime:desc",
    })
  }
  /** Table methods */
  const onRefreshHandler = () => {
    resetState()
    fetchResult()
  }

  const onSearch = searchText => {
    let val = {
      search: searchText || ''
    }
    setModel({ ...model, ...val })
    onGetValidResultList({ ...model, ...val, page: 1, size: 10 })
  }

  const onSizePerPageChange = size => {
    setModel({ ...model })
    onGetValidResultList({ ...model, page: 1, size })
  }

  const onPageChange = page => {
    setModel({ ...model })
    onGetValidResultList({ ...model, page })
  }

  const onSubmitFilter = values => {
    onGetValidResultList({ page: 1, ...values })
  }

  const onSortHandler = (field, order) => {
    const sortString = `${field}:${order}`
    onGetValidResultList({ page: 1, sort: sortString })
  }

  const onUpdateValidHandler = () => {
    onUpdateValidResults({ results: rows, callback: afterUpdateValid })
  }

  const onUpdateInValidHandler = () => {
    onUpdateInValidResults({ results: rows, callback: afterUpdateValid })
  }

  const afterUpdateValid = () => {
    setIsSelected(true)
    setConfirmUpdateInValidModal(false)
    setConfirmUpdateValidModal(false)
    setConfirmReloadDownloadModal(false)
    setConfirmSuspendDownloadModal(false)
    fetchResult()
  }

  /**-----CYCLE------ */

  useEffect(() => {
    onResetValidResultSearchQuery()
  }, [])

  const onUpdateVALHandler = () => {
    onUpdateStatusUpdateResults({
      requestDate: convertDateFormat(row.requestDate, "YYYY-MM-DD"),
      status: TestResultStatus.VAL,
      id: ids,
      callback: afterUpdateValid,
    })
    setConfirmUpdateVALModal(false)
  }
  const onUpdateEHandler = () => {

    onUpdateStatusUpdateResults({
      requestDate: convertDateFormat(row.requestDate, "YYYY-MM-DD"),
      status: TestResultStatus.E,
      id: ids,
      callback: afterUpdateValid,
    })
    setConfirmUpdateEModal(false)
  }

  const onUpdateUPLHandler = () => {
    onUpdateStatusUpdateResults({
      requestDate: convertDateFormat(row.requestDate, "YYYY-MM-DD"),
      status: TestResultStatus.UPL,
      id: ids,
      callback: afterUpdateValid,
    })
    setConfirmUpdateUPLModal(false)
  }

  //Update status confirm
  const onUpdateValidStatusToggleHandler = (e, param) => {
    onActionToggle({
      rows,
      row: param || rowDelete,
      setConfirmUpdateModal: setConfirmUpdateValidModal,
      setWarningModal,
    })
  }

  const onUpdateInValidStatusToggleHandler = (e, param) => {
    onActionToggle({
      rows,
      row: param || rowDelete,
      setConfirmUpdateModal: setConfirmUpdateInValidModal,
      setWarningModal,
    })
  }

  const onAnalysisProcessExport = async () => {
    let payload = { page: 1, sort: "createdDate:desc" }
    const fileData = await validResultExport({ ...payload, ...searchQuery, page: payload.page, size: payload.size, })
    const blob = new Blob([fileData])
    saveAs(blob, "ExportValidResult.xlsx")
  }

  return (
    <React.Fragment>
      {/* Body */}
      <TitleAndTable
        table={() => (
          <AnalysisProcessTable
            results={results}
            onSelect={onSelectCheckbox}
            onSelectAll={onSelectAllCheckbox}
            onSearch={onSearch}
            onSort={onSortHandler}
            onRefresh={onRefreshHandler}
            onPageChange={onPageChange}
            paging={paging}
            onSizePerPageChange={onSizePerPageChange}
            onSubmitFilter={onSubmitFilter}
            onEdit={() => { }}
            isSelected={isSelected}
            // onDelete={onDeleteToggleHandler}
            loading={loadingResult}
            updatedTime={updateResultTime}
            onAnalysisProcessExport={onAnalysisProcessExport}
            // selectedRows={rows}
            onChangeModel={val => {
              setModel({ ...model, ...val })
            }}
          />
        )}
        resource={RESOURCE}
        buttons={() => (
          <HeaderButtons
            resource={RESOURCE}
            onChangeSIDClick={() => toggle()}
            onChangeSIDQCClick={(isSIDQC) => toggleSIDQC(isSIDQC)}
            onUpdateValidClick={onUpdateValidStatusToggleHandler}
            onUpdateInvalidClick={onUpdateInValidStatusToggleHandler}
            onUpdateVALClick={onUpdateVALStatusToggleHandler}
            onUpdateEClick={onUpdateEStatusToggleHandler}
            onUpdateUPLClick={onUpdateUPLStatusToggleHandler}
            // onDeleteClick={onDeleteToggleHandler}
            isStatusUpdating={isStatusUpdating}
            model={{}}
            isUPL={checkUPL}
            isChangeSIDQC={isChangeSIDQC}
            data={row}
            allData={rows}
          />
        )}
        external
        onEdit={() => { }}
        // onDelete={onDeleteToggleHandler}
        onClone={() => { }}
        title={t("Analytical Process")}
        subtitle={t("Valid Result List")}
      />

      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("message:SelectRowWarning")}
      />
      {/* <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("Result")}`}
        message={t("message:DeleteConfirm")}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteResultHandler}
      /> */}

      <OkCancelModal
        modal={confirmUpdateValidModal}
        title={`${t("Change Status")} ${t("Result")}`}
        message={t("Update Valid Status")}
        onToggle={onUpdateValidStatusToggleHandler}
        onAction={onUpdateValidHandler}
      />

      <OkCancelModal
        modal={confirmUpdateInValidModal}
        title={`${t("Change Status")} ${t("Result")}`}
        message={t("Update InValid Status")}
        onToggle={onUpdateInValidStatusToggleHandler}
        onAction={onUpdateInValidHandler}
      />

      <OkCancelModal
        modal={confirmUpdateEModal}
        title={`${t("Change Status")} ${t("Result")}`}
        message={`${t("Publish Result")}: E*`}
        onToggle={onUpdateEStatusToggleHandler}
        onAction={onUpdateEHandler}
      />

      <OkCancelModal
        modal={confirmUpdateVALModal}
        title={`${t("Change Status")} ${t("Result")}`}
        message={`${t("Publish Result")}: VAL`}
        onToggle={onUpdateVALStatusToggleHandler}
        onAction={onUpdateVALHandler}
      />

      <OkCancelModal
        modal={confirmUpdateUPLModal}
        title={`${t("Change Status")} ${t("Result")}`}
        message={`${t("Publish Result")}: UPL`}
        onToggle={onUpdateUPLStatusToggleHandler}
        onAction={onUpdateUPLHandler}
      />
      <ChangeSIDModal
        formEl={formEl}
        modal={modal}
        isEdit={false}
        onValidSubmit={handleValidSubmit}
        toggle={toggle}
        data={result}
      />

      <ChangeSIDQCModal
        formEl={formEl}
        modal={modalQC}
        isEdit={false}
        onValidSubmit={handleValidSubmitSIDQC}
        toggle={toggleSIDQC}
        data={row}
        allData={rows}
      />
      <UpdateProcessBar />
    </React.Fragment>
  )
}

AnalysisProcess.propTypes = {
  results: PropTypes.array,
  result: PropTypes.object,
  paging: PropTypes.object,
  loadingResult: PropTypes.bool,
  updatingResult: PropTypes.bool,
  updateResultTime: PropTypes.any,
  t: PropTypes.any,

  onGetValidResults: PropTypes.func,
  onDeleteResult: PropTypes.func,
  onResetValidResultSearchQuery: PropTypes.func,
  onUpdateValidResults: PropTypes.func,
  onUpdateInValidResults: PropTypes.func,
  onUpdateResultsSampleId: PropTypes.func,
  onUpdateUPLUpdateResults: PropTypes.func,
  onUpdateVALUpdateResults: PropTypes.func,
  onUpdateEUpdateResults: PropTypes.func,
  onGetWorkOrders: PropTypes.func,
  onResetWorkOrderSearchQuery: PropTypes.func,
}

const mapStateToProps = ({ validResult, updateResult }) => {
  return {
    results: validResult.results,
    result: validResult.result,
    paging: validResult.paging,
    loadingResult: validResult.loadingResult,
    updateResultTime: validResult.updateResultTime,
    updatingResult: validResult.updatingResult,
    isStatusUpdating: updateResult.isStatusUpdating,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetValidResults: payload => dispatch(getValidResultsList(payload)),
  onDeleteResult: results => dispatch(deleteValidResults(results)),
  onResetValidResultSearchQuery: () => dispatch(resetValidResultSearchQuery()),
  onUpdateValidResultsSampleId: payload =>
    dispatch(updateValidResultsSampleId(payload)),
  onChangeSIDQC: payload => dispatch(updateValidResultSIDQC(payload)),
  onUpdateStatusUpdateResults: payload =>
    dispatch(updateStatusResults(payload)),
  onUpdateValidResults: payload => dispatch(updateValidResultsVAL(payload)),
  onUpdateInValidResults: payload => dispatch(updateInValidResultsREV(payload)),
  onUpdateResultsSampleId: payload => dispatch(updateResultsSampleId(payload)),
  onUpdateUPLUpdateResults: payload =>
    dispatch(updateUPLUpdateResults(payload)),
  onUpdateVALUpdateResults: payload =>
    dispatch(updateVALUpdateResults(payload)),
  onUpdateEUpdateResults: payload => dispatch(updateEUpdateResults(payload)),
  // onGetWorkOrders: payload => dispatch(getWorkOders(payload)),
  onUpdateWorkOrders: payload => dispatch(updateWorkOrderMultiple(payload)),
  onResetWorkOrderSearchQuery: () => dispatch(resetSearchQuery()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["resultPage", "message"])(AnalysisProcess)))
