import { showToast } from "components/Common"
import {
  createExampleTypes,
  updateExampleTypes,
  deleteExampleTypes,
  getExampleTypesList,
  getExampleTypesById,
} from "helpers/app-backend/VS/VSExampleType_backend_helper"
import i18n from "i18n"
import { call, put, takeEvery } from "redux-saga/effects"
import EXAMPLE_TYPES from "./actionTypes"
import {
  addNewExampleTypesFail,
  addNewExampleTypesSuccess,
  deleteExampleTypesFail,
  deleteExampleTypesSuccess,
  getListExampleTypesFail,
  getListExampleTypesSuccess,
  getExampleTypesDetailFail,
  getExampleTypesDetailSuccess,
  updateExampleTypesFail,
  updateExampleTypesSuccess,
} from "./actions"
const t = (msg, param) => i18n.t(msg, param)

function* fetchGetListExampleTypes({ payload }) {
  try {
    let response = yield call(getExampleTypesList, payload)
    yield put(getListExampleTypesSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getListExampleTypesFail(error))
  }
}

function* fetchExampleTypesDetail({ id, callback }) {
  try {
    const response = yield call(getExampleTypesById, id)
    yield put(getExampleTypesDetailSuccess(response))
    callback && callback(response)
  } catch (error) {
    console.log(error)
    yield put(getExampleTypesDetailFail(error))
  }
}

function* onAddNewExampleTypes({ payload: { exampleTypes, callback } }) {
  try {
    const response = yield call(createExampleTypes, exampleTypes)
    yield put(addNewExampleTypesSuccess(exampleTypes))
    showToast(
      `${t("message:CreatedMessage", {
        field: `${t("")} <span class='text-decoration-underline fw-bold'>${
          exampleTypes["code"]
        }</span>`,
      })}`
    )

    callback && callback()
  } catch (error) {
    console.log(error)
    yield put(addNewExampleTypesFail(error))
  }
}

function* onUpdateExampleTypes({ payload: { exampleTypes, callback } }) {
  try {
    yield call(updateExampleTypes, exampleTypes)
    yield put(updateExampleTypesSuccess(exampleTypes))
    showToast(`${t("message:UpdatedMessage")}`)
    callback && callback()
  } catch (error) {
    console.log(error)
    yield put(updateExampleTypesFail(error))
  }
}

function* onDeleteExampleTypes({ payload: { exampleTypes, callback } }) {
  try {
    yield call(deleteExampleTypes, exampleTypes)
    yield put(deleteExampleTypesSuccess())
    showToast(`${t("message:DeletedMessage")}`)
    callback && callback()
  } catch (error) {
    yield put(deleteExampleTypesFail(error))
  }
}

function* ExampleTypesSaga() {
  yield takeEvery(
    EXAMPLE_TYPES.GET_LIST_EXAMPLE_TYPES,
    fetchGetListExampleTypes
  )
  yield takeEvery(
    EXAMPLE_TYPES.GET_EXAMPLE_TYPES_DETAIL,
    fetchExampleTypesDetail
  )
  yield takeEvery(EXAMPLE_TYPES.ADD_NEW_EXAMPLE_TYPES, onAddNewExampleTypes)
  yield takeEvery(EXAMPLE_TYPES.UPDATE_EXAMPLE_TYPES, onUpdateExampleTypes)
  yield takeEvery(EXAMPLE_TYPES.DELETE_EXAMPLE_TYPES, onDeleteExampleTypes)
}

export default ExampleTypesSaga
