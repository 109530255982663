import PropTypes from "prop-types";
import { Check, CustomBreadcrumb, PageContent } from "components/Common";
import { ModuleIds, permissionType } from "constant";
import { withTranslation } from "react-i18next";
import ResultIQC from "./ResultIQC";

const RESOURCE = ModuleIds.ResultIQC
const ExpressionIQCContainer = ({ t }) => {
    const titleTag = t(`Result IQC`)
    return (
        <div className="page-content-result-iqc-detail">
            <PageContent title={titleTag}>
                <CustomBreadcrumb
                    breadcrumbItem={t("Result IQC")}
                    resource={RESOURCE}
                />
                <Check permission={permissionType.R} resource={RESOURCE}>
                    <ResultIQC />
                </Check>
            </PageContent>
        </div>
    );
}

ExpressionIQCContainer.propTypes = {
    t: PropTypes.any,
}

export default withTranslation(["resultIQCPage"])(ExpressionIQCContainer)