import { showToast } from "components/Common"
import {
  createProgresses,
  updateProgresses,
  deleteProgresses,
  getProgressesList,
  getProgressesById,
} from "helpers/app-backend/VS/VSProgress_backend_helper"
import i18n from "i18n"
import { call, put, takeEvery } from "redux-saga/effects"
import PROGRESSES from "./actionTypes"
import {
  addNewProgressesFail,
  addNewProgressesSuccess,
  deleteProgressesFail,
  deleteProgressesSuccess,
  getListProgressesFail,
  getListProgressesSuccess,
  getProgressesDetailFail,
  getProgressesDetailSuccess,
  updateProgressesFail,
  updateProgressesSuccess,
} from "./actions"
const t = (msg, param) => i18n.t(msg, param)

function* fetchGetListProgresses({ payload }) {
  try {
    let response = yield call(getProgressesList, payload)
    yield put(getListProgressesSuccess(response))
  } catch (error) {
    console.log(error)
    yield put(getListProgressesFail(error))
  }
}

function* fetchProgressesDetail({ id, callback }) {
  try {
    const response = yield call(getProgressesById, id)
    yield put(getProgressesDetailSuccess(response))
    callback && callback(response)
  } catch (error) {
    console.log(error)
    yield put(getProgressesDetailFail(error))
  }
}

function* onAddNewProgresses({ payload: { progresses, callback } }) {
  try {
    const response = yield call(createProgresses, progresses)
    yield put(addNewProgressesSuccess(progresses))
    showToast(
      `${t("message:CreatedMessage", {
        field: `${t("")} <span class='text-decoration-underline fw-bold'>${
          progresses["code"]
        }</span>`,
      })}`
    )

    callback && callback()
  } catch (error) {
    console.log(error)
    yield put(addNewProgressesFail(error))
  }
}

function* onUpdateProgresses({ payload: { progresses, callback } }) {
  try {
    yield call(updateProgresses, progresses)
    yield put(updateProgressesSuccess(progresses))
    showToast(`${t("message:UpdatedMessage")}`)
    callback && callback()
  } catch (error) {
    console.log(error)
    yield put(updateProgressesFail(error))
  }
}

function* onDeleteProgresses({ payload: { progresses, callback } }) {
  try {
    yield call(deleteProgresses, progresses)
    yield put(deleteProgressesSuccess())
    showToast(`${t("message:DeletedMessage")}`)
    callback && callback()
  } catch (error) {
    console.log("error", error)
    yield put(deleteProgressesFail(error))
  }
}

function* ProgressesSaga() {
  yield takeEvery(PROGRESSES.GET_LIST_PROGRESSES, fetchGetListProgresses)
  yield takeEvery(PROGRESSES.GET_PROGRESSES_DETAIL, fetchProgressesDetail)
  yield takeEvery(PROGRESSES.ADD_NEW_PROGRESSES, onAddNewProgresses)
  yield takeEvery(PROGRESSES.UPDATE_PROGRESSES, onUpdateProgresses)
  yield takeEvery(PROGRESSES.DELETE_PROGRESSES, onDeleteProgresses)
}

export default ProgressesSaga
