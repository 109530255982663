import { spreadSearchQuery } from "helpers/utilities"
import { get } from "../../api_helper2"
const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/qc/datapoints`
const GetQCDatapoints = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}
const GetQCDatapointsPosNeg = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}/PosNeg?${q}`)
}
const GetTestForChart = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}/GetTestForChart?${q}`)
}
export {
    GetQCDatapoints,
    GetTestForChart,
    GetQCDatapointsPosNeg
}