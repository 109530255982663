import {
  Button
} from "reactstrap"

import ExcelJS from "exceljs"
import { saveAs } from "file-saver"
import { useState } from "react"
import { withTranslation } from "react-i18next"
import { WarningModal } from "../Modals"

const CustomExportExcel = ({ t,
  data,
  config = {
    fileName: 'test',
    calculateData: null,
    messageEmpty: t("common:NoDataToExport"),
    icon: "fas fa-file-export",
    tooltipTitle: t("Export All"),
  }
}) => {
  const configModel = {
    fileName: 'test',
    calculateData: null,
    messageEmpty: t("common:NoDataToExport"),
    icon: "fas fa-file-export",
    tooltipTitle: t("Export All"),
  }

  const [warningModal, setWarningModal] = useState(false)

  const exportToExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet("Sheet1");
    worksheet.addRows(data);
    if (config.calculateData) {
      worksheet = config.calculateData(worksheet)
    }
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    saveAs(blob, config.fileName + ".xlsx");
  };
  const exportHandler = (e) => {
    if (data.length == 0) {
      setWarningModal(true)
      e.stopPropagation()
    }
    else {
      exportToExcel()
    }
  }
  return (
    <>
      <div className={config.classNameTooltip || ""}>
        <div className={config.classNameTooltipButton || ""}>
          <Button color="secondary" outline
            onClick={(e) => {
              exportHandler(e)
            }}
          ><i className={config.icon || configModel.icon}></i></Button>
          {config.classNameTooltipTitle &&
            <span className={config.classNameTooltipTitle || ""}>
              {config.tooltipTitle || configModel.tooltipTitle}
            </span>
          }
        </div>
      </div>
      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={config.messageEmpty}
      />
    </>
  )
}

export default withTranslation(["common"])(CustomExportExcel)
