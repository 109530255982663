
import { ModalBody, ModalFooter, TabContent, TabPane } from "reactstrap"
import { Check, ConfirmModal2, CustomBreadcrumb, CustomNav, CustomNavLink, PageContent, showErrToast, showToast } from "components/Common";
import { withTranslation } from "react-i18next";
import {
    CustomButton,
    CustomModal
} from "components/Common"

import React, { useEffect, useRef, useState } from "react"
import { connect, useSelector } from "react-redux"
import KPH from "./KPH";
import KP from "./KP";
import { AvForm } from "availity-reactstrap-validation";
import { checkCurrentReportNumber, getCurrentReportNumber, getFilePrintResult, getResultRuleActionDetail, getResultRuleCauseDetail, pingToPrintService, sendDataPrint, updateQCResultRuleAction, updateQCResultRuleCause } from "helpers/app-backend";
import moment from "moment";
import { ModuleIds, permissionType, ReportResource } from "constant";
import PrintConfig from "pages/Laboratory/PrintConfig/PrintConfig";
import { GetDataUrlReportConfig, GetResourceReportIdConfig } from "helpers/utilities";
import { isEmpty } from "lodash";
import ModalPreview from "components/Common/Modals/ModalPreview";

let isEditKPHGlobal = false
let isEditKPGlobal = false
const ResultQCReportModal = ({
    modal,
    toggle,
    t,
    data,
    onRefreshData,
    checkRoleKPH,
    checkRoleHDKP,
    RESOURCE = ModuleIds.ResultIQC
}) => {

    const [currentTab, setCurrentTab] = useState(checkRoleKPH == true ? '1' : '2')
    const [reportCodeKPH, setReportCodeKPH] = useState('')
    const [reportCodeKP, setReportCodeKP] = useState('')
    const [confirmModal, setConfirmModal] = useState(false)
    const [dataSubmit, setDataSubmit] = useState({})
    const [oldDataKPH, setOldDataKPH] = useState({ source: [], causes: [] });
    const [oldDataKP, setOldDataKP] = useState({ action: [] });
    const [isEditKPH, setIsEditKPH] = useState(false)
    const [isEditKP, setIsEditKP] = useState(false)
    const [dataReport, setDataReport] = useState({})
    const [datapointId, setDatapointId] = useState()
    const item = localStorage.getItem("printResultConfig")
    const [printResultConfig, setPrintResultConfig] = useState({})
    const [modalPreview, setModalPreview] = useState(false)

    const getPrintResultConfig = () => {
        if (item) {
            try {
                setPrintResultConfig(JSON.parse(item))
            } catch (error) {
                console.log('error', error)
            }
        }
    }

    useEffect(() => {
        getPrintResultConfig()
        window.addEventListener('storage', getPrintResultConfig)
        return () => {
            window.removeEventListener('storage', getPrintResultConfig)
        }
    }, [item])

    const refKPH = useRef()
    const refKP = useRef()

    const { reportInfos } = useSelector(state => ({
        reportInfos: state.Authorization.reportinfo || [],
    }))

    useEffect(() => {
        if (data && data.id) {
            getDataKPH()
            getDataKP()
        }
    }, [data])

    useEffect(() => {
        setDatapointId(data.id)
    }, [data])

    useEffect(() => {
        if (currentTab == '1') {
            setDataReport([reportInfos[0]])
        } else setDataReport([reportInfos[1]])
    }, [reportInfos, currentTab])

    useEffect(() => {
        getReportCodeKPH()
        getReportCodeKP()
    }, [])

    const getStateButtonEdit = () => {
        if (currentTab == '1') {
            if (isEditKPH == true) {
                return false;
            }
            return true
        }
        else {
            if (isEditKP == true) {
                return false;
            }
            return true
        }
    }

    const getStateButtonSave = () => {
        if (currentTab == '1') {
            if (isEditKPH == true) {
                return true;
            }
            return false
        }
        else {
            if (isEditKP == true) {
                return true;
            }
            return false
        }

    }

    const getDataKPH = async () => {
        let res = await getResultRuleCauseDetail({
            RuleName: data.rule,
            DatapointId: data.id
        })
        console.log(65421);

        if (res && res.id != 0) {
            isEditKPHGlobal = true
            setIsEditKPH(true)
            setReportCodeKPH(res.reportCode)
            res.source = res.source?.split(',') || []
            res.causes = res.causes?.split(',') || []
            setOldDataKPH(res)
        }
        else {
            isEditKPHGlobal = false
        }
    }

    const getDataKP = async () => {
        let res = await getResultRuleActionDetail({
            RuleName: data.rule,
            DatapointId: data.id
        })
        if (res && res.id != 0) {
            isEditKPGlobal = true
            setIsEditKP(true)
            setReportCodeKP(res.reportCode)
            res.action = res.action?.split(',') || []
            setOldDataKP(res)
        }
        else {
            isEditKPGlobal = false
        }
    }


    const getReportCodeKPH = async () => {
        console.log(data.runTime);

        let res = await getCurrentReportNumber({ isKPH: true, runTime: moment(data.runTime).format("YYYY-MM-DD") })
        if (res) {
            setReportCodeKPH(res.reportCode || '')
        }
    }

    const getReportCodeKP = async () => {
        let res = await getCurrentReportNumber({ isKPH: false, runTime: moment(data.runTime).format("YYYY-MM-DD") })
        if (res) {
            setReportCodeKP(res.reportCode || '')
        }
    }

    const handleValidSubmitKPH = async (e, vals) => {
        setDataSubmit(vals)
        if (isEditKPHGlobal == true) {
            sendDataKPH(vals)
        }
        else {
            let check = await checkCurrentReportNumber({ isKPH: true, reportCode: reportCodeKPH })
            if (check && check.isExist == true) {
                setConfirmModal(true)
            }
            else {
                sendDataKPH(vals)
            }
        }
    }

    const handleValidSubmitKP = async (e, vals) => {
        setDataSubmit(vals)
        if (isEditKPGlobal == true) {
            sendDataKP(vals)
        } else {
            let check = await checkCurrentReportNumber({ isKPH: false, reportCode: reportCodeKPH })
            if (check && check.isExist == true) {
                setConfirmModal(true)
            }
            else {
                sendDataKP(vals)
            }
        }
    }


    const submitKPHAgain = async () => {
        sendDataKPH(dataSubmit, true)
    }

    const submitKPAgain = async () => {
        sendDataKP(dataSubmit, true)
    }
    const sendDataKPH = async (vals, isNewReport = false) => {
        let dt = {
            datapointId: data.id,
            ruleName: data.rule,
            causes: vals.causes.join(),
            discoverer: vals.discoverer,
            discovertTime: moment(vals.discovertTime, "HH:mm DD-MM-YYYY").format("YYYY-MM-DD HH:mm"),
            errorType: vals.errorType,
            location: vals.location,
            source: vals.source.join(),
            runtime: moment(data.runTime).format("YYYY-MM-DD HH:mm"),
            reportCode: reportCodeKPH,
            isNewReport: isNewReport,
        }
        let res = await updateQCResultRuleCause(dt);
        if (res.isCreated == true) {
            showToast((t("Report {code} created successfully.")).replaceAll("{code}", res.reportCode))
        }
        else {
            showToast((t("Report {code} updated successfully.")).replaceAll("{code}", res.reportCode))
        }
        setConfirmModal(false)
        onRefreshData()
        toggle()

    }

    const sendDataKP = async (vals, isNewReport = false) => {
        let dt = {
            datapointId: data.id,
            ruleName: data.rule,
            action: vals.action.join(),
            discoverer: vals.discoverer,
            improve: vals.improve,
            result: vals.result,
            remark: vals.remark,
            runtime: moment(data.runTime).format("YYYY-MM-DD HH:mm"),
            reportCode: reportCodeKP,
            isNewReport: isNewReport,
        }
        let res = await updateQCResultRuleAction(dt);
        if (res.isCreated == true) {
            showToast((t("Report {code} created successfully.")).replaceAll("{code}", res.reportCode))
        }
        else {
            showToast((t("Report {code} updated successfully.")).replaceAll("{code}", res.reportCode))
        }
        onRefreshData()
        setConfirmModal(false)
        toggle()

    }

    const PrintConfig = async () => {
        let reportCodeKPHFormat = reportCodeKPH.slice(3);
        let reportCodeKPFormat = reportCodeKP.slice(2);

        if (dataReport.length > 0 && !isEmpty(dataReport[0].uri)) {
            let res = await getFilePrintResult({
                ...GetDataUrlReportConfig(dataReport, ReportResource.ResultIQC, ""),
                ResultId: datapointId,
                ReportCode: currentTab == '1' ? reportCodeKPHFormat : reportCodeKPFormat,
                PrinterName: printResultConfig?.printerName,
            })

            try {
                const getPingPrintService = await pingToPrintService();
                if (getPingPrintService.pingTime) {
                    const dataSendPrint = await sendDataPrint({
                        ...GetDataUrlReportConfig(dataReport, ReportResource.ResultIQC, ""),
                        "filePath": `${res.reportUrl}`,
                        "printerNameBarcode": printResultConfig.printerName
                        // "quantity": rowState.numberOfLabels
                    })
                    if (dataSendPrint === 'failed') {
                        showErrToast(
                            'Vui lòng chọn máy in'
                        )

                        setTimeout(() => {
                            window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
                        }, 1500)

                    } else {
                        await getFilePrintResult({
                            ...GetDataUrlReportConfig(reportResult, ReportResource.ResultIQC, ""),
                            ResultId: datapointId,
                            ReportCode: currentTab == '1' ? reportCodeKPHFormat : reportCodeKPFormat,
                            PrinterName: printResultConfig.printerName,
                        })
                    }
                } else {
                    window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
                }
            }
            catch {
                window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
            }
        }
        else {
            const print = localStorage.getItem("printConfig")
            if (isEmpty(print)) {
                setOpen(true)
            }
            else {
                setModalPreview(true);
            }
        }
    }

    const onPreviewHandler = () => {
        setModalPreview(true);
    }

    return (
        <React.Fragment>
            <CustomModal modal={true} title={t("")} onToggle={toggle} size="xl" customClass={"result-qc-report-modal"} centered={false}>
                <button type="button" className="btn-close" aria-label="Close" onClick={toggle}></button>
                <ModalBody>
                    <CustomNav
                        onToggle={e => {
                            setCurrentTab(e);
                        }}
                        defaultTab={checkRoleKPH == true ? '1' : '2'}
                        tabs
                        className="nav-tabs-custom"
                        tabContents={customActiveTab => (
                            <TabContent
                                activeTab={customActiveTab}
                                className="p-0 text-muted nav-tabs-custom-result-report-qc"
                            >
                                {checkRoleKPH == true &&
                                    <TabPane tabId="1">
                                        <AvForm ref={refKPH}
                                            onValidSubmit={(e, v) => {
                                                handleValidSubmitKPH(e, v)
                                            }}
                                        >
                                            <KPH
                                                isEdit={isEditKPH}
                                                reportCode={reportCodeKPH}
                                                data={data}
                                                oldData={oldDataKPH}
                                            />
                                        </AvForm>
                                    </TabPane>
                                }
                                {checkRoleHDKP == true &&
                                    <TabPane tabId="2">
                                        <AvForm ref={refKP}
                                            onValidSubmit={(e, v) => {
                                                handleValidSubmitKP(e, v)
                                            }}
                                        >
                                            <KP
                                                isEdit={isEditKP}
                                                reportCode={reportCodeKP}
                                                data={data}
                                                oldData={oldDataKP}
                                            />
                                        </AvForm>
                                    </TabPane>
                                }
                            </TabContent>
                        )}
                    >
                        {checkRoleKPH == true &&
                            <CustomNavLink tabId="1">
                                <span>{t("Report Causal")}</span>
                            </CustomNavLink>
                        }
                        {checkRoleHDKP == true &&
                            <CustomNavLink tabId="2">
                                <span>{t("Report Corrective Action")}</span>
                            </CustomNavLink>
                        }
                    </CustomNav>
                </ModalBody>
                <ModalFooter>
                    <CustomButton
                        text={t("common:Preview")}
                        type="button"
                        onClick={() => onPreviewHandler()}
                        className="button-width"
                        color="info"
                        disabled={!getStateButtonSave()}
                    />
                    {/* chỉ cần role CreateReport */}
                    {/* <Check permission={permissionType.U} resource={RESOURCE}> */}
                    <>
                        <CustomButton
                            text={t("common:Edit")}
                            type="button"
                            disabled={getStateButtonEdit()}
                            onClick={() => {
                                if (currentTab == '1') {
                                    setIsEditKPH(false)
                                }
                                else {
                                    setIsEditKP(false)
                                }
                            }}
                            className="button-width"
                            color="warning"
                        />
                        <CustomButton
                            text={t("common:Save")}
                            type="button"
                            color="success"
                            disabled={getStateButtonSave()}
                            onClick={() => {
                                if (currentTab == '1') {
                                    refKPH.current?.submit()
                                }
                                else {
                                    refKP.current?.submit()
                                }
                            }}
                            className="save-user button-width"
                            isEdit
                        />
                    </>
                    {/* </Check> */}
                    <CustomButton
                        text={t("Print Report")}
                        type="button"
                        onClick={() => PrintConfig()}
                        className="button-width"
                        color="primary"
                        disabled={!getStateButtonSave()}
                    />
                </ModalFooter>
            </CustomModal>
            <ConfirmModal2
                modal={confirmModal}
                title={t("message:Warning")}
                message={`Mã báo cáo ${reportCodeKPH} trùng, xác nhận tạo mã mới!`}
                onToggle={() => { setConfirmModal(prev => !prev) }}
                onConfirm={() => {
                    if (currentTab == '1') {
                        submitKPHAgain()
                    }
                    else {
                        submitKPAgain()
                    }
                    setConfirmModal(false)
                }}
                btnConfirmText={t("common:Ok")}
                isEdit
            />
            {modalPreview &&
                <ModalPreview
                    modal={modalPreview}
                    toggle={() => { setModalPreview(false) }}
                    fileId={""}
                    reportId={GetResourceReportIdConfig(dataReport)}
                    resourceReport={ReportResource.ResultIQC}
                    dataUrl={
                        {
                            ...GetDataUrlReportConfig(dataReport, ReportResource.ResultIQC, ""),
                            ResultId: datapointId,
                            ReportCode: currentTab == '1' ? reportCodeKPH.slice(3) : reportCodeKP.slice(2),
                            // PrinterName: printResultConfig?.printerName,
                        }}
                />
            }
        </React.Fragment >
    )
}

ResultQCReportModal.defaultProps = {}

const mapStateToProps = ({ testResult }) => ({
})

const mapDispatchToProps = dispatch => ({
    // onGetNotifications: payload => dispatch(getNotifications(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["resultIQCPage", "common"])(ResultQCReportModal))
