import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import AntibioticResistanceTable from "./AntibioticResistanceTable"
import AntibioticResistanceModal from "./Modal/AntibioticResistanceModal"

import {
  Check,
  ConfirmModal,
  CustomButton,
  TitleAndTable,
  WarningModal,
} from "components/Common"
import {
  onDelete,
  onDeleteToggle,
  selectCheckboxHandler,
} from "helpers/utilities"

import { ModuleIds, permissionType } from "constant"
import { withTranslation } from "react-i18next"
import {
  addNewAntibioticResistances,
  deleteAntibioticResistances,
  getListAntibioticResistances,
  getAntibioticResistancesDetail,
  updateAntibioticResistances,
} from "store/actions"

const RESOURCE = ModuleIds.MicroorganismOrther

const AntibioticResistances = ({
  onGetListAntibioticResistances,
  antibioticResistancesDetail,
  paging,
  updating,
  updatedTime,
  antibioticResistances,
  loading,
  onGetListAntibioticResistancesDetail,
  onCreateAntibioticResistances,
  onUpdateAntibioticResistances,
  onDeleteExpressionQC,
  t,
}) => {
  const [confirmModal, setConfirmModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(true)
  const [row, setRow] = useState({})
  const [rowDelete, setRowDelete] = useState({})
  const [rows, setRows] = useState([])
  const [warningModal, setWarningModal] = useState(false)
  const [keySearch, setKeySearch] = useState("")
  const [isClone, setIsClone] = useState(false)
  const [currentSize, setCurrentSize] = useState(10)

  const onEditHandler = (e, testId, param) => {
    const id = testId || row?.id
    if (id) {
      setRow(param)
      onGetListAntibioticResistancesDetail(id)

      setIsEdit(true)
      setModal(true)
    } else {
      setWarningModal(true)
    }
  }

  const onCloneHandler = param => {
    const id = param.id || row?.id
    if (id) {
      setRow(param)
      onGetListAntibioticResistancesDetail(id)
      setIsEdit(false)
      setIsClone(true)
      setModal(true)
    } else {
      setWarningModal(true)
    }
  }

  const resetState = () => {
    setRow({})
    setRows([])
  }

  const onDeleteToggleHandler = (e, test) => {
    onDeleteToggle({
      rows,
      row: test || rowDelete,
      setConfirmModal,
      setWarningModal,
      setRowDelete,
    })
    if (confirmModal) {
      setRowDelete({})
    }
  }

  const onDeleteMultipleRows = rowsState => {
    onDeleteExpressionQC({
      antibioticResistances: rowsState,
      callback: () => {
        afterSubmit()
        resetState()
      },
    })
  }

  const onDeleteSingleRow = rowState => {
    onDeleteExpressionQC({
      antibioticResistances: rowState,
      callback: () => {
        afterSubmit()
        resetState()
      },
    })

    setRowDelete({})
    setRow({})
  }

  const onDeleteTestHandler = () => {
    onDelete({
      rowDelete,
      rows,
      onDeleteSingleRow,
      onDeleteMultipleRows,
    })
    // toggle modal
    setConfirmModal(false)
  }

  /**Get selected row and set to state
   *
   */
  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }

  const onRefreshHandler = () => {
    onGetListAntibioticResistances({ page: 1, search: keySearch })
  }

  const onSearch = searchText => {
    setKeySearch(searchText)
    onGetListAntibioticResistances({ page: 1, search: searchText })
  }

  const onSizePerPageChange = size => {
    onGetListAntibioticResistances({ page: 1, size })
    setCurrentSize(size)
  }

  const onPageChange = page => {
    onGetListAntibioticResistances({ page, size: currentSize })
  }

  const onSubmitFilter = values => {
    onGetListAntibioticResistances({ ...values, page: 1 })
  }

  const afterSubmit = () => {
    setModal(false)
    onRefreshHandler()
  }
  const onSubmit = val => {
    if (isEdit) {
      val.id = antibioticResistancesDetail.id
      onUpdateAntibioticResistances({
        antibioticResistances: val,
        callback: afterSubmit,
      })
    } else {
      onCreateAntibioticResistances({
        antibioticResistances: val,
        callback: afterSubmit,
      })
    }
  }
  useEffect(() => {
    onRefreshHandler()
  }, [])

  const getDataDisplay = datas => {
    if (datas && datas.length > 0) {
      return datas.map((x, index) => {
        return {
          ...x,
        }
      })
    }
    return datas
  }
  return (
    <React.Fragment>
      <TitleAndTable
        table={() => (
          <AntibioticResistanceTable
            dataConfigs={getDataDisplay(antibioticResistances || [])}
            onSelect={onSelectCheckbox}
            onSelectAll={onSelectAllCheckbox}
            onSearch={onSearch}
            onRefresh={onRefreshHandler}
            onPageChange={onPageChange}
            paging={paging}
            onSizePerPageChange={onSizePerPageChange}
            onSubmitFilter={onSubmitFilter}
            onEdit={onEditHandler}
            onClone={onCloneHandler}
            onDelete={onDeleteToggleHandler}
            loading={loading}
            updatedTime={updatedTime}
          />
        )}
        resource={RESOURCE}
        onEdit={onEditHandler}
        onClone={onCloneHandler}
        onDelete={onDeleteToggleHandler}
        isDisableClone
        isHideEdit
        isDropDown
        title={t("MasterData Entry")}
        subtitle={t("KS resistance list")}
        buttons={() => (
          <>
            <Check permission={permissionType.C} resource={RESOURCE}>
              <CustomButton
                color="primary"
                onClick={() => {
                  setIsClone(false)
                  setIsEdit(false)
                  setModal(true)
                }}
                outline
              >
                {t("common:Add")}
              </CustomButton>
            </Check>
            <Check permission={permissionType.D} resource={RESOURCE}>
              <CustomButton
                color="primary"
                onClick={e => {
                  onDeleteToggleHandler(e, rows.id)
                }}
                outline
              >
                {t("common:Delete")}
              </CustomButton>
            </Check>
          </>
        )}
      />
      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("message:SelectRowWarning")}
      />
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")}`}
        message={t("message:DeleteConfirm")}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteTestHandler}
      />
      {modal == true && (
        <AntibioticResistanceModal
          modal={modal}
          isEdit={isEdit}
          onValidSubmit={(e, val) => {
            onSubmit(e, val)
          }}
          toggle={() => {
            setModal(!modal)
          }}
          data={
            isEdit == false
              ? isClone
                ? antibioticResistancesDetail
                : {}
              : antibioticResistancesDetail
          }
        />
      )}
    </React.Fragment>
  )
}

AntibioticResistances.propTypes = {
  t: PropTypes.any,
}

const mapStateToProps = ({ antibioticResistances }) => ({
  antibioticResistancesDetail:
    antibioticResistances.antibioticResistancesDetail,
  paging: antibioticResistances.paging,
  loading: antibioticResistances.loading,
  updating: antibioticResistances.updating,
  updatedTime: antibioticResistances.updatedTime,
  antibioticResistances: antibioticResistances.antibioticResistances,
})

const mapDispatchToProps = dispatch => ({
  onGetListAntibioticResistances: payload =>
    dispatch(getListAntibioticResistances(payload)),
  onGetListAntibioticResistancesDetail: payload =>
    dispatch(getAntibioticResistancesDetail(payload)),
  onCreateAntibioticResistances: payload =>
    dispatch(addNewAntibioticResistances(payload)),
  onUpdateAntibioticResistances: payload =>
    dispatch(updateAntibioticResistances(payload)),
  onDeleteExpressionQC: payload =>
    dispatch(deleteAntibioticResistances(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withRouter(
    withTranslation(["otherMicroorganismsCategory", "message"])(
      AntibioticResistances
    )
  )
)
