import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import GenusTable from "./GenusTable"

import {
    Check,
    ConfirmModal,
    CustomButton,
    TitleAndTable,
    WarningModal
} from "components/Common"
import {
    onDelete,
    onDeleteToggle,
    selectCheckboxHandler,
} from "helpers/utilities"


import { ModuleIds, permissionType, VS_ORGM_GROUP_TYPE } from "constant"
import { withTranslation } from "react-i18next"
import { addNewOrganismsGenus, deleteOrganismsGenus, getListOrganismsGenus, getOrganismsGenusDetail, updateOrganismsGenus } from "store/actions"
import GenusModal from "./Modal/GenusModal"
import AddOrganismModal from "./Modal/AddOrganismModal"

const RESOURCE = ModuleIds.MicroorganismList

const OrganismsGenus = ({
    onGetListOrganismsGenus,
    organismsGenusDetail,
    paging,
    updating,
    updatedTime,
    organismsGenus,
    loading,
    onGetListOrganismsGenusDetail,
    onCreateOrganismsGenus,
    onUpdateOrganismsGenus,
    onDeleteExpressionQC,
    t,
}) => {
    const [confirmModal, setConfirmModal] = useState(false)
    const [modal, setModal] = useState(false)
    const [isEdit, setIsEdit] = useState(true)
    const [row, setRow] = useState({})
    const [rowDelete, setRowDelete] = useState({})
    const [rows, setRows] = useState([])
    const [warningModal, setWarningModal] = useState(false)
    const [keySearch, setKeySearch] = useState('')
    const [isClone, setIsClone] = useState(false)
    const [modalAdd, setModalAdd] = useState(false)

    const onEditHandler = (e, testId, param) => {
        const id = testId || row?.id;
        if (id) {
            setRow(param)
            onGetListOrganismsGenusDetail(id)

            setIsEdit(true)
            setModal(true)
        } else {
            setWarningModal(true)
        }
    }

    const onCloneHandler = (param) => {
        const id = param.id || row?.id
        if (id) {
            setRow(param)
            onGetListOrganismsGenusDetail(id)
            setIsEdit(false)
            setIsClone(true)
            setModal(true)
        } else {
            setWarningModal(true);
        }

    }

    const resetState = () => {
        setRow({})
        setRows([])
    }


    const onDeleteToggleHandler = (e, test) => {
        onDeleteToggle({
            rows,
            row: test || rowDelete,
            setConfirmModal,
            setWarningModal,
            setRowDelete
        })
        if (confirmModal) {
            setRowDelete({})
        }
    }

    const onDeleteMultipleRows = rowsState => {
        onDeleteExpressionQC({
            organisms: rowsState, callback: () => {
                afterSubmit()
                resetState()
            }
        })
    }

    const onDeleteSingleRow = rowState => {
        onDeleteExpressionQC({
            organisms: rowState,
            callback: () => {
                afterSubmit()
                resetState()
            }
        })

        setRowDelete({})
        setRow({})
    }

    const onDeleteTestHandler = () => {
        onDelete({
            rowDelete,
            rows,
            onDeleteSingleRow,
            onDeleteMultipleRows,
        })
        // toggle modal
        setConfirmModal(false)
    }

    /**Get selected row and set to state
    *
    */
    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const onRefreshHandler = () => {
        onGetListOrganismsGenus({ page: 1, search: keySearch, type: VS_ORGM_GROUP_TYPE.Genus })
    }

    const onSearch = searchText => {
        setKeySearch(searchText)
        onGetListOrganismsGenus({ page: 1, search: searchText, type: VS_ORGM_GROUP_TYPE.Genus })
    }

    const onSizePerPageChange = size => {
        onGetListOrganismsGenus({ page: 1, size, type: VS_ORGM_GROUP_TYPE.Genus })
    }

    const onPageChange = page => {
        onGetListOrganismsGenus({ page, type: VS_ORGM_GROUP_TYPE.Genus })
    }

    const onSubmitFilter = values => {
        onGetListOrganismsGenus({ ...values, page: 1, type: VS_ORGM_GROUP_TYPE.Genus })
    }

    const afterSubmit = () => {
        setModal(false)
        setModalAdd(false)
        onRefreshHandler()
    }
    const onSubmit = (val) => {
        if (isEdit) {
            val.id = organismsGenusDetail.id
            onUpdateOrganismsGenus({ organisms: val, callback: afterSubmit })
        } else {
            onCreateOrganismsGenus({ organisms: val, callback: afterSubmit })
        }
    }
    const onSubmitChangeSubCodes = (subCode) => {
        onUpdateOrganismsGenus({ organisms: { ...organismsGenusDetail, subCode }, callback: afterSubmit })
    }
    useEffect(() => {
        onRefreshHandler()
    }, [])

    const getDataDisplay = (datas) => {
        if (datas && datas.length > 0) {
            return datas.map((x, index) => {
                return {
                    ...x
                }
            })
        }
        return datas;
    }
    return (
        <React.Fragment>
            <TitleAndTable
                table={() => (
                    <GenusTable
                        dataConfigs={getDataDisplay(organismsGenus || [])}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        onSearch={onSearch}
                        onRefresh={onRefreshHandler}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSizePerPageChange={onSizePerPageChange}
                        onSubmitFilter={onSubmitFilter}
                        onEdit={onEditHandler}
                        onClone={onCloneHandler}
                        onDelete={onDeleteToggleHandler}
                        loading={loading}
                        updatedTime={updatedTime}
                    />
                )}
                resource={RESOURCE}
                onEdit={onEditHandler}
                onClone={onCloneHandler}
                onDelete={onDeleteToggleHandler}
                isDisableClone
                isHideEdit
                isDropDown
                title={t("MasterData Entry")}
                subtitle={t("Genus List")}
                buttons={() => (
                    <>
                        <Check permission={permissionType.C} resource={RESOURCE}>
                            <CustomButton disabled={row.id == undefined || row.id == null} color="primary" onClick={() => {
                                if (row && row.id) {
                                    onGetListOrganismsGenusDetail(row.id)
                                    setModalAdd(true)
                                }
                                else {
                                    setWarningModal(true)
                                }
                            }} outline>
                                {t("Add/Edit Organism")}
                            </CustomButton>
                        </Check>
                        <Check permission={permissionType.C} resource={RESOURCE}>
                            <CustomButton color="primary" onClick={() => {
                                setIsClone(false)
                                setIsEdit(false)
                                setModal(true)
                            }} outline>
                                {t("common:Add New")}
                            </CustomButton>
                        </Check>
                        <Check permission={permissionType.D} resource={RESOURCE}>
                            <CustomButton color="primary" onClick={(e) => { onDeleteToggleHandler(e, rows.id) }} outline>
                                {t("common:Delete")}
                            </CustomButton>
                        </Check>
                    </>
                )}
            />
            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            <ConfirmModal
                modal={confirmModal}
                title={`${t("common:Delete")}`}
                message={t("message:DeleteConfirm")}
                onToggle={onDeleteToggleHandler}
                onDelete={onDeleteTestHandler}
            />
            {modal == true &&
                <GenusModal
                    modal={modal}
                    isEdit={isEdit}
                    onValidSubmit={(e, val) => { onSubmit(e, val) }}
                    toggle={() => { setModal(!modal) }}
                    data={isEdit == false ? (isClone ? organismsGenusDetail : {}) : organismsGenusDetail}
                />
            }
            {modalAdd == true &&
                <AddOrganismModal
                    modal={modalAdd}
                    onValidSubmit={(vals) => {
                        onSubmitChangeSubCodes(vals || '')
                    }}
                    toggle={() => { setModalAdd(x => !x) }}
                    data={row}
                />
            }
        </React.Fragment>
    )
}

OrganismsGenus.propTypes = {
    t: PropTypes.any,
}

const mapStateToProps = ({ organisms }) => ({
    organismsGenusDetail: organisms.organismsGenusDetail,
    paging: organisms.paging,
    loading: organisms.loading,
    updating: organisms.updating,
    updatedTime: organisms.updatedTime,
    organismsGenus: organisms.organismsGenus

})

const mapDispatchToProps = dispatch => ({
    onGetListOrganismsGenus: payload => dispatch(getListOrganismsGenus(payload)),
    onGetListOrganismsGenusDetail: payload => dispatch(getOrganismsGenusDetail(payload)),
    onCreateOrganismsGenus: payload => dispatch(addNewOrganismsGenus(payload)),
    onUpdateOrganismsGenus: payload => dispatch(updateOrganismsGenus(payload)),
    onDeleteExpressionQC: payload => dispatch(deleteOrganismsGenus(payload)),

})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["organismsPage", "message"])(OrganismsGenus)))