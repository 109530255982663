import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../../api_helper2"
const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/vs/organisms`
const BASE_API_URL_GROUP = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/vs/organisms-group`

const createOrganisms = req => {
    return post(`${BASE_API_URL}`, req)
}
const updateOrganisms = req => {
    const { id } = req
    return put(`${BASE_API_URL}/${id}`, req)
}
const deleteOrganisms = req => {
    let ids = ""
    req.forEach(_item => (ids += `id=${_item.id}&`))
    return del(`${BASE_API_URL}?${ids}`)
}
const getOrganismsList = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}
const getOrganismsById = id => {
    return get(`${BASE_API_URL}/${id}`)
}

const createOrganismsGroup = req => {
    return post(`${BASE_API_URL_GROUP}`, req)
}
const updateOrganismsGroup = req => {
    const { id } = req
    return put(`${BASE_API_URL_GROUP}/${id}`, req)
}
const deleteOrganismsGroup = req => {
    let ids = ""
    req.forEach(_item => (ids += `id=${_item.id}&`))
    return del(`${BASE_API_URL_GROUP}?${ids}`)
}
const getOrganismsGroupList = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL_GROUP}?${q}`)
}
const getOrganismsGroupById = id => {
    return get(`${BASE_API_URL_GROUP}/${id}`)
}

const getVSOrganismsInGroup = id => {
    return get(`${BASE_API_URL_GROUP}/${id}/GetVSOrganismsInGroup`)
}

export {
    createOrganisms,
    updateOrganisms,
    deleteOrganisms,
    getOrganismsList,
    getOrganismsById,
    createOrganismsGroup,
    updateOrganismsGroup,
    deleteOrganismsGroup,
    getOrganismsGroupList,
    getOrganismsGroupById,
    getVSOrganismsInGroup
}
