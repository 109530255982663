import { AvForm } from "availity-reactstrap-validation"
import {
    CustomAvField,
    CustomButton,
    CustomButtonGroup,
    CustomCheckbox,
    CustomSelect
} from "components/Common"
import { parameterCode } from "constant/utility"
import { getInvalidMessageI18n } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"

const EditMode = ({ onValidSubmit, data, onCancel, t, isEdit, isHasNumber }) => {
    const accessionSchemas = data.accessionSchemas?.map(_r => _r.extraKey) || []
    const isExistedDefault = accessionSchemas.includes("default")
    return (
        <Row>
            <Col sm="12">
                <AvForm onValidSubmit={onValidSubmit} model={data}>
                    <Row className="px-3">
                        <Col sm="6">
                            <div className="mb-3">
                                <div className="d-none">
                                    <CustomAvField name="id" type="hidden" value={data.id || ""} />
                                </div>
                                <CustomSelect
                                    name="companyId"
                                    value={data.companyId || ''}
                                    code={parameterCode.COMPANIES}
                                    label={t("Company")}
                                    detected={isEdit}
                                    required
                                    errorMessage={getInvalidMessageI18n(t, "Company")}
                                    validate={{
                                        required: { value: true },
                                    }}
                                />
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="mb-3">
                                <CustomSelect
                                    name="accessionSchemas"
                                    value={accessionSchemas}
                                    code={parameterCode.ACCESSION_NUMBER}
                                    // isMulti
                                    label={t("Select schema")}
                                    detected={isEdit}
                                    required
                                    errorMessage={getInvalidMessageI18n(t, "Select schema")}
                                    validate={{
                                        required: { value: true },
                                    }}
                                    readOnly={isHasNumber}
                                />
                            </div>
                        </Col>
                        <Col xs="6">
                            <div className="mb-3">
                                <CustomAvField
                                    name="remark"
                                    value={data.remark || ""}
                                    label={t("Remark")}
                                    detected={isEdit}
                                    type="textarea"
                                    rows="3"
                                    maxLength={200}
                                />
                            </div>
                        </Col>
                        {!isExistedDefault &&
                        <Col xs="6">
                            <div className="mb-3">
                                <CustomCheckbox
                                    type="checkbox"
                                    direction={"down"}
                                    name="statuss"
                                    checked={!!data.status}
                                    label={`${t("common:Active")} ?`}
                                    detected={isEdit}
                                />
                            </div>
                        </Col>
                        }
                    </Row>
                    <Row>
                        <Col>
                            <CustomButtonGroup className="justify-content-center">
                                <CustomButton
                                    text={t("common:Cancel")}
                                    type="button"
                                    onClick={onCancel}
                                    className="button-width"
                                />
                                <CustomButton
                                    text={t("common:Save")}
                                    type="submit"
                                    color="primary"
                                    className="save-user button-width"
                                    isEdit
                                    isModal
                                />
                            </CustomButtonGroup>
                        </Col>
                    </Row>
                </AvForm>
            </Col>
        </Row >
    )
}

export default withTranslation(["accessionNumberPage", "common", "message"])(EditMode)
