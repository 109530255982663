import React, { useRef, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setFieldChange } from "store/actions"



const useDetectedChanges = (value, defaultValue, name) => {
  const [isMounted, setIsMounted] = useState(false)
  const [isChanged, setIsChanged] = useState(false)
  const oldValue = useRef()
  const fieldChange = useSelector(state => state.common.fieldChange)

  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      let field = [...fieldChange]
      field = field.filter(item => item != name)
      dispatch(setFieldChange(field))
    }
  }, [])

  useEffect(() => {
    if (name) {
      let field = [...fieldChange]
      if (isChanged && !field.includes(name)) {
        field.push(name)
        dispatch(setFieldChange(field))
      }
      if (!isChanged && field.includes(name)) {
        field = field.filter(item => item != name)
        dispatch(setFieldChange(field))
      }
    }
  }, [isChanged, defaultValue])

  useEffect(() => {
    setIsChanged(oldValue?.current !== defaultValue + "")
  }, [defaultValue])

  useEffect(() => {
    oldValue.current = value + ""
    setIsMounted(prev => !prev)
  }, [oldValue])

  return [oldValue.current, isChanged]
}

export default useDetectedChanges
