import { AvForm } from "availity-reactstrap-validation"

import { ModalBody, ModalFooter } from "reactstrap"

import {
    Accordion,
    AccordionBody,
    AccordionWrapper,
    CustomAvField,
    CustomButton,
    CustomModal,
    CustomSelectAsync,
    showErrToast
} from "components/Common"

//i18n
import React, { useEffect, useReducer, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { parameterCode } from "constant"
import { isEmpty } from "lodash"
import { convertDateFormat, getInvalidMessageI18n, showInvalidMessageNumber } from "helpers/utilities"

const LotTestModal = ({
    modal,
    toggle,
    data,
    t,
    onValidSubmit,
}) => {
    const formEl = useRef()
    const [disableSave, setDisableSave] = useState(true)
    const [model, setModel] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {

        }
    )
    useEffect(() => {
        if (modal == false) {
            setDisableSave(true)
        }
    }, [modal])
    const checkValidate = () => {
        let check = formEl.current?.state?.invalidInputs
        if (check == undefined || check == null) {
            setDisableSave(true)
            return;
        }
        if (Object.keys(formEl.current?.state?.invalidInputs).length == 0) {
            setDisableSave(false)
            return;
        }
        else {
            setDisableSave(true)
            return;
        }
    }

    if (isEmpty(data)) return null;
    return (
        <React.Fragment>
            <CustomModal modal={modal} title={t("Add Lot Test - Quantitative")} onToggle={toggle} size="xl">
                <ModalBody>
                    <AvForm
                        ref={formEl}
                        id="quantitative"
                        onValidSubmit={(e, vals) => {
                            let tmp = {
                                testCode: vals.testCode,
                                temperature: vals.temperature,
                                tea: vals.tea,
                                method: vals.method || '',
                                machine: data.instrumentId || '0',
                                lotId: data.lotId,
                                machineName: data.machineName || '',
                                qcInfo: []
                            }
                            let length = Number(data.levels) == 0 ? 3 : Number(data.levels)
                            for (let index = 0; index < length; index++) {
                                if (vals[`meanPI${index + 1}`] && vals[`meanPI${index + 1}`] != '' && vals[`sdpi${index + 1}`] && vals[`sdpi${index + 1}`] != '') {
                                    tmp.qcInfo.push({
                                        level: index + 1, meanPI: vals[`meanPI${index + 1}`], sdpi: vals[`sdpi${index + 1}`], deCOdd: vals[`deCOdd${index + 1}`], mean: vals[`meanPI${index + 1}`]
                                    })
                                }

                            }
                            onValidSubmit && onValidSubmit(tmp)
                        }}
                        onInvalidSubmit={() => {
                            showErrToast(t("Please input data test, level"))
                            return;
                        }}
                        model={data}
                    >
                        <div className="row">
                            <div className="col-12">
                                <AccordionWrapper defaultTab={["1"]}>
                                    <Accordion tabId={"1"} title={t("Lot Test Infomation")}>
                                        <AccordionBody className="accordion-body px-0 pb-0">
                                            <div className="row">
                                                <div className="col-12 mb-3 modal-add-lot-test-title">
                                                    {t("Lot")}:
                                                    <span>{data.lotId} - {data.lotName} - {t("Level")}: {data.levels}, {t("Expiration")}: {convertDateFormat(data.expireDate, "HH:mm DD-MM-YYYY")},</span>
                                                    {t("Machine")}: {data.machineName}
                                                </div>
                                                <div className="col-12 mb-3">
                                                    <CustomSelectAsync
                                                        name="testCode"
                                                        value={""}
                                                        valueName={""}
                                                        code={parameterCode.TEST_CODE_QC}
                                                        label={`${t("Test")}`}
                                                        required={true}
                                                        errorMessage={getInvalidMessageI18n(t, "Test")}
                                                        onChange={() => {
                                                            setTimeout(() => {
                                                                checkValidate()
                                                            }, 100);
                                                        }}
                                                    />
                                                </div>
                                                <div className="col-lg-4">
                                                    <CustomAvField
                                                        name="temperature"
                                                        type="text"
                                                        value={data.temperature}
                                                        label={t("Temperature")}
                                                        onKeyDown={(e) => {
                                                            const { value } = e.target;
                                                            if (!/[0-9.]/.test(e.key) && !["Backspace", "ArrowLeft", "ArrowRight", "Delete"].includes(e.key)) {
                                                                e.preventDefault();
                                                            }
                                                            if (e.key === "." && value.includes(".")) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onInput={(e) => {
                                                            if (e.target.value === ".") {
                                                                e.target.value = "0.";
                                                            }
                                                        }}
                                                        maxLength={10}
                                                    />
                                                </div>
                                                <div className="col-lg-4">
                                                    <CustomAvField
                                                        name="method"
                                                        type="text"
                                                        value={data.method}
                                                        label={t("Method")}
                                                        maxLength={155}
                                                    />
                                                </div>
                                                <div className="col-lg-4" id="input-tea-manageqc">
                                                    <CustomAvField
                                                        name="tea"
                                                        type="number"
                                                        value={data.tea}
                                                        label={t("TEA %")}
                                                        min={0}
                                                        errorMessage={getInvalidMessageI18n(t, "TEA %")}
                                                        onKeyDown={(e) => {
                                                            const { value } = e.target;
                                                            if (!/[0-9.]/.test(e.key) && !["Backspace", "ArrowLeft", "ArrowRight", "Delete"].includes(e.key)) {
                                                                e.preventDefault();
                                                            }
                                                            if (e.key === "." && value.includes(".")) {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                        onInput={(e) => {
                                                            if (e.target.value === ".") {
                                                                e.target.value = "0.";
                                                            }
                                                        }}
                                                        onKeyUp={() => {
                                                            showInvalidMessageNumber("input-tea-manageqc", getInvalidMessageI18n(t, "TEA %"))
                                                        }}
                                                        onBlur={() => {
                                                            showInvalidMessageNumber("input-tea-manageqc", getInvalidMessageI18n(t, "TEA %"))
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionBody>
                                    </Accordion>
                                </AccordionWrapper>
                            </div>
                        </div>
                        <div className="col-12">
                            <AccordionWrapper defaultTab={["1"]}>
                                <Accordion tabId={"1"} title={t("QC Levels Infomation")}>
                                    <AccordionBody>
                                        <div className="row">
                                            {Array.from({ length: Number(data.levels) == 0 ? 3 : Number(data.levels) }, (_, index) => (
                                                <>
                                                    <div className="col-12">
                                                        {t("Level")} {index + 1}
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <CustomAvField
                                                            name={`meanPI${index + 1}`}
                                                            type="text"
                                                            value={data[`meanPI${index + 1}`]}
                                                            label={t("Mean NSX - Trung bình NSX")}
                                                            onChange={val => {
                                                                model[`meanPI${index + 1}`] = val || ''
                                                                setModel(model)
                                                                setTimeout(() => {
                                                                    checkValidate()
                                                                }, 100);
                                                            }}
                                                            onKeyDown={(e) => {
                                                                const { value } = e.target;
                                                                if (!/[0-9.]/.test(e.key) && !["Backspace", "ArrowLeft", "ArrowRight", "Delete"].includes(e.key)) {
                                                                    e.preventDefault();
                                                                }
                                                                if (e.key === "." && value.includes(".")) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                            onInput={(e) => {
                                                                if (e.target.value === ".") {
                                                                    e.target.value = "0.";
                                                                }
                                                            }}
                                                            // required={true}
                                                            required={model[`sdpi${index + 1}`] != undefined && model[`sdpi${index + 1}`] != null && model[`sdpi${index + 1}`] != ''}
                                                            errorMessage={getInvalidMessageI18n(t, "Mean NSX - Trung bình NSX")}
                                                        />
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <CustomAvField
                                                            name={`sdpi${index + 1}`}
                                                            type="text"
                                                            value={data[`sdpi${index + 1}`]}
                                                            label={t("SD NSX - Độ lệch chuẩn")}
                                                            onChange={val => {
                                                                model[`sdpi${index + 1}`] = val || ''
                                                                setModel(model)
                                                                setTimeout(() => {
                                                                    checkValidate()
                                                                }, 100);
                                                            }}
                                                            onKeyDown={(e) => {
                                                                const { value } = e.target;
                                                                if (!/[0-9.]/.test(e.key) && !["Backspace", "ArrowLeft", "ArrowRight", "Delete"].includes(e.key)) {
                                                                    e.preventDefault();
                                                                }
                                                                if (e.key === "." && value.includes(".")) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                            onInput={(e) => {
                                                                if (e.target.value === ".") {
                                                                    e.target.value = "0.";
                                                                }
                                                            }}
                                                            // required={true}
                                                            required={model[`meanPI${index + 1}`] != undefined && model[`meanPI${index + 1}`] != null && model[`meanPI${index + 1}`] != ''}
                                                            errorMessage={getInvalidMessageI18n(t, "SD NSX - Độ lệch chuẩn")}
                                                        />
                                                    </div>
                                                    <div className="col-lg-4" id={`input-deCOdd-manageqc-${index + 1}`}>
                                                        <CustomAvField
                                                            name={`deCOdd${index + 1}`}
                                                            type="number"
                                                            value={data[`deCOdd${index + 1}`] || ''}
                                                            label={t("Odd")}
                                                            min={0}
                                                            onChange={val => {
                                                                model[`deCOdd${index + 1}`] = val || ''
                                                                setModel(model)
                                                                setTimeout(() => {
                                                                    checkValidate()
                                                                }, 100);
                                                            }}
                                                            onKeyDown={(e) => {
                                                                const { value } = e.target;
                                                                if (!/[0-9]/.test(e.key) && !["Backspace", "ArrowLeft", "ArrowRight", "Delete"].includes(e.key)) {
                                                                    e.preventDefault();
                                                                }
                                                                if (e.key === "." && value.includes(".")) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                            onBlur={(e) => {
                                                                // if (e.target.value === "") {
                                                                //     e.target.value = "0";
                                                                // }
                                                                showInvalidMessageNumber(`input-deCOdd-manageqc-${index + 1}`, getInvalidMessageI18n(t, "Odd"))
                                                            }}
                                                            // required={true}
                                                            // required={(model[`meanPI${index + 1}`] != undefined && model[`meanPI${index + 1}`] != null && model[`meanPI${index + 1}`] != '')
                                                            //     || model[`sdpi${index + 1}`] != undefined && model[`sdpi${index + 1}`] != null && model[`sdpi${index + 1}`] != ''}
                                                            errorMessage={getInvalidMessageI18n(t, "Odd")}
                                                            onKeyUp={() => {
                                                                showInvalidMessageNumber(`input-deCOdd-manageqc-${index + 1}`, getInvalidMessageI18n(t, "Odd"))
                                                            }}
                                                        />
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </AccordionBody>
                                </Accordion>
                            </AccordionWrapper>
                        </div>
                    </AvForm>

                </ModalBody>
                <ModalFooter>
                    <CustomButton
                        text={t("common:Cancel")}
                        type="button"
                        onClick={toggle}
                        data-dismiss="modal"
                        className="button-width"
                    />

                    <CustomButton
                        text={t("common:Save")}
                        type="submit"
                        color="primary"
                        disabled={disableSave}
                        onClick={() => {
                            formEl?.current?.submit()
                        }}
                        className="save-user button-width"
                        isEdit
                    />
                </ModalFooter>
            </CustomModal>
        </React.Fragment>
    )
}

LotTestModal.defaultProps = {}

const mapStateToProps = ({ testResult }) => ({
})

const mapDispatchToProps = dispatch => ({
    // onGetNotifications: payload => dispatch(getNotifications(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["managementQCPage", "common"])(LotTestModal))
