import * as signalR from '@microsoft/signalr';
import { showPersistentToastNotify } from 'components/Common/PersistentToastrNotify';
import { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
const NotifyComponent = ({ t }) => {
    let userInfo = localStorage.getItem("userInfo");
    let userInfoObject = JSON.parse(userInfo || "{}");
    useEffect(() => {
        const savedNotifications = JSON.parse(localStorage.getItem("persistentNotifications") || "[]");
        const uniqueNotifications = savedNotifications.reduce((acc, notification) => {
            acc[notification.message] = notification;
            return acc;
        }, {});
        Object.values(uniqueNotifications).forEach(notification => {
            if (!notification.dismissed && userInfo !== null) {
                showPersistentToastNotify(notification.id, notification.message);
            }
        });

        try {
            let connection = new signalR.HubConnectionBuilder()
               .withUrl(process.env.REACT_APP_INSTRUMENT_ENDPOINT.replaceAll("/v1", "") + "/notification", {
                   transport: signalR.HttpTransportType.LongPolling
               })
               .withAutomaticReconnect()
               .build();

            let lastMessage = null;
            connection.on("notification", data => {
               if (data && data.warningMessage !== lastMessage) {
                   const notificationId = Date.now();

                   // Show persistent notification
                   showPersistentToastNotify(notificationId, data.warningMessage);

                   // Save notification to localStorage
                   const currentNotifications = JSON.parse(localStorage.getItem("persistentNotifications") || "[]");
                   const updatedNotifications = currentNotifications.filter(n => n.message !== data.warningMessage);
                   updatedNotifications.push({ id: notificationId, message: data.warningMessage, dismissed: false });
                   localStorage.setItem("persistentNotifications", JSON.stringify(updatedNotifications));

                   lastMessage = data.warningMessage;
               }
            });
            connection.start();
        } catch (error) {
            console.log(error);
        }

        return () => {
            localStorage.removeItem("persistentNotifications");
        };
    }, []);

    return (
        <></>
    );
}
export default withTranslation(["testRequestPage"])(NotifyComponent);