import { Check, CustomBootstrapTable } from "components/Common"
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"
import CustomPopover from "components/Common/Input/CustomPopover"
import { ModuleIds, RESULT_STATE_Text, TestRequest_Test_Type, parameterCode, permissionType } from "constant"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import ReactTooltip from "react-tooltip"

const RESOURCE = ModuleIds.ChartiQC

const LotIndexTable = ({
    t,
    configs
}) => {
    const [data, setData] = useState([]);
    const [checkLevel, setCheckLevel] = useState({})
    useEffect(() => {
        setData(configs?.slice(0, -1) || [])
        setCheckLevel(configs.length > 0 ? configs[configs.length - 1] : {})
    }, [configs])
    const columns = [
        {
            dataField: "id",
            text: "id",
            hidden: true,
            searchable: false,
        },
        {
            dataField: "idx",
            text: "#",
            searchable: false,
            formatter: (cellContent, user, index) => {
                return index + 1
            },
        },
        {
            dataField: "index",
            text: t("Index"),
        },
        {
            dataField: "level1",
            text: t("Level") + ' 1',
            hidden: 1 in checkLevel ? false : true
        },
        {
            dataField: "level2",
            text: t("Level") + ' 2',
            hidden: 2 in checkLevel ? false : true
        },
        {
            dataField: "level3",
            text: t("Level") + ' 3',
            hidden: 3 in checkLevel ? false : true
        },
        {
            dataField: "level4",
            text: t("Level") + ' 4',
            hidden: 4 in checkLevel ? false : true
        },
        {
            dataField: "level5",
            text: t("Level") + ' 5',
            hidden: 5 in checkLevel ? false : true
        },
    ]

    return (
        <div className={`table-level-chart-${Object.keys(checkLevel)?.length} table-patient-visit table-expand-custom`}>
            <CustomBootstrapTableAsync
                columns={columns}
                data={data}
                resource={RESOURCE}
                keyField="id"
                isEnableRefresh={false}
                paging={{ dataSize: data?.length, size: data?.length }}
                showSelectRow={false}
                isScrollable={true}
                headerFixed={true}
                isEnableExport={false}
                disableToggle={true}
                TableTitle={t("Index")}
                headerClasses={'table-light result-test-color'}
            />
        </div>
    )
}

export default withTranslation(["chartiQCPage", "common"])(LotIndexTable)
