import PROGRESSES from "./actionTypes"

const INIT_STATE = {
  progresses: [],
  progressesDetail: null,
  loading: false,
  paging: {},
  loadingDetail: false,
  updating: false,
  updateTime: new Date(),
  error: null,
}

const AntibioticResistances = (state = INIT_STATE, action) => {
  switch (action.type) {
    case PROGRESSES.GET_LIST_PROGRESSES: {
      return { ...state, loading: true, error: {} }
    }
    case PROGRESSES.GET_LIST_PROGRESSES_SUCCESS: {
      const { data, totalElements, ...rest } = action.payload
      return {
        ...state,
        progresses: data,
        paging: { ...rest, dataSize: totalElements },
        loading: false,
        updatedTime: new Date(),
      }
    }
    case PROGRESSES.GET_LIST_PROGRESSES_FAIL: {
      return { ...state, loading: false, error: action.payload }
    }
    case PROGRESSES.GET_PROGRESSES_DETAIL: {
      return {
        ...state,
        loadingDetail: true,
        progressesDetail: {},
        error: {},
      }
    }
    case PROGRESSES.GET_PROGRESSES_DETAIL_SUCCESS: {
      return {
        ...state,
        progressesDetail: action.payload,
        loadingDetail: false,
      }
    }
    case PROGRESSES.GET_PROGRESSES_DETAIL_FAIL: {
      return { ...state, loadingDetail: false, error: action.payload }
    }
    case PROGRESSES.ADD_NEW_PROGRESSES: {
      return { ...state, error: {} }
    }
    case PROGRESSES.ADD_NEW_PROGRESSES_SUCCESS: {
      return { ...state }
    }
    case PROGRESSES.ADD_NEW_PROGRESSES_FAIL: {
      return { ...state, error: action.payload }
    }
    case PROGRESSES.UPDATE_PROGRESSES: {
      return { ...state, updating: true, error: {} }
    }
    case PROGRESSES.UPDATE_PROGRESSES_SUCCESS: {
      return { ...state, updating: false }
    }
    case PROGRESSES.UPDATE_PROGRESSES_FAIL: {
      return { ...state, updating: false, error: action.payload }
    }
    case PROGRESSES.DELETE_PROGRESSES: {
      return { ...state }
    }
    case PROGRESSES.DELETE_PROGRESSES_SUCCESS: {
      return { ...state }
    }
    case PROGRESSES.DELETE_PROGRESSES_FAIL: {
      return { ...state, error: action.payload }
    }
    default:
      return state
  }
}

export default AntibioticResistances
