//VSV
export const GET_LIST_ORGANISMS = "GET_LIST_ORGANISMS"
export const GET_LIST_ORGANISMS_SUCCESS = "GET_LIST_ORGANISMS_SUCCESS"
export const GET_LIST_ORGANISMS_FAIL = "GET_LIST_ORGANISMS_FAIL"
export const GET_ORGANISMS_DETAIL = "GET_ORGANISMS_DETAIL"
export const GET_ORGANISMS_DETAIL_SUCCESS = "GET_ORGANISMS_DETAIL_SUCCESS"
export const GET_ORGANISMS_DETAIL_FAIL = "GET_ORGANISMS_DETAIL_FAIL"
export const ADD_NEW_ORGANISMS = "ADD_NEW_ORGANISMS"
export const ADD_NEW_ORGANISMS_SUCCESS = "ADD_NEW_ORGANISMS_SUCCESS"
export const ADD_NEW_ORGANISMS_FAIL = "ADD_NEW_ORGANISMS_FAIL"
export const UPDATE_ORGANISMS = "UPDATE_ORGANISMS"
export const UPDATE_ORGANISMS_SUCCESS = "UPDATE_ORGANISMS_SUCCESS"
export const UPDATE_ORGANISMS_FAIL = "UPDATE_ORGANISMS_FAIL"
export const DELETE_ORGANISMS = "DELETE_ORGANISMS"
export const DELETE_ORGANISMS_SUCCESS = "DELETE_ORGANISMS_SUCCESS"
export const DELETE_ORGANISMS_FAIL = "DELETE_ORGANISMS_FAIL"

//HỌ
export const GET_LIST_ORGANISMS_GROUP = "GET_LIST_ORGANISMS_GROUP"
export const GET_LIST_ORGANISMS_GROUP_SUCCESS = "GET_LIST_ORGANISMS_GROUP_SUCCESS"
export const GET_LIST_ORGANISMS_GROUP_FAIL = "GET_LIST_ORGANISMS_GROUP_FAIL"
export const GET_ORGANISMS_GROUP_DETAIL = "GET_ORGANISMS_GROUP_DETAIL"
export const GET_ORGANISMS_GROUP_DETAIL_SUCCESS = "GET_ORGANISMS_GROUP_DETAIL_SUCCESS"
export const GET_ORGANISMS_GROUP_DETAIL_FAIL = "GET_ORGANISMS_GROUP_DETAIL_FAIL"
export const ADD_NEW_ORGANISMS_GROUP = "ADD_NEW_ORGANISMS_GROUP"
export const ADD_NEW_ORGANISMS_GROUP_SUCCESS = "ADD_NEW_ORGANISMS_GROUP_SUCCESS"
export const ADD_NEW_ORGANISMS_GROUP_FAIL = "ADD_NEW_ORGANISMS_GROUP_FAIL"
export const UPDATE_ORGANISMS_GROUP = "UPDATE_ORGANISMS_GROUP"
export const UPDATE_ORGANISMS_GROUP_SUCCESS = "UPDATE_ORGANISMS_GROUP_SUCCESS"
export const UPDATE_ORGANISMS_GROUP_FAIL = "UPDATE_ORGANISMS_GROUP_FAIL"
export const DELETE_ORGANISMS_GROUP = "DELETE_ORGANISMS_GROUP"
export const DELETE_ORGANISMS_GROUP_SUCCESS = "DELETE_ORGANISMS_GROUP_SUCCESS"
export const DELETE_ORGANISMS_GROUP_FAIL = "DELETE_ORGANISMS_GROUP_FAIL"

//CHI
export const GET_LIST_ORGANISMS_GENUS = "GET_LIST_ORGANISMS_GENUS"
export const GET_LIST_ORGANISMS_GENUS_SUCCESS = "GET_LIST_ORGANISMS_GENUS_SUCCESS"
export const GET_LIST_ORGANISMS_GENUS_FAIL = "GET_LIST_ORGANISMS_GENUS_FAIL"
export const GET_ORGANISMS_GENUS_DETAIL = "GET_ORGANISMS_GENUS_DETAIL"
export const GET_ORGANISMS_GENUS_DETAIL_SUCCESS = "GET_ORGANISMS_GENUS_DETAIL_SUCCESS"
export const GET_ORGANISMS_GENUS_DETAIL_FAIL = "GET_ORGANISMS_GENUS_DETAIL_FAIL"
export const ADD_NEW_ORGANISMS_GENUS = "ADD_NEW_ORGANISMS_GENUS"
export const ADD_NEW_ORGANISMS_GENUS_SUCCESS = "ADD_NEW_ORGANISMS_GENUS_SUCCESS"
export const ADD_NEW_ORGANISMS_GENUS_FAIL = "ADD_NEW_ORGANISMS_GENUS_FAIL"
export const UPDATE_ORGANISMS_GENUS = "UPDATE_ORGANISMS_GENUS"
export const UPDATE_ORGANISMS_GENUS_SUCCESS = "UPDATE_ORGANISMS_GENUS_SUCCESS"
export const UPDATE_ORGANISMS_GENUS_FAIL = "UPDATE_ORGANISMS_GENUS_FAIL"
export const DELETE_ORGANISMS_GENUS = "DELETE_ORGANISMS_GENUS"
export const DELETE_ORGANISMS_GENUS_SUCCESS = "DELETE_ORGANISMS_GENUS_SUCCESS"
export const DELETE_ORGANISMS_GENUS_FAIL = "DELETE_ORGANISMS_GENUS_FAIL"
