import { AvForm } from "availity-reactstrap-validation"
import {
  Accordion,
  AccordionBody,
  AccordionWrapper,
  Check,
  CustomButton,
  CustomDatePicker,
  CustomSelectAsync,
} from "components/Common"
import FormSwitchForm from "components/Common/FormSwitchForm"
import { BIAS, CV_FORMULA, ModuleIds, parameterCode, permissionType } from "constant"
import { convertDateFormat, getInvalidMessageI18n } from "helpers/utilities"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { getLottestListQCSuccess, updateCvPeer, updateMeanPeer } from "store/actions"
import AddCVInfoModal from "./AddCVInfoModal"
import AddMeanEQCModal from "./AddMeanEQCModal"
import AddMeanPeerInfoModal from "./AddMeanPeerInfoModal"
import moment from "moment"

let cvCalculateName = ""
let biasCalculateName = ""

let newDataCalculate = []
const ConfigCalculateModal = ({
  t,
  submitConfig,
  model,
  data,
  onGetLottestListQCSuccess,
  lot,
  onMeanPeerUpdate,
  onCVPeerUpdate,
  machine,
  resource
}) => {
  const ref = useRef()
  const [height, setHeight] = useState(window.innerHeight)
  const [isRender, setIsRender] = useState(false)
  const [modal, setModal] = useState(false)
  const [isCV, setIsCV] = useState(false)
  const [isLot, setIsLot] = useState(false)
  const [isBias, setIsBias] = useState(false)
  const [isValid, setIsValid] = useState(true)
  const [cvCalculation, setCVcalCulation] = useState("")
  const [biasCalculation, setBiasCalculation] = useState("")
  const [cvDate, setCVDate] = useState([
    convertDateFormat(new Date(), "DD-MM-YYYY"),
    convertDateFormat(new Date(), "DD-MM-YYYY"),
  ])
  const [biasDate, setBiasDate] = useState([
    convertDateFormat(new Date(), "DD-MM-YYYY"),
    convertDateFormat(new Date(), "DD-MM-YYYY"),
  ])
  const [lots, setLots] = useState([])
  const [cvModal, setCVModal] = useState(false)
  const [meanPeerModal, setMeanPeerModal] = useState(false)
  const [meanEQC, setMeanEQCModal] = useState(false)


  useEffect(() => {
    if (lot?.firstPointTime) {
      cvDate[0] = convertDateFormat(lot?.firstPointTime || new Date(), "DD-MM-YYYY")
      setCVDate([...cvDate])
      biasDate[0] = convertDateFormat(lot?.firstPointTime || new Date(), "DD-MM-YYYY")
      setBiasDate([...biasDate])
    }
  }, [lot])

  useEffect(() => {
    setLots([])
  }, [model?.insId])

  const onInputInfo = (values) => {
    const newDataCalculate = values.map((item) => ({
        ...item,
        lottests: item.lottests.map((x) => {
            let biasPercent = null;
            let biasUnit = null;
            let te = null;
            let sigma = null;
            let qgi = null;
            let cvPXN = null;

            if (x?.cv != null && (x?.mean != null || x?.meanPeer != null)) {
              if (x.meanPeer != null && x.mean == null) {
                x.mean = x.meanPeer
              }
              x.sd = parseFloat(((x.cv * x.mean) / 100)?.toFixed(6))
            }
            
            if (x.mean) {
              if (x.meanPI) {
                  biasPercent = Math.abs(x.mean - x.meanPI) / x.meanPI * 100;
                  biasUnit = Math.abs(x.mean - x.meanPI);
              } else if (x.meanPeer) {
                  biasPercent = Math.abs(x.mean - x.meanPeer) / x.meanPeer * 100;
                  biasUnit = Math.abs(x.mean - x.meanPeer);
              } else if (x.meanEQC) {
                  biasPercent = Math.abs(x.mean - x.meanEQC) / x.meanEQC * 100;
                  biasUnit = Math.abs(x.mean - x.meanEQC);
              } else {
                biasPercent = 0;
                biasUnit = 0;
              }
          }

            // Tính SD dựa trên CV
            if (x.cv !== null && x.cv !== undefined) {
                x.sd = (x.cv * x.mean) / 100;
            }

            // Tính cvPXN: sd PXN / mean PXN * 100
            if (x.sd !== null && x.sd !== undefined && x.mean !== null) {
                cvPXN = (x.sd / x.mean) * 100;
            }

            // Tính TE
            if (biasPercent !== null && x.sd !== undefined) {
                te = Math.abs(biasPercent) + 1.65 * x.sd;
            }

            // Tính Sigma theo cvPXN
            if (x.tEa !== null && x.tEa !== undefined && biasPercent !== null && cvPXN !== null) {
                sigma = (x.tEa - biasPercent) / cvPXN;
            }

            // Tính QGI theo cvPXN
            if (biasPercent !== null && cvPXN !== null) {
                qgi = biasPercent / (1.5 * cvPXN);
            }

            return { ...x, biasPercent, biasUnit, te, sigma, qgi, cvPXN };
        }),
    }));

    return newDataCalculate;
};

const firstPointTime = lot?.firstPointTime ? moment(lot.firstPointTime, 'YYYY-MM-DD').format("DD-MM-YYYY 00:00:00") : new Date()
  return (
    <React.Fragment>
      <Check
        resource={resource}
        permission={permissionType.CalculationSetting}>
      <div className="settings-config">
        <CustomButton
          color="secondary"
          outline
          disabled={data.length == 0}
          id="settings-config-button"
          onClick={() => {
            setIsRender(true)
            if (isRender) {
              ref.current.click()
            }
          }}
        >
          <i style={{ fontSize: 16 }} className="bx bx-cog"></i>
        </CustomButton>

        {isRender && (
          <div
          className={`settings-config-modal ${isRender ? 'active' : 'inactive'}`}
            style={{ height: "auto", maxHeight: `${height}px` }}
          >
            <div className="settings-config-modal-loading">
              <div className="col-12">
                <AccordionWrapper defaultTab={["1"]}>
                  <Accordion tabId={"1"} title={t("Calculate Signma, TE Setting")}>
                    <AccordionBody>
                      <AvForm
                        onValidSubmit={(e, values) => {
                          values.cvCalculateName = cvCalculateName
                          values.biasCalculateName = biasCalculateName
                          submitConfig(values)
                          setIsRender(prev => !prev)
                          if (newDataCalculate?.length > 0) {
                            onGetLottestListQCSuccess(newDataCalculate)
                          } else {
                            onGetLottestListQCSuccess(data)
                          }
                          newDataCalculate = []
                        }}
                      >
                        <div className="mx-3">
                          <FormSwitchForm
                            name="isBias"
                            label={t("Bias Calculate")}
                            value={isBias}
                            onChange={value => {
                              setIsBias(value)
                              onMeanPeerUpdate([])
                              if (!value) {
                                setBiasCalculation("")
                                if (lot?.firstPointTime) {
                                  setBiasDate([convertDateFormat(lot?.firstPointTime || new Date(), "DD-MM-YYYY"), convertDateFormat(new Date(), "DD-MM-YYYY")])
                                } else {
                                  setBiasDate([convertDateFormat(new Date(), "DD-MM-YYYY"), convertDateFormat(new Date(), "DD-MM-YYYY")])
                                }
                                cvCalculateName = ""
                              }
                            }}
                          />
                        </div>
                        <div className="d-flex mx-3 mb-5 gap-1">
                          <div className="col-sm-6">
                            <CustomSelectAsync
                              name="biasCalculation"
                              customSearchQuery={{ applicable: "%Bias" }}
                              portal
                              value={biasCalculation}
                              isEdit
                              errorMessage={getInvalidMessageI18n(
                                t,
                                "Bias Calculate"
                              )}
                              required={isBias}
                              readOnly={!isBias}
                              code={parameterCode.EXPRESSION_IQC}
                              onChange={(name, value, label, item) => {
                                if (!value[0]) {
                                  setBiasCalculation('')
                                } else {
                                  setBiasCalculation(value)
                                }
                                biasCalculateName = label
                                onMeanPeerUpdate([])
                                if (item[0].manageCode == BIAS.MEAN_PEER) {
                                  setMeanPeerModal(true)
                                }
                                if (item[0].manageCode == BIAS.MEAN_EQC) {
                                  setMeanEQCModal(true)
                                }
                              }}
                            />
                          </div>

                          <div className="col-sm-6">
                            <CustomDatePicker
                              name="biasDate"
                              disabled={!isBias}
                              value={biasDate}
                              isEdit
                              dateFormat={"d-m-Y"}
                              placeholder={"DD-MM-YYYY"}
                              onChangeHandler={(date, value) => {
                                if (date.time.length == 2) {
                                  setBiasDate([
                                    convertDateFormat(
                                      date.time[0] || new Date(),
                                      "DD-MM-YYYY"
                                    ),
                                    convertDateFormat(
                                      date.time[1] || new Date(),
                                      "DD-MM-YYYY"
                                    ),
                                  ])
                                }
                              }}
                              mode="range"
                              minDate={lot?.firstPointTime ? convertDateFormat(lot?.firstPointTime, "DD-MM-YYYY") : null}
                              maxDate={new Date()}
                              closeOnSelect={true}
                            />
                          </div>
                        </div>
                        <div className="d-flex mx-3">
                          <FormSwitchForm
                            name="isCV"
                            label={t("CV Calculate Method")}
                            value={isCV}
                            onChange={value => {
                              setIsCV(value)
                              onCVPeerUpdate([])
                              if (!value) {
                                setIsLot(false)
                                setCVcalCulation('')
                                if (lot?.firstPointTime) {
                                  setCVDate([convertDateFormat(lot?.firstPointTime || new Date(), "DD-MM-YYYY"), convertDateFormat(new Date(), "DD-MM-YYYY")])
                                } else {
                                  setCVDate([convertDateFormat(new Date(), "DD-MM-YYYY"), convertDateFormat(new Date(), "DD-MM-YYYY")])
                                }
                                setLots([])
                              }
                              // biasCalculateName = ''
                            }}
                          />
                        </div>
                        <div className="d-flex gap-1 mx-3 mb-2">
                          <div className="col-sm-6">
                            <CustomSelectAsync
                              name="cvCalculation"
                              portal
                              isEdit
                              readOnly={!isCV}
                              required={isCV}
                              value={cvCalculation}
                              customSearchQuery={{ applicable: "CV" }}
                              code={parameterCode.EXPRESSION_IQC}
                              errorMessage={getInvalidMessageI18n(
                                t,
                                "CV Calculate Method"
                              )}
                              onChange={(name, value, label, item) => {
                                if (!value[0]) {
                                  setCVcalCulation('')
                                } else {
                                  setCVcalCulation(value)
                                }
                                cvCalculateName = label
                                if (item[0]?.manageCode == CV_FORMULA.CVNGK) {
                                  setCVModal(true)
                                } else {
                                  onCVPeerUpdate([])
                                }          
                              }}
                            />
                          </div>
                          <div className="col-sm-6">
                            <CustomDatePicker
                              name="cvDate"
                              value={cvDate}
                              isEdit
                              disabled={!(isCV && !isLot)}
                              placeholder={"DD-MM-YYYY"}
                              dateFormat={"d-m-Y"}
                              onChangeHandler={(date, value) => {
                                if (date.time.length == 2) {
                                  setCVDate([
                                    convertDateFormat(date.time[0] || new Date(), "DD-MM-YYYY"),
                                    convertDateFormat(date.time[1] || new Date(), "DD-MM-YYYY")
                                  ])
                                }
                              }}
                              mode="range"
                              minDate={lot?.firstPointTime && !isLot ? convertDateFormat(lot?.firstPointTime, "DD-MM-YYYY") : null}
                              maxDate={new Date()}
                              closeOnSelect={true}
                            />
                          </div>
                        </div>

                        <div className="d-flex mx-3">
                          <FormSwitchForm
                            name="lotQcs"
                            label={t("Lot Calculate")}
                            value={isLot}
                            disabled={!isCV}
                            onChange={value => {
                              setIsLot(value)
                              if (!value) {
                                setLots([])
                                cvDate[0] = convertDateFormat(lot?.firstPointTime || new Date(), "DD-MM-YYYY")
                                setCVDate([...cvDate])
                              }
                            }}
                          />
                        </div>
                        <div className="gap-1 mx-3 mb-5 ">
                          <div className="col-sm-12">
                            <CustomSelectAsync
                              isMulti
                              name="LotIds"
                              portal
                              isEdit
                              readOnly={!isLot}
                              required={isLot}
                              customSearchQuery={{
                                ...model,
                                instrumentId: model.insId,
                                hasPoint: true,
                                firstPointDate: moment(firstPointTime, 'DD-MM-YYYY').format("YYYY-MM-DD 00:00:00")
                              }}
                              errorMessage={getInvalidMessageI18n(
                                t,
                                "Lot Calculate"
                              )}
                              value={lots}
                              code={parameterCode.LOT_QC_CALCULATION}
                              onChange={(name, value, label, item) => {
                                setLots(value)
                                const firstPointTimes = item.map(x => x.firstPointTime)
                                const date = Math.min(...firstPointTimes.map(date => new Date(date)))
                                if (date && date != 'Infinity') {
                                  cvDate[0] = moment(date).format('DD-MM-YYYY HH:mm:ss')
                                  setCVDate([...cvDate])
                                } else {
                                    cvDate[0] = convertDateFormat(lot?.firstPointTime || new Date(), "DD-MM-YYYY")
                                    setCVDate([...cvDate])
                                }
                                }}
                            />
                          </div>
                        </div>

                        <div className="mx-3 mb-3">
                          <FormSwitchForm
                            name="isValid"
                            label={t("Point Valid Calculate")}
                            value={isValid ?? true}
                            onChange={value => {
                              setIsValid(value)
                            }}
                          />
                        </div>
                        <div>
                        <button type="submit" className="d-none" ref={ref} />
                          <CustomButton
                            text={t("common:Save")}
                            type="submit"
                            className="m-3"
                            color="primary"
                            isEdit
                          />
                        </div>
                      </AvForm>
                    </AccordionBody>
                  </Accordion>
                </AccordionWrapper>
              </div>
            </div>
          </div>
        )}

        <AddMeanEQCModal
          modal={meanEQC}
          toggle={() => {
            setMeanEQCModal(prev => !prev)
          }}
          machine={machine}
          data={data}
          onInputInfo={onInputInfo}
        />

        {meanPeerModal && <AddMeanPeerInfoModal
          modal={meanPeerModal}
          toggle={() => { setMeanPeerModal(prev => !prev) }}
          data={data}
          machine={machine}
          onInputInfo={onInputInfo}
        />}

        {cvModal && <AddCVInfoModal
          modal={cvModal}
          toggle={() => { setCVModal(prev => !prev) }}
          data={data}
          machine={machine}
          onInputInfo={onInputInfo}
        />}
      </div>
      </Check>
    </React.Fragment>
  )
}

ConfigCalculateModal.defaultProps = {}

const mapStateToProps = ({ calculation }) => ({})

const mapDispatchToProps = dispatch => ({
  onGetLottestListQCSuccess: (payload) => dispatch(getLottestListQCSuccess(payload)),
  onMeanPeerUpdate: (data) => (dispatch(updateMeanPeer(data))),
  onCVPeerUpdate: (data) => (dispatch(updateCvPeer(data)))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["calculationQCPage", "common"])(ConfigCalculateModal))
