import { AvForm } from "availity-reactstrap-validation"
import { CustomSelect } from "components/Common"
import { parameterCode } from "constant"
import React from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"

const ChangeSIDQCButtons = ({ model, onChange, onSubmit, t }) => {
  return (
    <React.Fragment>
      <AvForm>
        <div className="d-flex">
          <div style={{ width: "200px", marginLeft: "5px" }}>
            <CustomSelect
              name="instrumentId"
              portal
              placeholder={t("Machines")}
              showID
              value={model.instrumentId || ""}
              code={parameterCode.INSTRUMENT_QC}
              onChange={(name, value, label, item) => {
                onChange({
                  instrumentId: value[0],
                })
                setTimeout(() => {
                  onSubmit({ ...model, instrumentId: value[0] || "" })
                }, 100)
              }}
            />
          </div>
        </div>
      </AvForm>
    </React.Fragment>
  )
}

const mapStateToProps = ({ hisConnector }) => ({})
const mapDispatchToProps = dispatch => ({})

ChangeSIDQCButtons.displayName = "ChangeSIDQCButtons"
export default withTranslation(["changeSIDQCPage", "common", "button"], {
  withRef: true,
})(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    ChangeSIDQCButtons
  )
)
