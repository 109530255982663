import React, { useState, useRef, useEffect } from "react"
import { Input, Label } from "reactstrap"
import PropTypes from "prop-types"
import * as moment from "moment"

// DateTimePicker
import Datetime from "react-datetime"
import "react-datetime/css/react-datetime.css"

import classnames from "classnames"
import { RollbackButton } from "."
import { useDetectedChanges } from "helpers/hooks"
import { convertDateFormat } from "helpers/utilities"
import AvField from "availity-reactstrap-validation/lib/AvField"

const CustomDatePicker = props => {
  const {
    name,
    formName,
    disabled,
    readOnly,
    value,
    isRequired,
    hasError,
    tooltip,
    detected,
    label,
    format,
    onChange,
    showRemove = false,
  } = props

  const [formattedDate, setFormattedDate] = useState()
  const [selectedDate, setSelectedDate] = useState()
  const inputEl = useRef(null)

  const [valueInput, isChanged] = useDetectedChanges(value || "", formattedDate, name)

  useEffect(() => {
    setFormattedDate(convertDateFormat(selectedDate))
    //eslint-disable-next-line
  }, [selectedDate])

  useEffect(() => {
    setSelectedDate(dateFormatHandler(value))
    if (value == null || value == '' || value == undefined) {
      setFormattedDate(null)
      onChangeHandler(name, '')
      if (inputEl)
        inputEl.current.state.inputValue = '';
    }
  }, [value])

  const dateFormatHandler = date => {
    const dateFormat = date ? moment(date).format() : ""
    const defaultDate = moment(dateFormat).isValid()
      ? new Date(dateFormat)
      : null
    return defaultDate
  }

  const onUndoHandler = () => {
    const dateFormat = dateFormatHandler(valueInput)
    // setSelectedDate(dateFormat);

    onChangeHandler(name, dateFormat)
  }

  const onChangeHandler = (_name, _value) => {
    const newDate = new Date(_value)
    setSelectedDate(newDate)

    const currentElement = document.getElementsByName(_name)[0]
    let formNameConst
    if (currentElement) {
      formNameConst =
        formName || currentElement.closest("form")?.getAttribute("name")
    }

    onChange({ name: _name, value: newDate, formName: formNameConst })
  }

  const goToToday = e => {
    e.preventDefault()
    // Reset
    const currentDate = new Date()
    setSelectedDate(currentDate)
    onChangeHandler(name, currentDate)

    inputEl.current.setViewDate(currentDate)
    inputEl.current.navigate("days")
  }

  const renderView = (mode, renderDefault) => {
    // Only for years, months and days view
    if (mode === "time") return renderDefault()

    return (
      <div className="wrapper">
        {renderDefault()}
        {/* <div className="controls text-center mt-2">
          <button className="btn btn-primary today" onClick={e => goToToday(e)}>
            Today
          </button>
        </div> */}
      </div>
    )
  }
  const isVaildClass =
    isRequired &&
    hasError &&
    hasError(formName, name, "required") &&
    "is-invalid"

  return (
    <div
      className={classnames(
        { "has-changed": isChanged && detected },
        "input-wrapper"
      )}
    >
      <div className="label-group-relative position-relative">
        {label && (
          <Label for={name}>
            {label}
            {isRequired && <span className="text-danger">*</span>}&nbsp;
          </Label>
        )}

        <RollbackButton
          display={isChanged && detected}
          onClick={onUndoHandler}
        />
      </div>
      <div style={{ position: 'relative' }}>
        <Datetime
          ref={inputEl}
          inputProps={{
            className: "form-control " + isVaildClass,
            disabled: disabled,
            readOnly: readOnly,
            name: name,
            required: true,
            placeholder: tooltip,
          }}
          name={name}
          value={selectedDate || undefined}
          dateFormat={format || "dd-mm-yy"}
          closeOnSelect={true}
          timeFormat={false}
          onChange={val => onChangeHandler(name, val._d)}
          renderView={(mode, renderDefault) => renderView(mode, renderDefault)}
        />

        <div className="remove-input-indicatorContainer" onClick={() => {
        }}>
          {showRemove && selectedDate && !disabled &&
            <div onClick={() => {
              setSelectedDate(undefined)
            }}>
              <svg height="20" width="20" viewBox="0 0 20 20" className="css-tj5bde-Svg">
                <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
              </svg>
            </div>
          }
        </div>
      </div>

      <div className="d-none">
        <AvField
          name={name}
          type="text"
          value={convertDateFormat(selectedDate, format || "dd-mm-yy") || undefined}
        />
      </div>
    </div>
  )
}

CustomDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  disabled: PropTypes.bool, // ...prop type definitions here
  onChange: PropTypes.func.isRequired, // ...prop type definitions here
  isRequired: PropTypes.bool, // ...prop
  hasError: PropTypes.func, // ...prop
  detected: PropTypes.bool,
  label: PropTypes.any,
  format: PropTypes.any,
}

CustomDatePicker.defaultProps = {
  // date: null,
  isRequired: false,
  onChange: () => { },
}
export default CustomDatePicker
