import { Check, CustomButton } from "components/Common"
import { permissionType } from "constant"
import PropTypes from "prop-types"

import { withTranslation } from "react-i18next"

const HeaderButtons = ({ resource, onCalculate, t, onApply, lottests, isCalculation }) => {
  return (
    <>
      <Check permission={permissionType.Apply} resource={resource}>
        <CustomButton color="warning" onClick={onApply} disabled={!isCalculation} outline>
          {t("Apply")}
        </CustomButton>
      </Check>
      <Check permission={permissionType.Calculate} resource={resource}>
      <CustomButton color="primary" onClick={onCalculate} disabled={lottests?.length == 0} outline>
          {t("Calculate")}
        </CustomButton>
      </Check>
    </>
  )
}

HeaderButtons.propTypes = {
  resource: PropTypes.string.isRequired,
  onAddConfiglotQCModalClick: PropTypes.func.isRequired,
  onUpdateValidClick: PropTypes.func.isRequired,
  onUpdateInvalidClick: PropTypes.func.isRequired,
  // onDeleteClick: PropTypes.func.isRequired,
}

export default withTranslation(["calculationQCPage", "common"])(HeaderButtons)
