import i18n from "i18next"
import { call, put, select, takeEvery } from "redux-saga/effects"

import {
    GET_QC_DATAPOINTS,
    GET_QC_DATAPOINTS_POSNEG,
    GET_TEST_FOR_CHART
} from "./actionTypes"

import {
    getQCDatapointsFail,
    getQCDatapointsSuccess,
    getQCDatapointsPosNegFail,
    getQCDatapointsPosNegSuccess,
    getTestForChartFail,
    getTestForChartSuccess
} from "./actions"

import { GetQCDatapoints, GetQCDatapointsPosNeg, GetTestForChart } from "helpers/app-backend/IQC/QCDatapoints_backend_helper"
const t = (msg, param) => i18n.t(msg, param)

function* fetchQCDatapoints({ payload,callback }) {
    try {
        let response = yield call(GetQCDatapoints, payload)
        yield put(getQCDatapointsSuccess(response))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(getQCDatapointsFail(error))
    }
}

function* fetchQCDatapointsPosNeg({ payload,callback }) {
    try {
        let response = yield call(GetQCDatapointsPosNeg, payload)
        yield put(getQCDatapointsPosNegSuccess(response))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(getQCDatapointsPosNegFail(error))
    }
}

function* fetchTestForChart({ payload,callback }) {
    try {
        let response = yield call(GetTestForChart, payload)
        yield put(getTestForChartSuccess(response))
        callback && callback()
    } catch (error) {
        console.log(error)
        yield put(getTestForChartFail(error))
    }
}

function* qcDatapointsSaga() {
    yield takeEvery(GET_QC_DATAPOINTS, fetchQCDatapoints)   
    yield takeEvery(GET_QC_DATAPOINTS_POSNEG, fetchQCDatapointsPosNeg)   
    yield takeEvery(GET_TEST_FOR_CHART, fetchTestForChart)   
}

export default qcDatapointsSaga