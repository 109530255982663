import { spreadSearchQuery } from "helpers/utilities"
import { get, put } from "../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_MASTER_SETUP_ENDPOINT}/personal-setting`

const getPersonalSetting = data => {
  let q = spreadSearchQuery(data)
  return get(`${BASE_API_URL}/GetPersonalSettingList?${q}`)
}

const getSettingField = (companyId) => {
  return get(`${BASE_API_URL}/GetPersonalSettingFieldList/${companyId}`)
}

const updatePersonalSetting = req => {
  const { companyId } = req
  return put(`${BASE_API_URL}/${companyId}`, req)
}

const getClientIp = () => {
  return get(`${BASE_API_URL}/get-client-ip`)
}

export {
  getClientIp,
  getPersonalSetting,
  getSettingField,
  updatePersonalSetting
}

