import { LabelAndValue, TitleAndInformation } from "components/Common"
import { ModuleIds } from "constant"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Col, Row } from "reactstrap"

const RESOURCE = ModuleIds.Instrument

const TitleAndInfo = ({ onEdit, onClone, data, t, countTest, ...rest }) => {
    return (
        <TitleAndInformation
            onEdit={onEdit}
            onClone={onClone}
            resource={RESOURCE}
            {...rest}
        >
            <Row>
                <Col xs="12" md="12">
                    <Row>
                        <Col md="auto" className="pr-6">
                            <LabelAndValue label={t("Id")} value={data.id} />
                        </Col>
                        <Col md="auto" className="pr-6">
                            <LabelAndValue
                                label={t("common:Code")}
                                value={data.manageCode}
                            />
                        </Col>
                        <Col md="auto" >
                            <LabelAndValue
                                label={t("Number of Tests")}
                                value={countTest}
                                className={"fw-bold"}
                            />
                        </Col>
                        <Col md="auto" className="d-flex justify-content-end ms-auto">
                            <i className="bx bx-transfer-alt" style={{ fontSize: '48px', color: '#adb5bd' }}></i>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </TitleAndInformation>
    )
}

TitleAndInfo.propTypes = {
    onEdit: PropTypes.func,
    onClone: PropTypes.func,
    parameter: PropTypes.object,
    t: PropTypes.any,
}

const mapStateToProps = ({ machine }) => {
    return {
        countTest: machine.countTest || 0,
    }
}

const mapDispatchToProps = dispatch => ({
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["machinePage", "common"])(TitleAndInfo)))