import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { AvField } from "availity-reactstrap-validation"
import { Label } from "reactstrap"
import classnames from "classnames"

import RollbackButton from "./RollbackButton"
import { useDetectedChanges } from "helpers/hooks"

const CustomAvField = ({
  label,
  name,
  required,
  value,
  detected,
  id,
  className,
  onUndoClick,
  onChange,
  helpMessage,
  errorMessage,
  disabled,
  ...rest
}) => {
  const [defaultValue, setDefaultValue] = useState()
  const [valueInput, isChanged] = useDetectedChanges(value, defaultValue, name)

  const onChangeHandler = (e, value) => {
    const inputValue = value
    setDefaultValue(inputValue)
    if (onChange) {
      onChange(value)
    }
  }

  const onUndoHandler = () => {
    setDefaultValue(valueInput)
  }

  const onFocusHandler = event => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "new-password"
    }
  }

  useEffect(() => {
    if (onUndoClick) onUndoClick()
  }, [defaultValue])


  useEffect(() => {
    setDefaultValue(value)
  }, [value])
  return (
    <>
      <div className="label-group-relative position-relative">
        {label && (
          <Label for={name}>
            {label}
            {required && <span className="text-danger">*</span>}
          </Label>
        )}

        <RollbackButton
          onClick={onUndoHandler}
          display={isChanged && detected}
        />
      </div>
      <AvField
        name={name}
        id={id}
        value={defaultValue}
        onChange={onChangeHandler}
        onFocus={onFocusHandler}
        className={classnames(
          { "has-changed": isChanged && detected },
          className
        )}
        errorMessage={errorMessage}
        helpMessage={helpMessage}
        required={required}
        disabled={disabled}
        {...rest}
      />
    </>
  )
}

CustomAvField.propTypes = {
  label: PropTypes.any,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  required: PropTypes.bool,
  detected: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool
}

CustomAvField.defaultProps = {
  detected: false,
  className: "",
  disabled: false
}

export default CustomAvField
