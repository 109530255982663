import React from "react"
import PropTypes from "prop-types"
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"

const CustomErrorModal = ({
  modal,
  title = 'Error details',
  onToggle,
  classHeader = 'bg-danger',
  msg,
  titleFooter
}) => {

  const renderErrorBody = () => {
    return <Row>
      <Col className="col-12">
        <div className="mb-3">
          <p className="font-size-18" dangerouslySetInnerHTML={{ __html: msg }}></p>
        </div>
      </Col>
    </Row>

  }

  return (
    <Modal
      centered={true}
      scrollable={true}
      size="lg"
      isOpen={modal}
    >
      <ModalHeader
        className={classHeader}
        toggle={onToggle}
        tag="h5"
      >
        {title}
      </ModalHeader>
      <ModalBody>
        {renderErrorBody()}
      </ModalBody>
      <ModalFooter>
        <button
          type="button"
          onClick={onToggle}
          className="btn btn-secondary"
          data-dismiss="modal"
        >
          {titleFooter}
        </button>
      </ModalFooter>
    </Modal>
  )
}

CustomErrorModal.propTypes = {
  onToggle: PropTypes.func,
}

CustomErrorModal.defaultProps = {
  onToggle: () => { },
}

export default CustomErrorModal
