import { AvForm } from "availity-reactstrap-validation"
import {
  Accordion,
  AccordionWrapper,
  CustomAvField,
  CustomButton,
  CustomButtonGroup,
  CustomSelect,
  CustomSelectAsync
} from "components/Common"
import FormSwitchForm from "components/Common/FormSwitchForm"
import { parameterCode } from "constant/utility"
import { getInvalidMessageI18n } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import { Col, Row } from "reactstrap"

const EditMode = ({ onValidSubmit, data, onCancel, t, isEdit }) => {
    return (
      <Row>
        <Col sm="12">
          <AvForm onValidSubmit={onValidSubmit} model={data}>
            <Row className="px-3">
              <Col sm="6">
                <div className="mb-3">
                  <div className="d-none">
                    <CustomAvField
                      name="id"
                      type="hidden"
                      value={data.id || ""}
                    />
                  </div>
                  <CustomAvField
                    name="manageCode"
                    type="text"
                    required
                    errorMessage={getInvalidMessageI18n(t, "Manage Code")}
                    validate={{
                      required: { value: true },
                    }}
                    value={data.manageCode || ""}
                    label={t("Manage Code")}
                    detected={isEdit}
                    maxLength={20}
                  />
                </div>
              </Col>
              <Col xs="6">
                <div className="mb-3">
                  <CustomAvField
                    name="instrumentID"
                    type="number"
                    value={data.instrumentID || 0}
                    label={t("common:Instrument ID")}
                    detected={isEdit}
                    required
                    validate={{
                      required: { value: true },
                    }}
                    errorMessage={getInvalidMessageI18n(
                      t,
                      "common:Instrument ID"
                    )}
                  />
                </div>
              </Col>
            </Row>

            <Row className="px-3">
              <Col sm="6">
                <div className="mb-3">
                  <CustomAvField
                    name="name"
                    type="text"
                    value={data.name || ""}
                    label={t("common:Name")}
                    detected={isEdit}
                    maxLength={100}
                    required
                    validate={{
                      required: { value: true },
                    }}
                    errorMessage={getInvalidMessageI18n(t, "common:Name")}
                  />
                </div>
              </Col>
              <Col xs="6">
                <div className="mb-3">
                  <CustomSelect
                    name="connectionType"
                    value={data.connectionType || ""}
                    code={parameterCode.CONNECTION_TYPE}
                    label={t("Connection Type")}
                    detected={isEdit}
                  />
                </div>
              </Col>
            </Row>

            <Row className="px-3">
              <Col xs="6">
                <div className="mb-3">
                  <CustomSelect
                    name="protocol"
                    value={data.protocol || ""}
                    code={parameterCode.PROTOCOL}
                    label={t("Protocol")}
                    detected={isEdit}
                  />
                </div>
              </Col>
              <Col xs="6">
                <div className="mb-3">
                  <CustomAvField
                    name="remark"
                    value={data.remark || ""}
                    label={t("common:Remark")}
                    detected={isEdit}
                    type="textarea"
                    rows="3"
                    maxLength={200}
                  />
                </div>
              </Col>
              <Col xs="6">
                <div className="mb-3">
                  <FormSwitchForm
                    name="statuss"
                    label={`${t("common:Active")}: `}
                    value={data.status ?? true}
                  />
                </div>
              </Col>
            </Row>
            <AccordionWrapper defaultTab={"1"}>
              <Accordion tabId={"1"} title={t("Integration Setting")}>
                <Row className="px-3">
                  <Col xs="6">
                    <Col xs="6">
                      <div className="mb-3">
                        <CustomSelect
                          name="companyId"
                          value={data.companyId || ""}
                          code={parameterCode.COMPANIES}
                          label={t("common:Company")}
                          detected={isEdit}
                          required
                          errorMessage={getInvalidMessageI18n(
                            t,
                            "common:Company"
                          )}
                          validate={{
                            required: { value: true },
                          }}
                        />
                      </div>
                    </Col>
                  </Col>
                  <Col xs="6">
                    <div className="mb-3">
                      <CustomSelectAsync
                        name="mappingKey"
                        value={data.mappingKey || ""}
                        valueName={data.mappingKeyName || ""}
                        code={parameterCode.ALL_PARAMETER}
                        group={parameterCode.MAPPING_CODE}
                        isDependent={true}
                        label={t("Mapping code")}
                        detected={isEdit}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="px-3 mt-3">
                  <Col xs="6">
                    <div className="mb-3">
                      <FormSwitchForm
                        name="allowReceiveWarning"
                        label={`${t("Receive Warnings")} ?`}
                        value={data.allowReceiveWarning}
                      />
                    </div>
                  </Col>
                  <Col xs="6">
                    <div className="mb-3">
                      <FormSwitchForm
                        name="allowSelfVaild"
                        label={`${t("Auto Valid")} ?`}
                        value={data.allowSelfVaild}
                      />
                    </div>
                  </Col>
                </Row>
              </Accordion>
            </AccordionWrapper>
            <Row>
              <Col>
                <CustomButtonGroup className="justify-content-center">
                  <CustomButton
                    text={t("common:Cancel")}
                    type="button"
                    onClick={onCancel}
                    className="button-width"
                  />
                  <CustomButton
                    text={t("common:Save")}
                    type="submit"
                    color="primary"
                    className="save-user button-width"
                    isEdit
                    isModal
                  />
                </CustomButtonGroup>
              </Col>
            </Row>
          </AvForm>
        </Col>
      </Row>
    )
}

export default withTranslation(["machinePage", "common", "message"])(EditMode)
