import {
    convertDateFormat,
    insertUrlParam
} from "helpers/utilities"
import PropTypes from "prop-types"
import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import {
    TitleAndTable,
    WarningModal
} from "components/Common"
import { selectCheckboxHandler } from "helpers/utilities"
import { ModuleIds } from "constant"
import { withTranslation } from "react-i18next"
import { getQcResults, resetSearchQuery } from "store/analysis-process/qcresult/actions"
import QCResultTable from "./QCResultTable"
import HeaderButton from "./HeaderButton"
import {
    updateValidResultSIDQC
} from "store/analysis-process/validresults/actions"
import ChangeSIDQCModal from "../Modals/ChangeSIDQCModal"

const RESOURCE = ModuleIds.AnalyticalProcess

const QCResult = ({
    history,
    tests,
    paging,
    searchQuery,
    onChangeSIDQC,
    onGetQCResults,
    onResetQCResultSearchQuery,
    loadingTests,
    updatedTestTime,
    t,
}) => {
    const [model, setModel] = useState({
        search: "",
        page: 1,
        size: 10,
        fromDate: convertDateFormat(new Date(), "YYYY-MM-DD 00:00:00"),
        toDate: convertDateFormat(new Date(), "YYYY-MM-DD HH:mm:ss"),
    })
    const [isSelected, setIsSelected] = useState(false)
    const [checkSIDQC, setCheckSIDQC] = useState()
    const [row, setRow] = useState(false)
    const [rows, setRows] = useState([])
    const [warningModal, setWarningModal] = useState(false)
    const [modalQC, setModalQC] = useState(false)
    const [isChangeSIDQC, setIsChangeSIDQC] = useState()
    const resetState = () => {
        setRow({})
        setRows([])
    }

    useEffect(() => {
        setModel(prevModel => ({
            ...prevModel,
            toDate: convertDateFormat(new Date(), "YYYY-MM-DD HH:mm:ss"),
        }));
    }, []);

    const [tableKey, setTableKey] = useState(0);

    useEffect(() => {
        setTableKey(prevKey => prevKey + 1);
    }, [tests]);

    useEffect(() => {
        let checkSID = new Set(rows.map(item => item.qcsid)).size > 1;
        let checkInsID = new Set(rows.map(item => item.instrumentId)).size > 1;
        setIsChangeSIDQC(checkSID || checkInsID);
    }, [rows])

    const formEl = useRef(null)

    const onSelectCheckbox = (row, isSelected) => {
        const { rowsState, currentRow } = selectCheckboxHandler(
            rows,
            row,
            isSelected
        )
        setRows(rowsState)
        setRow(currentRow)
    }

    const toggleSIDQC = (isSIDQC) => {
        setCheckSIDQC(isSIDQC)
        setModalQC(prev => !prev)
    }

    const handleValidSubmitSIDQC = values => {
        const newObjData = { ...values }
        if (checkSIDQC) {
            newObjData.SIDQC = values.currentSID
            newObjData.currentSID = 0;
            newObjData.isSID_QC = checkSIDQC;
        } else {
            newObjData.SIDQC = ""
            newObjData.currentSID = Number(values.currentSID)
            newObjData.isSID_QC = checkSIDQC
        }
        onChangeSIDQC({ data: newObjData, callback: afterUpdateSIDQC })
    }

    const afterUpdateSIDQC = () => {
        onRefreshHandler()
        toggleSIDQC()
    }

    const onSelectAllCheckbox = rows => {
        setRows(rows)
        if (rows.length < 1) setRow({})
        else setRow(rows[rows.length - 1])
    }

    const onGetQCReusultList = payload => {
        insertUrlParam(payload)
        onGetQCResults(payload)
    }

    const fetchTests = () => {
        onGetQCReusultList({ ...model, sort: "requestDate:desc,createdDate:desc" })
    }

    const onRefreshHandler = () => {
        resetState()
        fetchTests()
    }

    const onSearch = searchText => {
        let val = {
            search: searchText || ''
        }
        setModel({ ...model, ...val })
        onGetQCReusultList({ ...model, ...val, page: 1, size: 10 })
    }

    const onSizePerPageChange = size => {
        setModel({ ...model })
        onGetQCReusultList({ ...model, page: 1, size })
    }

    const onPageChange = page => {
        setModel({ ...model })
        onGetQCReusultList({ ...model, page })
    }

    const onSubmitFilter = values => {
        onGetQCReusultList({ page: 1, ...values })
    }

    const onSortHandler = (field, order) => {
        const sortString = `${field}:${order}`
        onGetQCReusultList({ page: 1, sort: sortString })
    }

    useEffect(() => {
        onResetQCResultSearchQuery()
    }, [])

    useEffect(() => {
        fetchTests()
    }, [])

    return (
        <React.Fragment>
            <TitleAndTable
                table={() => (
                    <QCResultTable
                        key={tableKey}
                        tests={tests}
                        onSelect={onSelectCheckbox}
                        onSelectAll={onSelectAllCheckbox}
                        onSearch={onSearch}
                        onSort={onSortHandler}
                        onRefresh={onRefreshHandler}
                        isSelected={isSelected}
                        onPageChange={onPageChange}
                        paging={paging}
                        onSizePerPageChange={onSizePerPageChange}
                        onSubmitFilter={onSubmitFilter}
                        onEdit={() => { }}
                        onDelete={() => { }}
                        loading={loadingTests}
                        updatedTime={updatedTestTime}
                        onChangeModel={val => {
                            setModel({ ...model, ...val })
                        }}
                    />
                )}
                buttons={() => (
                    <HeaderButton
                        resource={RESOURCE}
                        // onChangeSIDClick={""}
                        onChangeSIDQCClick={(isSIDQC) => toggleSIDQC(isSIDQC)}
                        // onDeleteClick={onDeleteToggleHandler}
                        // isStatusUpdating={isStatusUpdating}
                        model={{}}
                        data={row}
                        isChangeSIDQC={isChangeSIDQC}
                        allData={rows}
                    />
                )}
                external
                resource={RESOURCE}
                onEdit={() => { }}
                onDelete={() => { }}
                onClone={() => { }}
                title={t("Analytical Process")}
                subtitle={t("QC Results List")}
            />
            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={t("message:SelectRowWarning")}
            />
            <ChangeSIDQCModal
                formEl={formEl}
                modal={modalQC}
                isEdit={false}
                onValidSubmit={handleValidSubmitSIDQC}
                toggle={toggleSIDQC}
                data={row}
                allData={rows}
            // isSIDQC={checkSIDQC}
            />
        </React.Fragment>
    )
}

QCResult.propTypes = {
    tests: PropTypes.array,
    paging: PropTypes.object,
    onGetQCResults: PropTypes.func,
    onResetQCResultSearchQuery: PropTypes.func,
    onGetTestDetail: PropTypes.func,
    test: PropTypes.object,
    loadingTests: PropTypes.bool,
    updatedTestTime: PropTypes.any,
    t: PropTypes.any,
}

const mapStateToProps = ({ qcResult }) => ({
    tests: qcResult.tests,
    test: qcResult.test,
    paging: qcResult.paging,
    loadingTests: qcResult.loadingTests,
    updatedTestTime: qcResult.updatedTime,
    searchQuery: qcResult.searchQuery,
})

const mapDispatchToProps = dispatch => ({
    onGetQCResults: payload => dispatch(getQcResults(payload)),
    onResetQCResultSearchQuery: () => dispatch(resetSearchQuery()),
    onChangeSIDQC: payload => dispatch(updateValidResultSIDQC(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["qcResultPage", "message"])(QCResult)))
