import {
  GET_COMMON_CODES,
  GET_COMMON_CODES_FAIL,
  GET_COMMON_CODES_SUCCESS,
  GET_COMMON_COMPANIES,
  GET_COMMON_COMPANIES_FAIL,
  GET_COMMON_COMPANIES_SUCCESS,
  GET_COMMON_DEPARTMENTS,
  GET_COMMON_DEPARTMENTS_FAIL,
  GET_COMMON_DEPARTMENTS_SUCCESS,
  SET_COMMON_UPDATING,
  SET_COMMON_UPDATING_SUCCESS,
  SET_COMMON_UPDATING_FAIL,
  DISPLAY_ERRORS,
  CLEAR_ERRORS,
  SET_COMMON_FORBIDDEN_ERROR,
  GET_LANGUAGE_CODES,
  GET_LANGUAGE_CODES_FAIL,
  GET_LANGUAGE_CODES_SUCCESS,
  SET_ERRORS,
  SET_RULE_ERRORS,
  CLEAR_RULE_ERRORS,
  SET_SID_LENGTH,
  SET_FIELD_CHANGE,
} from "./actionTypes"

const INIT_STATE = {
  codes: [],
  languages: [],
  companies: [],
  departments: [],
  loading: false,
  updating: false,
  error: {},
  errorCommon: {},
  isForbidden: false,
  ruleError: {},
  sidLength: 10,
  fieldChange: []
}

const CommonCodes = (state = INIT_STATE, action) => {
  switch (action.type) {
    // CODES
    case GET_COMMON_CODES:
      return {
        ...state,
        loading: true,
      }

    case GET_COMMON_CODES_SUCCESS:
      return {
        ...state,
        codes: action.payload,
        loading: false,
      }

    case GET_COMMON_CODES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    // COMPANIES
    case GET_COMMON_COMPANIES:
      return {
        ...state,
        companies: [],
        loading: true,
      }

    case GET_COMMON_COMPANIES_SUCCESS:
      const { data: companies } = action.payload
      return {
        ...state,
        companies,
        loading: false,
      }

    case GET_COMMON_COMPANIES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    // DEPARTMENTS
    case GET_COMMON_DEPARTMENTS:
      return {
        ...state,
        departments: [],
        loading: true,
      }

    case GET_COMMON_DEPARTMENTS_SUCCESS:
      const { data: departments } = action.payload
      return {
        ...state,
        departments,
        loading: false,
      }

    case GET_COMMON_DEPARTMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    // UPDATING STATE
    case SET_COMMON_UPDATING:
      return {
        ...state,
        updating: true,
      }

    case SET_COMMON_UPDATING_SUCCESS:
      return {
        ...state,
        updating: false,
      }

    case SET_COMMON_UPDATING_FAIL:
      return {
        ...state,
        error: action.payload,
        updating: false,
      }

    // DISPLAY ERRORS
    case DISPLAY_ERRORS:
      return {
        ...state,
        errorCommon: action.payload,
      }

    // CLEAR ERRORS
    case CLEAR_ERRORS:
      return {
        ...state,
        errorCommon: {},
      }
    case SET_RULE_ERRORS:
      return {
        ...state,
        ruleError: action.payload,
      }
    case CLEAR_RULE_ERRORS:
      return {
        ...state,
        ruleError: {},
      }
    // SET FORBIDDEN
    case SET_COMMON_FORBIDDEN_ERROR:
      return {
        ...state,
        isForbidden: action.payload.isForbidden,
      }

    // LANGUAGE CODES
    case GET_LANGUAGE_CODES:
      return {
        ...state,
        loading: true,
      }

    case GET_LANGUAGE_CODES_SUCCESS:
      return {
        ...state,
        languages: action.payload,
        loading: false,
      }

    case GET_LANGUAGE_CODES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case SET_SID_LENGTH:
      return {
        ...state,
        sidLength: action.payload,
      }

    case SET_FIELD_CHANGE:
      return {
        ...state,
        fieldChange: JSON.parse(JSON.stringify(action.payload)),
      }
    default:
      return state
  }
}

export default CommonCodes
