import { AvForm } from "availity-reactstrap-validation"
import { Check, CustomBootstrapTable } from "components/Common"
import FormSwitchForm from "components/Common/FormSwitchForm"
import IconStatus from "components/Common/IconStatus"
import { ModuleIds, permissionType, statusTypes } from "constant"
import { indexCalculator } from "helpers/utilities"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import { CustomButton } from "components/Common"

const RESOURCE = ModuleIds.MachineTestiQC

const MachineTable = ({
  machines,
  onSearch,
  onSort,
  onRefresh,
  onPageChange,
  paging,
  onSizePerPageChange,
  onSubmitFilter,
  loading,
  onQCChange,
  onChangeSIDQC,
  t,
  updatedTime,
  userPermissions,
  onExportExcel
}) => {
  const [data, setData] = useState([])
  const [model, setModel] = useState({
    search: "",
  })

  const searchQuery = useSelector(state => state.machine.searchQuery)

  const checkPermission = (permission, resource) => {
    const userPermission = userPermissions[resource]?.permissions || []
    return userPermission.includes(permission)
  }
  const onResetHandler = () => {
    const initModel = {
      search: "",
    }
    setModel(initModel)
  }

  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])

  useEffect(() => {
    setData(machines)
  }, [machines])

  const columns = [
    {
      dataField: "id",
      text: "#",
      style: { width: 50 },
      formatter: (cellContent, machine, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "insID",
      text: t("common:Instrument Id"),
      align: "center",
      headerAlign: "center",
    },
    {
      dataField: "name",
      text: t("common:Name"),
      sort: true,
    },
    {
      dataField: "manageCode",
      text: t("Manage Code"),
      sort: true,
    },
    {
      dataField: "remark",
      text: t("common:Description"),
      sort: true,
    },
    {
      dataField: "isChangeSIDQC",
      text: t("Auto SID Change"),
      formatter: (cellContent, instrument, index) => {
        let hasPermission = checkPermission(permissionType.AutoSIDChange, RESOURCE);
        let isDisabled = !instrument.instrumentQC || !hasPermission;
    
        return (
          <AvForm>
            <FormSwitchForm
              name="isChangeSIDQC"
              label=""
              value={!!cellContent}
              onChange={(value) => { onChangeSIDQC(instrument, value); }}
              disabled={isDisabled}
            />
          </AvForm>
        );
      },
    },    
    {
      dataField: "instrumentQC",
      text: t("QC"),
      formatter: (cellContent, instrument, index) => {
        let hasPermission = checkPermission(permissionType.U, RESOURCE);
        if (!hasPermission) {
          hasPermission = true;
        } else {
          hasPermission = false;
        }
        return (
          <AvForm>
            <FormSwitchForm
              name="instrumentQC"
              label=""
              value={!!cellContent}
              onChange={(value) => { onQCChange(instrument, value); }}
              disabled={hasPermission}
            />
          </AvForm>
        );
      },
    },
    {
      dataField: "status",
      text: t("common:Status"),
      sort: true,
      align: "center",
      headerAlign: "center",
      formatter: (cellContent, user, index) => {
        return (
          <IconStatus
            isRound={true}
            type={cellContent ? statusTypes.ACTIVE : statusTypes.INACTIVE}
          />
        )
      },
    },
  ]

  return (
    <CustomBootstrapTable
      columns={columns}
      search
      data={data}
      paging={paging}
      onSearch={onSearch}
      onSort={onSort}
      onRefresh={onRefresh}
      // filterForm={() =>
      //   <FilterForm model={model} />
      // }
      onReset={onResetHandler}
      onPageChange={onPageChange}
      onSizePerPageChange={onSizePerPageChange}
      onSubmitFilter={onSubmitFilter}
      searchText={model.search}
      loading={loading}
      updatedTime={updatedTime}
      resource={RESOURCE}
      isImportExportButton={true}
      importExportForm={() =>
        <div className="tooltip-custom">
          <CustomButton
            isEdit
            color="secondary"
            outline
            onClick={onExportExcel}
          >
            <i className={"fas fa-download"}></i>
          </CustomButton>
          <span className="tooltiptext-custom-button-icon-left">
            {t("common:Export All")}
          </span>
        </div>
      }
    />
  )
}

const mapStateToProps = ({ Authorization }) => {
  return {
    userPermissions: Authorization.permissions,
  }
}

export default connect(
  mapStateToProps,
  null
)(withTranslation(["machinePage", "common"])(MachineTable))
