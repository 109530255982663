import { AvForm } from "availity-reactstrap-validation"

import { ModalBody, ModalFooter } from "reactstrap"

import {
  CustomButton,
  CustomModal
} from "components/Common"
import { ModuleIds } from "constant/utility"

//i18n
import CustomBootstrapTable from "components/Common/CustomTable/CustomBootstrapTable"
import FormSwitchForm from "components/Common/FormSwitchForm"
import { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { getVSOrganismsInGroup } from "helpers/app-backend"
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"

const RESOURCE = ModuleIds.MicroorganismList;
let isChange = false;
const AddOrganismModal = ({
  modal,
  toggle,
  onValidSubmit,
  data,
  t,
}) => {

  const formEl = useRef()
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    if (data.id) {
      getDataSource(data.id)
    }
  }, [data])


  const getDataSource = async () => {
    setLoading(true)
    let res = await getVSOrganismsInGroup(data.id)
    setDataSource(res || [])
    setLoading(false)
  }
  const changeAdd = (id, value) => {
    let tmp = dataSource
    let item = tmp.find(x => x.id == id)
    if (item != null) {
      item.isAdded = value
      setDataSource([...tmp])
    }
  }
  const title = t("Add Organism List")
  const columns = [
    {
      dataField: "code",
      text: t("Code"),
      style: { width: 150 },
      formatter: (cellContent, row) => {
        return <span style={{ color: (row.subCodes && row.subCodes.length > 0) ? '#556ee6' : '' }}>{cellContent}</span>
      }
    },
    {
      dataField: "shortName",
      text: t("Short"),
      style: { width: 100 },
      formatter: (cellContent, row) => {
        return <span>{cellContent}</span>
      }
    },
    {
      dataField: "name",
      text: t("Organisms Name"),
      // style: { width: 300 },
      formatter: (cellContent, row) => {
        return <span>{cellContent}</span>
      }
    },
    {
      dataField: "isAdd",
      text: t("Add Organism"),
      style: { width: 200 },
      formatter: (cellContent, row) => {
        return <FormSwitchForm
          name="isAdd"
          label=''
          value={row.isAdded == true}
          onChange={(val) => {
            changeAdd(row.id, val)
          }}
        />
      }
    },
  ]

  const getDataDisplay = () => {
    let tmp = dataSource
    tmp = tmp.filter(x =>
      x.name.toLocaleLowerCase().includes(search) || x.code.toLocaleLowerCase().includes(search) || x.shortName.toLocaleLowerCase().includes(search)
    )
    return tmp
  }
  return (
    <CustomModal modal={modal} title={title} onToggle={() => { toggle() }}>
      <ModalBody>
        <AvForm
          ref={formEl}
          id="machineForm"
          onValidSubmit={(e, values) => {
            let dt = dataSource.filter(x => x.isAdded == true).map(x => x.id).toString()
            onValidSubmit(dt)
          }}
          model={data}
        >
          <CustomBootstrapTableAsync
            columns={columns}
            search
            data={getDataDisplay() || []}
            paging={{
              page: 1,
              dataSize: dataSource.length,
              size: dataSource.length,
              totalPages: dataSource.length / 30
            }}
            // paging={paging}
            // onSelect={onSelect}
            // onSelectAll={onSelectAll}
            onSearch={(val) => {
              setSearch(val || '')
            }}
            onRefresh={() => {
              if (data.id) {
                getDataSource(data.id)
              }
            }}
            onReset={() => { }}
            searchText={''}
            loading={loading}
            updatedTime={new Date()}
            resource={RESOURCE}
            isEnableExport={false}
            showSelectRow={false}
          />

        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={() => {
            toggle()
          }}
          data-dismiss="modal"
          className="button-width"
        />

        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          disabled={dataSource.length == 0}
          onClick={() => {
            formEl?.current?.submit()
          }}
          className="save-user button-width"
          isEdit
        />
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["organismsPage", "common"])(AddOrganismModal)
